import {Component, OnInit, OnDestroy, AfterViewInit, ViewEncapsulation} from '@angular/core';
import {WindowModule} from 'smart-webcomponents-angular/window';
import {ButtonModule} from "smart-webcomponents-angular/button";
import {BrowserModule} from "@angular/platform-browser";
import {ActivatedRoute, Router} from "@angular/router";
import {HouseService} from "../services/house.service";
import {CommonModule} from "@angular/common";

@Component({
    selector: 'create-owner',
    templateUrl: 'create-owner.component.html',
    providers: [BrowserModule, ButtonModule, WindowModule, CommonModule],
    styleUrls: ['create-owner.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class CreateOwnerComponent implements AfterViewInit, OnDestroy, OnInit {


    constructor(
        public houseService: HouseService,
        private router: Router,
        private activeRouter: ActivatedRoute // Отображает текущий роут компонента
    ) {
        Object.assign(CreateOwnerComponent, {
            HouseService,
        });
    }

    ngAfterViewInit(): void {
    }

    ngOnDestroy(): void {
    }

    // Метод вызывается когда инициализируется компонент
    ngOnInit(): void {

    }

}
