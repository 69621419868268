import {Component, OnInit} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {StorageService} from '../services/storage.service';
import {Router} from "@angular/router";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})

/**
 * Компонент входа также использует AuthService для работы с объектом Observable.
 * Кроме того, он вызывает методы StorageService для проверки статуса входа в систему и сохранения информации о пользователе в хранилище сеансов.
 */
export class LoginComponent implements OnInit {
    form: any = {
        username: null,
        password: null
    };
    isSignUpFailed = true; // Изначально юзер считается не зарегистрированным
    isLoggedIn = false; // Есть ли session storage access_token пользователя
    isLoginFailed = false;
    errorMessage = '';
    authMessage = ''; // Сообщения по регистрации которые летят из RegisterComponent
    roles: string[] = [];

    constructor(
        private router: Router,
        private authService: AuthService,
        private storageService: StorageService
    ) {

        // Сразу узнаем прошел ли пользователь регистрацию или нет, так как после прохождение регистрации в state параметр в
        // компоненте RegisterComponent передается true что регистрация пройдена
        console.log(this.authMessage + "f");
        if(this.isSignUpFailed !== undefined && this.router.getCurrentNavigation().extras.state !== undefined) {
            console.log('Проверяем регистрацию');
            // Получаем из state сообщение и выводим его
            if(this.router.getCurrentNavigation().extras.state.isSignUpFaile !== undefined) {
                this.isSignUpFailed = this.router.getCurrentNavigation().extras.state.isSignUpFailed ?? false
                this.authMessage = this.router.getCurrentNavigation().extras.state.authMessage
            }
        }

        console.log("isLoggedIn " + this.isLoggedIn)
        console.log("isLoginFailed " + this.isLoginFailed)
        console.log("isSignUpFailed " + this.isSignUpFailed)
    }

    ngOnInit(): void {
        if (this.storageService.isLoggedIn()) {
            this.isLoggedIn = true;
            this.roles = this.storageService.getUser().roles;
            this.router.navigate(['/'],{ state: {isSignUpFailed: false } }).then(_ => {});
        }
    }

    /**
     * Метод срабатывает при отправке формы авторизации при входе пользователя в систему
     * На данный момент он уже по идее должен быть зареган если его нет пользователя в бд, то переправляем его на страницу регистрации
     * В данный момент на бэке авторизуется пользователь и ему выдается и бэк возвращает токен авторизации для дальнейшего выполнения всех запросов
     * токен авторизации сохраняется в localstorage
     */
    onSubmit(): void {
        const {username, password} = this.form;
        this.authMessage = '';
        this.authService.login(username, password).subscribe({
            next: data => {
                this.storageService.saveUser(data);
                this.isLoginFailed = false;
                this.isLoggedIn = true;
                this.roles = this.storageService.getUser().roles;
                // Перезагружаем страницу
                this.reloadPage();
            },
            error: err => {
                this.errorMessage = err.error.message;
                this.isLoginFailed = true;
            }
        });
    }

    /**
     * Перезагружаем страницу с паузой 2500
     */
    reloadPage(): void {
        setTimeout(window.location.reload.bind(window.location), 2500);
    }

    /**
     * Редирект на страницу регистрации
     */
    redirectToRegistration($event: CustomEvent) {
        this.router.navigate(['register/']).then(_ => {});
    }
}