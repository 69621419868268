<div class="container wizard">
    <div class="row wizard-header-wrapper">
        <div class="wizard-header">
            <h4>Wizard</h4>
        </div>
    </div>

    <div class="custom-row">
        <div class="custom-col wizard-entity-lg-6">
            <div class="custom-card">
                <div class="card-body">
                    <smart-tabs #defaultTabs (click)="onTabClick(defaultTabs ,$event)" (onChange)="onTabsChange(defaultTabs,$event)" class="w-100" animation="none">
                        <smart-tab-item [label]="'Step 1'">
                            Do this first
                            <div class="wizard-tabs-buttons-wrapper">
                                <smart-button class="small-button primary outlined prev" [disabled]="true">Previous
                                </smart-button>
                                <smart-button class="small-button primary next">Next</smart-button>
                            </div>
                        </smart-tab-item>
                        <smart-tab-item [label]="'Step 2'">
                            Do this second
                            <div class="wizard-tabs-buttons-wrapper">
                                <smart-button class="small-button primary outlined prev">Previous</smart-button>
                                <smart-button class="small-button primary next">Next</smart-button>
                            </div>
                        </smart-tab-item>
                        <smart-tab-item [label]="'Step 3'">
                            Do this third
                            <div class="wizard-tabs-buttons-wrapper">
                                <smart-button class="small-button primary outlined prev">Previous</smart-button>
                                <smart-button class="small-button primary next">Next</smart-button>
                            </div>
                        </smart-tab-item>
                        <smart-tab-item [label]="'Step 4'">
                            Do this fourth
                            <div class="wizard-tabs-buttons-wrapper">
                                <smart-button class="small-button primary outlined prev">Previous</smart-button>
                                <smart-button class="small-button primary next" [disabled]="true">Next</smart-button>
                            </div>
                        </smart-tab-item>
                    </smart-tabs>

                    <smart-tabs #successTabs (click)="onTabClick(successTabs ,$event)" (onChange)="onTabsChange(successTabs, $event)" class="w-100 success" animation="none">
                        <smart-tab-item [label]="'Step 1'">
                            Do this first
                            <div class="wizard-tabs-buttons-wrapper">
                                <smart-button class="small-button primary outlined prev" [disabled]="true">Previous
                                </smart-button>
                                <smart-button class="small-button primary next">Next</smart-button>
                            </div>
                        </smart-tab-item>
                        <smart-tab-item [label]="'Step 2'">
                            Do this second
                            <div class="wizard-tabs-buttons-wrapper">
                                <smart-button class="small-button primary outlined prev">Previous</smart-button>
                                <smart-button class="small-button primary next">Next</smart-button>
                            </div>
                        </smart-tab-item>
                        <smart-tab-item [label]="'Step 3'">
                            Do this third
                            <div class="wizard-tabs-buttons-wrapper">
                                <smart-button class="small-button primary outlined prev">Previous</smart-button>
                                <smart-button class="small-button primary next">Next</smart-button>
                            </div>
                        </smart-tab-item>
                        <smart-tab-item [label]="'Step 4'">
                            Do this fourth
                            <div class="wizard-tabs-buttons-wrapper">
                                <smart-button class="small-button primary outlined prev">Previous</smart-button>
                                <smart-button class="small-button primary next" [disabled]="true">Next</smart-button>
                            </div>
                        </smart-tab-item>
                    </smart-tabs>

                    <smart-tabs #errorTabs (click)="onTabClick(errorTabs ,$event)" (onChange)="onTabsChange(errorTabs, $event)" class="w-100 error" animation="none">
                        <smart-tab-item [label]="'Step 1'">
                            Do this first
                            <div class="wizard-tabs-buttons-wrapper">
                                <smart-button class="small-button primary outlined prev" [disabled]="true">Previous
                                </smart-button>
                                <smart-button class="small-button primary next">Next</smart-button>
                            </div>
                        </smart-tab-item>
                        <smart-tab-item [label]="'Step 2'">
                            Do this second
                            <div class="wizard-tabs-buttons-wrapper">
                                <smart-button class="small-button primary outlined prev">Previous</smart-button>
                                <smart-button class="small-button primary next">Next</smart-button>
                            </div>
                        </smart-tab-item>
                        <smart-tab-item [label]="'Step 3'">
                            Do this third
                            <div class="wizard-tabs-buttons-wrapper">
                                <smart-button class="small-button primary outlined prev">Previous</smart-button>
                                <smart-button class="small-button primary next">Next</smart-button>
                            </div>
                        </smart-tab-item>
                        <smart-tab-item [label]="'Step 4'">
                            Do this fourth
                            <div class="wizard-tabs-buttons-wrapper">
                                <smart-button class="small-button primary outlined prev">Previous</smart-button>
                                <smart-button class="small-button primary next" [disabled]="true">Next</smart-button>
                            </div>
                        </smart-tab-item>
                    </smart-tabs>
                </div>
            </div>
        </div>

        <div class="custom-col wizard-entity-lg-6">
            <div class="custom-card">
                <div class="card-body">
                    <smart-tabs #fancyDefaultTabs (click)="onTabClick(fancyDefaultTabs ,$event)" (onChange)="onTabsChange(fancyDefaultTabs, $event)" class="w-100 fancy" animation="none">
                        <smart-tab-item [label]="'Step 1'">
                            Do this first
                            <div class="wizard-tabs-buttons-wrapper">
                                <smart-button class="small-button primary outlined prev" [disabled]="true">Previous
                                </smart-button>
                                <smart-button class="small-button primary next">Next</smart-button>
                            </div>
                        </smart-tab-item>
                        <smart-tab-item [label]="'Step 2'">
                            Do this second
                            <div class="wizard-tabs-buttons-wrapper">
                                <smart-button class="small-button primary outlined prev">Previous</smart-button>
                                <smart-button class="small-button primary next">Next</smart-button>
                            </div>
                        </smart-tab-item>
                        <smart-tab-item [label]="'Step 3'">
                            Do this third
                            <div class="wizard-tabs-buttons-wrapper">
                                <smart-button class="small-button primary outlined prev">Previous</smart-button>
                                <smart-button class="small-button primary next">Next</smart-button>
                            </div>
                        </smart-tab-item>
                        <smart-tab-item [label]="'Step 4'">
                            Do this fourth
                            <div class="wizard-tabs-buttons-wrapper">
                                <smart-button class="small-button primary outlined prev">Previous</smart-button>
                                <smart-button class="small-button primary next" [disabled]="true">Next</smart-button>
                            </div>
                        </smart-tab-item>
                    </smart-tabs>

                    <smart-tabs #fancySuccessTabs (click)="onTabClick(fancySuccessTabs ,$event)" (onChange)="onTabsChange(fancySuccessTabs, $event)" class="w-100 fancy success" animation="none">
                        <smart-tab-item [label]="'Step 1'">
                            Do this first
                            <div class="wizard-tabs-buttons-wrapper">
                                <smart-button class="small-button primary outlined prev" [disabled]="true">Previous
                                </smart-button>
                                <smart-button class="small-button primary next">Next</smart-button>
                            </div>
                        </smart-tab-item>
                        <smart-tab-item [label]="'Step 2'">
                            Do this second
                            <div class="wizard-tabs-buttons-wrapper">
                                <smart-button class="small-button primary outlined prev">Previous</smart-button>
                                <smart-button class="small-button primary next">Next</smart-button>
                            </div>
                        </smart-tab-item>
                        <smart-tab-item [label]="'Step 3'">
                            Do this third
                            <div class="wizard-tabs-buttons-wrapper">
                                <smart-button class="small-button primary outlined prev">Previous</smart-button>
                                <smart-button class="small-button primary next">Next</smart-button>
                            </div>
                        </smart-tab-item>
                        <smart-tab-item [label]="'Step 4'">
                            Do this fourth
                            <div class="wizard-tabs-buttons-wrapper">
                                <smart-button class="small-button primary outlined prev">Previous</smart-button>
                                <smart-button class="small-button primary next" [disabled]="true">Next</smart-button>
                            </div>
                        </smart-tab-item>
                    </smart-tabs>

                    <smart-tabs #fancyErrorTabs (click)="onTabClick(fancyErrorTabs ,$event)" (onChange)="onTabsChange(fancyErrorTabs, $event)" class="w-100 fancy error" animation="none">
                        <smart-tab-item [label]="'Step 1'">
                            Do this first
                            <div class="wizard-tabs-buttons-wrapper">
                                <smart-button class="small-button primary outlined prev" [disabled]="true">Previous
                                </smart-button>
                                <smart-button class="small-button primary next">Next</smart-button>
                            </div>
                        </smart-tab-item>
                        <smart-tab-item [label]="'Step 2'">
                            Do this second
                            <div class="wizard-tabs-buttons-wrapper">
                                <smart-button class="small-button primary outlined prev">Previous</smart-button>
                                <smart-button class="small-button primary next">Next</smart-button>
                            </div>
                        </smart-tab-item>
                        <smart-tab-item [label]="'Step 3'">
                            Do this third
                            <div class="wizard-tabs-buttons-wrapper">
                                <smart-button class="small-button primary outlined prev">Previous</smart-button>
                                <smart-button class="small-button primary next">Next</smart-button>
                            </div>
                        </smart-tab-item>
                        <smart-tab-item [label]="'Step 4'">
                            Do this fourth
                            <div class="wizard-tabs-buttons-wrapper">
                                <smart-button class="small-button primary outlined prev">Previous</smart-button>
                                <smart-button class="small-button primary next" [disabled]="true">Next</smart-button>
                            </div>
                        </smart-tab-item>
                    </smart-tabs>
                </div>
            </div>
        </div>

        <div class="custom-col wizard-entity-12">
            <div class="custom-card">
                <div class="card-body">
                    <form id="checkoutForm"></form>
                </div>
            </div>
        </div>
    </div>
</div>