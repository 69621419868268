<div class="table-with-buttons">
    <div class="custom-row">
        <div class="table-with-buttons-header custom-col">
            <h4>Мои вопросы <i data-feather="clipboard"></i> <span></span></h4>
            <p>Вы можете создавать свои вопросы и потом использовать их в собраниях</p>

        </div>
    </div>
    <div class="controls-container">
        <smart-button (onClick)="addQuestion($event)">Создать вопрос</smart-button>
        &nbsp;&nbsp;
        <smart-button #button2 (onClick)="deleteSelectedCheckBox($event)" [disabled]=this.isDisabledButtonDeleteAll>
            Удалить выбранные
        </smart-button>
    </div>
    <div class="custom-card">
        <div class="card-body">
            <smart-table #table id="table" [dataSource]="dataSource"
                         [paging]="questionsDataSettings.paging" [sortMode]="questionsDataSettings.sortMode"
                         [tooltip]="questionsDataSettings.tooltip" [onInit]="questionsDataSettings.onInit"
                         [columns]="questionsDataSettings.columns" [filtering]="questionsDataSettings.filtering"
                         [filterRow]="questionsDataSettings.filterRow" [pageIndex]="questionsDataSettings.pageIndex"
                         [pageSize]="questionsDataSettings.pageSize"
                         [selection]="questionsDataSettings.selection" [editing]="questionsDataSettings.editing"
                         (onCellEndEdit)="onItemAfterEdit($event)" (onCellClick)="onCellClick($event)"
                         (onChange)="onChangeCheckbox($event)"
            ></smart-table>
        </div>
    </div>

    <smart-window #formWindow id="formWindow" [footerTemplate]="'footerTemplate'" [opened]="false" [label]="'Внимание!'"
                  (onReady)="onWindowReady($event)">
        <div id="article">
            <section>
                <h3>Уверены что хотите удалить выделенные вопросы?</h3>
                <p>При нажатии на кнопку все выделенные вопросы удаляться их нельзя будет вернуть </p>
            </section>
        </div>
    </smart-window>
    <template id='footerTemplate'>
        <smart-button id="сancelButton">Нет</smart-button>
        <smart-button id="agreeHandler">Да</smart-button>
    </template>
</div>
