<form [formGroup] = "copyCreateForm" (ngSubmit)="copyQuestionsMeeting()">
    <div class="container basic-inputs">
        <div class="custom-row">
            <div class="custom-col">
                  <div class="custom-card">
                    <div class="card-header">
                        <h4 class="label">Скопировать вопросы из уже существующего собрания</h4>
                    </div>
                    <div class="card-body">
                        <smart-input class="basic-input" [placeholder]="'Укажите номер собрания'" formControlName="meetingId"></smart-input>
                    </div>
                </div>
            </div>
        </div>
        <div class="custom-row">
            <div class="custom-col">
                <div class="custom-card">
                    <div class="card-header">
                    </div>
                    <div class="card-body">
                        <smart-button type="submit" [disabled]="copyCreateForm.invalid">Скопировать</smart-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
