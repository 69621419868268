<div class="container responsive-table">
  <div class="custom-row">
    <div class="responsive-table-header custom-col">
      <h4>Список организации</h4>
    </div>
  </div>
  <div class="custom-row">
    <div class="custom-col">
      <div class="custom-card">
        <div class="card-body">
          <sm-basic-filters></sm-basic-filters>
        </div>
      </div>
    </div>
  </div>

  <div class="custom-row">
    <div class="custom-col">
      <div class="custom-card">
        <div class="card-body">
          <p>Smart.Table web component can be used to wrap or replace standard Tables.</p>
          <smart-table #responsiveDebitorkaTable id="multi-selection"
                       [dataSource]="tableSettings.dataSource"
                       [paging]="tableSettings.paging"
                       [sortMode]="tableSettings.sortMode"
                       [tooltip]="tableSettings.tooltip"
                       [onInit]="tableSettings.onInit"
                       [columns]="tableSettings.columns"
                       [filtering]="tableSettings.filtering"
                       [filterRow]="tableSettings.filterRow"
                       [selection]="tableSettings.selection">
          </smart-table>
        </div>
      </div>
    </div>
  </div>
</div>
