import { Component } from '@angular/core';

@Component({
  selector: 'sm-five-o-o',
  templateUrl: './five-o-o.component.html',
  styleUrls: ['./five-o-o.component.scss']
})
export class FiveOOComponent {

  constructor() { }

}
