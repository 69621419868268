<ng-container>
    <div class="header-header" [class.header-sidebar-not-expanded]="!isSidebarExpanded">
        <div class="navigation-mobile js-mobile-menu">
            <div class="logo">
                <a href="./"><img src="../../../assets/images/logo-htmlelements.svg" /></a>
            </div>

            <ul class="nav">
                <li class="nav-item menu-account" (click)="toggleNavigationOnMobile('right', navigationRight)">
                    <span class="material-icons">person</span>
                </li>

                <li class="nav-item menu-additional" (click)="toggleNavigationOnMobile('left', navigationLeft)">
                    <span class="material-icons">more_horiz</span>
                </li>

                <li class="nav-item main-menu" (click)="toggleSidebar()">
                    <span class="material-icons">menu</span>
                </li>
            </ul>
        </div>

        <ul #navigationLeft class="nav navigation-left">
            <li>
                <a routerLink="/" (click)="animate()">Дебиторка</a>
            </li>
            <li class="nav-item active">
                <a>Pages</a>
            </li>
            <li>
                <a>Features</a>
            </li>
            <li>
                <a>App</a>
            </li>
        </ul>

        <ul #navigationRight class="nav navigation-right">
            <li #messagesButton id="messages" (pointerup)="stopPropagation($event)"
                (click)="toggleMenu(messagesButton, messagesMenu)">
                <span class="position-relative">
                    <span class="material-icons">message</span>
                    <span class="cnt">3</span>
                </span>
            </li>
            <li #notificationsButton (pointerup)="stopPropagation($event)"
                (click)="toggleMenu(notificationsButton, notificationsMenu)">
                <span class="material-icons">notifications_off</span>
            </li>
            <li #languagesButton (pointerup)="stopPropagation($event)"
                (click)="toggleMenu(languagesButton, languagesMenu)">
                <img src="https://img.icons8.com/office/30/000000/usa.png" />
            </li>
            <li #searchButton (pointerup)="stopPropagation($event)" (click)="toggleMenu(searchButton, searchMenu)">
                <span class="material-icons">search</span>
            </li>

            <li #profileButton (pointerup)="stopPropagation($event)" (click)="toggleMenu(profileButton, profileMenu)">
                <div class="holder">
                    <div class="avatar" style="background-image: url('../../../assets/images/phonebook/mark.png');">
                    </div>
                </div>
            </li>
        </ul>
    </div>

    <smart-menu #messagesMenu id="messagesMenu" class="dashboard1-menu smart-custom-dropdown-menu" mode="dropDown"
        (pointerUp)="closeMenu($event, messagesMenu)">
        <smart-menu-item label="newMessages" class="menu-header">
            <strong>3 New Messages</strong>
        </smart-menu-item>

        <smart-menu-item>
            <div class="message-wrapper">
                <div class="message-logo-wrapper">
                    <div class="img-as-background">
                        <img src="../../../assets/images/phonebook/michael.png" />
                    </div>
                </div>
                <div class="message-info-wrapper">
                    <div class="name font-weight-bold">Frederick Murphy</div>
                    <div class="content text-break-line-2">Lorem ipsum dolor sit amet.</div>
                    <small class="time text-muted">7 min ago</small>
                </div>
            </div>
        </smart-menu-item>

        <smart-menu-item>
            <div class="message-wrapper">
                <div class="message-logo-wrapper">
                    <div class="img-as-background">
                        <img src="../../../assets/images/phonebook/maya.png" />
                    </div>
                </div>
                <div class="message-info-wrapper">
                    <div class="name font-weight-bold">Eva Hayes</div>
                    <div class="content text-break-line-2">Nulla porta, risus in suscipit tincidunt.</div>
                    <small class="time text-muted">15 min ago</small>
                </div>
            </div>
        </smart-menu-item>

        <smart-menu-item>
            <div class="message-wrapper">
                <div class="message-logo-wrapper">
                    <div class="img-as-background">
                        <img src="../../../assets/images/phonebook/maria.png" />
                    </div>
                </div>
                <div class="message-info-wrapper">
                    <div class="name font-weight-bold">Joyce Scott</div>
                    <div class="content text-break-line-2">Maecenas eleifend, nunc eu convallis mollis, ante lacus
                        euismod elit, iaculis tempor ex
                        sapien quis est.
                    </div>
                    <small class="time text-muted">Yesterday</small>
                </div>
            </div>
        </smart-menu-item>

        <smart-menu-item label="Show all messages" class="menu-footer"></smart-menu-item>
    </smart-menu>

    <smart-menu #notificationsMenu class="dashboard1-menu smart-custom-dropdown-menu" mode="dropDown"
        (pointerUp)="closeMenu($event, notificationsMenu)">
        <smart-menu-item label="newNotifications" class="menu-header">
            <strong>4 New Notifications</strong>
        </smart-menu-item>
        <smart-menu-item>
            <div class="notification-wrapper">
                <div class="notification-logo-wrapper">
                    <span class="material-icons text-warning notification-logo">stay_current_portrait</span>
                </div>

                <div class="notification-info-wrapper">
                    <div class="name font-weight-bold">New device detected</div>
                    <div class="content text-break-line-2">Login from a new device has been detected.</div>
                    <small class="time text-muted">2 hours ago</small>
                </div>
            </div>
        </smart-menu-item>

        <smart-menu-item>
            <div class="notification-wrapper">
                <div class="notification-logo-wrapper">
                    <span class="material-icons text-primary notification-logo">person_add</span>
                </div>

                <div class="notification-info-wrapper">
                    <div class="name font-weight-bold">Friend request</div>
                    <div class="content text-break-line-2">Joyce Scott has been added as a friend.</div>
                    <small class="time text-muted">3 hours ago</small>
                </div>
            </div>
        </smart-menu-item>
        <smart-menu-item>
            <div class="notification-wrapper">
                <div class="notification-logo-wrapper">
                    <span class="material-icons text-danger notification-logo">healing</span>
                </div>

                <div class="notification-info-wrapper">
                    <div class="name font-weight-bold">Downtime</div>
                    <div class="content text-break-line-2">Our servers will undertake maintenance tomorrow.</div>
                    <small class="time text-muted">Yesterday</small>
                </div>
            </div>
        </smart-menu-item>
        <smart-menu-item>
            <div class="notification-wrapper">
                <div class="notification-logo-wrapper">
                    <span class="material-icons text-primary notification-logo">person_add</span>
                </div>

                <div class="notification-info-wrapper">
                    <div class="name font-weight-bold">Friend request</div>
                    <div class="content text-break-line-2">Nathan Hoffman has been added as a friend.</div>
                    <small class="time text-muted">3 days ago</small>
                </div>
            </div>
        </smart-menu-item>
        <smart-menu-item label="Show all notifications" class="menu-footer"></smart-menu-item>
    </smart-menu>

    <smart-menu #languagesMenu class="dashboard1-menu smart-custom-dropdown-menu" mode="dropDown"
        (pointerUp)="closeMenu($event, languagesMenu)">
        <smart-menu-item>
            <div class="language-text"><img src="https://img.icons8.com/office/30/000000/usa.png" class="mr-3" />
                English
            </div>
        </smart-menu-item>
        <smart-menu-item>
            <div class="language-text"><img src="https://img.icons8.com/office/30/000000/china.png" class="mr-3" />
                Chinese
            </div>
        </smart-menu-item>
        <smart-menu-item>
            <div class="language-text"><img src="https://img.icons8.com/office/30/000000/spain.png" class="mr-3" />
                Spanish
            </div>
        </smart-menu-item>
        <smart-menu-item>
            <div class="language-text"><img src="https://img.icons8.com/office/30/000000/india.png" class="mr-3" />
                Hindi
            </div>
        </smart-menu-item>
        <smart-menu-item>
            <div class="language-text"><img src="https://img.icons8.com/office/30/000000/russian-federation.png"
                    class="mr-3" /> Russian</div>
        </smart-menu-item>
    </smart-menu>

    <smart-menu #searchMenu class="dashboard1-menu smart-custom-dropdown-menu" mode="dropDown"
        (pointerUp)="closeMenu($event, searchMenu)">
        <smart-menu-item>
            <span class="material-icons">search</span>
            <smart-input class="search-input" placeholder="Search something..." autofocus="true"></smart-input>
        </smart-menu-item>
    </smart-menu>

    <smart-menu #profileMenu class="dashboard1-menu smart-custom-dropdown-menu" mode="dropDown"
        (pointerUp)="closeMenu($event, profileMenu)">
        <smart-menu-item>
            <span class="material-icons profile-icon">supervised_user_circle</span> Profile
        </smart-menu-item>
        <smart-menu-item [separator]="true">
            <span class="material-icons profile-icon">pie_chart</span>Analytics
        </smart-menu-item>
        <smart-menu-item>Settings & Privacy</smart-menu-item>
        <smart-menu-item>Help</smart-menu-item>
        <smart-menu-item><a href class="nav-link" (click)="this.logout()">LogOut</a></smart-menu-item>

    </smart-menu>

</ng-container>
