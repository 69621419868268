import { Component } from '@angular/core';

@Component({
  selector: 'sm-google-maps',
  templateUrl: './google-maps.component.html',
  styleUrls: ['./google-maps.component.scss']
})
export class GoogleMapsComponent {

  constructor() { }

}
