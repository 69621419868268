<div class="container toasts">
    <div class="custom-row">
        <div class="toasts-header custom-col">
            <h4>Toasts</h4>
        </div>
    </div>

    <div class="toasts-icon-options-wrapper custom-row">
        <div class="toasts-icon-option custom-col">
            <div class="custom-card card">
                <div class="card-header">
                    <h4 class="label">Toast with icon</h4>
                </div>
                <div class="card-body">
                    <div id="toastWithIconContainer" class="toasts-toast-container">
                        <smart-toast #toastWithIcon [position]="'top-left'" [autoOpen]="true" [showCloseButton]="true"
                            [appendTo]="'toastWithIconContainer'" [type]="'info'" class="bootstrap">
                            <b>An info toast!</b>
                        </smart-toast>
                    </div>
                </div>
            </div>
        </div>

        <div class="toasts-icon-option custom-col">
            <div class="custom-card card">
                <div class="card-header">
                    <h4 class="label">Toast with no icon</h4>
                </div>
                <div class="card-body">
                    <div id="toastWithNoIconContainer" class="toasts-toast-container">
                        <smart-toast #toastWithNoIcon [position]="'top-left'" [autoOpen]="true" [showCloseButton]="true"
                            [appendTo]="'toastWithNoIconContainer'" [type]="'info'" class="bootstrap">
                            <b>An info toast!</b>
                        </smart-toast>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="toasts-large-content-buttons-wrapper custom-row">
        <div class="toasts-option custom-col">
            <div class="custom-card card">
                <div class="card-header">
                    <h4 class="label">Toasts with large content</h4>
                </div>
                <div class="card-body">
                    <div id="toastWithLargeContentContainer" class="toasts-toast-container">
                        <smart-toast #toastLargeContent1 [position]="'top-left'" [autoOpen]="true"
                            [showCloseButton]="true" [appendTo]="'toastWithLargeContentContainer'" [type]="'mail'"
                            class="bootstrap toasts-toast-content">
                            <h3>Hello!</h3>
                            <p>Sed finibus libero mi. Cras convallis nibh ultrices leo tincidunt efficitur. Aliquam
                                vehicula
                                iaculis nisl, at fringilla nisi bibendum id. Praesent efficitur leo ante, eget.</p>
                            <hr />
                            <p>Nulla at molestie nulla. Quisque aliquam diam eget lectus consectetur placerat. Maecenas
                                sagittis massa semper.</p>
                        </smart-toast>
                        <smart-toast #toastLargeContent2 [position]="'top-left'" [autoOpen]="true"
                            [showCloseButton]="true" [appendTo]="'toastWithLargeContentContainer'" [type]="'success'"
                            class="bootstrap toasts-toast-content">
                            <h3>Hello!</h3>
                            <p>Sed finibus libero mi. Cras convallis nibh ultrices leo tincidunt efficitur. Aliquam
                                vehicula
                                iaculis nisl, at fringilla nisi bibendum id. Praesent efficitur leo ante, eget.</p>
                            <hr />
                            <p>Nulla at molestie nulla. Quisque aliquam diam eget lectus consectetur placerat. Maecenas
                                sagittis massa semper.</p>
                        </smart-toast>
                    </div>
                </div>
            </div>
        </div>
        <div class="toasts-option custom-col">
            <div class="custom-card card">
                <div class="card-header">
                    <h4 class="label">Toasts with buttons</h4>
                </div>
                <div class="card-body">
                    <div id="toastWithButtonsContainer" class="toasts-toast-container">
                        <smart-toast #toastWithButtons1 [position]="'top-left'" [autoOpen]="true"
                            [showCloseButton]="true" [appendTo]="'toastWithButtonsContainer'" [type]="'mail'"
                            class="bootstrap toasts-toast-content">
                            <h3>Hello!</h3>
                            <p>Sed finibus libero mi. Cras convallis nibh ultrices leo tincidunt efficitur. Aliquam
                                vehicula
                                iaculis nisl, at fringilla nisi bibendum id. Praesent efficitur leo ante, eget.</p>
                            <hr />
                            <p>
                                <smart-button class="small-button">OK</smart-button>&nbsp;<smart-button
                                    class="small-button">Dismiss</smart-button>
                            </p>
                        </smart-toast>
                        <smart-toast #toastWithButtons2 [position]="'top-left'" [autoOpen]="true"
                            [showCloseButton]="true" [appendTo]="'toastWithButtonsContainer'" [type]="'success'"
                            class="bootstrap toasts-toast-content">
                            <h3>Hello!</h3>
                            <p>Sed finibus libero mi. Cras convallis nibh ultrices leo tincidunt efficitur. Aliquam
                                vehicula
                                iaculis nisl, at fringilla nisi bibendum id. Praesent efficitur leo ante, eget.</p>
                            <hr />
                            <p>
                                <smart-button class="small-button primary">OK</smart-button>&nbsp;<smart-button
                                    class="small-button secondary">Dismiss</smart-button>
                            </p>
                        </smart-toast>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>