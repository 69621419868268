import {ActivatedRoute, ActivatedRouteSnapshot, Params, Resolve, RouterStateSnapshot} from "@angular/router";
import {Injectable} from "@angular/core";
import {HouseService} from "../../pages/meetings/services/house.service";
import {UrlService} from "../../pages/meetings/services/url.service";

// Благодаря такому декоратору @Injectable мы можем инжектировать в данный сервис другие сущности
@Injectable({
    // Данной строкой мы говорим чтобы ангуляр зарегал данный сервис в корневом модуле (в системе в единственном экз.).
    providedIn: 'root'
    // Поэтому из корневого модуля можно исключить MeetingService так как он регается/ тут
    // Данный сервис зареган глобально и виден во всех компонентах
    // Можно регать сервисы локально (каждый раз создается копия сервиса с новыми данными) в конкретном компоненте тогда нужно в конкретный компонент в декоратор
    //  @Component({
    //    providers: [MessageService],
    //  })
    // Также можно использовать сервисы внутри других сервисов. Внедрение через конструктор
})
export class ApartmentOwnerResolver implements Resolve<any> {
    constructor(
        public houseService: HouseService,
        public activeRouter: ActivatedRoute,
        private urlService: UrlService,
    ) {
        Object.assign(ApartmentOwnerResolver, {
            HouseService,
        });
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        // Получаем параметры из url
        this.activeRouter.params.subscribe((params: Params) => {
            this.urlService.urlParams = params
        })
        const params: any[any] = [];
        params.houseId = route.params.houseId
        return this.houseService.getOwners(params)
    }

}

