<div class="container table-with-buttons">
    <div class="custom-row">
        <div class="table-with-buttons-header custom-col">
            <h4>Table With Buttons</h4>
        </div>
    </div>

    <div class="custom-card">
        <div class="card-body">
            <smart-table #tableWithButtons id="table-with-buttons" [dataSource]="tableWithButtonsSettings.dataSource"
                [paging]="tableWithButtonsSettings.paging" [sortMode]="tableWithButtonsSettings.sortMode"
                [tooltip]="tableWithButtonsSettings.tooltip" [onInit]="tableWithButtonsSettings.onInit"
                [columns]="tableWithButtonsSettings.columns" [filtering]="tableWithButtonsSettings.filtering">
            </smart-table>
        </div>
    </div>
</div>