import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'sm-input-groups',
  templateUrl: './input-groups.component.html',
  styleUrls: ['./input-groups.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InputGroupsComponent {

  constructor() { }

}
