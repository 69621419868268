<form [formGroup] = "houseCreateForm" (ngSubmit)="createHouse()">
  <div class="container basic-inputs">
    <div class="custom-row">
      <div class="custom-col">
        <div class="custom-card">
          <div class="card-header">
            <h4 class="label">Введите адрес дома</h4>
          </div>
          <div class="card-body">
            <smart-input class="basic-input" [placeholder]="'Адрес дома'" formControlName="address"></smart-input>
            <!--Дебажим значения в форме чтобы глянуть какие ошибки есть-->
          <!--  <pre>{{meetingCreateForm.get('address').errors | json}}</pre>-->
            <div
              *ngIf="houseCreateForm.get('address').invalid && houseCreateForm.get('address').touched"
              class="validation"
            >
              <small *ngIf="houseCreateForm.get('address').errors.required">
                Поле адрес не может быть пустым
              </small>

              <small *ngIf="houseCreateForm.get('address').errors.minlength">
                Длинна должна быть не менее {{houseCreateForm.get('address').errors.minlength.requiredLength}}
                Сейчас кол-во символов {{houseCreateForm.get('address').errors.minlength.actualLength}}
              </small>
            </div>
          </div>
        </div>
        <div class="custom-card">
          <div class="card-header">
            <h4 class="label">Комментарий</h4>
          </div>
          <div class="card-body">
            <smart-text-area class="basic-input" [placeholder]="'Комментарий'" formControlName="comment"></smart-text-area>
          </div>
        </div>
      </div>
<!--      <div class="custom-col">
        <div class="custom-card">
          <div class="card-header">
            <h4 class="label">Дата собрания</h4>
          </div>
          <div class="card-body">
            <smart-date-input class="basic-input"  formControlName="date"></smart-date-input>
          </div>
        </div>
      </div>-->
    </div>
    <div class="custom-row">
      <div class="custom-col">
        <div class="custom-card">
          <div class="card-header">
          </div>
          <div class="card-body">
            <smart-button type="submit" [disabled]="houseCreateForm.invalid">Создать дом</smart-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
