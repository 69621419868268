<div class="content">
  <div class="container sign-in">
    <div class="row sign-in-form">
      <div class="sign-in-header">
        <h2>Здравствуйте обезьяна :)</h2>
        <p>Регистрация пользователя</p>
      </div>
      <div class="sign-in-body custom-row">
        <div class="card-wrapper custom-col">
          <div class="card-shadow">
            <div class="card-body">
              <div class="img-as-background">
                <!--    <img src="../../../../assets/images/phonebook/mark.png">-->
                <img src="../../../../assets/images/internet/monkey.jpg">
              </div>
              <form
                      *ngIf="!isSuccessful"
                      name="form"
                      (ngSubmit)="f.form.valid && onSubmit()"
                      #f="ngForm"
                      novalidate
              >
                <div class="form-group">
                  <label>Username</label>
                  <input
                          type="text"
                          class="form-control"
                          name="username"
                          [(ngModel)]="form.username"
                          required
                          minlength="3"
                          maxlength="20"
                          #username="ngModel"
                          [ngClass]="{ 'is-invalid': f.submitted && username.errors }"
                  />
                  <div class="invalid-feedback" *ngIf="username.errors && f.submitted">
                    <div *ngIf="username.errors['required']">Username is required</div>
                    <div *ngIf="username.errors['minlength']">
                      Username must be at least 3 characters
                    </div>
                    <div *ngIf="username.errors['maxlength']">
                      Username must be at most 20 characters
                    </div>
                  </div>
                  <div *ngIf="username.errors && f.submitted" class="invalid-feedback">
                    Поле Login обязательно для заполнения! Не буть ПАВИАНОМ !!!
                  </div>
                </div>
                <div class="form-group">
                  <label>Password</label>
                  <input
                          type="password"
                          class="form-control"
                          name="password"
                          [(ngModel)]="form.password"
                          required
                          minlength="6"
                          #password="ngModel"
                          [ngClass]="{ 'is-invalid': f.submitted && password.errors }"
                  />
                  <div class="invalid-feedback" *ngIf="password.errors && f.submitted">
                    <div *ngIf="password.errors['required']">Password is required</div>
                    <div *ngIf="password.errors['minlength']">
                      Password must be at least 6 characters
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Email</label>
                  <input
                          type="email"
                          class="form-control"
                          name="email"
                          [(ngModel)]="form.email"
                          required
                          email
                          #email="ngModel"
                          [ngClass]="{ 'is-invalid': f.submitted && email.errors }"
                  />
                  <div class="invalid-feedback" *ngIf="email.errors && f.submitted">
                    <div *ngIf="email.errors['required']">Email is required</div>
                    <div *ngIf="email.errors['email']">
                      Email must be a valid email address
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <smart-check-box [checked]="true">Запомнить меня, сука навсегда</smart-check-box>
                </div>
                <div class="form-group">
                  <smart-check-box [checked]="true">Согласие с правилами сервиса.</smart-check-box>
                  <p>Тут без Варика Если не нажмешь то ТЫ не ПРОЙДЕШЬ</p>
                </div>
                <div class="row">
                  <div class="col-md-3"></div>
                  <div class="col-md-6">
                    <div class="text-center">
                      <div class="form-group">
                        <button class="btn btn-primary btn-block">Зарегистрироваться</button>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3"></div>
                </div>

                <div class="form-group">
                  <div *ngIf="f.submitted" class="alert alert-danger" role="alert">
                    Почти смогли, но нет :) {{ errorMessage }}
                  </div>
                  <div class="alert alert-warning" *ngIf="f.submitted && isSignUpFailed">
                    Signup failed!<br />{{ errorMessage }}
                  </div>
                </div>
              </form>
              <div class="alert alert-success" *ngIf="isSuccessful">
                Your registration is successful!
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>







