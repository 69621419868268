<ng-container>
    <aside id="sideBar" class="sidebar" [class.sidebar-not-expanded]="!isExpanded" #sidebar
        (mouseenter)="handleMouseEnter($event)">
        <div class="logo-holder">
           <div class="logo" [class.hide-logo]="!isExpanded">
                <a routerLink="/">
                    <img src="../../../assets/images/logo-htmlelements.svg" alt="logo">
                </a>
            </div>
            <div id="toggleButton" class="toggle-button" (click)="toggleSidebar(sidebar)">
                <svg class="sidebar-toggle-svg" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24"
                    version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <polygon points="0 0 24 0 24 24 0 24"></polygon>
                        <path
                            d="M5.29288961,6.70710318 C4.90236532,6.31657888 4.90236532,5.68341391 5.29288961,5.29288961 C5.68341391,4.90236532 6.31657888,4.90236532 6.70710318,5.29288961 L12.7071032,11.2928896 C13.0856821,11.6714686 13.0989277,12.281055 12.7371505,12.675721 L7.23715054,18.675721 C6.86395813,19.08284 6.23139076,19.1103429 5.82427177,18.7371505 C5.41715278,18.3639581 5.38964985,17.7313908 5.76284226,17.3242718 L10.6158586,12.0300721 L5.29288961,6.70710318 Z"
                            fill="#000000" fill-rule="nonzero"
                            transform="translate(8.999997, 11.999999) scale(-1, 1) translate(-8.999997, -11.999999)">
                        </path>
                        <path
                            d="M10.7071009,15.7071068 C10.3165766,16.0976311 9.68341162,16.0976311 9.29288733,15.7071068 C8.90236304,15.3165825 8.90236304,14.6834175 9.29288733,14.2928932 L15.2928873,8.29289322 C15.6714663,7.91431428 16.2810527,7.90106866 16.6757187,8.26284586 L22.6757187,13.7628459 C23.0828377,14.1360383 23.1103407,14.7686056 22.7371482,15.1757246 C22.3639558,15.5828436 21.7313885,15.6103465 21.3242695,15.2371541 L16.0300699,10.3841378 L10.7071009,15.7071068 Z"
                            fill="#000000" fill-rule="nonzero" opacity="0.3"
                            transform="translate(15.999997, 11.999999) scale(-1, 1) rotate(-270.000000) translate(-15.999997, -11.999999)">
                        </path>
                    </g>
                </svg>
            </div>
        </div>

        <div class="tree-holder">
            <smart-tree #tree id="tree" expand-mode="single" selected-indexes='["1.0"]' selection-mode="one"
                selection-target="leaf" toggle-element-position="far" toggle-mode="click">
                <smart-tree-item routerLink="/">
                    <span class="material-icons">layers</span>
                    <a>
                        <span class="label-name">Dashboard</span>
                    </a>
                </smart-tree-item>

                <smart-tree-item id="pagesLabel" label="PAGES" class="group-label"></smart-tree-item>

              <smart-tree-items-group>
                <span class="material-icons">dashboard</span> <span class="label-name">Собрания</span>
                <smart-tree-item routerLink="/houses"><a>Все дома</a></smart-tree-item>
                <smart-tree-item routerLink="/my-questions"><a>Избранные вопросы</a></smart-tree-item>
              </smart-tree-items-group>

                <smart-tree-items-group>
                    <span class="material-icons">dashboard</span> <span class="label-name">Applications</span>

                    <smart-tree-items-group>Dashboards
                        <smart-tree-item routerLink="/debitorka"><a>Дебиторка</a></smart-tree-item>
                        <smart-tree-item routerLink="/dashboard-1"><a>Dashboard 1</a></smart-tree-item>
                        <smart-tree-item routerLink="/dashboard-2"><a>Dashboard 2</a></smart-tree-item>
                        <smart-tree-item routerLink="/dashboard-3"><a>Dashboard 3</a> <span
                                class="smart-badge smart-badge-danger">New</span></smart-tree-item>
                    </smart-tree-items-group>

                    <smart-tree-items-group>E-commerce
                        <smart-tree-item routerLink="/e-commerce"><a>Dashboard</a>
                        </smart-tree-item>
                        <smart-tree-item routerLink="/clients"><a>Clients</a></smart-tree-item>
                        <smart-tree-item routerLink="/e-commerce-details"><a>Details</a>
                            <span class="smart-badge smart-badge-danger">New</span></smart-tree-item>
                        <smart-tree-item routerLink="/e-commerce-list"><a>List</a> <span
                                class="smart-badge smart-badge-danger">New</span></smart-tree-item>
                        <smart-tree-item routerLink="/e-commerce-checkout"><a>Checkout</a>
                            <span class="smart-badge smart-badge-danger">New</span></smart-tree-item>
                    </smart-tree-items-group>

                    <smart-tree-items-group>Analytics
                        <smart-tree-item routerLink="/analytics"><a>Dashboard</a></smart-tree-item>
                    </smart-tree-items-group>

                    <smart-tree-items-group>Projects
                        <smart-tree-item routerLink="/projects-list-1"><a>List 1</a>
                        </smart-tree-item>
                        <smart-tree-item routerLink="/projects-list-2"><a>List 2</a> <span
                                class="smart-badge smart-badge-danger">New</span></smart-tree-item>
                        <smart-tree-item routerLink="/detail"><a>Detail</a></smart-tree-item>
                    </smart-tree-items-group>

                    <smart-tree-items-group>Crypto
                        <smart-tree-item routerLink="/crypto"><a>Dashboard</a></smart-tree-item>
                    </smart-tree-items-group>

                    <smart-tree-items-group>Profile
                        <smart-tree-item routerLink="/profile-1"><a>Overview 1</a></smart-tree-item>
                        <smart-tree-item routerLink="/profile-2"><a>Overview 2</a> <span
                                class="smart-badge smart-badge-danger">New</span></smart-tree-item>
                        <smart-tree-item routerLink="/users-list"><a>Users</a> <span
                                class="smart-badge smart-badge-danger">New</span></smart-tree-item>
                        <smart-tree-item routerLink="/social"><a>Social Page</a></smart-tree-item>
                        <smart-tree-item routerLink="/settings"><a>Account Settings</a>
                        </smart-tree-item>
                    </smart-tree-items-group>

                    <smart-tree-items-group>Kanban
                        <smart-tree-item routerLink="/tasks"><a>Tasks 1</a></smart-tree-item>
                        <smart-tree-item routerLink="/tasks-2"><a>Tasks 2</a> <span
                                class="smart-badge smart-badge-danger">New</span></smart-tree-item>
                    </smart-tree-items-group>

                    <smart-tree-item routerLink="/chat"><a>Chat</a></smart-tree-item>
                    <smart-tree-item routerLink="/inbox"><a>Inbox</a> <span
                            class="smart-badge smart-badge-danger">New</span>
                    </smart-tree-item>
                </smart-tree-items-group>

                <smart-tree-items-group>
                    <span class="material-icons">contact_page</span> <span class="label-name">Pages</span>
                    <smart-tree-item routerLink="/sign-in"><a>Sign In</a></smart-tree-item>
                    <smart-tree-item routerLink="/sign-up"><a>Sign Up</a></smart-tree-item>
                    <smart-tree-item routerLink="/reset-password"><a>Reset Password</a></smart-tree-item>

                    <smart-tree-item routerLink="/invoice"><a>Invoice</a></smart-tree-item>
                    <smart-tree-item routerLink="/pricing"><a>Pricing</a></smart-tree-item>

                    <smart-tree-item routerLink="/404"><a>404 Page</a></smart-tree-item>
                    <smart-tree-item routerLink="/500"><a>500 Page</a></smart-tree-item>

                    <smart-tree-item routerLink="/knowledge-base"><a>Knowledge Base</a> <span
                            class="smart-badge smart-badge-danger">New</span></smart-tree-item>

                    <smart-tree-item routerLink="/blank"><a>Blank Page</a></smart-tree-item>
                </smart-tree-items-group>

                <smart-tree-items-group>
                    <span class="material-icons">library_books</span> <span class="label-name">Documentation</span>
                    <smart-tree-item routerLink="/introduction"><a>Introduction</a></smart-tree-item>
                    <smart-tree-item routerLink="/getting-started"><a>Getting Started</a></smart-tree-item>
                    <smart-tree-item routerLink="/plugins"><a>Plugins</a></smart-tree-item>
                    <smart-tree-item routerLink="/changelog"><a>Changelog</a></smart-tree-item>
                </smart-tree-items-group>

                <smart-tree-item id="toolsLabel" label="TOOLS & COMPONENTS" class="group-label"></smart-tree-item>

                <smart-tree-items-group>
                    <span class="material-icons">grid_on</span> <span class="label-name">UI Elements</span>
                    <smart-tree-item routerLink="/toasts"><a>Toasts</a></smart-tree-item>
                    <smart-tree-item routerLink="/buttons"><a>Buttons</a></smart-tree-item>
                    <smart-tree-item routerLink="/cards"><a>Cards</a></smart-tree-item>
                    <smart-tree-item routerLink="/carousel"><a>Carousel</a></smart-tree-item>
                    <smart-tree-item routerLink="/embed-video"><a>Embed Video</a></smart-tree-item>
                    <smart-tree-item routerLink="/general"><a>General</a></smart-tree-item>
                    <smart-tree-item routerLink="/grid-layout"><a>Grid Layout</a></smart-tree-item>
                    <smart-tree-item routerLink="/modals"><a>Modals</a></smart-tree-item>
                    <smart-tree-item routerLink="/tabs"><a>Tabs</a></smart-tree-item>
                    <smart-tree-item routerLink="/typography"><a>Typography</a></smart-tree-item>
                </smart-tree-items-group>

                <smart-tree-items-group>
                    <span class="material-icons">favorite</span> <span class="label-name">Icons</span>
                    <smart-tree-item routerLink="/feather"><a>Feather</a></smart-tree-item>
                    <smart-tree-item routerLink="/font-awesome"><a>Font Awesome</a></smart-tree-item>
                </smart-tree-items-group>

                <smart-tree-items-group>
                    <span class="material-icons">fact_check</span> <span class="label-name">Forms</span>
                    <smart-tree-item routerLink="/layouts"><a>Layouts</a></smart-tree-item>
                    <smart-tree-item routerLink="/basic-inputs"><a>Basic Inputs</a></smart-tree-item>
                    <smart-tree-item routerLink="/input-groups"><a>Input Groups</a></smart-tree-item>
                </smart-tree-items-group>

                <smart-tree-item routerLink="/tables">
                    <span class="material-icons">table_chart</span><a><span class="label-name">Tables</span></a>
                </smart-tree-item>

                <smart-tree-item id="pluginLabel" label="PLUGIN & ADDONS" class="group-label"></smart-tree-item>

                <smart-tree-items-group>
                    <span class="material-icons">fact_check</span> <span class="label-name">Form Plugins</span>
                    <smart-tree-item routerLink="/advanced-inputs"><a>Advanced Inputs</a></smart-tree-item>
                    <smart-tree-item routerLink="/editor"><a>Editor</a></smart-tree-item>
                    <smart-tree-item routerLink="/validation"><a>Validation</a></smart-tree-item>
                    <smart-tree-item routerLink="/wizard"><a>Wizard</a></smart-tree-item>
                </smart-tree-items-group>

                <smart-tree-items-group>
                    <span class="material-icons">table_chart</span> <span class="label-name">Data Tables</span>
                    <smart-tree-item routerLink="/responsive-table"><a>Responsive Table</a></smart-tree-item>
                    <smart-tree-item routerLink="/table-with-buttons"><a>Table with Buttons</a></smart-tree-item>
                    <smart-tree-item routerLink="/column-search"><a>Column Search</a></smart-tree-item>
                    <smart-tree-item routerLink="/multi-selection"><a>Multi Selection</a></smart-tree-item>
                    <smart-tree-item routerLink="/ajax-data"><a>Ajax Sourced Data</a></smart-tree-item>
                </smart-tree-items-group>

                <smart-tree-item routerLink="/charts">
                    <span class="material-icons">pie_chart</span><a><span class="label-name">Charts</span></a>
                </smart-tree-item>

                <smart-tree-item routerLink="/notifications">
                    <span class="material-icons">alarm</span><a><span class="label-name">Notifications</span></a>
                </smart-tree-item>

                <smart-tree-items-group>
                    <span class="material-icons">place</span> <span class="label-name">Maps</span>
                    <smart-tree-item routerLink="/google-maps"><a>Google Maps</a></smart-tree-item>
                </smart-tree-items-group>

                <smart-tree-item routerLink="/scheduler">
                    <span class="material-icons">calendar_today</span><a><span class="label-name">Scheduler</span></a>
                </smart-tree-item>

                <smart-tree-items-group>
                    <span class="material-icons">share</span> <span class="label-name">Multi Level</span>
                    <smart-tree-items-group>Two Levels
                        <smart-tree-item>Item 1</smart-tree-item>
                        <smart-tree-item>Item 2</smart-tree-item>
                    </smart-tree-items-group>
                    <smart-tree-items-group>Three Levels
                        <smart-tree-items-group>Item 1
                            <smart-tree-item>Item 1</smart-tree-item>
                            <smart-tree-item>Item 2</smart-tree-item>
                        </smart-tree-items-group>
                        <smart-tree-item>Item 2</smart-tree-item>
                    </smart-tree-items-group>
                </smart-tree-items-group>
            </smart-tree>
        </div>
        <smart-tooltip id="usageTooltipNormal" selector="toggleButton" position="right" class="animation">
            <span class="show">Open Sidebar</span>
            <span class="hide">Close Sidebar</span>
        </smart-tooltip>
    </aside>
</ng-container>
