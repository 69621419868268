<div class="container projects-list-1">
    <div class="custom-row">
        <div class="custom-col col-entity-col-12 projects-list-1-header">
            <h4>Projects List 1</h4>
        </div>
    </div>

    <div class="custom-row">
        <div class="custom-col col-entity-col-12">
            <div id="cardView"></div>
            <smart-card-view #cardview [columns]="cardViewSettings.columns" [titleField]="cardViewSettings.titleField"
                [dataSource]="cardViewSettings.dataSource" [coverField]="cardViewSettings.coverField" id="cardView">
            </smart-card-view>
        </div>
    </div>
</div>