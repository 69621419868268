import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'sm-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SignInComponent {

  constructor() {
    console.log('Работает компонент SignInComponent');
}

  
}
