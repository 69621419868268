<div class="container blank">
    <div class="custom-row blank-header">
        <div class="custom-col"><h4>Blank Page</h4></div>
    </div>

    <div class="custom-row blank-body">
        <div class="custom-col">
            <div class="custom-card">
                <div class="card-body text-center">
                    <br />
                    <br />
                    <br />
                    <p>Empty card</p>
                    <br />
                    <br />
                    <br />
                </div>
            </div>
        </div>
    </div>
</div>