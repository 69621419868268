<div class="container knowledge-base">
    <div class="custom-row">
        <div class="knowledge-base-header custom-col">
            <h4>Knowledge Base</h4>
        </div>
    </div>


    <div class="jumbotron bg-primary-opacity-3" style="background-image: url('assets/images/banner.png')">
        <h2 class="text-primary text-center">Dedicated Source Used on Website</h2>
        <p class="text-center knowledge-base-jumbotron-content">
            <span>Popular searches: </span><span class="font-weight-bolder">Sales automation, Email marketing</span>
        </p>

        <div class="knowledge-base-jumbotron-ask-question-wrapper custom-row">
            <div class="knowledge-base-jumbotron-ask-question custom-col">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1"><span
                                class="material-icons">search</span></span>
                    </div>
                    <smart-input class="form-control" id="search" placeholder="Ask a question">
                    </smart-input>
                </div>
            </div>
        </div>
    </div>


    <div class="custom-row">
        <div class="card-item custom-col">
            <smart-card class="card simple">
                <div class="card-body text-center">
                    <img src="assets/images/sales.svg">

                    <h4 class="knowledge-base-card-header">Sales Automation</h4>
                    <p>There is perhaps no better demonstration of the folly of image of our tiny world.</p>
                    <smart-button class="primary outlined small">Learn More</smart-button>
                </div>
            </smart-card>
        </div>

        <div class="card-item custom-col">
            <smart-card class="card simple">
                <div class="card-body text-center">
                    <img src="assets/images/marketing.svg">

                    <h4 class="knowledge-base-card-header">Marketing Automation</h4>
                    <p>Look again at that dot. That’s here. That’s home. That’s us. On it everyone you love.</p>
                    <smart-button class="primary outlined small">Learn More</smart-button>
                </div>
            </smart-card>
        </div>

        <div class="card-item custom-col">
            <smart-card class="card simple">
                <div class="card-body text-center">
                    <img src="assets/images/api.svg">

                    <h4 class="knowledge-base-card-header">API Questions</h4>
                    <p>Every hero and coward, every creator and destroyer of civilization.</p>
                    <smart-button class="primary outlined small">Learn More</smart-button>
                </div>
            </smart-card>
        </div>

        <div class="card-item custom-col">
            <smart-card class="card simple">
                <div class="card-body text-center">
                    <img src="assets/images/personalization.svg">

                    <h4 class="knowledge-base-card-header">Personalization</h4>
                    <p>It has been said that astronomy is a humbling and character experience.</p>
                    <smart-button class="primary outlined small">Learn More</smart-button>
                </div>
            </smart-card>
        </div>

        <div class="card-item custom-col">
            <smart-card class="card simple">
                <div class="card-body text-center">
                    <img src="assets/images/email.svg">

                    <h4 class="knowledge-base-card-header">Email Marketing</h4>
                    <p>There is perhaps no better demonstration of the folly of human conceits.</p>
                    <smart-button class="primary outlined small">Learn More</smart-button>
                </div>
            </smart-card>
        </div>

        <div class="card-item custom-col">
            <smart-card class="card simple">
                <div class="card-body text-center">
                    <img src="assets/images/demand.svg">

                    <h4 class="knowledge-base-card-header">Demand Generation</h4>
                    <p>Competent means we will never take anything for granted.</p>
                    <smart-button class="primary outlined small">Learn More</smart-button>
                </div>
            </smart-card>
        </div>

        <div class="no-result text-center custom-col">
            <h4>No results found</h4>
        </div>
    </div>

</div>