<form [formGroup]="meetingCreateForm" (ngSubmit)="createMeeting()">
  <div class="container basic-inputs">
    <div class="custom-row">
      <div class="custom-col">
        <div class="custom-card">
          <div class="card-header">
            <h4 class="label">Введите номер собрания</h4>
          </div>
          <div class="card-body">
            <smart-input class="basic-input" [placeholder]="'Номер собрания'" formControlName="meeting_number"></smart-input>
            <!--Дебажим значения в форме чтобы глянуть какие ошибки есть-->
            <!--  <pre>{{meetingCreateForm.get('address').errors | json}}</pre>-->
            <div
                    *ngIf="meetingCreateForm.get('meeting_number').invalid && meetingCreateForm.get('meeting_number').touched"
                    class="validation"
            >
              <small *ngIf="meetingCreateForm.get('meeting_number').errors.required">
                Поле номер не может быть пустым
              </small>

              <small *ngIf="meetingCreateForm.get('meeting_number').errors.minlength">
                Длинна должна быть не менее {{meetingCreateForm.get('meeting_number').errors.minlength.requiredLength}}
                Сейчас кол-во символов {{meetingCreateForm.get('meeting_number').errors.minlength.actualLength}}
              </small>
            </div>
          </div>
        </div>

        <div class="custom-card">
          <div class="card-header">
            <h4 class="label">Участники собрания</h4>
          </div>
          <div class="card-body">
            <smart-input
                    class="basic-input"
                    drop-down-button-position="right"
                    formControlName="meetingParticipants"
                    [placeholder]="'Выберете участников'"
                    [readonly]="true"
                    (change)="changeMeetingParticipants($event)"

            >
              <option
                      *ngFor="let item of meetingService.meetingParticipants"
                      value="{{item.id}}"
              >
                {{item.title}}
              </option>
            </smart-input>
          </div>
        </div>

        <div class="custom-card">
          <div class="card-header">
            <h4 class="label">Тип собрания</h4>
          </div>
          <div class="card-body">
            <smart-input
                    class="basic-input"
                    drop-down-button-position="right"
                    formControlName="meetingType"
                    [placeholder]="'Выберете тип собрания'"
                    [readonly]="true"
                    (change)="changeMeetingType($event)"
            >
              <option
                     *ngFor="let item of meetingService.meetingType"
                     value="{{item.id}}"
              >
                     {{item.title}}
              </option>
            </smart-input>
          </div>
        </div>

        <div class="custom-card"
           *ngIf="showInputNameTsg"
        >
          <div class="card-header">
            <h4 class="label">Введите название ТСЖ</h4>
          </div>
          <div class="card-body">
            <smart-input class="basic-input" [placeholder]="'Название ТСЖ'" formControlName="meetingTsgName"></smart-input>
            <!--Дебажим значения в форме чтобы глянуть какие ошибки есть-->
            <!--  <pre>{{meetingCreateForm.get('address').errors | json}}</pre>-->
            <div
                    *ngIf="meetingCreateForm.get('meetingTsgName').invalid && meetingCreateForm.get('meetingTsgName').touched"
                    class="validation"
            >
              <small *ngIf="meetingCreateForm.get('meetingTsgName').errors.required">
                Поле номер не может быть пустым
              </small>

              <small *ngIf="meetingCreateForm.get('meetingTsgName').errors.minlength">
                Длинна должна быть не менее {{meetingCreateForm.get('meetingTsgName').errors.minlength.requiredLength}}
                Сейчас кол-во символов {{meetingCreateForm.get('meetingTsgName').errors.minlength.actualLength}}
              </small>
            </div>
          </div>
        </div>

        <div class="custom-card"
             *ngIf="showInputNameTsn"
        >
          <div class="card-header">
            <h4 class="label">Введите название ТСН</h4>
          </div>
          <div class="card-body">
            <smart-input class="basic-input" [placeholder]="'Название ТСН'" formControlName="meetingTsnName"></smart-input>
            <!--Дебажим значения в форме чтобы глянуть какие ошибки есть-->
            <!--  <pre>{{meetingCreateForm.get('address').errors | json}}</pre>-->
            <div
                    *ngIf="meetingCreateForm.get('meetingTsnName').invalid && meetingCreateForm.get('meetingTsnName').touched"
                    class="validation"
            >
              <small *ngIf="meetingCreateForm.get('meetingTsnName').errors.required">
                Поле номер не может быть пустым
              </small>

              <small *ngIf="meetingCreateForm.get('meetingTsnName').errors.minlength">
                Длинна должна быть не менее {{meetingCreateForm.get('meetingTsnName').errors.minlength.requiredLength}}
                Сейчас кол-во символов {{meetingCreateForm.get('meetingTsnName').errors.minlength.actualLength}}
              </small>
            </div>
          </div>
        </div>

        <div class="custom-card">
          <div class="card-header">
            <h4 class="label">Комментарий</h4>
          </div>
          <div class="card-body">
            <smart-text-area class="basic-input" [placeholder]="'Комментарий'"
                             formControlName="meetingComment"></smart-text-area>
          </div>
        </div>
      </div>
      <div class="custom-col">
        <div>
          <div>
            <h4 class="label">Дата начала собрания</h4>
          </div>
          <div>
            <smart-date-time-picker
                    #datetimepickerBegin
                    [calendarButton]="true"
                    [enableMouseWheelAction]="true"
                    [dropDownPosition]="'overlay-top'"
                    [spinButtons]="true"
                    [spinButtonsPosition]="'left'"
                    formControlName="meetingDateBegin"
                    [displayKind]="'UTC'"
                    [formatString]="'dd-MM-yyyy HH:mm:ss'"
            >
            </smart-date-time-picker>
          </div>
        </div>
        <div>
          <div>
            <h4 class="label">Дата окончания собрания</h4>
          </div>
          <div>
            <smart-date-time-picker
                    #datetimepickerEnd
                    [calendarButton]="true"
                    [enableMouseWheelAction]="true"
                    [dropDownPosition]="'overlay-top'"
                    [spinButtons]="true"
                    [spinButtonsPosition]="'left'"
                    formControlName="meetingDateEnd"
                    [displayKind]="'UTC'"
                    [formatString]="'dd-MM-yyyy HH:mm:ss'"
            >
            </smart-date-time-picker>
          </div>
        </div>
      </div>
    </div>
    <div class="custom-row">
      <div class="custom-col">
        <div class="custom-card">
          <div class="card-header">
          </div>
          <div class="card-body">
            <smart-button type="submit" [disabled]="meetingCreateForm.invalid">Создать собрание</smart-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
