<div class="container inbox">
    <div class="custom-row">
        <div class="custom-col col-entity-12 header-col">
            <h4>Inbox</h4>
        </div>
    </div>

    <div class="custom-row mailbox">
        <div class="custom-col left-side">
            <div class="custom-card">
                <div class="card-body">
                    <div class="compose-button-wrapper">
                        <a href="#" class="btn btn-primary btn-block font-weight-bold text-uppercase text-center"
                            data-bs-toggle="modal">Compose</a>
                    </div>
                    <div class="nav flex-column upper-nav">
                        <a href="javascript:;" class="nav-link rounded active">
                            <span><i class="material-icons">inbox</i>Inbox</span>
                            <span style="padding: 0.25rem 0.5rem;"
                                class="badge bg-primary-opacity-3 text-primary">3</span>
                        </a>
                        <a href="javascript:;" class="nav-link rounded">
                            <span><i class="material-icons">star_half</i>Marked</span>
                        </a>
                        <a href="javascript:;" class="nav-link rounded">
                            <span><i class="material-icons">drafts</i>Draft</span>
                            <span style="padding: 0.25rem 0.5rem;"
                                class="badge bg-warning-opacity-3 text-warning">5</span>
                        </a>
                        <a href="javascript:;" class="nav-link rounded">
                            <span><i class="material-icons">mark_email_read</i> Sent</span>
                        </a>
                        <a href="javascript:;" class="nav-link rounded">
                            <span><i class="material-icons">delete</i>Trash</span>
                        </a>
                    </div>

                    <div class="nav flex-column nav-labels">
                        <a href="javascript:;" class="nav-link rounded"><span><i
                                    class="material-icons text-danger">fiber_manual_record</i> Projects</span> <span
                                class="badge bg-danger-opacity-3 text-danger"
                                style="padding: 0.25rem 0.5rem;">9</span></a>
                        <a href="javascript:;" class="nav-link rounded"><span><i
                                    class="material-icons text-primary">fiber_manual_record</i> Tasks</span></a>
                        <a href="javascript:;" class="nav-link rounded"><span><i
                                    class="material-icons text-info">fiber_manual_record</i> Notifications</span></a>
                        <a href="javascript:;" class="nav-link rounded"><span><i class="material-icons text-primary"
                                    style="font-size: 24px;">add</i> Add Label</span></a>
                    </div>
                </div>
            </div>
        </div>

        <div class="custom-col right-side">
            <div class="custom-card">
                <div class="card-body">
                    <div class="list-header custom-row justify-content-between d-flex">
                        <div
                            class="custom-col col-entity-12 col-entity-sm-6 col-entity-lg-auto d-flex align-items-center order-2 order-xl-1">
                            <div class="d-flex align-items-center list-header-first-col-buttons">
                                <div class="custom-control custom-checkbox flex-shrink-0">
                                    <input type="checkbox" class="custom-control-input" id="customCheckAll">
                                    <label class="custom-control-label" for="customCheckAll"></label>
                                </div>

                                <div class="dropdown" data-bs-toggle="tooltip" data-bs-placement="bottom"
                                    title="Quick actions">
                                    <button type="button" class="btn btn-clean btn-icon btn-sm"
                                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="material-icons m-0">keyboard_arrow_down</i>
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <a class="dropdown-item" href="#">All</a>
                                        <a class="dropdown-item" href="#">Read</a>
                                        <a class="dropdown-item" href="#">Unread</a>
                                        <a class="dropdown-item" href="#">Starred</a>
                                        <a class="dropdown-item" href="#">Unstarred</a>
                                    </div>
                                </div>

                                <span class="btn btn-clean btn-icon btn-sm" style="margin-right: 0.5rem;"
                                    data-bs-toggle="tooltip" data-bs-placement="bottom" title="Reload list"><i
                                        class="material-icons">refresh</i></span>
                            </div>

                            <div class="d-flex align-items-center list-header-first-col-buttons">
                                <a href="#"
                                    class="btn btn-light text-muted hover-text-primary btn-sm list-header-first-col-button"
                                    data-bs-toggle="tooltip" data-bs-placement="bottom" title="Archive">
                                    <i class="material-icons">archive</i>
                                </a>

                                <a href="#"
                                    class="btn btn-light text-muted hover-text-primary btn-sm list-header-first-col-button d-none"
                                    data-bs-toggle="tooltip" data-bs-placement="bottom" title="Spam">
                                    <i class="material-icons"></i>
                                </a>

                                <a href="#"
                                    class="btn btn-light text-muted hover-text-primary btn-sm list-header-first-col-button"
                                    data-bs-toggle="tooltip" data-bs-placement="bottom" title="Delete">
                                    <i class="material-icons">delete</i>
                                </a>

                                <a href="#"
                                    class="btn btn-light text-muted hover-text-primary btn-sm list-header-first-col-button"
                                    data-bs-toggle="tooltip" data-bs-placement="bottom" title="Mark as read">
                                    <i class="material-icons">note</i>
                                </a>

                                <a href="#"
                                    class="btn btn-light text-muted hover-text-primary btn-sm list-header-first-col-button"
                                    data-bs-toggle="tooltip" data-bs-placement="bottom" title="Move">
                                    <i class="material-icons">folder_special</i>
                                </a>
                            </div>
                        </div>

                        <div
                            class="custom-col col-entity-lg-12 col-entity-xl-auto d-flex align-items-center justify-content-center order-1 order-xl-2 search-col">
                            <div class="search-field input-group">
                                <input type="text" class="form-control" placeholder="Search...">
                                <div class="input-group-append search-button-wrapper">
                                    <button type="button" class="btn input-group-text search-button">
                                        <i class="material-icons">search</i>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="custom-col col-entity-12 col-entity-sm-6 col-entity-lg-auto d-flex align-items-center justify-content-sm-end text-right order-3 order-xl-3"
                            style="margin: 0.5rem 0;">
                            <div class="d-flex align-items-center records-per-page" data-bs-toggle="tooltip"
                            data-bs-placement="bottom" title="Records per page">
                                <div class="dropdown">
                                    <button type="button" class="btn btn-clean text-muted font-weight-bold"
                                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        1 - 50 of 235
                                    </button>
                                    <div class="dropdown-menu p-0 m-0 dropdown-menu-sm">
                                        <a class="dropdown-item" href="#">20 per page</a>
                                        <a class="dropdown-item" href="#">50 per page</a>
                                        <a class="dropdown-item" href="#">100 per page</a>
                                    </div>
                                </div>
                            </div>

                            <a href="javascript:;" class="btn btn-light text-muted hover-text-primary btn-sm records-per-page-button"
                                data-bs-toggle="tooltip" data-bs-placement="bottom" title="Previose page">
                                <i class="material-icons">keyboard_arrow_left</i>
                            </a>
                            <a href="javascript:;" class="btn btn-light text-muted hover-text-primary btn-sm records-per-page-button"
                                data-bs-toggle="tooltip" data-bs-placement="bottom" title="Next page">
                                <i class="material-icons">keyboard_arrow_right</i>
                            </a>

                            <button class="btn btn-light text-muted hover-text-primary btn-sm records-per-page-button">
                                <i class="material-icons">tune</i>
                            </button>

                            <button class="btn btn-light text-muted hover-text-primary btn-sm records-per-page-button">
                                <i class="material-icons">more_horiz</i>
                            </button>
                        </div>
                    </div>

                    <div class="list">
                        <div class="d-block d-lg-flex align-items-start list-item mail">
                            <div class="d-sm-flex align-items-center">
                                <div class="d-flex align-items-center check-star-label-wrapper">
                                    <div class="custom-control custom-checkbox flex-shrink-0">
                                        <input type="checkbox" class="custom-control-input" id="customCheck1">
                                        <label class="custom-control-label" for="customCheck1"></label>
                                    </div>
                                    <a href="#" class="btn btn-sm text-muted px-1 hover-text-warning"><i
                                            class="material-icons">star</i></a>
                                    <a href="#" class="btn btn-sm text-muted px-1 hover-text-warning"><i
                                            class="material-icons">label</i></a>
                                </div>

                                <div class="d-flex align-items-center avatar-wrapper">
                                    <div class="img-as-background square rounded"><img
                                            src="assets/images/phonebook/mark.png" /></div>
                                    <a href="#" class="font-weight-500">Sean Paul</a>
                                </div>
                            </div>

                            <div class="content-holder">
                                <div>
                                    <span class="font-weight-bold" style="margin-right: 0.5rem;">Digital PPV Customer Confirmation - </span>
                                    <span class="text-muted">Thank you for ordering UFC 240 Holloway vs Edgar Alternate
                                        camera angles...</span>
                                </div>
                                <div class="badges-wrapper">
                                    <span
                                        class="badge bg-primary-opacity-3 text-primary font-weight-bold">inbox</span>
                                    <span class="badge bg-danger-opacity-3 text-danger font-weight-bold">task</span>
                                </div>
                            </div>

                            <div class="font-weight-bold text-right time-right">8:30 PM</div>
                        </div>

                        <div class="d-block d-lg-flex align-items-start list-item mail">
                            <div class="d-sm-flex align-items-center">
                                <div class="d-flex align-items-center check-star-label-wrapper">
                                    <div class="custom-control custom-checkbox flex-shrink-0">
                                        <input type="checkbox" class="custom-control-input" id="customCheck2">
                                        <label class="custom-control-label" for="customCheck2"></label>
                                    </div>
                                    <a href="#" class="btn btn-sm text-muted px-1 hover-text-warning"><i
                                            class="material-icons">star</i></a>
                                    <a href="#" class="btn btn-sm text-muted px-1 hover-text-warning"><i
                                            class="material-icons">label</i></a>
                                </div>

                                <div class="d-flex align-items-center flex-nowrap avatar-wrapper">
                                    <div class="img-as-background square rounded"><img
                                            src="assets/images/phonebook/mark.png" /></div>
                                    <a href="#" class="font-weight-500">Oliver Jake</a>
                                </div>
                            </div>

                            <div class="content-holder">
                                <div>
                                    <span class="font-weight-bold" style="margin-right: 0.5rem;">Your iBuy.com grocery shopping confirmation
                                        - </span>
                                    <span class="text-muted">Please make sure that you have one of the following cards
                                        with you when we deliver your order...</span>
                                </div>
                            </div>

                            <div class="font-weight-bold text-right time-right">day ago</div>
                        </div>

                        <div class="d-block d-lg-flex align-items-start list-item mail">
                            <div class="d-sm-flex align-items-center">
                                <div class="d-flex align-items-center check-star-label-wrapper">
                                    <div class="custom-control custom-checkbox flex-shrink-0">
                                        <input type="checkbox" class="custom-control-input" id="customCheck3">
                                        <label class="custom-control-label" for="customCheck3"></label>
                                    </div>
                                    <a href="#" class="btn btn-sm text-muted px-1 hover-text-warning"><i
                                            class="material-icons">star</i></a>
                                    <a href="#" class="btn btn-sm text-muted px-1 hover-text-warning"><i
                                            class="material-icons">label</i></a>
                                </div>

                                <div class="d-flex align-items-center flex-nowrap avatar-wrapper">
                                    <div
                                        class="d-inline-flex align-items-center justify-content-center no-image rounded bg-info-opacity-3">
                                        <span class="font-weight-bold text-info">EB</span>
                                    </div>
                                    <a href="#" class="font-weight-500">Enrico Bugatti</a>
                                </div>
                            </div>

                            <div class="content-holder">
                                <div>
                                    <span class="font-weight-bold" style="margin-right: 0.5rem;">Your Order #224820998666029 has been Confirmed
                                        - </span>
                                    <span class="text-muted">Your Order #224820998666029 has been placed on Saturday, 29
                                        June</span>
                                </div>
                            </div>

                            <div class="font-weight-normal text-right text-muted time-left">11:20PM</div>
                        </div>

                        <div class="d-block d-lg-flex align-items-start list-item mail">
                            <div class="d-sm-flex align-items-center">
                                <div class="d-flex align-items-center check-star-label-wrapper">
                                    <div class="custom-control custom-checkbox flex-shrink-0">
                                        <input type="checkbox" class="custom-control-input" id="customCheck4">
                                        <label class="custom-control-label" for="customCheck4"></label>
                                    </div>
                                    <a href="#" class="btn btn-sm text-muted px-1 hover-text-warning"><i
                                            class="material-icons">star</i></a>
                                    <a href="#" class="btn btn-sm text-muted px-1 hover-text-warning"><i
                                            class="material-icons">label</i></a>
                                </div>

                                <div class="d-flex align-items-center flex-nowrap avatar-wrapper">
                                    <div class="img-as-background square rounded"><img
                                            src="assets/images/phonebook/mark.png" /></div>
                                    <a href="#" class="font-weight-500">Jane Goodall</a>
                                </div>
                            </div>

                            <div class="content-holder">
                                <div>
                                    <span class="font-weight-bold" style="margin-right: 0.5rem;">Payment Notification DLOP2329KD - </span>
                                    <span class="text-muted">Your payment of 4500USD to AirCar has been authorized and
                                        confirmed, thank you your account. This...</span>
                                </div>
                                <div class="badges-wrapper">
                                    <span class="badge bg-danger-opacity-3 text-danger font-weight-bold">new</span>
                                </div>
                            </div>

                            <div class="font-weight-normal text-right text-muted time-left">2 days ago
                            </div>
                        </div>

                        <div class="d-block d-lg-flex align-items-start list-item mail">
                            <div class="d-sm-flex align-items-center">
                                <div class="d-flex align-items-center check-star-label-wrapper">
                                    <div class="custom-control custom-checkbox flex-shrink-0">
                                        <input type="checkbox" class="custom-control-input" id="customCheck5">
                                        <label class="custom-control-label" for="customCheck5"></label>
                                    </div>
                                    <a href="#" class="btn btn-sm text-muted px-1 hover-text-warning"><i
                                            class="material-icons">star</i></a>
                                    <a href="#" class="btn btn-sm text-muted px-1 hover-text-warning"><i
                                            class="material-icons">label</i></a>
                                </div>

                                <div class="d-flex align-items-center flex-nowrap avatar-wrapper">
                                    <div
                                        class="d-inline-flex align-items-center justify-content-center no-image rounded bg-primary-opacity-3">
                                        <span class="font-weight-bold text-primary">MP</span>
                                    </div>
                                    <a href="#" class="font-weight-500">Max O'Brien Planck</a>
                                </div>
                            </div>

                            <div class="content-holder">
                                <div>
                                    <span class="font-weight-bold" style="margin-right: 0.5rem;">Congratulations on your iRun Coach subscription
                                        - </span>
                                    <span class="text-muted">Congratulations on your iRun Coach subscription. You made
                                        no space for excuses and you</span>
                                </div>
                            </div>

                            <div class="font-weight-normal text-right text-muted time-left">July 25</div>
                        </div>

                        <div class="d-block d-lg-flex align-items-start list-item mail">
                            <div class="d-sm-flex align-items-center">
                                <div class="d-flex align-items-center check-star-label-wrapper">
                                    <div class="custom-control custom-checkbox flex-shrink-0">
                                        <input type="checkbox" class="custom-control-input" id="customCheck6">
                                        <label class="custom-control-label" for="customCheck6"></label>
                                    </div>
                                    <a href="#" class="btn btn-sm text-muted px-1 hover-text-warning"><i
                                            class="material-icons">star</i></a>
                                    <a href="#" class="btn btn-sm text-muted px-1 hover-text-warning"><i
                                            class="material-icons">label</i></a>
                                </div>

                                <div class="d-flex align-items-center flex-nowrap avatar-wrapper">
                                    <div class="img-as-background square rounded"><img
                                            src="assets/images/phonebook/mark.png" /></div>
                                    <a href="#" class="font-weight-500">Rita Levi-Montalcini</a>
                                </div>
                            </div>

                            <div class="content-holder">
                                <div>
                                    <span class="font-weight-bold" style="margin-right: 0.5rem;">Pay bills &amp; win up to 600$ Cashback!
                                        - </span>
                                    <span class="text-muted">Congratulations on your iRun Coach subscription. You made
                                        no space for excuses and you decided on a healthier and happier life...</span>
                                </div>
                            </div>

                            <div class="font-weight-normal text-right text-muted time-left">July 24</div>
                        </div>

                        <div class="d-block d-lg-flex align-items-start list-item mail">
                            <div class="d-sm-flex align-items-center">
                                <div class="d-flex align-items-center check-star-label-wrapper">
                                    <div class="custom-control custom-checkbox flex-shrink-0">
                                        <input type="checkbox" class="custom-control-input" id="customCheck7">
                                        <label class="custom-control-label" for="customCheck7"></label>
                                    </div>
                                    <a href="#" class="btn btn-sm text-muted px-1 hover-text-warning"><i
                                            class="material-icons">star</i></a>
                                    <a href="#" class="btn btn-sm text-muted px-1 hover-text-warning"><i
                                            class="material-icons">label</i></a>
                                </div>

                                <div class="d-flex align-items-center flex-nowrap avatar-wrapper">
                                    <div class="img-as-background square rounded"><img
                                            src="assets/images/phonebook/mark.png" /></div>
                                    <a href="#" class="font-weight-500">Stephen Hawking</a>
                                </div>
                            </div>

                            <div class="content-holder">
                                <div>
                                    <span class="font-weight-bold" style="margin-right: 0.5rem;">Activate your LIPO Account today - </span>
                                    <span class="text-muted">Thank you for creating a LIPO Account. Please click the
                                        link below to activate your account.</span>
                                </div>
                                <div class="badges-wrapper">
                                    <span
                                        class="badge bg-warning-opacity-3 text-warning font-weight-bold" style="margin-right: 0.5rem;">task</span>
                                </div>
                            </div>

                            <div class="font-weight-normal text-right text-muted time-left">July 13</div>
                        </div>

                        <div class="d-block d-lg-flex align-items-start list-item mail">
                            <div class="d-sm-flex align-items-center">
                                <div class="d-flex align-items-center check-star-label-wrapper">
                                    <div class="custom-control custom-checkbox flex-shrink-0">
                                        <input type="checkbox" class="custom-control-input" id="customCheck8">
                                        <label class="custom-control-label" for="customCheck8"></label>
                                    </div>
                                    <a href="#" class="btn btn-sm text-muted px-1 hover-text-warning"><i
                                            class="material-icons">star</i></a>
                                    <a href="#" class="btn btn-sm text-muted px-1 hover-text-warning"><i
                                            class="material-icons">label</i></a>
                                </div>

                                <div class="d-flex align-items-center flex-nowrap avatar-wrapper">
                                    <div
                                        class="d-inline-flex align-items-center justify-content-center no-image rounded bg-light mr-3">
                                        <span class="font-weight-bold">WE</span>
                                    </div>
                                    <a href="#" class="font-weight-500">Wolfgang Ernst Pauli</a>
                                </div>
                            </div>

                            <div class="content-holder">
                                <div>
                                    <span class="font-weight-bold" style="margin-right: 0.5rem;">About your request for PalmLake - </span>
                                    <span class="text-muted">What you requested can't be arranged ahead of time but
                                        PalmLake said they'll do their best to accommodate you upon arrival....</span>
                                </div>
                            </div>

                            <div class="mt-2 mr-3 font-weight-bold text-muted w-100px text-right">25 May</div>
                        </div>

                        <div class="d-block d-lg-flex align-items-start list-item mail">
                            <div class="d-sm-flex align-items-center">
                                <div class="d-flex align-items-center check-star-label-wrapper">
                                    <div class="custom-control custom-checkbox flex-shrink-0">
                                        <input type="checkbox" class="custom-control-input" id="customCheck9">
                                        <label class="custom-control-label" for="customCheck9"></label>
                                    </div>
                                    <a href="#" class="btn btn-sm text-muted px-1 hover-text-warning"><i
                                            class="material-icons">star</i></a>
                                    <a href="#" class="btn btn-sm text-muted px-1 hover-text-warning"><i
                                            class="material-icons">label</i></a>
                                </div>

                                <div class="d-flex align-items-center flex-nowrap avatar-wrapper">
                                    <div class="img-as-background square rounded"><img
                                            src="assets/images/phonebook/mark.png" /></div>
                                    <a href="#" class="font-weight-500">Patty Jo Watson</a>
                                </div>
                            </div>

                            <div class="content-holder">
                                <div>
                                    <span class="font-weight-bold" style="margin-right: 0.5rem;">Welcome, Patty - </span>
                                    <span class="text-muted">Discover interesting ideas and unique perspectives. Read,
                                        explore and follow your interests. Get personalized recommendations delivered to
                                        you....</span>
                                </div>
                            </div>

                            <div class="font-weight-normal text-right text-muted time-left">July 24</div>
                        </div>

                        <div class="d-block d-lg-flex align-items-start list-item mail">
                            <div class="d-sm-flex align-items-center">
                                <div class="d-flex align-items-center check-star-label-wrapper">
                                    <div class="custom-control custom-checkbox flex-shrink-0">
                                        <input type="checkbox" class="custom-control-input" id="customCheck10">
                                        <label class="custom-control-label" for="customCheck10"></label>
                                    </div>
                                    <a href="#" class="btn btn-sm text-muted px-1 hover-text-warning"><i
                                            class="material-icons">star</i></a>
                                    <a href="#" class="btn btn-sm text-muted px-1 hover-text-warning"><i
                                            class="material-icons">label</i></a>
                                </div>

                                <div class="d-flex align-items-center flex-nowrap avatar-wrapper">
                                    <div class="img-as-background square rounded"><img
                                            src="assets/images/phonebook/mark.png" /></div>
                                    <a href="#" class="font-weight-500">Blaise Pascal</a>
                                </div>
                            </div>

                            <div class="content-holder">
                                <div>
                                    <span class="font-weight-bold" style="margin-right: 0.5rem;">Free Video Marketing Guide - </span>
                                    <span class="text-muted">Video has rolled into every marketing platform or channel,
                                        leaving...</span>
                                </div>
                                <div class="badges-wrapper">
                                    <span
                                        class="badge bg-success-opacity-3 text-success font-weight-bold label-inline">project</span>
                                </div>
                            </div>

                            <div class="font-weight-normal text-right text-muted time-left">July 13</div>
                        </div>

                        <div class="d-block d-lg-flex align-items-start list-item mail">
                            <div class="d-sm-flex align-items-center">
                                <div class="d-flex align-items-center check-star-label-wrapper">
                                    <div class="custom-control custom-checkbox flex-shrink-0">
                                        <input type="checkbox" class="custom-control-input" id="customCheck11">
                                        <label class="custom-control-label" for="customCheck11"></label>
                                    </div>
                                    <a href="#" class="btn btn-sm text-muted px-1 hover-text-warning"><i
                                            class="material-icons">star</i></a>
                                    <a href="#" class="btn btn-sm text-muted px-1 hover-text-warning"><i
                                            class="material-icons">label</i></a>
                                </div>

                                <div class="d-flex align-items-center flex-nowrap avatar-wrapper">
                                    <div
                                        class="d-inline-flex align-items-center justify-content-center no-image rounded bg-warning-opacity-3">
                                        <span class="font-weight-bold text-warning">RO</span>
                                    </div>
                                    <a href="#" class="font-weight-500">Roberts O'Neill Wilson</a>
                                </div>
                            </div>

                            <div class="content-holder">
                                <div>
                                    <span class="font-weight-bold" style="margin-right: 0.5rem;">Your iBuy.com grocery shopping confirmation
                                        - </span>
                                    <span class="text-muted">Please make sure that you have one of the following cards
                                        with you when we deliver your order...</span>
                                </div>
                            </div>

                            <div class="font-weight-bold text-right time-right">day ago</div>
                        </div>

                        <div class="d-block d-lg-flex align-items-start list-item mail">
                            <div class="d-sm-flex align-items-center">
                                <div class="d-flex align-items-center check-star-label-wrapper">
                                    <div class="custom-control custom-checkbox flex-shrink-0">
                                        <input type="checkbox" class="custom-control-input" id="customCheck12">
                                        <label class="custom-control-label" for="customCheck12"></label>
                                    </div>
                                    <a href="#" class="btn btn-sm text-muted px-1 hover-text-warning"><i
                                            class="material-icons">star</i></a>
                                    <a href="#" class="btn btn-sm text-muted px-1 hover-text-warning"><i
                                            class="material-icons">label</i></a>
                                </div>

                                <div class="d-flex align-items-center flex-nowrap avatar-wrapper">
                                    <div
                                        class="d-inline-flex align-items-center justify-content-center no-image rounded bg-primary-opacity-3">
                                        <span class="font-weight-bold text-primary">EF</span>
                                    </div>
                                    <a href="#" class="font-weight-500">Enrico Fermi</a>
                                </div>
                            </div>

                            <div class="content-holder">
                                <div>
                                    <span class="font-weight-bold" style="margin-right: 0.5rem;">Your Order #224820998666029 has been Confirmed
                                        - </span>
                                    <span class="text-muted">Your Order #224820998666029 has been placed on Saturday, 29
                                        June</span>
                                </div>
                            </div>

                            <div class="font-weight-normal text-right text-muted time-left">11:20PM</div>
                        </div>

                        <div class="d-block d-lg-flex align-items-start list-item mail">
                            <div class="d-sm-flex align-items-center">
                                <div class="d-flex align-items-center check-star-label-wrapper">
                                    <div class="custom-control custom-checkbox flex-shrink-0">
                                        <input type="checkbox" class="custom-control-input" id="customCheck13">
                                        <label class="custom-control-label" for="customCheck13"></label>
                                    </div>
                                    <a href="#" class="btn btn-sm text-muted px-1 hover-text-warning"><i
                                            class="material-icons">star</i></a>
                                    <a href="#" class="btn btn-sm text-muted px-1 hover-text-warning"><i
                                            class="material-icons">label</i></a>
                                </div>

                                <div class="d-flex align-items-center flex-nowrap avatar-wrapper">
                                    <div class="img-as-background square rounded"><img
                                            src="assets/images/phonebook/mark.png" /></div>
                                    <a href="#" class="font-weight-500">Jane Goodall</a>
                                </div>
                            </div>

                            <div class="content-holder">
                                <div>
                                    <span class="font-weight-bold" style="margin-right: 0.5rem;">Payment Notification DLOP2329KD - </span>
                                    <span class="text-muted">Your payment of 4500USD to AirCar has been authorized and
                                        confirmed, thank you your account. This...</span>
                                </div>
                            </div>

                            <div class="font-weight-normal text-right text-muted time-left">2 days ago
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<smart-toast position="top-right" auto-open show-close-button type="info" class="bootstrap with-header mark-as-readed"
    item-template="toastTemplate">Mark as readed</smart-toast>