import {Component, OnInit, OnDestroy, ViewChild, AfterViewInit, ViewEncapsulation} from '@angular/core';
import {MeetingService} from "src/app/pages/meetings/services/meeting.service";
import {Smart, DataAdapter} from 'smart-webcomponents-angular/grid';
import {Subscription} from "rxjs";
import {Meeting} from "../../../models/metting";
import {TableComponent} from "smart-webcomponents-angular/table";
import {WindowComponent, Window, WindowModule} from 'smart-webcomponents-angular/window';
import {ButtonComponent, ButtonModule} from "smart-webcomponents-angular/button";
import {BrowserModule} from "@angular/platform-browser";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {HouseService} from "../services/house.service";
import {UrlService} from "../services/url.service";
import {DatePipe} from "@angular/common";

// @ts-ignore
@Component({
    selector: 'list-meetings',
    templateUrl: './list-meeting.component.html',
    providers: [BrowserModule, ButtonModule, WindowModule],
    styleUrls: ['./list-meeting.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ListMeetingComponent implements AfterViewInit, OnDestroy, OnInit {

    static meetingService: MeetingService;
    meetings: Meeting[];
    // Переменная отвечающая за подписку
    mSub: Subscription
    selectedItems: Array<any> = [];
    agreeDeleteCheckbox: boolean = false; // Флаг кнопка согласие в модальном окне
    isDisabledButtonDeleteAll: boolean = true; // Изначально отключено. Кнопка удалить все

    constructor(
        private meetingService: MeetingService,
        private houseService: HouseService,
        private urlService: UrlService,
        private router: Router,
        private activeRouter: ActivatedRoute // Отображает текущий роут компонента
    ) {
        Object.assign(ListMeetingComponent, {
            MeetingService,
        });
    }

    @ViewChild('button', {read: ButtonComponent, static: false}) button!: ButtonComponent;
    @ViewChild('modalWindow', {read: WindowComponent, static: false}) modalWindow: WindowComponent;
    @ViewChild('formWindow', {read: WindowComponent, static: false}) formWindow: WindowComponent;
    @ViewChild('table', {read: TableComponent, static: false}) tableWithButtons!: TableComponent;

    /**
     * Очищаем подписку на стрим чтобы не было утечек по памяти
     */
    ngOnDestroy() {
        if (this.mSub) {
            this.mSub.unsubscribe();
        }
    }

    onWindowReady(event: any): void {
        this.init();
    }


    onButtonClick(event: any): void {
        this.formWindow.opened ? this.formWindow.close() : this.formWindow.open();
    }

    onButtonClick2(event: any): void {
        this.formWindow.close();
    }

    /**
     * Запускается 3 - им
     */
    init(): void {
        // init code.
        console.log('Запустился init()');

    }

    /**
     * Св-во нужно для инициализации. Для интерфейса. Нужен для норм работы
     */
    onInit(): void {
        // init code.
        console.log('Запустился onInit()');
    };


    /**
     * Запускается 1 на странице
     */
    ngOnInit() {
        console.log('Запустился ngOnInit()');
        // Получаем параметры из url
        this.activeRouter.params.subscribe((params: Params) => {
            this.urlService.urlParams = params
        })
        console.log(this.router.url);
        // Получаем начальные данные по запросу
        this.getMeetings();
     }

    /**
     * Запускается 2 на странице после ngOnInit()
     */
    ngAfterViewInit() {
        // Заменяет иконки после прорисовки компонента на нужные
        const windowWithFeather = window as any;
        windowWithFeather.feather.replace();
        console.log('Запустился  ngAfterViewInit()');
        window.onload = function () {
            console.log(' window.onload ');
            /*      const elems = document.body.querySelector('.ggggg1')
                  // @ts-ignore
                  elems.addEventListener('click', function(event) {
                    event.preventDefault();
                    alert('Дорогу осилит идущий');
                  })*/
        }
    }

    /**
     **  Провайдер данных обозначаем какие поля в нем будут
     */
    dataAdapter: DataAdapter = new Smart.DataAdapter({
        dataSource: [],
        dataFields: [
            'id: number',
            'meeting_number: string',
  /*          'address: any',*/
            'meetingDateBegin: string',
            'initiator: string',
            'ownerCount: number',
            'questionCount: number',
            'meetingStatus: number',
            'paymentStatus: number',
            'comment: string',
        ],
    })

    dataSource = this.dataAdapter;

    /**
     * Вызывается обработчик события и апдейтится поле когда закончили редактирование в колонке
     * @param event
     */
    onItemAfterEdit(event: any) {
        console.log(event)
        this.mSub = this.meetingService.updateMeetingValue(event.detail).subscribe(meetings => {
            // Получили обновленные данные и записали в this.dataAdapter.dataSource
            this.getMeetings();
            // Скинули все чекбоксы
            this.tableWithButtons.clearSelection();
        });
    }

    /**
     * Вызывается обработчик события когда кликнули по иконкам или чекбоксу
     * @param event
     */
    onCellClick(event: { detail: { dataField: string; row: { id: number; }; id: string; }; target: any; }) {

        //Клик по иконке edit
        if (event.detail.dataField === 'actionEdit') {
            console.log('actionEdit')
            console.log(event);
            this.router.navigate([this.router.url + '/current-meeting/' + event.detail.row.id]);
        }
        //Клик по иконке delete
        if (event.detail.dataField === 'actionDelete') {
            this.deleteMeeting(event.detail.row.id)
            document.querySelector('[row-id="' + event.detail.id + '"]')?.remove();
        }

        //Клик по чекбоксу
        if (this.selectedItems === undefined || this.selectedItems.length == 0) {
            this.selectedItems.push(event.detail.row.id);
        } else {
            // Если еще нет значения в массиве то добавляем
            if (!this.selectedItems.includes(event.detail.row.id)) {
                //console.log('еще нет значения');
                this.selectedItems.push(event.detail.row.id);
            } else {
                //console.log('такое есть значения'); Удаляем из массива selectedItems
                const index = this.selectedItems.indexOf(event.detail.row.id, 0);
                //console.log('index - ' + index);
                if (index > -1) {
                    this.selectedItems.splice(index, 1);
                }
            }
        }
    }

    /**
     * Клик по выбрать все чекбоксы. Записываем данные в this.selectedItems
     * @param event
     */
    onChangeCheckbox(event: { detail: { dataField: string; row: { id: number; }; id: string; }; target: any; type: string }) {
        let allCheckboxSelected = document.querySelectorAll('th.selected');
        let sameCheckboxSelected = document.querySelectorAll('th.indeterminate');

        if (allCheckboxSelected.length > 0 || sameCheckboxSelected.length > 0) {
            this.isDisabledButtonDeleteAll = false;

            let checked = document.querySelectorAll('tr[aria-selected="true"]');
            checked.forEach((item, key) => {

                // @ts-ignore
                if(item.querySelector('[data-field="id"]')) {
                    let val = item.querySelector('[data-field="id"]').innerHTML;
                    // Если еще нет значения в массиве то добавляем
                    if (!this.selectedItems.includes(Number(val))) {
                        //console.log('еще нет значения');
                        this.selectedItems.push(Number(val));
                    }
                }

            })
        } else {
            this.selectedItems = [];
            this.isDisabledButtonDeleteAll = true;
        }
    }

    /**
     * Кнопка Удалить все выбранные чекбоксы. Http запрос на сервер и визуал
     */
    deleteSelectedCheckBox(event: any): void {
        // Навешиваем обработчики событий.
        const cancelHandler = (event: CustomEvent): void => {
            const target: HTMLElement = event.target as HTMLElement,
                modal = this.formWindow.nativeElement;
            target.closest('smart-window') === modal ? modal.close() : modal.close();
            this.agreeDeleteCheckbox = false;
        }
        // Подтверждение
        const agreeHandler = (event: CustomEvent): void => {
            const target: HTMLElement = event.target as HTMLElement,
                modal = this.formWindow.nativeElement;
            target.closest('smart-window') === modal ? modal.close() : modal.close();
            this.agreeDeleteCheckbox = true;
            this.isDisabledButtonDeleteAll = true;
            this.deleteSelectedCheckBox(event)
        }
        // @ts-ignore may be null
        document.getElementById('сancelButton').addEventListener('click', cancelHandler);
        // @ts-ignore may be null
        document.getElementById('agreeHandler').addEventListener('click', agreeHandler);
        // Конец навешиваем обработчики событий.

        let sameCheckboxSelected = document.querySelectorAll('th.indeterminate');
        let allCheckboxSelected = document.querySelectorAll('th.selected');
        const target: HTMLElement = event.target as HTMLElement,
            modal = this.formWindow.nativeElement;
        if (allCheckboxSelected.length > 0 || sameCheckboxSelected.length > 0) {
            target.closest('smart-window') === modal ? modal.open() : modal.open();
            this.isDisabledButtonDeleteAll = false;
        } else {
            this.selectedItems = [];
            this.isDisabledButtonDeleteAll = true;
        }
        if (this.agreeDeleteCheckbox) {
            target.closest('smart-window') === modal ? modal.close() : modal.close();

            this.mSub = this.meetingService.deleteMeetings(this.selectedItems)
                .subscribe(meetings => {
                    // Обновили таблицу
                    // this.getMeetings();
                    // Скинули все чекбоксы
                    this.tableWithButtons.clearSelection();
                    this.selectedItems = [];
                    this.agreeDeleteCheckbox = false;
                    // this.ngOnInit()
                    //Перезагружаем текущую страницу
                    location.reload()
                });
        }
    }

    /**
     * Получить все собрания
     */
    getMeetings(): void {
        // Параметр houseId берется из роутинга из url. В роутинге есть параметр 'houses/:houseId/meetings'
        this.mSub = this.meetingService.getMeetings(this.urlService.urlParams)
            .subscribe(meetings => {
                // Заполнили данными
                this.dataAdapter.dataSource = meetings.results;
            });
    }


    /**
     * Удалить собрание
     */
    deleteMeeting(id: number): void {
        this.mSub = this.meetingService.deleteMeeting(id)
            .subscribe(meetings => {
                // Заполнили данными
                // this.dataAdapter.dataSource = meetings.result;
            });
    }

    /**
     * Создать новое собрание
     */
    addMeeting(): void {
        this.router.navigate([this.router.url + '/create']);
    }

    /**
     * Настройки таблицы
     */
    meetingsDataSettings = {
        /**
         * Св-во нужно для инициализации. Для интерфейса. Нужен для норм работы
         */

        onInit: () => {
        },

        behavior: {columnResizeMode: 'growAndShrink'},
        meetingsService: ListMeetingComponent.meetingService,
        // Пример запроса по ajax. Проблема fetch делается несколько раз
        /*    dataSource: new window.Smart.DataAdapter({
              virtualDataSourceCache: false,
              virtualDataSource:  function (resultCallbackFunction: ResultCallbackFunction) {
                fetch('/api/v1/product/getMeetings/')
                  .then(response => response.json())
                  .then(json => {
                    const data: [] = json.results.map((result: {
                      id: number;
                      address: any;
                      meetingDate: string
                      initiator: string;
                      ownerCount: number;
                      questionCount: number;
                      meetingStatus: number;
                      paymentStatus: number;
                      comment: string;

                    }) => {
                      return {
                        id: result.id,
                        meetingDate: result.meetingDate,
                        address: result.address,
                        initiator: result.initiator,
                        ownerCount: result.ownerCount,
                        questionCount: result.questionCount,
                        meetingStatus: result.meetingStatus,
                        paymentStatus: result.paymentStatus,
                        comment: result.comment,
                      };
                    });
                    resultCallbackFunction({
                      dataSource: data
                    });
                  });
              },
              dataFields: [
                'id: number',
                'address: string',
                'meetingDate: string',
                'initiator: string',
                'ownerCount: number',
                'questionCount: number',
                'meetingStatus: number',
                'paymentStatus: number',
                'comment:       string',
                'createDate:    string',
                'updateDate: string'
              ],
            }),*/

        // Параметры таблицы
        pageIndex: 0,
        pageSize: "10",
        filtering: true,
        paging: true,
        sortMode: 'many',
        filterRow: false,
        tooltip: true,
        selection: true,
        editing: true,
        editMode: 'row',
        keyboardNavigation: true,

        // Колонки в таблице
        columns: [
            {
                label: 'id', dataField: 'id', dataType: 'number', responsivePriority: 3, width: 0, visible: false
            },
            {
                label: 'Id', dataField: 'actionEdit', dataType: 'number', responsivePriority: 3, width: 50,
                formatFunction: function (settings: any) {
                    settings.template =
                        '<a href="javascript:;" class="nav-link rounded action-icon" style="color:#007bff">' + settings.data.id + '</a>';
                }
            },
            {label: '№', dataField: 'meeting_number', dataType: 'string', responsivePriority: 3, width: 50},
            {
                label: 'Дата собрания', dataField: 'meetingDateBegin', allowEdit: false, dataType: 'string', responsivePriority: 3,
                formatFunction: (value:any) => {
                    new DatePipe('en-US').transform(value.value, 'dd-MM-yyyy');
                    value.template = new DatePipe('en-US').transform(value.value, 'dd.MM.yyyy');
                }
            },
          /*  {label: 'Адрес', dataField: 'address', dataType: 'string', responsivePriority: 4},*/
            {label: 'Инициатор', dataField: 'initiator', dataType: 'string'},
            {label: 'Кол-во собственников', dataField: 'ownerCount', dataType: 'number'},
            {label: 'Кол-во вопросов', dataField: 'questionCount', dataType: 'number', responsivePriority: 3},
            {label: 'Статус собрания', dataField: 'meetingStatus', dataType: 'number', responsivePriority: 3},
            {label: 'Статус оплаты', dataField: 'paymentStatus', dataType: 'number', responsivePriority: 3},
            {label: 'Комментарий', dataField: 'comment', dataType: 'string', responsivePriority: 3},
            {
                label: '', dataField: 'actionEdit', allowEdit: false, width: 50,
                formatFunction: function (settings: any) {
                    settings.template =
                        '<a href="javascript:;" class="nav-link rounded action-icon">' +
                        '<i class="material-icons">edit</i>' + '</a>';
                }
            },
            {
                label: '', dataField: 'actionDelete', allowEdit: false, width: 50,
                formatFunction: function (settings: any) {
                    settings.template =
                        '<a href="javascript:;" class="nav-link rounded action-icon">' +
                        '<i class="material-icons">delete_forever</i>' + '</a>';
                }
            },
        ],
    }

}
