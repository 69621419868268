<div class="table-with-buttons">
    <div class="custom-row">
        <div class="table-with-buttons-header custom-col">
            <h4>Выбрать мои вопросы <i data-feather="clipboard"></i> <span></span></h4>
            <p>Данные вопросы редактируются пользователем</p>
        </div>
    </div>
    <smart-button #button2 (onClick)="addMyQuestionToCurrentMeeting($event)" [disabled]=this.isDisabledButtonDeleteAll>
        Добавить выбранные
    </smart-button>
  <div class="custom-card">
    <div class="card-body">
     <smart-table #table id="myQuestions"  [dataSource]="dataSource"
                  [paging]="questionsDataSettings.paging" [sortMode]="questionsDataSettings.sortMode"
                  [tooltip]="questionsDataSettings.tooltip" [onInit]="questionsDataSettings.onInit"
                  [columns]="questionsDataSettings.columns" [filtering]="questionsDataSettings.filtering"
                  [filterRow]="questionsDataSettings.filterRow" [pageIndex]="questionsDataSettings.pageIndex" [pageSize]= "questionsDataSettings.pageSize"
                  [selection]="questionsDataSettings.selection" [editing]="questionsDataSettings.editing"
                  (onCellClick)="onCellClick($event)" (onChange)="onChangeCheckbox($event)"
     ></smart-table>
    </div>
  </div>
</div>
