import {Injectable} from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError} from "rxjs/operators";
import {HandleError, HttpErrorHandler} from "../../../services/http-error-handler.service";

const AUTH_API = 'https://debitorka-online.ru/api/v1/';

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
    providedIn: 'root',
})
/**
 * Эта служба отправляет HTTP POST-запросы на регистрацию, вход и выход на серверную часть. Он обеспечивает следующие важные функции
 */
export class FileUploadService {

    private readonly handleError: HandleError;

    constructor(
        private http: HttpClient,
        httpErrorHandler: HttpErrorHandler
    ) {
        this.handleError = httpErrorHandler.createHandleError('FileUploadService');
    }

    /**
     * Загрузка файла exele c владельцами на back
     * @param params
     */
/*    upload(params: any): Observable<HttpEvent<any>> {
        const req = new HttpRequest(
            'POST',
            AUTH_API + 'house/upload-owner',
            params,
            {
            reportProgress: true,
            responseType: 'json'
        });

        return this.http.request(req);

        const url = `${this.housesUrl + 'createHouse'}`;
        return this.http.post(url, {
            params: params
        })
            .pipe(
                catchError(this.handleError('createHouse'))
            );

    }*/

    /**
     * Загрузка файла exele c владельцами на back
     * @param detail
     */
    upload(detail: any): Observable<any> {
        const url = `${AUTH_API + 'house/upload-owner'}`;
        const formData: FormData = new FormData();
        formData.append('file', detail.file);
        formData.append('house_id', detail.house_id);

        return this.http.post(url, formData, {
        })
            .pipe(
                catchError(this.handleError('uploadOwner', []))
            );
    }
}