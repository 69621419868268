<div class="container account-settings">
    <div class="custom-row">
        <div class="custom-col col-entity-12" style="margin-bottom: 0.75rem;">
            <h4>Settings</h4>
        </div>
    </div>

    <div class="custom-row">
        <div class="custom-col col-entity-lg-4 mb-5">
            <div class="custom-card">
                <div class="card-header">
                    <h4 class="label">Profile Settings</h4>
                </div>

                <div class="card-body">
                    <smart-list-box id="settingsListBox" (onChange)="onListBoxChange($event)">
                        <smart-list-item [selected]="true">Account</smart-list-item>
                        <smart-list-item>Password</smart-list-item>
                        <smart-list-item>Privacy and safety</smart-list-item>
                        <smart-list-item>Email notifications</smart-list-item>
                        <smart-list-item>Web notifications</smart-list-item>
                        <smart-list-item>Widgets</smart-list-item>
                        <smart-list-item>Your data</smart-list-item>
                        <smart-list-item>Delete account</smart-list-item>
                    </smart-list-box>
                </div>
            </div>
        </div>

        <div #mainCol id="mainCol" class="custom-col col-entity-lg-8">
            <div #accountTemplate id="accountTemplate">
                <div class="custom-card">
                    <div class="card-header">
                        <h4 class="label">Public info</h4>
                        <div class="settings-button" #publicInfoButton (click)="toggleMenu(publicInfoButton)"
                        (pointerup)="stopPropagation($event)">…</div>
                    </div>

                    <div class="card-body">
                        <div id="publicInfoContainer" class="custom-row d-flex">
                            <div class="custom-col col-entity-md-8 order-2 order-md-1">
                                <div style="margin-bottom: 0.75rem;">
                                    <label id="userNameLabel">Username</label>
                                    <smart-input id="userNameInput" class="w-100" placeholder="Username"></smart-input>
                                </div>

                                <div style="margin-bottom: 1rem;">
                                    <label id="biographyLabel">Biography</label>
                                    <smart-text-area id="biographyInput" class="w-100"
                                        placeholder="Share something here...">
                                    </smart-text-area>
                                </div>

                                <smart-button class="primary">Save changes</smart-button>
                            </div>

                            <div class="custom-col col-entity-md-4 order-1 order-md-2 text-center">
                                <div class="avatar-holder">
                                    <div class="img-as-background square rounded-circle">
                                        <img src="assets/images/phonebook/mark.png" />
                                    </div>
                                </div>

                                <div class="upload-btn-wrapper">
                                    <smart-button id="uploadButton" class="small-button primary"><span
                                            class="material-icons">cloud_upload</span> Upload</smart-button>
                                </div>

                                <small>Choose an image no <br />larger than 3MB</small>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="custom-card">
                    <div class="card-header">
                        <h4 class="label">Private info</h4>
                        <div class="settings-button" #privateInfoButton (click)="toggleMenu(privateInfoButton)"
                        (pointerup)="stopPropagation($event)">…</div>
                    </div>

                    <div class="card-body">
                        <div id="privateInfoContainer">
                            <div class="custom-row">
                                <div class="custom-col col-entity-sm-6">
                                    <label id="firstNameLabel">First name</label>
                                    <smart-input id="firstNameInput" class="w-100" placeholder="First name">
                                    </smart-input>
                                </div>
                                <div class="custom-col col-entity-sm-6">
                                    <label id="lastNameLabel">Last name</label>
                                    <smart-input id="lastNameInput" class="w-100" placeholder="Last name"></smart-input>
                                </div>
                                <div class="custom-col col-entity-sm-12">
                                    <label id="emailLabel">Email</label>
                                    <smart-input id="emailInput" class="w-100" placeholder="Email"></smart-input>
                                </div>
                                <div class="custom-col col-entity-sm-12">
                                    <label id="addressLabel">Address</label>
                                    <smart-input id="addressInput" class="w-100" placeholder="9581 Lovers Ln">
                                    </smart-input>
                                </div>
                                <div class="custom-col col-entity-sm-12">
                                    <label id="address2Label">Address 2</label>
                                    <smart-input id="address2Input" class="w-100" placeholder="7118 Spring St">
                                    </smart-input>
                                </div>
                                <div class="custom-col col-entity-sm-4">
                                    <label id="cityLabel">City</label>
                                    <smart-input id="cityInput" class="w-100"></smart-input>
                                </div>
                                <div class="custom-col col-entity-sm-4">
                                    <label id="stateLabel">State</label>
                                    <smart-input id="stateInput" class="w-100" placeholder="Select a state..."
                                        drop-down-button-position="right"
                                        data-source='["Andhra Pradesh", "Manipur", "Tamil Nadu"]'></smart-input>
                                </div>
                                <div class="custom-col col-entity-sm-4">
                                    <label id="zipLabel">ZIP</label>
                                    <smart-input id="zipInput" class="w-100"></smart-input>
                                </div>
                            </div>

                            <smart-button class="primary">Save changes</smart-button>
                        </div>
                    </div>
                </div>
            </div>
            <div #passwordTemplate id="passwordTemplate">
                <div class="custom-card">
                    <div class="card-header">
                        <h4 class="label">Password</h4>
                    </div>
                    <div class="card-body">
                        <div id="passwordInfoContainer">
                            <div class="current-pass">
                                <label>Current password</label>
                                <smart-input class="w-100"></smart-input>
                                <a href="#" class="styled forgot">Forgot your password?</a>
                            </div>

                            <div class="new-pass">
                                <label>New password</label>
                                <smart-input class="w-100"></smart-input>
                            </div>

                            <div class="re-pass">
                                <label>Verify password</label>
                                <smart-input class="w-100"></smart-input>
                            </div>

                            <smart-button class="primary">Save changes</smart-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>