import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'sm-changelog',
  templateUrl: './changelog.component.html',
  styleUrls: ['./changelog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChangelogComponent{

  constructor() { }

}
