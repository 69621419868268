<div class="container ajax-data">
    <div class="custom-row">
        <div class="ajax-data-header custom-col">
            <h4>Table With Data From Ajax</h4>
        </div>
    </div>

    <div class="custom-card">
        <div class="card-body">
            <p id="loading">Remote data is being loaded...</p>
            <smart-table #ajaxTable id="ajax-data" [dataSource]="ajaxDataSettings.dataSource"
                [columnReorder]="ajaxDataSettings.columnReorder" [sortMode]="ajaxDataSettings.sortMode"
                [columns]="ajaxDataSettings.columns" [paging]="false" [onInit]="ajaxDataSettings.onInit">
            </smart-table>
        </div>
    </div>
</div>