import {Component, OnInit, OnDestroy, AfterViewInit, ViewEncapsulation} from '@angular/core';
import {MeetingService} from "src/app/pages/meetings/services/meeting.service";
import {WindowModule} from 'smart-webcomponents-angular/window';
import {ButtonModule} from "smart-webcomponents-angular/button";
import {ActivatedRoute, Router} from "@angular/router";
import { CommonModule } from '@angular/common';
import {BrowserModule} from "@angular/platform-browser"; // Can't bind to 'ngIf' since it isn't

@Component({
    selector: 'create-meeting',
    templateUrl: 'create-meeting.component.html',
    providers: [BrowserModule, ButtonModule, WindowModule, CommonModule], // Can't bind to 'ngIf' since it isn't
    styleUrls: ['create-meeting.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class CreateMeetingComponent implements AfterViewInit, OnDestroy, OnInit {


    constructor(
        public meetingService: MeetingService,
        private router: Router,
        private activeRouter: ActivatedRoute // Отображает текущий роут компонента
    ) {
        Object.assign(CreateMeetingComponent, {
            MeetingService,
        });
    }

    ngAfterViewInit(): void {
    }

    ngOnDestroy(): void {
    }

    // Метод вызывается когда инициализируется компонент
    ngOnInit(): void {

    }

}
