import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {HouseService} from "src/app/pages/meetings/services/house.service";
import {DataAdapter, Smart} from 'smart-webcomponents-angular/grid';
import {Subscription} from "rxjs";
import {House} from "../../../models/house";
import {TableComponent} from "smart-webcomponents-angular/table";
import {WindowComponent, WindowModule} from 'smart-webcomponents-angular/window';
import {ButtonComponent, ButtonModule} from "smart-webcomponents-angular/button";
import {BrowserModule} from "@angular/platform-browser";
import {Router} from "@angular/router";
import {UrlService} from "../services/url.service";
import {DatePipe} from "@angular/common";

// @ts-ignore
@Component({
    selector: 'list-houses',
    templateUrl: './list-house.component.html',
    providers: [BrowserModule, ButtonModule, WindowModule],
    styleUrls: ['./list-house.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ListHouseComponent implements AfterViewInit, OnDestroy, OnInit {

    static houseService: HouseService;
    houses: House[];
    // Переменная отвечающая за подписку
    mSub: Subscription
    selectedItems: Array<any> = [];
    agreeDeleteCheckbox: boolean = false; // Флаг кнопка согласие в модальном окне
    isDisabledButtonDeleteAll: boolean = true; // Изначально отключено. Кнопка удалить все
    count_meeting: number
    person: any;

    constructor(
        private houseService: HouseService,
        private urlService: UrlService,
        private router: Router
    ) {
        Object.assign(ListHouseComponent, {
            houseService,
        });
    }

    @ViewChild('button', {read: ButtonComponent, static: false}) button!: ButtonComponent;
    @ViewChild('modalWindow', {read: WindowComponent, static: false}) modalWindow: WindowComponent;
    @ViewChild('formWindow', {read: WindowComponent, static: false}) formWindow: WindowComponent;
    @ViewChild('table', {read: TableComponent, static: false}) tableWithButtons!: TableComponent;

    /**
     * Очищаем подписку на стрим чтобы не было утечек по памяти
     */
    ngOnDestroy() {
        if (this.mSub) {
            this.mSub.unsubscribe();
        }
    }

    onWindowReady(event: any): void {
        this.init();
    }

    /**
     * Запускается 3 - им
     */
    init(): void {
        // init code.
        console.log('Запустился init()');
    }

    /**
     * Св-во нужно для инициализации. Для интерфейса. Нужен для норм работы
     */
    onInit(): void {
        // init code.
        console.log('Запустился onInit()');
    };


    /**
     * Запускается 1 на странице
     */
    ngOnInit() {
        console.log('Запустился ngOnInit()');
        // Получаем начальные данные по запросу
        this.getHouses();
    }

    /**
     * Запускается 2 на странице после ngOnInit()
     */
    ngAfterViewInit() {
        // Заменяет иконки после прорисовки компонента на нужные
        const windowWithFeather = window as any;
        windowWithFeather.feather.replace();
        console.log('Запустился  ngAfterViewInit()');
        window.onload = function () {
            console.log(' window.onload ');

        }
    }
    onButtonClick(event: any): void {
        this.formWindow.opened ? this.formWindow.close() : this.formWindow.open();
    }

    onButtonClick2(event: any): void {
        this.formWindow.close();
    }

    /**
     **  Провайдер данных обозначаем какие поля в нем будут
     */
    dataAdapter: DataAdapter = new Smart.DataAdapter({
        dataSource: [],
        dataFields: [
            'id: number',
            'count_meeting: number',
            'count_owners: number',
            'address: any',
            'comment: string',
            'create_date: string',
        ],
    })

    dataSource = this.dataAdapter;

    /**
     * Вызывается обработчик события и апдейтится поле когда закончили редактирование в колонке
     * @param event
     */
    onItemAfterEdit(event: any) {
        this.mSub = this.houseService.updateHouseValue(event.detail).subscribe(houses => {
            // Получили обновленные данные и записали в this.dataAdapter.dataSource
            this.getHouses();
            // Скинули все чекбоксы
            this.tableWithButtons.clearSelection();
        });
    }

    /**
     * Вызывается обработчик события когда кликнули по иконкам или чекбоксу
     * @param event
     */
    onCellClick(event: { detail: { dataField: string; row: { id: number; }; id: string; }; target: any; }) {
        //Клик по иконке edit
        if (event.detail.dataField === 'actionEdit') {
            this.urlService.urlParams = event.detail.row.id;
            this.router.navigate(['houses/' + event.detail.row.id + '/meetings']);
        }
        //Клик по иконке Id
        if (event.detail.dataField === 'actionId') {
            this.urlService.urlParams = event.detail.row.id;
            this.router.navigate(['houses/' + event.detail.row.id]);
        }
        //Клик по иконке delete
        if (event.detail.dataField === 'actionDelete') {
            this.deleteHouse(event.detail.row.id)
            document.querySelector('[row-id="' + event.detail.id + '"]')?.remove();
        }

        //Клик по чекбоксу
        if (this.selectedItems === undefined || this.selectedItems.length == 0) {
            this.selectedItems.push(event.detail.row.id);
        } else {
            // Если еще нет значения в массиве то добавляем
            if (!this.selectedItems.includes(event.detail.row.id)) {
                //console.log('еще нет значения');
                this.selectedItems.push(event.detail.row.id);
            } else {
                //console.log('такое есть значения'); Удаляем из массива selectedItems
                const index = this.selectedItems.indexOf(event.detail.row.id, 0);
                //console.log('index - ' + index);
                if (index > -1) {
                    this.selectedItems.splice(index, 1);
                }
            }
        }
    }

    /**
     * Клик по выбрать все чекбоксы. Записываем данные в this.selectedItems
     * @param event
     */
    onChangeCheckbox(event: { detail: { dataField: string; row: { id: number; }; id: string; }; target: any; type: string }) {
        let allCheckboxSelected = document.querySelectorAll('th.selected');
        let sameCheckboxSelected = document.querySelectorAll('th.indeterminate');

        if (allCheckboxSelected.length > 0 || sameCheckboxSelected.length > 0) {
            this.isDisabledButtonDeleteAll = false;

            let checked = document.querySelectorAll('tr[aria-selected="true"]');
            checked.forEach((item, key) => {

                // @ts-ignore
                let val = item.querySelector('[data-field="id"]').innerHTML;
                // Если еще нет значения в массиве то добавляем
                if (!this.selectedItems.includes(Number(val))) {
                    //console.log('еще нет значения');
                    this.selectedItems.push(Number(val));
                }
            })
        } else {
            this.selectedItems = [];
            this.isDisabledButtonDeleteAll = true;
        }
    }

    /**
     * Кнопка Удалить все выбранные чекбоксы. Http запрос на сервер и визуал
     */
    deleteSelectedCheckBox(event: any): void {
        // Навешиваем обработчики событий.
        const cancelHandler = (event: CustomEvent): void => {
            const target: HTMLElement = event.target as HTMLElement,
                modal = this.formWindow.nativeElement;
            target.closest('smart-window') === modal ? modal.close() : modal.close();
            this.agreeDeleteCheckbox = false;
        }
        // Подтверждение
        const agreeHandler = (event: CustomEvent): void => {
            const target: HTMLElement = event.target as HTMLElement,
                modal = this.formWindow.nativeElement;
            target.closest('smart-window') === modal ? modal.close() : modal.close();
            this.agreeDeleteCheckbox = true;
            this.isDisabledButtonDeleteAll = true;
            this.deleteSelectedCheckBox(event)
        }
        console.log('sdsds1');
        // @ts-ignore may be null
        document.getElementById('сancelButton').addEventListener('click', cancelHandler);
        // @ts-ignore may be null
        document.getElementById('agreeHandler').addEventListener('click', agreeHandler);
        // Конец навешиваем обработчики событий.

        let sameCheckboxSelected = document.querySelectorAll('th.indeterminate');
        let allCheckboxSelected = document.querySelectorAll('th.selected');
        const target: HTMLElement = event.target as HTMLElement,
            modal = this.formWindow.nativeElement;
        if (allCheckboxSelected.length > 0 || sameCheckboxSelected.length > 0) {
            target.closest('smart-window') === modal ? modal.open() : modal.open();
            this.isDisabledButtonDeleteAll = false;
        } else {
            this.selectedItems = [];
            this.isDisabledButtonDeleteAll = true;
        }
        if (this.agreeDeleteCheckbox) {
            target.closest('smart-window') === modal ? modal.close() : modal.close();
            this.mSub = this.houseService.deleteHouses(this.selectedItems)
                .subscribe(houses => {
                    // Обновили таблицу
                    this.getHouses();
                    // Скинули все чекбоксы
                    this.tableWithButtons.clearSelection();
                });
            this.selectedItems = [];
            this.agreeDeleteCheckbox = false;
            // this.ngOnInit()
            //Перезагружаем текущую страницу
            location.reload()
        }
        this.router.navigate(['houses/']);

        this.agreeDeleteCheckbox = false;
    }

    /**
     * Получить все дома
     */
    getHouses(): void {
/*        this.mSub = this.houseService.getCountMeetings().subscribe(houses => {
            // Заполнили данными
            // добавить в массив одну пару "ключ-значение"
            this.person = houses.results.houses;
            console.log(this.person);
          var obj = {...houses.results.houses[0], ...houses.results};
            console.log(obj);
        });*/
        this.mSub = this.houseService.getHouses()
            .subscribe(houses => {
      /*          console.log(houses.results.houses);
                    for (let key in this.person) {
                        for (let key1 in houses.results.houses) {
                        console.log('jjj');
                            if (houses.results.houses['id'] === this.person['house_id']) {
                                houses.results.houses[key].count_meeting = this.person[key]['count_meeting'];
                            }
                    }
                }*/
                // Заполнили данными
                console.log(houses.results.houses);
      /*          console.log('person');
                console.log(this.person);
                var obj = {...this.person, ...houses.results.houses[0]};
                console.log(obj);*/

               this.dataAdapter.dataSource = houses.results.houses
            });
    }

    /**
     * Удалить дом
     */
    deleteHouse(id: number): void {
        this.mSub = this.houseService.deleteHouse(id)
            .subscribe(houses => {
                // Заполнили данными
                // this.dataAdapter.dataSource = meetings.result;
            });
    }

    /**
     * Создать новый дом
     */
    addMeeting(event: any): void {
        this.router.navigate(['houses/create']).then();
    }

    /**
     * Настройки таблицы
     */
    meetingsDataSettings = {
        /**
         * Св-во нужно для инициализации. Для интерфейса. Нужен для норм работы
         */
        onInit: () => {
            console.log('gggg')
            console.log(this.dataAdapter.keyDataField)
            console.log(this.dataAdapter.dataSource.count_meeting)
        },
        currentUrl: this.router.url,
        behavior: {columnResizeMode: 'growAndShrink'},
        meetingsService: ListHouseComponent.houseService,
        // Пример запроса по ajax. Проблема fetch делается несколько раз
        /*    dataSource: new window.Smart.DataAdapter({
              virtualDataSourceCache: false,
              virtualDataSource:  function (resultCallbackFunction: ResultCallbackFunction) {
                fetch('/api/v1/product/getMeetings/')
                  .then(response => response.json())
                  .then(json => {
                        const data: [] = json.results.map((result: {
                          id: number;
                          address: any;
                          meetingDate: string
                          initiator: string;
                          ownerCount: number;
                          questionCount: number;
                          meetingStatus: number;
                          paymentStatus: number;
                          comment: string;

                        }) => {
                      return {
                        id: result.id,
                        meetingDate: result.meetingDate,
                        address: result.address,
                        initiator: result.initiator,
                        ownerCount: result.ownerCount,
                        questionCount: result.questionCount,
                        meetingStatus: result.meetingStatus,
                        paymentStatus: result.paymentStatus,
                        comment: result.comment,
                      };
                    });
                    resultCallbackFunction({
                      dataSource: data
                    });
                  });
              },
              dataFields: [
                'id: number',
                'address: string',
                'meetingDate: string',
                'initiator: string',
                'ownerCount: number',
                'questionCount: number',
                'meetingStatus: number',
                'paymentStatus: number',
                'comment:       string',
                'createDate:    string',
                'updateDate: string'
              ],
            }),*/

        // Параметры таблицы
        pageIndex: 0,
        pageSize: "10",
        filtering: true,
        paging: true,
        sortMode: 'many',
        filterRow: false,
        tooltip: true,
        selection: true,
        editing: true,
        editMode: 'row',
        keyboardNavigation: true,

        // Колонки в таблице
        columns: [
            {
                label: 'id', dataField: 'id', dataType: 'number', responsivePriority: 3, width: 0
            },
            {
                label: 'Id', dataField: 'actionId', dataType: 'number', responsivePriority: 3, width: 50,
                formatFunction: function (settings: any) {
                    settings.template =
                        '<a href="javascript:;" class="nav-link rounded action-icon" style="color:#007bff">' + settings.data.id + '</a>';
                }
            },
            {
                label: '', dataField: 'actionEdit', allowEdit: false, width: 100,
                formatFunction: function (settings: any) {
                    settings.template = '<div class="actions-container"><smart-button class="small-button outlined primary">Собрания</smart-button></div>';
                }
            },
           {label: 'Кол-во собраний', dataField: 'count_meeting', dataType: 'number', responsivePriority: 3},
           {label: 'Кол-во собственников', dataField: 'count_owners', dataType: 'number', responsivePriority: 3},
     /*       {
                label: 'Кол-во собраний',
                dataField: 'count_meeting',
                allowEdit: false,
                dataType: 'number',
                responsivePriority: 3,
                formatFunction: (value: any) => {
                    console.log('value.dataSource')
              console.log(value.dataSource)
                }
            },*/
            {label: 'Адрес', dataField: 'address', dataType: 'string', responsivePriority: 4},
            {label: 'Комментарий', dataField: 'comment', dataType: 'string', responsivePriority: 3},
            /*    {label: 'Дата создания дома', dataField: 'createDate', allowEdit: false, dataType: 'string', responsivePriority: 3},*/
            {
                label: 'Дата создания дома',
                dataField: 'create_date',
                allowEdit: false,
                dataType: 'string',
                responsivePriority: 3,
                formatFunction: (value: any) => {
                    new DatePipe('en-US').transform(value.value, 'dd-MM-yyyy');
                    value.template = new DatePipe('en-US').transform(value.value, 'dd.MM.yyyy');
                }
            },
            {
                label: '', dataField: 'actionEdit', allowEdit: false, width: 50,
                formatFunction: function (settings: any) {
                    settings.template =
                        '<a href="javascript:;" class="nav-link rounded action-icon">' +
                        '<i class="material-icons">edit</i>' + '</a>';
                }
            },
            {
                label: '', dataField: 'actionDelete', allowEdit: false, width: 50,
                formatFunction: function (settings: any) {
                    settings.template =
                        '<a href="javascript:;" class="nav-link rounded action-icon">' +
                        '<i class="material-icons">delete_forever</i>' + '</a>';
                }
            },
        ],

    }

}
