<div class="table-with-buttons">
    <div class="custom-row">
        <div class="table-with-buttons-header custom-col">
            <h4>Дома <i data-feather="clipboard"></i> <span></span></h4>
            <p>В доме может быть несколько собраний</p>

        </div>
    </div>
    <div class="controls-container">
        <smart-button (onClick)="addMeeting($event)">Создать дом</smart-button>
        &nbsp;&nbsp;
        <smart-button #button2 (onClick)="deleteSelectedCheckBox($event)" [disabled]=this.isDisabledButtonDeleteAll>
            Удалить выбранные
        </smart-button>
    </div>
    <div class="custom-card">
        <div class="card-body">
            <smart-table #table id="table" [dataSource]="dataSource"
                         [paging]="meetingsDataSettings.paging" [sortMode]="meetingsDataSettings.sortMode"
                         [tooltip]="meetingsDataSettings.tooltip" [onInit]="meetingsDataSettings.onInit"
                         [columns]="meetingsDataSettings.columns" [filtering]="meetingsDataSettings.filtering"
                         [filterRow]="meetingsDataSettings.filterRow" [pageIndex]="meetingsDataSettings.pageIndex"
                         [pageSize]="meetingsDataSettings.pageSize"
                         [selection]="meetingsDataSettings.selection" [editing]="meetingsDataSettings.editing"
                         (onCellEndEdit)="onItemAfterEdit($event)" (onCellClick)="onCellClick($event)"
                         (onChange)="onChangeCheckbox($event)"
            ></smart-table>
        </div>
    </div>

    <smart-window #formWindow id="formWindow" [footerTemplate]="'footerTemplate'" [opened]="false" [label]="'Внимание!'"
                  (onReady)="onWindowReady($event)">
        <div id="article">
            <section>
                <h3>Уверены что хотите удалить выделенные дома?</h3>
                <p>При нажатии на кнопку все выделенные дома удаляться их нельзя будет вернуть </p>
                <p>Также удалятся все собрания которые проводились в этих домах  </p>
            </section>
        </div>
    </smart-window>
    <template id='footerTemplate'>
        <smart-button id="сancelButton">Нет</smart-button>
        <smart-button id="agreeHandler">Да</smart-button>
    </template>
</div>
