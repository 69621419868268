import { Component, ViewChild } from '@angular/core';
import { TableComponent } from 'smart-webcomponents-angular/table';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'sm-responsive-table',
  templateUrl: './responsive-debitorka-table.component.html',
  styleUrls: ['./responsive-debitorka-table.component.scss']
})
export class ResponsiveDebitorkaTableComponent {

  @ViewChild('responsiveDebitorkaTable', { read: TableComponent, static: false }) columnSearchTable!: TableComponent;

  tableSettings = {
    dataSource: new window.Smart.DataAdapter({
      dataSource: this.dataService.generateData(50),
      dataFields: [
        'id: number',
        'organizationName: string',
        'lastName: string',
        'productName: string',
        'quantity: number',
        'price: number',
        'total: number'
      ]
    }),
    onInit: () => {
      this.columnSearchTable.sortBy('lastName', 'asc');
    },
    filtering: true,
    filterRow: true,
    selection: true,
    paging: true,
    sortMode: 'one',
    tooltip: true,
    columns: [
      { label: 'id', dataField: 'id', dataType: 'number' },
      { label: 'Название организации', dataField: 'organizationName', dataType: 'string', responsivePriority: 4 },
      { label: 'Last Name', dataField: 'lastName', dataType: 'string' },
      { label: 'Product Name', dataField: 'productName', dataType: 'string' },
      { label: 'Quantity', dataField: 'quantity', dataType: 'number', responsivePriority: 3 },
      { label: 'Price', dataField: 'price', dataType: 'number', responsivePriority: 3 },
      { label: 'Total', dataField: 'total', dataType: 'number' },
      {
        label: 'Action', dataField: 'action', formatFunction: function (settings: any) {
          settings.template = '<div class="actions-container"><span class="material-icons">edit</span><span class="material-icons">delete_forever</span></div>';
        }
      }
    ]
  }
  constructor(private dataService: DataService) { }

}
