import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {HttpErrorHandler, HandleError} from '../../../services/http-error-handler.service';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Meeting} from '../../../models/metting';


// Благодаря такому декоратору @Injectable мы можем инжектировать в данный сервис другие сущности
@Injectable({
    // Данной строкой мы говорим чтобы ангуляр зарегал данный сервис в корневом модуле (в системе в единственном экз.).
    providedIn: 'root'
    // Поэтому из корневого модуля можно исключить MeetingService так как он регается тут
    // Данный сервис зареган глобально и виден во всех компонентах
    // Можно регать сервисы локально (каждый раз создается копия сервиса с новыми данными) в конкретном компоненте тогда нужно в конкретный компонент в декоратор
    //  @Component({
    //    providers: [MessageService],
    //  })
    // Также можно использовать сервисы внутри других сервисов. Внедрение через конструктор
})

export class MeetingService {

    private readonly handleError: HandleError;
    meetingsUrl = '/api/v1/meeting/';  // URL to web api

    // Переменная содержит выпадающий список
    meetingType: any
    meetingParticipants: any
    meetingInitiator: any
    meetingOnline: any
    meetingQueue: any
    meetingPeople: any
    apartmentOwner: any
    apartmentOwnerSource: any = [] // Нужен именно такой формат массива чтобы нормально в инпуте отображалось

    //Данные по предзагрузке для уведомления
    preloadMeetingNotify: any;

    constructor(
        private http: HttpClient,
        httpErrorHandler: HttpErrorHandler
    ) {
        this.handleError = httpErrorHandler.createHandleError('MeetingService');
      }

    getTheme() {
        return document.body.getAttribute('theme');
    }

    getMeetings(detail: any): Observable<any> {
        const url = `${this.meetingsUrl + 'getMeetings'}`;

        return this.http.post(url, {
            params: {
                houseId: detail.houseId
            }
        })
            .pipe(
                catchError(this.handleError('getMeetings', []))
            );
    }

    getHouses(): Observable<any> {
        return this.http.post(this.meetingsUrl + 'getHouses', [])
            .pipe(
                catchError(this.handleError('getHouses', []))
            );
    }

    updateMeetingValue(detail: any): Observable<{}> {
        const url = `${this.meetingsUrl + 'updateMeetingValue'}`;
        return this.http.post(url, {
            params: detail
        })
            .pipe(
                catchError(this.handleError('updateMeetingValue'))
            );
    }

    createMeeting(detail: any): Observable<any> {
        const url = `${this.meetingsUrl + 'createMeeting'}`;
        return this.http.post(url, {
            params: detail
        })
            .pipe(
                catchError(this.handleError('createMeetings'))
            );
    }

    deleteMeeting(id: number): Observable<{}> {
        const url = `${this.meetingsUrl + 'deleteMeeting'}/${id}`;
        return this.http.post(url,
            {
                params: {
                    "id": id,
                }
            })
            .pipe(
                catchError(this.handleError('deleteMeeting'))
            );
    }

    deleteMeetings(ids: any): Observable<{}> {
        const url = `${this.meetingsUrl + 'deleteMeetings'}`;
        return this.http.post(url, {
            params: {
                "ids": ids,
            }
        })
            .pipe(
                catchError(this.handleError('deleteMeetings'))
            );
    }

    updateMeeting(meeting: Meeting): Observable<any> {
        return this.http.post(this.meetingsUrl, meeting)
            .pipe(
                catchError(this.handleError('updateMeeting', meeting))
            );
    }

    /**
     * Получить тип собрания
     */
    getMeetingType(): Observable<{}> {
        const url = `${this.meetingsUrl + 'getMeetingType'}`;
        return this.http.post(url, {}).pipe(
            catchError(error => {
                console.log('Error: ', error.message)
                return throwError(error)
            })
        );

    }

    /**
     * Получить участников собрания
     */
    getMeetingParticipants(): Observable<any> {
        const url = `${this.meetingsUrl + 'getMeetingParticipant'}`;
        return this.http.post(url, {}).pipe(
            catchError(error => {
                console.log('Error: ', error.message)
                return throwError(error)
            })
        );

    }

    /**
     * Получить инициатора собрания
     */
    getMeetingInitiator(): Observable<any> {
        const url = `${this.meetingsUrl + 'getMeetingInitiator'}`;
        return this.http.post(url, {}).pipe(
            catchError(error => {
                console.log('Error: ', error.message)
                return throwError(error)
            })
        );

    }

    /**
     * Получить тип собрания (очное/заочное и т.д)
     */
    getMeetingOnline(): Observable<any> {
        const url = `${this.meetingsUrl + 'getMeetingOnline'}`;
        return this.http.post(url, {}).pipe(
            catchError(error => {
                console.log('Error: ', error.message)
                return throwError(error)
            })
        );

    }

    /**
     * Получить очередность собрания
     */
    getMeetingQueue(): Observable<any> {
        const url = `${this.meetingsUrl + 'getMeetingQueue'}`;
        return this.http.post(url, {}).pipe(
            catchError(error => {
                console.log('Error: ', error.message)
                return throwError(error)
            })
        );

    }

    /**
     * Получить тип собрания (Общее собрание собственников/Общее собрание членов ТСЖ)
     */
    getMeetingPeople(): Observable<any> {
        const url = `${this.meetingsUrl + 'getMeetingPeople'}`;
        return this.http.post(url, {}).pipe(
            catchError(error => {
                console.log('Error: ', error.message)
                return throwError(error)
            })
        );

    }

    /**
     * Получить данные по предзагрузке для уведомления для input
     * @param meetingId
     */
    getMeetingNotify(meetingId: any): Observable<any> {
        return this.http.post(this.meetingsUrl + 'getMeetingNotify', {
            params: {
                meetingId: Number(meetingId),
            }
        })
            .pipe(
                catchError(this.handleError('getMeetingNotify', []))
            );
    }

    /**
     * Создать уведомление в конкретном собрании
     * @param detail
     */
    createNotify(detail: any): Observable<any> {
        const url = `${this.meetingsUrl + 'createNotify'}`;
        return this.http.post(url, {
            params: detail
        })
            .pipe(
                catchError(this.handleError('createNotify'))
            );
    }

    /**
     * Создать уведомление о собрании собственников
     */
    createNotifyMeetingDoc(params: any): Observable<any> {

        return this.http.post(this.meetingsUrl + 'createNotifyMeetingDocument', {
            params: {
                meetingId: params.meetingId,
                houseId: params.houseId,
                userId: params.userId
            }
        },
        { responseType: 'blob' }
        )
            .pipe(
                catchError(this.handleError('createNotifyMeetingDocument', []))
            );
    }

    /**
     * Создать акт о размещении уведомления о собрании собственников
     */
    createActNotifyDoc(params: any): Observable<any> {
        return this.http.post(this.meetingsUrl + 'createActNotifyDocument', {
            params: {
                meetingId: params.meetingId,
                houseId: params.houseId,
                userId: params.userId
            },
        },
        { responseType: 'blob' }
        )
            .pipe(
                catchError(this.handleError('createActNotifyDocument'))
            );
    }

    /**
     * Создать реестр собственников
     */
    createOwnersDoc(params: any): Observable<any> {
        return this.http.post(this.meetingsUrl + 'createOwnersDocument', {
                params: {
                    meetingId: params.meetingId,
                    houseId: params.houseId,
                    userId: params.userId
                },
            },
            { responseType: 'blob' }
        )
            .pipe(
                catchError(this.handleError('createOwnersDocument'))
            );
    }

    /**
     * Создать реестр собственников принявших участие в голосовании
     */
    createOwnersDocFact(params: any): Observable<any> {
        return this.http.post(this.meetingsUrl + 'createOwnersDocumentFact', {
                params: {
                    meetingId: params.meetingId,
                    houseId: params.houseId,
                    userId: params.userId
                },
            },
            { responseType: 'blob' }
        )
            .pipe(
                catchError(this.handleError('createOwnersDocumentFact'))
            );
    }


    /**
     * Создать бюллетень для голосования собственников
     */
    createAnketaOwnersDoc(params: any): Observable<any> {
        return this.http.post(this.meetingsUrl + 'createAnketaOwnersDocument', {
                params: {
                    meetingId: params.meetingId,
                    houseId: params.houseId,
                    userId: params.userId
                },
            },
            { responseType: 'blob' }
        )
            .pipe(
                catchError(this.handleError('createAnketaOwnersDoc'))
            );
    }

}
