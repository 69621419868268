<div id="cardsView" class="container cards">
    <div class="custom-row">
        <div class="cards-header custom-col"><h4>Cards</h4></div>
    </div>

    <div class="cards-with-images-wrapper custom-row">
        <div class="cars-card-image-link-button custom-col">
            <smart-card>
                <div class="custom-card h-100">
                    <div class="card-body">
                        <div class="img-as-background">
                            <img src="../../../../assets/images/travel/16.jpg"/>
                        </div>
                        <h4 class='cards-card-header'>Card with image and links</h4>
                        <p class='cards-card-content'>Nulla tempus neque id eros blandit, id congue sem ultricies. Nulla molestie viverra aliquet.
                            Praesent feugiat mollis lacus, quis sagittis leo.</p>
                        <a href="#">Sample link 1</a>&nbsp;<a href="#">Sample link 2</a>
                    </div>
                </div>
            </smart-card>
        </div>

        <div class="cars-card-image-link-button custom-col">
            <smart-card>
                <div class="custom-card h-100">
                    <div class="card-body">
                        <div class="img-as-background">
                            <img src="../../../../assets/images/travel/4.jpg"/>
                        </div>
                        <h4 class='cards-card-header'>Card with image and button</h4>
                        <p class='cards-card-content'>Pellentesque eget magna augue. Nam accumsan dignissim vulputate. In hac habitasse platea
                            dictumst.</p>
                        <smart-button class="primary">Sample button</smart-button>
                    </div>
                </div>
            </smart-card>
        </div>

        <div class="cars-card-image-link-button custom-col">
            <smart-card>
                <div class="custom-card h-100">
                    <div class="card-body">
                        <div class="img-as-background">
                            <img src="../../../../assets/images/travel/25.jpg"/>
                        </div>
                        <h4 class='cards-card-header'>Card with image and list</h4>
                        <p class='cards-card-content'>Aliquam erat volutpat. Aenean tincidunt scelerisque mauris, in pharetra mi venenatis ut.</p>
                        <ul class="list">
                            <li>Praesent scelerisque</li>
                            <li>Vestibulum quis</li>
                            <li>Mauris odio</li>
                            <li>Integer</li>
                        </ul>
                    </div>
                </div>
            </smart-card>
        </div>
    </div>

    <div class="cards-with-links-buttons-wrapper custom-row">
        <div class="cars-card-image-link-button custom-col">
            <smart-card>
                <div class="custom-card h-100">
                    <div class="card-body">
                        <h4 class='cards-card-header'>Card with links</h4>
                        <p class='cards-card-content'>Nulla tempus neque id eros blandit, id congue sem ultricies. Nulla molestie viverra aliquet. Praesent feugiat mollis lacus, quis sagittis leo.</p>
                        <a href="#">Sample link 1</a>&nbsp;<a href="#">Sample link 2</a>
                    </div>
                </div>
            </smart-card>
        </div>

        <div class="cars-card-image-link-button custom-col">
            <smart-card>
                <div class="custom-card h-100">
                    <div class="card-body">
                        <div class="content-holder-wrapper">
                            <div class="content-holder">
                                <h4 class='cards-card-header'>Card with button <span class="font-size-16px"> position bottom</span></h4>
                                <p class='cards-card-content'>Pellentesque eget magna augue. Nam accumsan dignissim vulputate. In hac habitasse platea dictumst.</p>
                            </div>

                            <div class="cards-card-with-button-button-wrapper">
                                <smart-button class="primary">Sample button</smart-button>
                            </div>
                        </div>
                    </div>
                </div>
            </smart-card>
        </div>

        <div class="cars-card-image-link-button custom-col">
            <smart-card>
                <div class="custom-card h-100">
                    <div class="card-body">
                        <h4 class='cards-card-header'>Card with list</h4>
                        <p class='cards-card-content'>Aliquam erat volutpat. Aenean tincidunt scelerisque mauris, in pharetra mi venenatis ut.</p>
                        <ul class="list">
                            <li>Praesent scelerisque</li>
                            <li>Vestibulum quis</li>
                            <li>Mauris odio</li>
                            <li>Integer</li>
                        </ul>
                    </div>
                </div>
            </smart-card>
        </div>
    </div>

    <div class="cards-with-tabs-wrapper custom-row">
        <div class="card-with-tab custom-col">
            <smart-card>
                <div class="custom-card h-100">
                    <div class="card-body">
                        <smart-tabs class="m-0 w-100">
                            <smart-tab-item label="Tab 1">
                                <h4 class='cards-card-header'>Card with tabs</h4>
                                <p class='cards-card-content'>Nulla tempus neque id eros blandit, id congue sem ultricies. Nulla molestie viverra aliquet. Praesent feugiat mollis lacus, quis sagittis leo.</p>
                                <smart-button class="primary">Sample button</smart-button>
                            </smart-tab-item>

                            <smart-tab-item label="Tab 2">
                                <h4 class='cards-card-header'>Card with tabs</h4>
                                <p class='cards-card-content'>Nunc lacus mi, fringilla et dignissim eu, pharetra sit amet sapien. Praesent lectus tellus, iaculis ut commodo id, auctor quis velit.</p>
                                <smart-button class="primary">Sample button 2</smart-button>
                            </smart-tab-item>

                            <smart-tab-item label="Tab 3">
                                <h4 class='cards-card-header'>Card with tabs</h4>
                                <p class='cards-card-content'>Aenean et viverra nibh. Sed aliquam facilisis libero, quis lacinia metus gravida ac. Curabitur vitae turpis accumsan diam malesuada placerat quis in felis.</p>
                                <smart-button class="primary">Sample button 3</smart-button>
                            </smart-tab-item>
                        </smart-tabs>
                    </div>
                </div>
            </smart-card>
        </div>

        <div class="card-with-tab custom-col">
            <smart-card>
                <div class="custom-card h-100">
                    <div class="card-body">
                        <smart-tabs class="m-0 w-100">
                            <smart-tab-item label="Pill 1">
                                <h4 class='cards-card-header'>Card with pills</h4>
                                <p class='cards-card-content'>Nulla tempus neque id eros blandit, id congue sem ultricies. Nulla molestie viverra aliquet. Praesent feugiat mollis lacus, quis sagittis leo.</p>
                                <smart-button class="primary">Sample button</smart-button>
                            </smart-tab-item>

                            <smart-tab-item label="Pill 2">
                                <h4 class='cards-card-header'>Card with pills</h4>
                                <p class='cards-card-content'>Nunc lacus mi, fringilla et dignissim eu, pharetra sit amet sapien. Praesent lectus tellus, iaculis ut commodo id, auctor quis velit.</p>
                                <smart-button class="primary">Sample button 2</smart-button>
                            </smart-tab-item>

                            <smart-tab-item label="Pill 3">
                                <h4 class='cards-card-header'>Card with pills</h4>
                                <p class='cards-card-content'>Aenean et viverra nibh. Sed aliquam facilisis libero, quis lacinia metus gravida ac. Curabitur vitae turpis accumsan diam malesuada placerat quis in felis.</p>
                                <smart-button class="primary">Sample button 3</smart-button>
                            </smart-tab-item>
                        </smart-tabs>
                    </div>
                </div>
            </smart-card>

        </div>
    </div>
</div>