import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {SharedService} from "../../service/shared.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'basic-input-select',
  templateUrl: './basic-input-select.component.html',
  styleUrls: ['./basic-input-select.component.scss'],
  providers: [SharedService],
  encapsulation: ViewEncapsulation.None
})
export class BasicInputSelectComponent implements OnInit{

  @Input() urlForGetData = ''
  @Input() title = 'Default select'
  @Input() formControlName = ''
  @Input() meetingCreateForm: FormGroup

  // Переменная содержит выпадающий список
  selectList: any

  constructor(
      private sharedService: SharedService,
      ) { }

  ngOnInit() {
    console.log(this.urlForGetData)
    this.sharedService.getSelectList(this.urlForGetData).subscribe((data:any) => this.selectList = data.results)
  }

}
