<div class="container notifications">
    <div class="custom-row">
        <div class="notifications-header custom-col">
            <h4>Notifications</h4>
        </div>
    </div>

    <div class="custom-card">
        <div class="card-body">
            <p>Smart.Toast represents an element which displays an unobtrusive notification to the user.</p>
            <br />
            <div class="custom-row">
                <div class="notifications-entity-lg-6 custom-col">
                    <div>
                        <label>Message</label>
                        <smart-input #messageInput (onChange)="messageInputChange()" id="messageInput"
                            placeholder="Enter a message" value="Sample notification">
                        </smart-input>
                        <label>Type</label>
                        <smart-input (onChange)="typeInputChange($event)" id="typeInput" drop-down-height="400"
                            data-source='["info", "warning", "success", "error", "mail", "time"]'
                            drop-down-button-position="right" [readonly]="true" value="warning"></smart-input>
                        <smart-check-box (onChange)="enableAutoCloseChange($event)" id="enableAutoClose" [checked]="true">Auto close</smart-check-box>
                        <smart-check-box (onChange)="enableBlinkChange($event)" id="enableBlink" [checked]="true">Blink</smart-check-box>
                        <smart-check-box (onChange)="enableCloseButtonChange($event)" id="enableCloseButton" [checked]="true">Close button</smart-check-box>
                    </div>
                </div>

                <div class="notifications-entity-lg-6 custom-col">
                    <div>
                        <label>Position</label>
                        <smart-radio-button (onChange)="topLeftChange()" id="topLeft">Top-left</smart-radio-button>
                        <smart-radio-button (onChange)="topRightChange()" id="topRight">Top-right</smart-radio-button>
                        <smart-radio-button (onChange)="bottomLeftChange()" id="bottomLeft">Bottom-left</smart-radio-button>
                        <smart-radio-button (onChange)="bottomRightChange()" id="bottomRight" [checked]="true">Bottom-right</smart-radio-button>
                    </div>
                </div>
            </div>

            <div class="notifications-buttons-wrapper">
                <smart-button (click)="showToast()" id="show" class="small-button primary">Show notification</smart-button>&nbsp;<smart-button
                    (click)="clearAllToasts()" id="clearAll" class="small-button primary outlined">Clear all</smart-button>
            </div>
        </div>
    </div>
</div>

<smart-toast #toast id="toast" class="blink" auto-close position="bottom-right" show-close-button type="warning">Sample
    notification</smart-toast>