<div class="container social-page">
    <div class="custom-row">
        <div class="custom-col col-entity-lg-8" style="margin-bottom: 1.5rem;">
            <div class="custom-card">
                <div class="card-body">
                    <div class="activity-wrapper border-bottom">
                        <div class="avatar-holder">
                            <div class="img-as-background square rounded-circle activity-main-image border">
                                <img src="assets/images/phonebook/maya.png" />
                            </div>
                        </div>

                        <div class="activity-content">
                            <div class="custom-row justify-content-between name-row">
                                <div class="custom-col">
                                    <h6 class="font-weight-bold">Eva Hayes</h6>
                                </div>
                                <div class="custom-col text-right">
                                    <small class="text-muted">7m ago</small>
                                </div>
                            </div>

                            <div class="content">
                                Aliquam eleifend orci eget tortor ultrices suscipit. In felis odio, viverra et est et,
                                mollis vestibulum lorem. Donec laoreet tellus sit amet ligula blandit, sed dignissim
                                lacus
                                imperdiet. Aliquam consequat auctor ex eget facilisis.
                                <br />
                                <br />
                                <img src="assets/images/travel/12.jpg" />
                            </div>

                            <div class="meta d-flex justify-content-between align-items-center">
                                <small class="text-muted">Today, 11:23 am</small>
                                <smart-button class="small-button outlined error"><span
                                        class="material-icons">favorite</span> Like</smart-button>
                            </div>

                            <div class="comments">
                                <h6 class="font-weight-bold">Comments:</h6>
                                <div class="comment d-flex">
                                    <div class="avatar-holder">
                                        <div class="img-as-background square rounded-circle border">
                                            <img src="assets/images/phonebook/michael.png" />
                                        </div>
                                    </div>

                                    <div class="content"><strong>Frederick Murphy:</strong> Vivamus quis ultrices
                                        nisi. Vestibulum
                                        sodales tristique odio, sodales
                                        mollis eros faucibus non.
                                    </div>
                                </div>

                                <div class="comment d-flex">
                                    <div class="avatar-holder">
                                        <div class="img-as-background square rounded-circle border">
                                            <img src="assets/images/phonebook/maya.png" />
                                        </div>
                                    </div>

                                    <div class="content"><strong>Eva Hayes:</strong> Thanks for your opinion.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="activity-wrapper border-bottom">
                        <div class="avatar-holder">
                            <div class="img-as-background square rounded-circle activity-main-image border">
                                <img src="assets/images/phonebook/maria.png" />
                            </div>
                        </div>

                        <div class="activity-content">
                            <div class="custom-row justify-content-between name-row">
                                <div class="custom-col">
                                    <h6 class="font-weight-bold">Joyce Scott</h6>
                                </div>
                                <div class="custom-col text-right">
                                    <small class="text-muted">2h ago</small>
                                </div>
                            </div>

                            <div class="content">
                                Phasellus mollis laoreet augue vel aliquet. Donec semper orci ut feugiat maximus.
                                Aliquam euismod scelerisque nisi quis molestie. Suspendisse posuere mauris egestas
                                lobortis suscipit.
                            </div>

                            <div class="meta d-flex justify-content-between align-items-center">
                                <small class="text-muted">Today, 9:11 am</small>
                                <smart-button class="small-button outlined error"><span
                                        class="material-icons">favorite</span> Like</smart-button>
                            </div>

                            <div class="comments">
                                <h6 class=" font-weight-bold">Comments:</h6>
                                <div class="comment d-flex">
                                    <div class="avatar-holder">
                                        <div class="img-as-background square rounded-circle border">
                                            <img src="assets/images/phonebook/maya.png" />
                                        </div>
                                    </div>

                                    <div class="content"><strong>Eva Hayes:</strong> Cras tincidunt ex id
                                        tincidunt interdum.</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="activity-wrapper border-bottom-0">
                        <div class="avatar-holder">
                            <div class="img-as-background square rounded-circle activity-main-image border">
                                <img src="assets/images/phonebook/monica.png" />
                            </div>
                        </div>

                        <div class="activity-content">
                            <div class="custom-row justify-content-between name-row">
                                <div class="custom-col">
                                    <h6 class="font-weight-bold">Victoria Ramos</h6>
                                </div>
                                <div class="custom-col text-right">
                                    <small class="text-muted">1d ago</small>
                                </div>
                            </div>

                            <div class="content">
                                In quis posuere lorem. Duis ac mauris vel neque bibendum luctus vel at metus. Cras
                                bibendum magna quis varius elementum.
                                <br />
                                <br />
                                <div class="text-center">
                                    <img src="assets/images/travel/11.jpg" style="margin-bottom: 0.75rem;" />&nbsp;<img
                                        src="assets/images/travel/10.jpg" style="margin-bottom: 0.75rem;" />
                                </div>
                            </div>

                            <div class="meta d-flex justify-content-between align-items-center">
                                <small class="text-muted">Yesterday, 06:14 pm</small>
                                <smart-button class="small-button outlined error"><span
                                        class="material-icons">favorite</span> Like</smart-button>
                            </div>

                            <div class="comments">
                                <h6 class=" font-weight-bold">Comments:</h6>

                                <span class=" text-muted">There is no comments for this article.</span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="custom-col col-entity-lg-4">
            <div class="custom-card" style="margin-bottom: 1.5rem;">
                <div class="card-body text-center">
                    <div class="img-as-background square rounded-circle profile-details-image">
                        <img src="assets/images/phonebook/mark.png" />
                    </div>

                    <h5>John Doe</h5>
                    <h6 class="profile-details-title">Manager, UX Department</h6>

                    <div>
                        <smart-button class="small-button primary outlined">Follow</smart-button>&nbsp;<smart-button
                            class="small-button primary outlined"><span class="material-icons">message</span> Chat
                        </smart-button>
                    </div>
                </div>
            </div>

            <div class="custom-card" style="margin-bottom: 1.5rem;">
                <div class="card-header">
                    <h4 class="label">Friends</h4>
                    <div class="settings-button" #friendsButton (click)="toggleMenu(friendsButton)"
                    (pointerup)="stopPropagation($event)">…</div>
                </div>
                <div class="card-body">
                    <div class="friends">
                        <div class="border-bottom friend">
                            <div class="img-as-background square rounded-circle w-50px">
                                <img src="assets/images/phonebook/maya.png" />
                            </div>

                            <div class="friend-info">
                                <h6>Eva Hayes</h6>
                                <smart-button class="small-button outlined primary">Remove</smart-button>
                            </div>
                        </div>

                        <div class="border-bottom friend">
                            <div class="img-as-background square rounded-circle w-50px">
                                <img src="assets/images/phonebook/maria.png" />
                            </div>

                            <div class="friend-info">
                                <h6>Joyce Scott</h6>
                                <smart-button class="small-button outlined primary">Remove</smart-button>
                            </div>
                        </div>

                        <div class="border-bottom friend">
                            <div class="img-as-background square rounded-circle w-50px">
                                <img src="assets/images/phonebook/michael.png" />
                            </div>

                            <div class="friend-info">
                                <h6>Frederick Murphy</h6>
                                <smart-button class="small-button outlined primary">Remove</smart-button>
                            </div>
                        </div>

                        <div class="border-bottom-0 friend">
                            <div class="img-as-background square rounded-circle w-50px">
                                <img src="assets/images/phonebook/monica.png" />
                            </div>

                            <div class="friend-info">
                                <h6>Victoria Ramos</h6>
                                <smart-button class="small-button outlined primary">Remove</smart-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="custom-card engagement-wrapper" style="margin-bottom: 1.5rem;">
                <div class="card-header">
                    <h4 class="label">Activities</h4>
                    <div class="settings-button" #activityButton (click)="toggleMenu(activityButton)"
                    (pointerup)="stopPropagation($event)">…</div>
                </div>

                <div class="card-body">
                    <div class="feeds">
                        <div class="feed custom-row">
                            <div class="feed-image-wrapper">
                                <div class="img-as-background">
                                    <img src="../../../assets/images/phonebook/michael.png" />
                                </div>
                            </div>

                            <div class="feed-text-wrapper">
                                <div class=""><strong>Frederick Murphy</strong> reacted to your post</div>
                                <small class="text-muted">Today 8:19 PM</small>
                            </div>

                            <div class="feed-image-wrapper">
                                <small class="w-auto text-muted">7 min ago</small>
                            </div>
                        </div>

                        <div class="feed custom-row">
                            <div class="feed-image-wrapper">
                                <div class="img-as-background">
                                    <img src="../../../assets/images/phonebook/maria.png" />
                                </div>
                            </div>

                            <div class="feed-text-wrapper">
                                <div class=""><strong>Elisha Scott</strong> is now engaged</div>
                                <small class="text-muted">Today 10:45 PM</small>
                            </div>

                            <div class="feed-posted-ago-wrapper">
                                <small class="w-auto text-muted">10 hours ago</small>
                            </div>
                        </div>

                        <div class="feed custom-row">
                            <div class="feed-image-wrapper">
                                <div class="img-as-background">
                                    <img src="../../../assets/images/phonebook/michael.png" />
                                </div>
                            </div>

                            <div class="feed-text-wrapper">
                                <div class=""><strong>John Doe</strong> is now engaged</div>
                                <small class="text-muted">Today 12:03 PM</small>
                            </div>

                            <div class="feed-posted-ago-wrapper">
                                <small class="w-auto text-muted">8 hours ago</small>
                            </div>
                        </div>
                    </div>

                    <smart-button class="primary social-page-load-more">Load more</smart-button>
                </div>
            </div>
        </div>
    </div>
</div>