<div class="container general">
    <div class="custom-row">
        <div class="general-header custom-col">
            <h4>General-Use Components</h4>
        </div>
    </div>

    <div class="custom-row">
        <div class="general-entity custom-col">
            <div class="custom-card">
                <div class="card-header">
                    <h4 class="label">Accordion</h4>
                </div>

                <div class="card-body">
                    <smart-accordion>
                        <smart-accordion-item label="First Item">Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Pellentesque aliquam at nunc sed aliquet. Quisque nec tristique lacus, sit amet
                            venenatis risus. Pellentesque nisl odio, scelerisque nec ipsum ut, ullamcorper fringilla
                            odio. Interdum et malesuada fames ac ante ipsum primis in faucibus.</smart-accordion-item>
                        <smart-accordion-item label="Second Item">Proin eu fringilla ante. Fusce tempus est at dui
                            posuere blandit. Pellentesque id eros dui.</smart-accordion-item>
                        <smart-accordion-item label="Third Item">Duis ut nunc non eros rutrum laoreet commodo nec nulla.
                            Duis volutpat dapibus mi in interdum. Praesent ornare ex metus.</smart-accordion-item>
                        <smart-accordion-item label="Fourth Item">Vestibulum in leo porta, tempus orci eleifend,
                            pharetra libero.</smart-accordion-item>
                    </smart-accordion>
                </div>
            </div>

            <div class="custom-card">
                <div class="card-header">
                    <h4 class="label">Breadcrumbs</h4>
                </div>
                <div class="card-body">
                    <smart-breadcrumb
                        data-source='[{ "label": "
                        <a class="general-breadcrumb-item">Home</a>", "value": "home" }, { "label": "<a class="general-breadcrumb-item">Catalog</a>", "value": "catalog" }, { "label": "<a class="general-breadcrumb-item">Apple Computers</a>", "value": "apple_computers" }, { "label": "Macbook Pro 13", "value": "macbook_pro_13" }'>
                    </smart-breadcrumb>
                </div>
            </div>

            <div class="custom-card">
                <div class="card-header">
                    <h4 class="label">Progressbars</h4>
                </div>
                <div class="card-body">
                    <smart-progress-bar [value]="30"></smart-progress-bar>
                    <smart-progress-bar class="barber-shop-effect" [value]="50"></smart-progress-bar>
                    <smart-progress-bar class="barber-shop-effect" [indeterminate]="true">
                    </smart-progress-bar>
                    <div class="general-circular-progress-wrapper">
                        <smart-circular-progress-bar [value]="50"></smart-circular-progress-bar>
                        <smart-circular-progress-bar class="barber-shop-effect" [indeterminate]="true">
                        </smart-circular-progress-bar>
                    </div>
                </div>
            </div>
        </div>
        <div class="general-entity custom-col">
            <div class="custom-card">
                <div class="card-header">
                    <h4 class="label">Badges</h4>
                </div>
                <div class="card-body">
                    <div class="general-upper-badges-wrapper">
                        <span class="smart-badge smart-badge-light">Light</span>&nbsp;<span
                            class="smart-badge smart-badge-dark">Dark</span>&nbsp;<span
                            class="smart-badge smart-badge-info">Info</span>&nbsp;<span
                            class="smart-badge smart-badge-success">Success</span>&nbsp;<span
                            class="smart-badge smart-badge-warning">Warning</span>&nbsp;<span
                            class="smart-badge smart-badge-danger">Danger</span>
                    </div>

                    <div>
                        <span class="smart-badge smart-badge-light smart-badge-pill">Light</span>&nbsp;<span
                            class="smart-badge smart-badge-dark smart-badge-pill">Dark</span>&nbsp;<span
                            class="smart-badge smart-badge-info smart-badge-pill">Info</span>&nbsp;<span
                            class="smart-badge smart-badge-success smart-badge-pill">Success</span>&nbsp;<span
                            class="smart-badge smart-badge-warning smart-badge-pill">Warning</span>&nbsp;<span
                            class="smart-badge smart-badge-danger smart-badge-pill">Danger</span>
                    </div>
                </div>
            </div>

            <div class="custom-card">
                <div class="card-header">
                    <h4 class="label">List Boxes</h4>
                </div>
                <div class="card-body">
                    <div class="general-listboxes-wrapper">
                        <div class="general-listbox">
                            <h6>With selection</h6>
                            <smart-list-box class="h-auto">
                                <smart-list-item>Item 1</smart-list-item>
                                <smart-list-item [selected]="true">Item 2</smart-list-item>
                                <smart-list-item>Item 3</smart-list-item>
                            </smart-list-box>
                        </div>
                        <div class="general-listbox">
                            <h6>No selection</h6>
                            <smart-list-box class="h-auto" selection-mode="none">
                                <smart-list-item>Item 1</smart-list-item>
                                <smart-list-item>Item 2</smart-list-item>
                                <smart-list-item>Item 3</smart-list-item>
                            </smart-list-box>
                        </div>
                        <div class="general-listbox">
                            <h6>Disabled item</h6>
                            <smart-list-box class="h-auto">
                                <smart-list-item disabled>Item 1</smart-list-item>
                                <smart-list-item [selected]="true">Item 2</smart-list-item>
                                <smart-list-item>Item 3</smart-list-item>
                            </smart-list-box>
                        </div>
                        <div class="general-listbox">
                            <h6>With grouping</h6>
                            <smart-list-box class="h-auto">
                                <smart-list-items-group label="Group 1">
                                    <smart-list-item>Item 1</smart-list-item>
                                </smart-list-items-group>
                                <smart-list-items-group label="Group 2">
                                    <smart-list-item [selected]="true">Item 2</smart-list-item>
                                    <smart-list-item>Item 3</smart-list-item>
                                </smart-list-items-group>
                            </smart-list-box>
                        </div>
                    </div>
                </div>
            </div>

            <div class="custom-card">
                <div class="card-header">
                    <h4 class="label">Pagers</h4>
                </div>
                <div class="card-body general-pagers-wrapper">
                    <smart-pager class="mb-5" [showPrevNextNavigationButtons]="true" [autoEllipsis]="'both'"
                        [showPageIndexSelectors]="true" [pagesCount]="20" [pageIndexSelectors]="10"></smart-pager>
                    <smart-pager class="mb-5" [showNavigationButtonLabels]="true" [showPrevNextNavigationButtons]="true"
                        [showFirstLastNavigationButtons]="true" [showPageIndexSelectors]="true" [pagesCount]="5"
                        [pageIndexSelectors]="5"></smart-pager>
                    <smart-pager class="w-100" [showPageSizeSelector]="true" [showNavigationInput]="true"
                        [showPrevNextNavigationButtons]="true" [pagesCount]="10" [showSummary]="true"></smart-pager>
                </div>
            </div>

            <div class="custom-card">
                <div class="card-header">
                    <h4 class="label">Tooltips</h4>
                </div>
                <div class="card-body">
                    <smart-button id="buttonWithTooltip1" class="small-button success">Tooltip on left</smart-button>
                    <smart-tooltip selector="buttonWithTooltip1" [arrow]="true" [position]="'left'">Tooltip on left
                    </smart-tooltip>&nbsp;<smart-button id="buttonWithTooltip2" class="small-button">Tooltip on top
                    </smart-button>
                    <smart-tooltip selector="buttonWithTooltip2" [arrow]="true" [position]="'top'">Tooltip on top
                    </smart-tooltip>&nbsp;<smart-button id="buttonWithTooltip3" class="small-button secondary">Tooltip
                        on bottom
                    </smart-button>
                    <smart-tooltip selector="buttonWithTooltip3" [arrow]="true" [position]="'bottom'">Tooltip on bottom
                    </smart-tooltip>&nbsp;<smart-button id="buttonWithTooltip4" class="small-button primary">Tooltip on
                        right</smart-button>
                    <smart-tooltip selector="buttonWithTooltip4" [arrow]="true" [position]="'right'">Tooltip on right
                    </smart-tooltip>
                </div>
            </div>
        </div>
    </div>
</div>