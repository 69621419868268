import { Injectable } from '@angular/core';

const USER_KEY = 'access_token';
const USER_ID = 'user_id';

// Благодаря такому декоратору @Injectable мы можем инжектировать в данный сервис другие сущности
@Injectable({
    // Данной строкой мы говорим чтобы ангуляр зарегал данный сервис в корневом модуле (в системе в единственном экз.).
    providedIn: 'root'
    // Поэтому из корневого модуля можно исключить StorageService так как он регается тут
    // Данный сервис зареган глобально и виден во всех компонентах
    // Можно регать сервисы локально (каждый раз создается копия сервиса с новыми данными) в конкретном компоненте тогда нужно в конкретный компонент в декоратор
    //  @Component({
    //    providers: [StorageService],
    //  })
    // Также можно использовать сервисы внутри других сервисов. Внедрение через конструктор
})

/**
 * Сервис управляет информацией пользователя (имя пользователя, адрес электронной почты, роли) внутри хранилища сеансов браузера.
 * Для выхода из системы мы очистим это хранилище сеансов.
 */
export class StorageService {
    constructor() {}

    public cleanSessionStorage(): void {
        console.log('Чистим sessionStorage');
        window.sessionStorage.clear();
    }

    public cleanLocalStorage(): void {
        console.log('Чистим localStorage');
        window.localStorage.clear();
    }

    /**
     * Удалить переменную из localStorage по имени
     * @param key
     */
    public deleteFromLocalStorageByName (key: string): any {
        window.localStorage.removeItem(key);
    }

    /**
     * Удалить переменную из sessionStorage по имени
     * @param key
     */
    public deleteFromSessionStorageByName (key: string): any {
        window.sessionStorage.removeItem(key);
    }

    /**
     * Сохранить Id пользователя в sessionStorage
     */
    public saveUser(date: any): void {
        window.sessionStorage.removeItem(USER_KEY);
        window.sessionStorage.setItem(USER_KEY, JSON.stringify(date.results.access_token));
        window.sessionStorage.setItem(USER_ID, JSON.stringify(date.results.id));
    }

    /**
     * Получить Id пользователя из sessionStorage
     */
    public getUser(): any {
        const user = window.sessionStorage.getItem(USER_ID);
        if (user) {
            return JSON.parse(user);
        }

        return {};
    }

    /**
     * Проверяем есть ли sesion storage access_token пользователя
     */
    public isLoggedIn(): boolean {
        const access_token = window.sessionStorage.getItem(USER_KEY);
        if (access_token) {
            return true;
        }

        return false;
    }

    /**
     * Сохранить в переменную в localStorage с любыми данными
     * @param key
     * @param date
     */
    public saveData(key: string, date: any): void {
        window.localStorage.removeItem(key);
        window.localStorage.setItem(key, JSON.stringify(date));
    }

    /**
     * Получить переменную по имени из localStorage
     */
    public getSomeVariable(key: string): any {
        console.log(key);
        const value = window.localStorage.getItem(key);
        if (value) {
            return JSON.parse(value);
        }

        return {};
    }

    /**
     * 1. Проверяем есть ли значение в указанной переменной в localstorage.
     * 2. Добавляем и удаляем. Должны быть только уникальные значения
     * @param arr
     * @param nameVariable
     */
    installArrayInlocalStorage(arr: Array<any>, nameVariable: string): void {
        const issetVar = window.localStorage.getItem(nameVariable);
        if (issetVar) {
            console.log(arr);
            arr.forEach((item, key) => {
                // Если еще нет значения в массиве то добавляем
                if (!issetVar.includes(item.id)) {
                    // @ts-ignore
                    issetVar.push(item.id);
                } else {
                    // Если уже есть такое значение то удаляем
                    const index = issetVar.indexOf(item.id, 0);
                    if (index > -1) {
                        // @ts-ignore
                        issetVar.splice(index, 1);
                    }
                }
                window.localStorage.setItem(nameVariable, issetVar);
            });
        } else {
            // @ts-ignore
            window.localStorage.setItem(nameVariable, arr);
            /*         const someVar: any[] = [];
                     arr.forEach((item, key) => {
                         someVar.push(item.id);
                         // @ts-ignore
                         window.localStorage.setItem(nameVariable, someVar);
                     });*/
        }
    }

    /**
     * Проверяем есть ли значение в указанной переменной в sessionStorage
     * @param val
     * @param nameVariable
     */
    checkValueInVariable(val: any, nameVariable: string): boolean {
        const access_token = window.sessionStorage.getItem(USER_KEY);
        if (access_token) {
            return true;
        }

        return false;
    }

    /**
     * Проверяем пустая ли переменная в sessionStorage
     * @param nameVariable
     */
    isEmpty(nameVariable: string): boolean {
        const issetVar = window.sessionStorage.getItem(nameVariable);
        if (issetVar && Array.isArray(issetVar)) {
            if (issetVar.length > 0) {
                return false;
            }
        }
        return true;
    }


}