import {Component, AfterViewInit, ViewEncapsulation, ViewChild} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {TableComponent} from "smart-webcomponents-angular/table";
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'sm-dashboard',
  templateUrl: './debitorka.component.html',
  styleUrls: ['./debitorka.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DebitorkaComponent implements AfterViewInit {
  constructor(
    private http: HttpClient,
    private dataService: DataService
  ) { }
  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }

  actionCreateUser(): void {
     this.http.post('/api/v1/product/create',{
       "name": "product1",
       "price": 10.99,
       "stock": 10
     }).subscribe(_ => {
     });
  }

  actionGetAllUsers(): void {
    this.http.get('/api/v1/product/getAll').subscribe(_ => {
    });
  }

  actionParser(): void {
    this.http.get('/api/v1/product/getExcel').subscribe(_ => {
    });
  }

  @ViewChild('tableWithButtons', { read: TableComponent, static: false }) tableWithButtons!: TableComponent;

  tableWithButtonsSettings = {
    dataSource: new window.Smart.DataAdapter({
      dataSource: this.dataService.generateData(50),
      dataFields: [
        'id: number',
        'firstName: string',
        'lastName: string',
        'productName: string',
        'quantity: number',
        'price: number',
        'total: number'
      ]
    }),
    onInit: () => {

      this.tableWithButtons.sortBy('lastName', 'asc');
      this.tableWithButtons.select([3, 5, 11]);

    },

    filtering: true,
    paging: true,
    sortMode: 'one',
    filterRow: true,
    tooltip: true,
    selection: true,

    columns: [
      { label: 'id', dataField: 'id', dataType: 'number', responsivePriority: 3 },
      { label: 'First Name', dataField: 'firstName', dataType: 'string', responsivePriority: 4 },
      { label: 'Last Name', dataField: 'lastName', dataType: 'string' },
      { label: 'Product Name', dataField: 'productName', dataType: 'string' },
      { label: 'Quantity', dataField: 'quantity', dataType: 'number', responsivePriority: 3 },
      { label: 'Price', dataField: 'price', dataType: 'number', responsivePriority: 3 },
    ]
  }

}
