<div class="content">
    <div class="container sign-in">
        <div class="row sign-in-form">
            <div class="sign-in-header">
                <h2>Здравствуйте обезьяна :)</h2>
            </div>
            <div class="sign-in-body custom-row">
                <div class="card-wrapper custom-col">
                    <div class="card-shadow">
                        <div class="card-body">
                            <div class="img-as-background">
                            <!--    <img src="../../../../assets/images/phonebook/mark.png">-->
                                <img src="../../../../assets/images/internet/monkey.jpg">
                            </div>
                            <form
                                    *ngIf="!isLoggedIn"
                                    name="form"
                                    (ngSubmit)="f.form.valid && onSubmit()"
                                    #f="ngForm"
                                    novalidate
                            >
                                <div class="form-group">
                                    <label>Username</label>
                                    <input
                                            type="text"
                                            class="form-control"
                                            name="username"
                                            [(ngModel)]="form.username"
                                            required
                                            #username="ngModel"
                                            [ngClass]="{ 'is-invalid': f.submitted && username.errors }"
                                    />
                                    <div *ngIf="username.errors && f.submitted" class="invalid-feedback">
                                        Поле Login обязательно для заполнения! Не буть ПАВИАНОМ !!!
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Password</label>
                                    <input
                                            type="password"
                                            class="form-control"
                                            name="password"
                                            [(ngModel)]="form.password"
                                            required
                                            minlength="6"
                                            #password="ngModel"
                                            [ngClass]="{ 'is-invalid': f.submitted && password.errors }"
                                    />
                                    <div *ngIf="password.errors && f.submitted" class="invalid-feedback">
                                        <div *ngIf="password.errors['required']">Поле Password обязательно для
                                            заполнения
                                        </div>
                                        <div *ngIf="password.errors['minlength']">
                                            Поле Password не должно быть меньше 6 символов. МАКАКА ито знает что пароли не должны быть простые
                                        </div>
                                    </div>
                                    <a href="#" class="small">Забыли пароль?</a>
                                </div>
                                <div class="form-group">
                                    <smart-check-box [checked]="true">Запомнить меня, сука навсегда</smart-check-box>
                                </div>
                                <div class="form-group">
                                    <smart-check-box [checked]="true">Согласие с правилами сервиса.</smart-check-box>
                                    <p>Тут без Варика Если не нажмешь то ТЫ не ПРОЙДЕШЬ</p>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="text-center">
                                            <div class="form-group">
                                                <button class="btn btn-primary btn-block">Войти</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div *ngIf="isSignUpFailed" class="text-center">
                                            <smart-button class="success"  (onClick)="redirectToRegistration($event)">Регистрация</smart-button>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <div *ngIf="f.submitted && isLoginFailed" class="alert alert-danger" role="alert">
                                        Пользователь с такими данными (логин и пароль) не найден. {{ errorMessage }}
                                    </div>
                                </div>
                                <div class="form-group" *ngIf="authMessage">
                                    <div class="alert">
                                       {{authMessage}}
                                    </div>
                                </div>
                            </form>
                            <div class="alert alert-success" *ngIf="isLoggedIn">
                                Авторизация прошла успешно :) Ваш статус - {{ roles }}.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



