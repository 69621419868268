<div class="custom-card">
    <div class="card-header">
        <h4 class="label">{{title}}</h4>
    </div>
    <div class="card-body">
        <smart-input
                class="basic-input"
                drop-down-button-position="right"
                value="Выберете тип"
                formControlName="{{formControlName}}"
        >
            <option *ngFor="let item of selectList">{{item.title}}</option>
        </smart-input>
    </div>
</div>


