<div class="container introduction">
    <div class="custom-row">
        <div class="introduction-header custom-col"><h4>Introduction</h4></div>
    </div>

    <div class="custom-card introduction-card-introduction">
        <div class="card-body">
            <h4 class="introduction-card-header">Introduction</h4>
            <p>Smart is a comprehensive and innovative UI library built on top of JavaScript, HTML and CSS. It
                empowers developers to deliver professional, cross-browser compatible web applications, while
                significantly minimizing their development time. Smart HTML Elements contains more than 60 UI
                components and is one of the fastest growing JavaScript UI frameworks on the Web.</p>
            <a href="./getting-started" class="btn btn-primary btn-sm">Getting Started</a>
        </div>
    </div>

    <div class="custom-card introduction-card-download-package">
        <div class="card-body">
            <h4 class="introduction-card-header">Download package contents</h4>
            <p>Inside our download package, you will find the following files and directories:</p>
            <div>
                <div class="file-structure-entry"><span class="material-icons">cloud</span>smart-elements</div>
                <div class="file-structure-entry"> ┣ <span class="material-icons">folder_open</span>export</div>
                <div class="file-structure-entry"> ┃ ┣ <span class="material-icons">text_snippet</span>data.js</div>
                <div class="file-structure-entry"> ┃ ┣ <span class="material-icons">text_snippet</span>data.xlsx
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>html2canvas.min.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span class="material-icons">text_snippet</span>jszip.min.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>pdfmake.min.js
                </div>
                <div class="file-structure-entry"> ┃ ┗ <span class="material-icons">text_snippet</span>vfs_fonts.js
                </div>
                <div class="file-structure-entry"> ┣ <span class="material-icons">folder_open</span>framework</div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.element-dev.js
                </div>
                <div class="file-structure-entry"> ┃ ┗ <span
                        class="material-icons">text_snippet</span>smart.element.js
                </div>
                <div class="file-structure-entry"> ┣ <span class="material-icons">folder_open</span>source</div>
                <div class="file-structure-entry"> ┃ ┣ <span class="material-icons">folder_open</span>modules</div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.accordion.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.breadcrumb.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.button.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.calendar.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.card.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.cardview.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.carousel.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.chart.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.checkbox.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.chip.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.colorinput.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.colorpanel.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.colorpicker.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.combobox.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.common.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.datetimepicker.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.dockinglayout.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.dropdownbutton.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.dropdownlist.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.element.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.fileupload.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.ganttchart.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.gauge.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.grid.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.gridpanel.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.input.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.kanban.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.layout.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.listbox.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.listmenu.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.maskedtextbox.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.menu.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.multilinetextbox.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.multisplitbutton.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.numerictextbox.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.pager.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.passwordtextbox.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.path.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.progressbar.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.querybuilder.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.radiobutton.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.rating.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.scrollbar.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.slider.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.sortable.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.splitter.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.switchbutton.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.table.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.tabs.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.tank.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.textbox.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.timepicker.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.toast.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.tooltip.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.tree.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.validator.js
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┗ <span
                        class="material-icons">text_snippet</span>smart.window.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span class="material-icons">folder_open</span>styles</div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span class="material-icons">folder_open</span>default
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.accordion.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.array.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.badge.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.breadcrumb.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.button.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.calendar.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.card.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.cardview.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.carousel.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.chart.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.checkbox.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.chip.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.colorpanel.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.colorpicker.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.combobox.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.common.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.currencyformatpanel.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.dateformatpanel.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.datetimepicker.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.dockinglayout.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.dropdown.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.dropdownbutton.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.dropdownlist.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.editors.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.fileupload.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.filterbuilder.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.filterpanel.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.ganttchart.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.gauge.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.grid.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.gridpanel.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.inputgroup.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.kanban.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.layout.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.layouts.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.led.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.listbox.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.listmenu.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.loader.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.maskedtextbox.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.menu.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.misc.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.multilinetextbox.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.multisplitbutton.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.numberformatpanel.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.numerictextbox.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.pager.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.passwordtextbox.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.path.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.powerbutton.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.progressbar.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.querybuilder.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.radiobutton.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.rating.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.scrollbar.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.scrollviewer.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.slider.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.sortable.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.splitter.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.switchbutton.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.table.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.tabs.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.tank.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.textbox.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.timepicker.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.toast.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.toggle.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.tooltip.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.tree.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.validationpanel.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.validator.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.variables.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┗ <span
                        class="material-icons">text_snippet</span>smart.window.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span class="material-icons">folder_open</span>font</div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart-icons.eot
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart-icons.svg
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart-icons.ttf
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart-icons.woff
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┗ <span
                        class="material-icons">text_snippet</span>smart-icons.woff2
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span class="material-icons">folder_open</span>images</div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span class="material-icons">text_snippet</span>error.png
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>grid-icons.svg
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>icons-sprite.svg
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>icons-stack.svg
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span class="material-icons">text_snippet</span>info.png
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>magnifier.png
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span class="material-icons">text_snippet</span>mail.png
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>radial.svg
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>success.png
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┣ <span class="material-icons">text_snippet</span>time.png
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┃ ┗ <span
                        class="material-icons">text_snippet</span>warning.png
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.bootstrap.css
                </div>
                <div class="file-structure-entry"> ┃ ┃ ┗ <span
                        class="material-icons">text_snippet</span>smart.default.css
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.accordion.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span class="material-icons">text_snippet</span>smart.ajax.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.array.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.bootstrap.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.breadcrumb.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.button.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.calendar.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span class="material-icons">text_snippet</span>smart.card.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.cardview.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.carousel.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.chart.annotations.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.chart.api.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.chart.core.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.chart.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.chart.rangeselector.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.chart.waterfall.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.checkbox.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span class="material-icons">text_snippet</span>smart.chip.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.colorinput.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.colorpanel.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.colorpicker.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.combobox.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.complex.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.customizationdialog.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span class="material-icons">text_snippet</span>smart.data.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span class="material-icons">text_snippet</span>smart.date.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.dateformatpanel.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.datetimepicker.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.dockinglayout.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span class="material-icons">text_snippet</span>smart.draw.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.dropdownbutton.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.dropdownlist.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.element-polyfills.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.element.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.elements.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.export.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.fileupload.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.filter.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.filterbuilder.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.filterpanel.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.format.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.ganttchart.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.gauge.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.grid.cell.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.grid.chart.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.grid.column.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.grid.core.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.grid.edit.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.grid.export.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.grid.filter.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.grid.group.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span class="material-icons">text_snippet</span>smart.grid.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.grid.menu.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.grid.pager.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.grid.resize.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.grid.row.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.grid.select.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.grid.sort.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.grid.tree.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.gridpanel.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.input.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.kanban.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.layout.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span class="material-icons">text_snippet</span>smart.led.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.listbox.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.listmenu.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.maskedtextbox.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span class="material-icons">text_snippet</span>smart.math.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span class="material-icons">text_snippet</span>smart.menu.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.multilinetextbox.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.multisplitbutton.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.numberformatpanel.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.numeric.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.numerictextbox.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.pager.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.passwordtextbox.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span class="material-icons">text_snippet</span>smart.path.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.powerbutton.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.progressbar.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.radiobutton.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.rating.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.scrollbar.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.slider.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span class="material-icons">text_snippet</span>smart.sort.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.sortable.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.splitter.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.switchbutton.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.table.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span class="material-icons">text_snippet</span>smart.tabs.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span class="material-icons">text_snippet</span>smart.tank.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.textbox.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.tickintervalhandler.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.timepicker.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.toast.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.tooltip.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span class="material-icons">text_snippet</span>smart.tree.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.validationpanel.js
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.validator.js
                </div>
                <div class="file-structure-entry"> ┃ ┗ <span
                        class="material-icons">text_snippet</span>smart.window.js
                </div>
                <div class="file-structure-entry"> ┣ <span class="material-icons">folder_open</span>typescript</div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.accordion.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.array.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.breadcrumb.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.button.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.calendar.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.card.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.cardview.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.carousel.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.chart.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.checkbox.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.chip.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.circularprogressbar.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.colorinput.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.colorpanel.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.colorpicker.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.combobox.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.customizationdialog.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.dataadapter.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.datetimepicker.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.dockinglayout.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.dropdownbutton.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.dropdownlist.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.element.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.elements.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.fileupload.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.filterbuilder.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.filterpanel.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.ganttchart.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.gauge.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.grid.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.grouppanel.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.input.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.layout.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.led.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.listbox.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.listmenu.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.maskedtextbox.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.menu.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.multicolumnfilterpanel.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.multilinetextbox.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.multisplitbutton.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.numerictextbox.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.pager.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.passwordtextbox.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.path.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.powerbutton.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.progressbar.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.querybuilder.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.radiobutton.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.rating.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.repeatbutton.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.scrollbar.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.slider.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.sortable.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.sortpanel.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.splitter.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.switchbutton.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.table.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.tabs.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.tabswindow.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.tank.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.textbox.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.timepicker.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.toast.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.togglebutton.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.tooltip.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┣ <span
                        class="material-icons">text_snippet</span>smart.tree.d.ts
                </div>
                <div class="file-structure-entry"> ┃ ┗ <span
                        class="material-icons">text_snippet</span>smart.window.d.ts
                </div>
                <div class="file-structure-entry"> ┣ <span class="material-icons">text_snippet</span>chart.png</div>
                <div class="file-structure-entry"> ┣ <span class="material-icons">text_snippet</span>docking.png
                </div>
                <div class="file-structure-entry"> ┣ <span class="material-icons">text_snippet</span>EULA.pdf</div>
                <div class="file-structure-entry"> ┣ <span class="material-icons">text_snippet</span>grid-sheet.png
                </div>
                <div class="file-structure-entry"> ┣ <span class="material-icons">text_snippet</span>grid.png</div>
                <div class="file-structure-entry"> ┣ <span class="material-icons">text_snippet</span>package.json
                </div>
                <div class="file-structure-entry"> ┣ <span class="material-icons">text_snippet</span>README.md</div>
                <div class="file-structure-entry"> ┣ <span
                        class="material-icons">text_snippet</span>smart-calendar.png
                </div>
                <div class="file-structure-entry"> ┗ <span class="material-icons">text_snippet</span>smart-tabs.png
                </div>
            </div>
        </div>
    </div>
</div>
