<div class="container scheduler">
    <div class="custom-row">
        <div class="scheduler-header custom-col">
            <h4>Scheduler</h4>
        </div>
    </div>

    <div class="custom-card h-100">
        <div class="card-header">
            <h4 class="label">Smart.Scheduler</h4>
        </div>
        <div class="card-body">
            <p>Smart.Scheduler is a custom element that displays appointments in different views (such as Day, Week,
                Month, etc.)</p>
            <smart-scheduler #scheduler id="scheduler" [dataSource]="schedulerSettings.dataSource"
                [dateCurrent]="schedulerSettings.dateCurrent"
                [view]="schedulerSettings.view" [hourStart]="schedulerSettings.hourStart"
                [timelineDayScale]="schedulerSettings.timelineDayScale">
            </smart-scheduler>
        </div>
    </div>
</div>