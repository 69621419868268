import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HTTP_INTERCEPTORS, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { StorageService } from '../services/storage.service';
import { EventBusService } from '../shared/event-bus.service';
import { EventData } from '../shared/event.class';

@Injectable()
/**
 * Класс для проверки и преобразования HTTP-запросов перед их отправкой на сервер.
 * HttpRequestInterceptor реализует HttpInterceptor. Мы добавим withCredentials: true, чтобы браузер включал Cookie в заголовок запроса (HttpOnly Cookie).
 */
export class HttpRequestInterceptor implements HttpInterceptor {
    private isRefreshing = false;

    constructor(private storageService: StorageService, private eventBusService: EventBusService) { }

    /**
     * intercept() получает объект HTTPRequest, изменяет его и передает метод handle() объекта HttpHandler.
     * Он преобразует объект HTTPRequest в Observable<HttpEvents>. next: объект HttpHandler представляет следующий перехватчик в цепочке перехватчиков.
     * Последним «следующим» в цепочке является Angular HttpClient.
     * @param req
     * @param next
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        req = req.clone({
            withCredentials: true,
        });

        return next.handle(req).pipe(
            catchError((error) => {
                if (
                    error instanceof HttpErrorResponse &&
                    !req.url.includes('auth/signin') &&
                    error.status === 401
                ) {
                    return this.handle401Error(req, next);
                }

                return throwError(() => error);
            })
        );
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
            this.isRefreshing = true;

            if (this.storageService.isLoggedIn()) {
                this.eventBusService.emit(new EventData('logout', null));
            }
        }

        return next.handle(request);
    }
}

export const httpInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
];