<div class="container sign-up">
    <div class="sign-up-header">
        <h2>Welcome!</h2>
        <p>Sign up for the best user experience</p>
    </div>

    <div class="sign-up-body custom-row">
        <div class="card-wrapper custom-col">
            <div class="card-shadow">
                <div class="card-body">
                    <div class="form-group">
                        <label>Name</label>
                        <smart-input placeholder="Enter your name"></smart-input>
                    </div>

                    <div class="form-group">
                        <label>Company</label>
                        <smart-input placeholder="Enter your company name"></smart-input>
                    </div>

                    <div class="form-group">
                        <label>Email</label>
                        <smart-input placeholder="Enter your email"></smart-input>
                    </div>

                    <div class="form-group">
                        <label>Password</label>
                        <smart-password-text-box placeholder="Enter a password"></smart-password-text-box>
                    </div>

                    <div class="text-center">
                        <smart-button class="primary">Sign up</smart-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>