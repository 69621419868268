<form [formGroup] = "ownerCreateForm" (ngSubmit)="createOwner()">
  <div class="container basic-inputs">
    <div class="custom-row">
      <div class="custom-col">
        <div class="custom-card">
          <div class="card-header">
            <h4 class="label">ФИО</h4>
          </div>
          <div class="card-body">
            <smart-input class="basic-input" [placeholder]="'ФИО'" formControlName="name"></smart-input>
            <!--Дебажим значения в форме чтобы глянуть какие ошибки есть-->
          <!--  <pre>{{meetingCreateForm.get('address').errors | json}}</pre>-->
            <div
              *ngIf="ownerCreateForm.get('name').invalid && ownerCreateForm.get('name').touched"
              class="validation"
            >
              <small *ngIf="ownerCreateForm.get('name').errors.required">
                Поле адрес не может быть пустым
              </small>

              <small *ngIf="ownerCreateForm.get('name').errors.minlength">
                Длинна должна быть не менее {{ownerCreateForm.get('name').errors.minlength.requiredLength}}
                Сейчас кол-во символов {{ownerCreateForm.get('name').errors.minlength.actualLength}}
              </small>
            </div>
          </div>
        </div>
        <div class="custom-card">
          <div class="card-header">
            <h4 class="label">Телефон</h4>
          </div>
          <div class="card-body">
            <smart-text-area class="basic-input" [placeholder]="'Телефон'" formControlName="phone"></smart-text-area>
          </div>
        </div>
        <div class="custom-card">
          <div class="card-header">
            <h4 class="label">Email</h4>
          </div>
          <div class="card-body">
            <smart-text-area class="basic-input" [placeholder]="'Email'" formControlName="email"></smart-text-area>
          </div>
        </div>
        <div class="custom-card">
          <div class="card-header">
            <h4 class="label">Номер регистрации</h4>
          </div>
          <div class="card-body">
            <smart-text-area class="basic-input" [placeholder]="'Номер регистрации'" formControlName="registration_number"></smart-text-area>
          </div>
        </div>
        <div class="custom-card">
          <div class="card-header">
            <h4 class="label">Кадастровый номер</h4>
          </div>
          <div class="card-body">
            <smart-text-area class="basic-input" [placeholder]="'Кадастровый номер'" formControlName="cadastral_number"></smart-text-area>
          </div>
        </div>
        <div class="custom-card">
          <div class="card-header">
            <h4 class="label">Дата регистрации</h4>
          </div>
          <div class="card-body">
            <smart-text-area class="basic-input" [placeholder]="'Дата регистрации'" formControlName="registration_date"></smart-text-area>
          </div>
        </div>
        <div class="custom-card">
          <div class="card-header">
            <h4 class="label">Номер помещения</h4>
          </div>
          <div class="card-body">
            <smart-text-area class="basic-input" [placeholder]="'Номер помещения'" formControlName="room_number"></smart-text-area>
          </div>
        </div>
        <div class="custom-card">
          <div class="card-header">
            <h4 class="label">Площадь</h4>
          </div>
          <div class="card-body">
            <smart-text-area class="basic-input" [placeholder]="'Площадь'" formControlName="area"></smart-text-area>
          </div>
        </div>
        <div class="custom-card">
          <div class="card-header">
            <h4 class="label">Доля</h4>
          </div>
          <div class="card-body">
            <smart-text-area class="basic-input" [placeholder]="'Доля'" formControlName="portion"></smart-text-area>
          </div>
        </div>
        <div class="custom-card">
          <div class="card-header">
            <h4 class="label">Тип владельца (Член ТСЖ Да/Нет)</h4>
          </div>
          <div class="card-body">
            <smart-text-area class="basic-input" [placeholder]="'Тип владельца (Член ТСЖ Да/Нет)'" formControlName="type_id"></smart-text-area>
          </div>
        </div>
      </div>
<!--      <div class="custom-col">
        <div class="custom-card">
          <div class="card-header">
            <h4 class="label">Дата собрания</h4>
          </div>
          <div class="card-body">
            <smart-date-input class="basic-input"  formControlName="date"></smart-date-input>
          </div>
        </div>
      </div>-->
    </div>
    <div class="custom-row">
      <div class="custom-col">
        <div class="custom-card">
          <div class="card-header">
          </div>
          <div class="card-body">
            <smart-button type="submit" [disabled]="ownerCreateForm.invalid">Создать владельца</smart-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
