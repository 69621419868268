<div class="container embed-video">
    <div class="custom-row">
        <div class="embed-video-header custom-col">
            <h4>Embed Video</h4>
        </div>
    </div>

    <div class="custom-row">
        <div class="embed-video-entity custom-col">
            <div class="custom-card h-100">
                <div class="card-header">
                    <h4 class="label">Responsive embeded video 21:9</h4>
                </div>
                <div class="card-body">
                    <iframe #iframe21 id="iframe21" src="https://www.youtube.com/embed/N1-Jmq7BLFE" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
                </div>
            </div>
        </div>

        <div class="embed-video-entity custom-col">
            <div class="custom-card h-100">
                <div class="card-header">
                    <h4 class="label">Responsive embeded video 16:9</h4>
                </div>
                <div class="card-body">
                    <iframe #iframe16 id="iframe16" src="https://www.youtube.com/embed/hVvEISFw9w0" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>

    <div class="custom-row">
        <div class="embed-video-entity custom-col">
            <div class="custom-card h-100">
                <div class="card-header">
                    <h4 class="label">Responsive embeded video 4:3</h4>
                </div>
                <div class="card-body">
                    <iframe #iframe4 id="iframe4" src="https://www.youtube.com/embed/mHUOCxVT5ro" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
                </div>
            </div>
        </div>
        <div class="embed-video-entity custom-col">
            <div class="custom-card h-100">
                <div class="card-header">
                    <h4 class="label">Responsive embeded video 1:1</h4>
                </div>
                <div class="card-body">
                    <iframe #iframe1 id="iframe1" src="https://www.youtube.com/embed/9QgT6ZIoXN8" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
</div>