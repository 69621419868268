<form [formGroup]="questionCreateForm" (ngSubmit)="createQuestion()">
  <div class="container basic-inputs">
    <div class="custom-row">
      <div class="custom-col">
        <div class="custom-card">
          <div class="card-header">
            <h4 class="label">Введите номер вопроса</h4>
          </div>
          <div class="card-body">
            <smart-input class="basic-input" type='number' formControlName="priority"></smart-input>
        <!--    &lt;!&ndash;Дебажим значения в форме чтобы глянуть какие ошибки есть&ndash;&gt;
            &lt;!&ndash;  <pre>{{questionCreateForm.get('address').errors | json}}</pre>&ndash;&gt;-->
            <div
                    *ngIf="questionCreateForm.get('priority').invalid && questionCreateForm.get('priority').touched"
                    class="validation"
            >
              <small *ngIf="questionCreateForm.get('priority').errors.required">
                Поле номер не может быть пустым
              </small>

              <small *ngIf="questionCreateForm.get('priority').errors.minlength">
                Длинна должна быть не менее {{questionCreateForm.get('priority').errors.minlength.requiredLength}}
                Сейчас кол-во символов {{questionCreateForm.get('priority').errors.minlength.actualLength}}
              </small>
            </div>
          </div>
        </div>

        <div class="custom-card">
          <div class="card-header">
            <h4 class="label">Формулировка для уведомления </h4>
          </div>
          <div class="card-body">
            <smart-input class="basic-input" [placeholder]="'Что будет в уведомлении'" formControlName="textMessage"></smart-input>
            <!--Дебажим значения в форме чтобы глянуть какие ошибки есть-->
            <!--  <pre>{{meetingCreateForm.get('address').errors | json}}</pre>-->
            <div
                    *ngIf="questionCreateForm.get('textMessage').invalid && questionCreateForm.get('textMessage').touched"
                    class="validation"
            >
              <small *ngIf="questionCreateForm.get('textMessage').errors.required">
                Поле номер не может быть пустым
              </small>

              <small *ngIf="questionCreateForm.get('textMessage').errors.minlength">
                Длинна должна быть не менее {{questionCreateForm.get('textMessage').errors.minlength.requiredLength}}
                Сейчас кол-во символов {{questionCreateForm.get('textMessage').errors.minlength.actualLength}}
              </small>
            </div>
          </div>
        </div>

        <div class="custom-card">
          <div class="card-header">
            <h4 class="label">Формулировка для решения собственника</h4>
          </div>
          <div class="card-body">
            <smart-text-area class="basic-input" [placeholder]="'Что будет в бюллетене'"
                             formControlName="textOwner"></smart-text-area>
          </div>
        </div>

        <div class="custom-card">
          <div class="card-header">
            <h4 class="label">Критерий принятия решения (доля проголосовавших) ч. 1 ст. 46 ЖК РФ</h4>
          </div>
          <div class="card-body">
            <smart-input
                    class="basic-input"
                    drop-down-button-position="right"
                    formControlName="questionSolution"
                    [placeholder]="'Выберете критерий'"
                    [readonly]="true"
                    (change)="changeQuestionSolution($event)"
            >
                    <option
                             *ngFor="let item of questionService.questionSolution"
                             value="{{item.id}}"
                     >
                       {{item.title}}
                     </option>
            </smart-input>
          </div>
        </div>
      </div>
    </div>


    <div class="custom-row">
      <div class="custom-col">
        <div class="custom-card">
          <div class="card-header">
          </div>
          <div class="card-body">
            <smart-button type="submit" [disabled]="questionCreateForm.invalid">Создать вопрос</smart-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
