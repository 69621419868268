import {Component, OnInit, OnDestroy, AfterViewInit, ViewEncapsulation} from '@angular/core';
import {HouseService} from "src/app/pages/meetings/services/house.service";
import {WindowModule} from 'smart-webcomponents-angular/window';
import {ButtonModule} from "smart-webcomponents-angular/button";
import {BrowserModule} from "@angular/platform-browser";
import {Subscription} from "rxjs";
import {CommonModule} from "@angular/common";


@Component({
    selector: 'create-house',
    templateUrl: 'create-house.component.html',
    providers: [BrowserModule, ButtonModule, WindowModule, CommonModule],
    styleUrls: ['create-house.component.scss'],
    encapsulation: ViewEncapsulation.None // Указывает на то что данный стили в этом компоненте глобальны, а не относится к одному компоненту
})

export class CreateHouseComponent implements AfterViewInit, OnDestroy, OnInit {

    constructor(
        private houseService: HouseService,
    ) {
        Object.assign(CreateHouseComponent, {
            houseService,
        });
    }

    ngAfterViewInit(): void {

    }

    ngOnDestroy(): void {
    }

    ngOnInit(): void {
    }


}
