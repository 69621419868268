import {Component, OnInit} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {Router} from "@angular/router";

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})

/**
 * Этот компонент связывает данные формы (имя пользователя, адрес электронной почты, пароль) из шаблона с методом AuthService.register(),
 * который возвращает объект Observable
 */
export class RegisterComponent implements OnInit {
    form: any = {
        username: null,
        email: null,
        password: null
    };
    isSuccessful = false;
    isSignUpFailed = true;
    errorMessage = '';

    constructor(
        private authService: AuthService,
        private router: Router,
    ) {
    }

    ngOnInit(): void {
    }

    onSubmit(): void {
        const {username, email, password} = this.form;

        this.authService.register(username, email, password).subscribe({
            next: data => {
                console.log(data);
                this.isSuccessful = true;
                this.isSignUpFailed = false; // Регистрация прошла успешно
                console.log('Регистрация прошла успешно');
                this.router.navigate(['/login'],{
                        state: {
                            isSignUpFailed: this.isSignUpFailed,
                            authMessage : "Регистрация прошла успешно. Вы можете Войти в систему по Вашим данным"
                        }
                    }
                ).then(_ => {});
            },
            error: err => {
                this.errorMessage = err.error.message;
                this.isSignUpFailed = true; // Регистрация провалена. Пользователь уже есть в системе либо какая-то ошибка при регистрации
                console.log('Регистрация провалена');
                this.router.navigate(['/login'],{
                        state: {
                            isSignUpFailed: this.isSignUpFailed,
                            authMessage : "Пользователь не Зарегистрирован, Так как уже ЕСТЬ в системе. Вы можете Войти в систему по Вашим данным"
                        }
                    }
                ).then(_ => {});
            }
        });
    }
}