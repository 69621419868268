<div class="content">
<div class="container sign-in">
    <div class="row sign-in-form">
        <div class="sign-in-header">
            <h2>Здравствуйте</h2>
            <p>Войдите в свою учетную запись, чтобы продолжить</p>
        </div>
        <div class="sign-in-body custom-row">
            <div class="card-wrapper custom-col">
                <div class="card-shadow">
                    <div class="card-body">
                        <div class="img-as-background">
                            <img src="../../../../assets/images/phonebook/mark.png">
                        </div>

                        <div class="form-group">
                            <label>Email</label>
                            <smart-input></smart-input>
                        </div>

                        <div class="form-group">
                            <label>Password</label>
                            <smart-password-text-box></smart-password-text-box>
                            <a href="#" class="small">Забыли пароль?</a>
                        </div>
                        <div class="form-group">
                            <smart-check-box [checked]="true">Запомнить меня</smart-check-box>
                        </div>
                        <div class="form-group">
                            <smart-check-box [checked]="true">Согласие с правилами сервиса</smart-check-box>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="text-center">
                                    <smart-button class="primary">Войти</smart-button>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="text-center">
                                    <smart-button class="success">Регистрация</smart-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>