<div class="container e-commerce-checkout">
    <div class="custom-row">
        <div class="custom-col e-commerce-checkout-header">
            <h4>Checkout</h4>
        </div>
    </div>


    <div class="custom-card h-100">
        <div class="card-body">
            <template id="tabTemplate1">
                <div class="d-flex">
                    <div class="tab-button-mr-3">
                        <button class="btn btn-primary btn-icon">
                            <i class="fas fa-shopping-cart m-0 font-size-16px"></i>
                        </button>
                    </div>

                    <div>
                        <span class="text-primary mb-1 d-block">Cart</span>
                        <small class="text-muted">Your Cart items</small>
                    </div>
                </div>
            </template>

            <template id="tabTemplate2">
                <div class="d-flex">
                    <div class="tab-button-mr-3">
                        <button class="btn btn-primary btn-icon">
                            <i class="fas fa-home m-0 font-size-16px"></i>
                        </button>
                    </div>

                    <div>
                        <span class="text-primary mb-1 d-block">Address</span>
                        <small class="text-muted">Enter Your Address</small>
                    </div>
                </div>
            </template>

            <template id="tabTemplate3">
                <div class="d-flex">
                    <div class="tab-button-mr-3">
                        <button class="btn btn-primary btn-icon">
                            <i class="fas fa-credit-card m-0 font-size-16px"></i>
                        </button>
                    </div>

                    <div>
                        <span class="text-primary mb-1 d-block">Payment</span>
                        <small class="text-muted">Select Payment Method</small>
                    </div>
                </div>
            </template>

            <smart-tabs class="w-100 auto-height" animation="none" #tabs
                (click)="onTabClick(tabs, $event)" (onChange)="onTabsChange(tabs, $event)">
                <smart-tab-item label="tabTemplate1">
                    <div class="custom-row">
                        <div class="custom-col col-entity-xl-8 items-col">
                            <div class="custom-card shadow-1 hover-shadow-3">
                                <div class="card-body">
                                    <div class="custom-row">
                                        <div class="custom-col custom-col-12 col-entity-md-4 text-center">
                                            <a href="#">
                                                <img src="assets/images/products/product-1.png" alt="">
                                            </a>
                                        </div>

                                        <div class="custom-col custom-col-8 col-entity-md-5">
                                            <h6 class="product-name">
                                                <a href="#" class="text-secondary">Apple Watch Series 5</a>
                                            </h6>

                                            <span class="product-manufacturer">By <a href="#"
                                                    class="text-primary">Apple</a></span>

                                            <span class="product-availability text-success">In Stock</span>

                                            <div class="input-group input-group-sm product-quantity-group">
                                                <div class="input-group-prepend">
                                                    <smart-button class="primary small">+</smart-button>
                                                </div>

                                                <smart-input class="form-control text-center" placeholder="qty"
                                                    value="3"></smart-input>

                                                <div class="input-group-append">
                                                    <smart-button class="primary small">-</smart-button>
                                                </div>
                                            </div>

                                            <p class="text-muted product-deliver-by">Delivery by, Wed Apr 25</p>
                                            <span class="text-success">17% off 4 offers Available</span>
                                        </div>

                                        <div class="custom-col custom-col-4 col-entity-md-3 text-center">
                                            <h4 class="item-price">$19.99</h4>
                                            <p>
                                                <span class="smart-badge smart-badge-success smart-badge-pill">Free
                                                    Shipping</span>
                                            </p>

                                            <button type="button"
                                                class="btn btn-sm btn-outline-danger product-remove-button">
                                                <i class="fas fa-trash font-size-16px mr-2"></i>
                                                <span>Remove</span>
                                            </button>

                                            <button type="button"
                                                class="btn btn-sm btn-outline-primary product-add-wishlist-button">
                                                <i class="fas fa-heart font-size-16px mr-2"></i>
                                                <span class="text-truncate">Add to Wishlist</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="custom-card shadow-1 hover-shadow-3">
                                <div class="card-body">
                                    <div class="custom-row">
                                        <div class="custom-col custom-col-12 col-entity-md-4 text-center">
                                            <a href="#">
                                                <img src="assets/images/products/product-2.png" alt="">
                                            </a>
                                        </div>

                                        <div class="custom-col custom-col-8 col-entity-md-5">
                                            <h6 class="product-name">
                                                <a href="#" class="text-secondary">Apple iPhone 11 Black 64GB</a>
                                            </h6>

                                            <span class="product-manufacturer">By <a href="#"
                                                    class="text-primary">Apple</a></span>

                                            <span class="product-availability text-success">In Stock</span>

                                            <div class="input-group input-group-sm product-quantity-group">
                                                <div class="input-group-prepend">
                                                    <smart-button class="primary small">+</smart-button>
                                                </div>

                                                <smart-input class="form-control text-center" placeholder="qty"
                                                    value="1"></smart-input>

                                                <div class="input-group-append">
                                                    <smart-button class="primary small">-</smart-button>
                                                </div>
                                            </div>

                                            <p class="text-muted product-deliver-by">Delivery by, Wed Apr 25</p>
                                            <span class="text-success">17% off 4 offers Available</span>
                                        </div>

                                        <div class="custom-col custom-col-4 col-entity-md-3 text-center">
                                            <h4 class="item-price">$379.99</h4>

                                            <button type="button"
                                                class="btn btn-sm btn-outline-danger product-remove-button">
                                                <i class="fas fa-trash font-size-16px mr-2"></i>
                                                <span>Remove</span>
                                            </button>

                                            <button type="button"
                                                class="btn btn-sm btn-outline-primary product-add-wishlist-button">
                                                <i class="fas fa-heart font-size-16px mr-2"></i>
                                                <span class="text-truncate">Add to Wishlist</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="custom-card shadow-1 hover-shadow-3">
                                <div class="card-body">
                                    <div class="custom-row">
                                        <div class="custom-col custom-col-12 col-entity-md-4 text-center">
                                            <a href="#">
                                                <img src="assets/images/products/product-3.png" alt="">
                                            </a>
                                        </div>

                                        <div class="custom-col custom-col-8 col-entity-md-5">
                                            <h6 class="product-name">
                                                <a href="#" class="text-secondary">Apple iMac 21.5"</a>
                                            </h6>

                                            <span class="product-manufacturer">By <a href="#"
                                                    class="text-primary">Apple</a></span>

                                            <span class="product-availability text-danger">Out of Stock</span>

                                            <div class="input-group input-group-sm product-quantity-group">
                                                <div class="input-group-prepend">
                                                    <smart-button class="primary small">+</smart-button>
                                                </div>

                                                <smart-input class="form-control text-center" placeholder="qty"
                                                    value="1"></smart-input>

                                                <div class="input-group-append">
                                                    <smart-button class="primary small">-</smart-button>
                                                </div>
                                            </div>

                                            <p class="text-muted product-deliver-by">Delivery by, Wed Dec 25</p>
                                        </div>

                                        <div class="custom-col custom-col-4 col-entity-md-3 text-center">
                                            <h4 class="item-price">$1219.99</h4>
                                            <p>
                                                <span class="smart-badge smart-badge-success smart-badge-pill">Free
                                                    Shipping</span>
                                            </p>

                                            <button type="button"
                                                class="btn btn-sm btn-outline-danger product-remove-button">
                                                <i class="fas fa-trash font-size-16px mr-2"></i>
                                                <span>Remove</span>
                                            </button>

                                            <button type="button"
                                                class="btn btn-sm btn-outline-primary product-add-wishlist-button">
                                                <i class="fas fa-heart font-size-16px mr-2"></i>
                                                <span class="text-truncate">Add to Wishlist</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="custom-card shadow-1 hover-shadow-3">
                                <div class="card-body">
                                    <div class="custom-row">
                                        <div class="custom-col custom-col-12 col-entity-md-4 text-center">
                                            <a href="#">
                                                <img src="assets/images/products/product-4.png" alt="">
                                            </a>
                                        </div>

                                        <div class="custom-col custom-col-8 col-entity-md-5">
                                            <h6 class="product-name">
                                                <a href="#" class="text-secondary">Headphones</a>
                                            </h6>

                                            <span class="product-manufacturer">By <a href="#"
                                                    class="text-primary">OneOdio</a></span>

                                            <span class="product-availability text-success">In Stock</span>

                                            <div class="input-group input-group-sm product-quantity-group">
                                                <div class="input-group-prepend">
                                                    <smart-button class="primary small">+</smart-button>
                                                </div>

                                                <smart-input class="form-control text-center" placeholder="qty"
                                                    value="3"></smart-input>

                                                <div class="input-group-append">
                                                    <smart-button class="primary small">-</smart-button>
                                                </div>
                                            </div>

                                            <p class="text-muted product-deliver-by">Delivery by, Wed Apr 25</p>
                                            <span class="text-success">20% off 3 offers Available</span>
                                        </div>

                                        <div class="custom-col custom-col-4 col-entity-md-3 text-center">
                                            <h4 class="item-price">$27.99</h4>
                                            <p>
                                                <span class="smart-badge smart-badge-success smart-badge-pill">Free
                                                    Shipping</span>
                                            </p>

                                            <button type="button"
                                                class="btn btn-sm btn-outline-danger product-remove-button">
                                                <i class="fas fa-trash font-size-16px mr-2"></i>
                                                <span>Remove</span>
                                            </button>

                                            <button type="button"
                                                class="btn btn-sm btn-outline-primary product-add-wishlist-button">
                                                <i class="fas fa-heart font-size-16px mr-2"></i>
                                                <span class="text-truncate">Add to Wishlist</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="custom-col col-entity-xl-4 total-col">
                            <div class="custom-card shadow-1">
                                <div class="card-body">
                                    <div class="input-group">
                                        <smart-input class="form-control" placeholder="Apply promo code"></smart-input>
                                        <div class="input-group-append">
                                            <smart-button class="primary">Apply</smart-button>
                                        </div>
                                    </div>

                                    <hr>

                                    <div class="price-details">
                                        <h6 class="price-title">Price Details</h6>
                                        <ul class="list-unstyled">
                                            <li>
                                                <div>Total MRP</div>
                                                <div>$598</div>
                                            </li>
                                            <li>
                                                <div>Bag Discount</div>
                                                <div class="text-success">-25$</div>
                                            </li>
                                            <li>
                                                <div>Estimated Tax</div>
                                                <div>$1.3</div>
                                            </li>
                                            <li>
                                                <div>EMI Eligibility</div>
                                                <a href="#" class="text-primary">Details</a>
                                            </li>
                                            <li>
                                                <div>Delivery Charges</div>
                                                <div class="text-success">Free</div>
                                            </li>
                                        </ul>
                                        <hr>
                                        <ul class="list-unstyled price-details-total-list">
                                            <li class="d-flex justify-content-between">
                                                <div class="font-weight-bolder text-primary">Total</div>
                                                <div class="font-weight-bolder text-primary">$574</div>
                                            </li>
                                        </ul>
                                        <smart-button class="primary w-100 next">Place Order</smart-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </smart-tab-item>

                <smart-tab-item label="tabTemplate2">
                    <div class="custom-row checkout-tab-2">
                        <div class="custom-col col-entity-xl-8">
                            <div class="custom-card shadow-1">
                                <div class="card-body">
                                    <form id="checkoutForm" class="w-100"></form>
                                </div>
                            </div>
                        </div>

                        <div class="custom-col col-entity-xl-4">
                            <div class="custom-card shadow-1">
                                <div class="card-body">
                                    <div class="price-details">
                                        <h4>John Doe</h4>
                                        <div class="price-details-delivery-info">
                                            <p class="mb-0">9247 Glen Eagles Drive</p>
                                            <p>Lewis Center, OH 12345</p>
                                            <p>UTC-5: Eastern Standard Time (EST)</p>
                                            <p>101-332-1143</p>
                                        </div>

                                        <smart-button class="primary w-100 next">Deliver To This Address</smart-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </smart-tab-item>

                <smart-tab-item label="tabTemplate3">
                    <div class="custom-row checkout-tab-3">
                        <div class="custom-col col-entity-xl-8">
                            <div class="custom-card shadow-1">
                                <div class="card-body">
                                    <h5>Payment options</h5>
                                    <p>Be sure to click on correct payment option</p>

                                    <h6>John Doe</h6>

                                    <div class="field-holder">
                                        <smart-radio-button enable-container-click group-name="delivery-options"
                                            [checked]="true">US Unlocked Debit Card 12XX XXXX XXXX 0000
                                        </smart-radio-button>
                                    </div>

                                    <div class="mt-1">
                                        <div class="form-inline">
                                            <label>Enter CVV:</label>
                                            <smart-masked-text-box
                                                hint="The last 3 digits of the back on your credit card" mask="###"
                                                class="cvv-masked-input"></smart-masked-text-box>
                                            <smart-button class="primary small">Apply</smart-button>
                                        </div>
                                    </div>

                                    <hr class="first-line">

                                    <ul class="list-unstyled">
                                        <li>
                                            <div class="field-holder">
                                                <smart-radio-button enable-container-click
                                                    group-name="delivery-options">Credit / Debit / ATM Card
                                                </smart-radio-button>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="field-holder">
                                                <smart-radio-button enable-container-click
                                                    group-name="delivery-options">Net Banking</smart-radio-button>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="field-holder">
                                                <smart-radio-button enable-container-click
                                                    group-name="delivery-options">EMI (Easy Installment)
                                                </smart-radio-button>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="field-holder">
                                                <smart-radio-button enable-container-click
                                                    group-name="delivery-options">Cash On Delivery</smart-radio-button>
                                            </div>
                                        </li>
                                    </ul>

                                    <hr class="second-line">

                                    <div class="add-gift-button">
                                        <button class="btn btn-outline-primary btn-sm">
                                            <i class="fas fa-plus-circle"></i>
                                            <span>Add Gift Card</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="custom-col col-entity-xl-4">
                            <div class="custom-card shadow-1">
                                <div class="card-body">
                                    <div class="price-details">
                                        <h4>Price Details</h4>

                                        <ul class="list-unstyled font-size-16px">
                                            <li>
                                                <div>Price of 3 items</div>
                                                <div>
                                                    <strong>$699.30</strong>
                                                </div>
                                            </li>
                                            <li>
                                                <div>Delivery Charges</div>
                                                <div class="text-success">Free</div>
                                            </li>
                                        </ul>
                                        <hr>
                                        <ul class="list-unstyled price-details-total-list">
                                            <li>
                                                <div class="font-weight-bolder">Amount Payable</div>
                                                <div class="font-weight-bolder text-primary">$699.30</div>
                                            </li>
                                        </ul>

                                        <smart-button class="primary w-100">CONTINUE TO PAYMENT</smart-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </smart-tab-item>
            </smart-tabs>
        </div>
    </div>

</div>