import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {DataAdapter, Smart} from 'smart-webcomponents-angular/grid';
import {Subscription} from "rxjs";
import {House} from "../../../models/house";
import {TableComponent} from "smart-webcomponents-angular/table";
import {WindowComponent, WindowModule} from 'smart-webcomponents-angular/window';
import {ButtonComponent, ButtonModule} from "smart-webcomponents-angular/button";
import {BrowserModule} from "@angular/platform-browser";
import {ActivatedRoute, Router} from "@angular/router";
import {UrlService} from "../services/url.service";
import {DatePipe} from "@angular/common";
import {QuestionService} from "../services/question.service";
import {StorageService} from "../../../services/storage.service";

// @ts-ignore
@Component({
    selector: 'list-my-question',
    templateUrl: './list-my-question.component.html',
    providers: [BrowserModule, ButtonModule, WindowModule],
    styleUrls: ['./list-my-question.component.scss'],
    encapsulation: ViewEncapsulation.None
})

/**
 * Компонент - в меню. Отвечает за шаблоны моих вопросов. Для того чтобы можно было дальше их использовать в собрании
 */
export class ListMyQuestionComponent implements AfterViewInit, OnDestroy, OnInit {

    static questionService: QuestionService;
    houses: House[];
    // Переменная отвечающая за подписку
    mSub: Subscription
    selectedItems: Array<any> = [];
    agreeDeleteCheckbox: boolean = false; // Флаг кнопка согласие в модальном окне
    isDisabledButtonDeleteAll: boolean = true; // Изначально отключено. Кнопка удалить все
    count_meeting: number
    person: any;

    constructor(
        private questionService: QuestionService,
        private urlService: UrlService,
        private router: Router,
        private activeRouter: ActivatedRoute, // Отображает текущий роут компонента
        private storageService: StorageService,
    ) {
        Object.assign(ListMyQuestionComponent, {
            QuestionService,
        });
    }

    @ViewChild('button', {read: ButtonComponent, static: false}) button!: ButtonComponent;
    @ViewChild('modalWindow', {read: WindowComponent, static: false}) modalWindow: WindowComponent;
    @ViewChild('formWindow', {read: WindowComponent, static: false}) formWindow: WindowComponent;
    @ViewChild('table', {read: TableComponent, static: false}) tableWithButtons!: TableComponent;

    /**
     * Очищаем подписку на стрим чтобы не было утечек по памяти
     */
    ngOnDestroy() {
        if (this.mSub) {
            this.mSub.unsubscribe();
        }
    }

    onWindowReady(event: any): void {
        this.init();
    }

    /**
     * Запускается 3 - им
     */
    init(): void {
        // init code.
        console.log('Запустился init()');
    }

    /**
     * Св-во нужно для инициализации. Для интерфейса. Нужен для норм работы
     */
    onInit(): void {
        // init code.
        console.log('Запустился onInit()');
    };


    /**
     * Запускается 1 на странице
     */
    ngOnInit() {
        console.log('Запустился ngOnInit()');
        // Получаем начальные данные по запросу
        this.getMyQuestions();
    }


    /**
     * Запускается 2 на странице после ngOnInit()
     */
    ngAfterViewInit() {
        // Заменяет иконки после прорисовки компонента на нужные
        const windowWithFeather = window as any;
        windowWithFeather.feather.replace();
        console.log('Запустился  ngAfterViewInit()');
        window.onload = function () {
            console.log(' window.onload ');

        }
    }
    onButtonClick(event: any): void {
        this.formWindow.opened ? this.formWindow.close() : this.formWindow.open();
    }

    onButtonClick2(event: any): void {
        this.formWindow.close();
    }

    /**
     * Получить все мои вопросы
     */
    getMyQuestions(): void {
        this.mSub = this.questionService.getMyQuestion()
            .subscribe(myQuestion => {
                // Заполнили данными
                this.dataAdapter.dataSource = myQuestion.results;
            });
    }

    /**
     * Вызывается обработчик события и апдейтится поле когда закончили редактирование в колонке
     * @param event
     */
    onItemAfterEdit(event: any) {
        this.mSub = this.questionService.updateMyQuestionValue(event.detail).subscribe(questions => {
            // Получили обновленные данные и записали в this.dataAdapter.dataSource
            this.getMyQuestions();
            // Скинули все чекбоксы
            this.tableWithButtons.clearSelection();
        });
    }

    /**
     * Вызывается обработчик события когда кликнули по иконкам или чекбоксу
     * @param event
     */
    onCellClick(event: { detail: { dataField: string; row: { id: number; }; id: string; }; target: any; }) {
        //Клик по иконке delete
        if (event.detail.dataField === 'actionDelete') {
            this.deleteMyQuestion(event.detail.row.id)
            document.querySelector('[row-id="' + event.detail.id + '"]')?.remove();
        }

        //Клик по чекбоксу
        if (this.selectedItems === undefined || this.selectedItems.length == 0) {
            this.selectedItems.push(event.detail.row.id);
        } else {
            // Если еще нет значения в массиве то добавляем
            if (!this.selectedItems.includes(event.detail.row.id)) {
                //console.log('еще нет значения');
                this.selectedItems.push(event.detail.row.id);
            } else {
                //console.log('такое есть значения'); Удаляем из массива selectedItems
                const index = this.selectedItems.indexOf(event.detail.row.id, 0);
                //console.log('index - ' + index);
                if (index > -1) {
                    this.selectedItems.splice(index, 1);
                }
            }
        }
    }

    /**
     * Клик по выбрать все чекбоксы. Записываем данные в this.selectedItems
     * @param event
     */
    onChangeCheckbox(event: { detail: { dataField: string; row: { id: number; }; id: string; }; target: any; type: string }) {
        let allCheckboxSelected = document.querySelectorAll('th.selected');
        let sameCheckboxSelected = document.querySelectorAll('th.indeterminate');

        if (allCheckboxSelected.length > 0 || sameCheckboxSelected.length > 0) {
            this.isDisabledButtonDeleteAll = false;

            let checked = document.querySelectorAll('tr[aria-selected="true"]');
            checked.forEach((item, key) => {

                // @ts-ignore
                let val = item.querySelector('[data-field="id"]').innerHTML;
                // Если еще нет значения в массиве то добавляем
                if (!this.selectedItems.includes(Number(val))) {
                    //console.log('еще нет значения');
                    this.selectedItems.push(Number(val));
                }
            })
        } else {
            this.selectedItems = [];
            this.isDisabledButtonDeleteAll = true;
        }
    }

    /**
     * Кнопка Удалить все выбранные чекбоксы. Http запрос на сервер и визуал
     */
    deleteSelectedCheckBox(event: any): void {
        // Навешиваем обработчики событий.
        const cancelHandler = (event: CustomEvent): void => {
            const target: HTMLElement = event.target as HTMLElement,
                modal = this.formWindow.nativeElement;
            target.closest('smart-window') === modal ? modal.close() : modal.close();
            this.agreeDeleteCheckbox = false;
        }
        // Подтверждение
        const agreeHandler = (event: CustomEvent): void => {
            const target: HTMLElement = event.target as HTMLElement,
                modal = this.formWindow.nativeElement;
            target.closest('smart-window') === modal ? modal.close() : modal.close();
            this.agreeDeleteCheckbox = true;
            this.isDisabledButtonDeleteAll = true;
            this.deleteSelectedCheckBox(event)
        }
        console.log('sdsds1');
        // @ts-ignore may be null
        document.getElementById('сancelButton').addEventListener('click', cancelHandler);
        // @ts-ignore may be null
        document.getElementById('agreeHandler').addEventListener('click', agreeHandler);
        // Конец навешиваем обработчики событий.

        let sameCheckboxSelected = document.querySelectorAll('th.indeterminate');
        let allCheckboxSelected = document.querySelectorAll('th.selected');
        const target: HTMLElement = event.target as HTMLElement,
            modal = this.formWindow.nativeElement;
        if (allCheckboxSelected.length > 0 || sameCheckboxSelected.length > 0) {
            target.closest('smart-window') === modal ? modal.open() : modal.open();
            this.isDisabledButtonDeleteAll = false;
        } else {
            this.selectedItems = [];
            this.isDisabledButtonDeleteAll = true;
        }
        if (this.agreeDeleteCheckbox) {
            target.closest('smart-window') === modal ? modal.close() : modal.close();
            this.mSub = this.questionService.deleteMyQuestions(this.selectedItems)
                .subscribe(questions => {
                    // Обновили таблицу
                    this.getMyQuestions();
                    // Скинули все чекбоксы
                    this.tableWithButtons.clearSelection();
                });
            this.selectedItems = [];
            this.agreeDeleteCheckbox = false;
            // this.ngOnInit()
            //Перезагружаем текущую страницу
            location.reload()
        }
       this.router.navigate(['/my-questions']);

        this.agreeDeleteCheckbox = false;
    }

    /**
     * Удалить мой вопрос
     */
    deleteMyQuestion(id: number): void {
        this.mSub = this.questionService.deleteMyQuestion(id)
            .subscribe(question => {
                // Заполнили данными
                // this.dataAdapter.dataSource = question.result;
            });
    }

    /**
     * Создать новый вопрос
     */
    addQuestion(event: any): void {
        this.router.navigate(['my-questions/create-question']).then();
    }

    /**
     **  Провайдер данных обозначаем какие поля в нем будут
     */
    dataAdapter: DataAdapter = new Smart.DataAdapter({
        dataSource: [],
        dataFields: [
            'id: number',
            'questionDateBegin: string',
            'text_message: string',
            'text_owner: string',
        ],
    })

    dataSource = this.dataAdapter;

    /**
     * Настройки таблицы
     */
    questionsDataSettings = {
        /**
         * Св-во нужно для инициализации. Для интерфейса. Нужен для норм работы
         */
        onInit: () => {

        },

        behavior: {columnResizeMode: 'growAndShrink'},
        questionService: ListMyQuestionComponent.questionService,
        // Пример запроса по ajax. Проблема fetch делается несколько раз
        /*    dataSource: new window.Smart.DataAdapter({
              virtualDataSourceCache: false,
              virtualDataSource:  function (resultCallbackFunction: ResultCallbackFunction) {
                fetch('/api/v1/product/getMeetings/')
                  .then(response => response.json())
                  .then(json => {
                    const data: [] = json.results.map((result: {
                      id: number;
                      address: any;
                      meetingDate: string
                      initiator: string;
                      ownerCount: number;
                      questionCount: number;
                      meetingStatus: number;
                      paymentStatus: number;
                      comment: string;

                    }) => {
                      return {
                        id: result.id,
                        meetingDate: result.meetingDate,
                        address: result.address,
                        initiator: result.initiator,
                        ownerCount: result.ownerCount,
                        questionCount: result.questionCount,
                        meetingStatus: result.meetingStatus,
                        paymentStatus: result.paymentStatus,
                        comment: result.comment,
                      };
                    });
                    resultCallbackFunction({
                      dataSource: data
                    });
                  });
              },
              dataFields: [
                'id: number',
                'address: string',
                'meetingDate: string',
                'initiator: string',
                'ownerCount: number',
                'questionCount: number',
                'meetingStatus: number',
                'paymentStatus: number',
                'comment:       string',
                'createDate:    string',
                'updateDate: string'
              ],
            }),*/

        // Параметры таблицы
        pageIndex: 0,
        pageSize: "10",
        filtering: true,
        paging: true,
        sortMode: 'many',
        filterRow: false,
        tooltip: true,
        selection: true,
        editing: true,
        editMode: 'row',
        keyboardNavigation: true,

        // Колонки в таблице
        columns: [
            {
                label: 'id', dataField: 'id', dataType: 'number', responsivePriority: 3, width: 50,
            },
            /*           {
                           label: 'Id', dataField: 'actionId', dataType: 'number', responsivePriority: 3, width: 50,
                           formatFunction: function (settings: any) {
                               settings.template =
                                   '<a href="javascript:;" class="nav-link rounded action-icon">' + settings.data.id + '</a>';
                           }
                       },*/
            {label: 'Формулировка для уведомления', dataField: 'text_message', dataType: 'string', responsivePriority: 3},
            {label: 'Формулировка для решения собственника', dataField: 'text_owner', dataType: 'string', responsivePriority: 3},
            /*       {
                       label: 'Кол-во собраний',
                       dataField: 'count_meeting',
                       allowEdit: false,
                       dataType: 'number',
                       responsivePriority: 3,
                       formatFunction: (value: any) => {
                           console.log('value.dataSource')
                     console.log(value.dataSource)
                       }
                   },*/
            /*    {label: 'Комментарий', dataField: 'comment', dataType: 'string', responsivePriority: 3},*/
            /*    {label: 'Дата создания дома', dataField: 'createDate', allowEdit: false, dataType: 'string', responsivePriority: 3},*/
            {
                label: 'Дата создания',
                dataField: 'questionDateBegin',
                allowEdit: false,
                dataType: 'string',
                responsivePriority: 3,
                width: 150,
                formatFunction: (value: any) => {
                    new DatePipe('en-US').transform(value.value, 'dd-MM-yyyy');
                    value.template = new DatePipe('en-US').transform(value.value, 'dd.MM.yyyy');
                }
            },
            {
                label: '', dataField: 'actionDelete', allowEdit: false, width: 50,
                formatFunction: function (settings: any) {
                    settings.template =
                        '<a href="javascript:;" class="nav-link rounded action-icon">' +
                        '<i class="material-icons">delete_forever</i>' + '</a>';
                }
            },
        ],

    }

}
