import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {MeetingService} from "../../pages/meetings/services/meeting.service";
import {Injectable} from "@angular/core";

// Благодаря такому декоратору @Injectable мы можем инжектировать в данный сервис другие сущности
@Injectable({
    // Данной строкой мы говорим чтобы ангуляр зарегал данный сервис в корневом модуле (в системе в единственном экз.).
    providedIn: 'root'
    // Поэтому из корневого модуля можно исключить MeetingService так как он регается/ тут
    // Данный сервис зареган глобально и виден во всех компонентах
    // Можно регать сервисы локально (каждый раз создается копия сервиса с новыми данными) в конкретном компоненте тогда нужно в конкретный компонент в декоратор
    //  @Component({
    //    providers: [MessageService],
    //  })
    // Также можно использовать сервисы внутри других сервисов. Внедрение через конструктор
})
export class MeetingQueueResolver implements Resolve<any> {
    constructor(
        public meetingService: MeetingService,
    ) {
        Object.assign(MeetingQueueResolver, {
            MeetingService,
        });
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        console.log('Loading MeetingQueueResolver')
        return this.meetingService.getMeetingQueue()
    }

}

