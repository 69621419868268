<div class="container">
  <div class="text-center">
    <smart-button class="success small" (click)="actionCreateUser()">Создать юзера</smart-button>
  </div>
  <div class="text-center">
    <smart-button class="success small" (click)="actionGetAllUsers()">Получить юзеров</smart-button>
  </div>
  <div class="text-center">
    <smart-button class="success small" (click)="actionParser()">Парсим Excel </smart-button>
  </div>
</div>
<div class="container table-with-buttons">
  <div class="custom-row">
    <div class="table-with-buttons-header custom-col">
      <h4>Table With Buttons</h4>
    </div>
  </div>

  <div class="custom-card">
    <div class="card-body">
      <smart-table #tableWithButtons id="table-with-buttons" [dataSource]="tableWithButtonsSettings.dataSource"
                   [paging]="tableWithButtonsSettings.paging" [sortMode]="tableWithButtonsSettings.sortMode"
                   [tooltip]="tableWithButtonsSettings.tooltip" [onInit]="tableWithButtonsSettings.onInit"
                   [columns]="tableWithButtonsSettings.columns" [filtering]="tableWithButtonsSettings.filtering"
                   [filterRow]="tableWithButtonsSettings.filterRow"
                   [selection]="tableWithButtonsSettings.selection">
      </smart-table>
    </div>
  </div>
</div>
