<div class="container input-groups">
    <div class="custom-row">
        <div class="input-groups-header custom-col">
            <h4>Input Groups</h4>
        </div>
    </div>

    <div class="custom-row">
        <div class="custom-col">
            <div class="custom-card">
                <div class="card-header">
                    <h4 class="label">Default</h4>
                </div>
                <div class="card-body">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">@</span>
                        </div>
                        <smart-input class="form-control" placeholder="Username"></smart-input>
                    </div>

                    <div class="input-group">
                        <smart-input class="form-control"
                            data-source="['oliver', 'george', 'harry', 'jack', 'jacob', 'noah', 'charlie']"
                            placeholder="Recipient's username"></smart-input>
                        <div class="input-group-append">
                            <span class="input-group-text">@example.com</span>
                        </div>
                    </div>

                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">https://example.com/users/</span>
                        </div>
                        <smart-input class="form-control"
                            data-source="['oliver', 'george', 'harry', 'jack', 'jacob', 'noah', 'charlie']"
                            placeholder="Recipient's username"></smart-input>
                    </div>

                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">$</span>
                        </div>
                        <smart-input class="form-control"></smart-input>
                        <div class="input-group-append">
                            <span class="input-group-text">.00</span>
                        </div>
                    </div>

                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">$</span>
                            <span class="input-group-text">0.00</span>
                        </div>
                        <smart-input class="form-control"></smart-input>
                    </div>
                </div>
            </div>

            <div class="custom-card">
                <div class="card-header">
                    <h4 class="label">Checkbox and radio buttons</h4>
                </div>
                <div class="card-body">
                    <div class="input-group-container">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <smart-check-box></smart-check-box>
                                </span>
                            </div>
                            <smart-input class="form-control"></smart-input>
                        </div>

                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <smart-radio-button></smart-radio-button>
                                </span>
                            </div>
                            <smart-input class="form-control"></smart-input>
                        </div>
                    </div>
                </div>
            </div>

            <div class="custom-card">
                <div class="card-header">
                    <h4 class="label">Buttons with dropdowns</h4>
                </div>
                <div class="card-body">
                    <div class="input-group-container">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <smart-multi-split-button buttons-data-source="['Action']" drop-down-append-to="body"
                                    drop-down-open-mode="auto" drop-down-width="auto">
                                    <smart-list-item>First action</smart-list-item>
                                    <smart-list-item>Second action</smart-list-item>
                                    <smart-list-item>Third action</smart-list-item>
                                </smart-multi-split-button>
                            </div>
                            <smart-input class="form-control" placeholder="Search for..."></smart-input>
                        </div>

                        <div class="input-group">
                            <smart-input class="form-control" placeholder="Search for..."></smart-input>
                            <div class="input-group-append">
                                <smart-multi-split-button buttons-data-source="['Action']" drop-down-append-to="body"
                                    drop-down-open-mode="auto" drop-down-width="auto">
                                    <smart-list-item>First action</smart-list-item>
                                    <smart-list-item>Second action</smart-list-item>
                                    <smart-list-item>Third action</smart-list-item>
                                </smart-multi-split-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="custom-col">
            <div class="custom-card">
                <div class="card-header">
                    <h4 class="label">Select addons</h4>
                </div>
                <div class="card-body">
                    <div class="input-group">
                        <smart-input class="form-control" data-source='["A", "B", "C", "D", "E"]'
                            drop-down-button-position="right" placeholder="Select..."></smart-input>
                        <div class="input-group-append">
                            <smart-button class="primary">GO!</smart-button>
                        </div>
                    </div>

                    <div class="input-group">
                        <smart-input class="form-control" placeholder="Search for..."></smart-input>
                        <smart-input class="form-control" data-source='["A", "B", "C", "D", "E"]'
                            drop-down-button-position="right" placeholder="Select..."></smart-input>
                        <div class="input-group-append">
                            <smart-button class="primary">GO!</smart-button>
                        </div>
                    </div>

                    <div class="input-group">
                        <smart-input class="form-control" data-source='["A", "B", "C", "D", "E"]'
                            drop-down-button-position="right" placeholder="Select..."></smart-input>
                        <smart-input class="form-control" placeholder="Search for..."></smart-input>
                        <div class="input-group-append">
                            <smart-button class="primary">GO!</smart-button>
                        </div>
                    </div>

                    <div class="input-group">
                        <div class="input-group-prepend">
                            <smart-button class="primary">GO!</smart-button>
                        </div>
                        <smart-input class="form-control" placeholder="Search for..."></smart-input>
                        <smart-input class="form-control" data-source='["A", "B", "C", "D", "E"]'
                            drop-down-button-position="right" placeholder="Select..."></smart-input>
                    </div>
                </div>
            </div>

            <div class="custom-card">
                <div class="card-header">
                    <h4 class="label">Button addons</h4>
                </div>
                <div class="card-body">
                    <div class="input-group-container">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <smart-button class="primary">GO!</smart-button>
                            </div>
                            <smart-input class="form-control" placeholder="Search for..."></smart-input>
                        </div>

                        <div class="input-group">
                            <smart-input class="form-control" placeholder="Search for..."></smart-input>
                            <div class="input-group-append">
                                <smart-button class="primary">GO!</smart-button>
                            </div>
                        </div>
                    </div>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <smart-button class="primary">Like!</smart-button>
                        </div>
                        <smart-input class="form-control" placeholder="Product name"></smart-input>
                        <div class="input-group-append">
                            <smart-button class="primary">Dislike!</smart-button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="custom-card">
                <div class="card-header">
                    <h4 class="label">Segmented buttons</h4>
                </div>
                <div class="card-body">
                    <div class="input-group-container">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <smart-multi-split-button buttons-data-source="['Action']" drop-down-append-to="body"
                                    drop-down-width="auto">
                                    <smart-list-item>First action</smart-list-item>
                                    <smart-list-item>Second action</smart-list-item>
                                    <smart-list-item>Third action</smart-list-item>
                                </smart-multi-split-button>
                            </div>
                            <smart-input class="form-control" placeholder="Search for..."></smart-input>
                        </div>

                        <div class="input-group">
                            <smart-input class="form-control" placeholder="Search for..."></smart-input>
                            <div class="input-group-append">
                                <smart-multi-split-button buttons-data-source="['Action']" drop-down-append-to="body"
                                    drop-down-width="auto">
                                    <smart-list-item>First action</smart-list-item>
                                    <smart-list-item>Second action</smart-list-item>
                                    <smart-list-item>Third action</smart-list-item>
                                </smart-multi-split-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>