import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'sm-basic-filters',
  templateUrl: './basic-filters.component.html',
  styleUrls: ['./basic-filters.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BasicFiltersComponent {

  constructor() { }

}
