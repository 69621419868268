<div class="container carousel">
    <div class="custom-row">
        <div class="carousel-header custom-col">
            <h4>Carousel</h4>
        </div>
    </div>

    <div class="custom-row">
        <div class="carousel-entity custom-col">
            <div class="custom-card h-100">
                <div class="card-header">
                    <h4 class="label">Slides only</h4>
                </div>
                <div class="card-body">
                    <smart-carousel [autoPlay]="true" [dataSource]="[
                    { 'image': '../../../../assets/images/travel/4.jpg' }, { 'image'
                        : '../../../../assets/images/travel/5.jpg' }, { 'image'
                        : '../../../../assets/images/travel/6.jpg' }]" [hideArrows]="true" [hideIndicators]="true"
                        [interval]="2000" [loop]="true" [slideShow]="true"></smart-carousel>
                </div>
            </div>
        </div>
        <div class="carousel-entity custom-col">
            <div class="custom-card h-100">
                <div class="card-header">
                    <h4 class="label">With controls</h4>
                </div>
                <div class="card-body">
                    <smart-carousel [autoPlay]="true" [dataSource]="[{ '
                        image': '../../../../assets/images/travel/4.jpg' }, { 'image'
                        : '../../../../assets/images/travel/5.jpg' }, { 'image'
                        : '../../../../assets/images/travel/6.jpg' }]" [hideIndicators]="true" [interval]="5000"
                        [loop]="true" [slideShow]="true">
                    </smart-carousel>
                </div>
            </div>
        </div>
    </div>
    <div class="custom-row">
        <div class="carousel-entity custom-col">
            <div class="custom-card h-100">
                <div class="card-header">
                    <h4 class="label">With indicators</h4>
                </div>
                <div class="card-body">
                    <smart-carousel [autoPlay]="true" [dataSource]="[{ '
                        image': '../../../../assets/images/travel/4.jpg' }, { 'image'
                        : '../../../../assets/images/travel/5.jpg' }, { 'image'
                        : '../../../../assets/images/travel/6.jpg' }]" [interval]="5000" [loop]="true"
                        [slideShow]="true"></smart-carousel>
                </div>
            </div>
        </div>
        <div class="carousel-entity custom-col">
            <div class="custom-card h-100">
                <div class="card-header">
                    <h4 class="label">With captions</h4>
                </div>
                <div class="card-body">
                    <smart-carousel id="carouselWithCaptions" [autoPlay]="true" [dataSource]="[{ '
                        image': '../../../../assets/images/travel/4.jpg' , 'label' : 'Photo 1' }, { 'image'
                        : '../../../../assets/images/travel/5.jpg' , 'label' : 'Photo 2' }, { 'image'
                        : '../../../../assets/images/travel/6.jpg' , 'label' : 'Photo 3' }]" [interval]="5000"
                        [loop]="true" [slideShow]="true"></smart-carousel>
                </div>
            </div>
        </div>
    </div>
    <div class="custom-row">
        <div class="carousel-entity custom-col">
            <div class="custom-card h-100">
                <div class="card-header">
                    <h4 class="label">Slide animation</h4>
                </div>
                <div class="card-body">
                    <smart-carousel class="slide" [autoPlay]="true" [dataSource]="[{ '
                        image': '../../../../assets/images/travel/4.jpg' }, { 'image'
                        : '../../../../assets/images/travel/5.jpg' }, { 'image'
                        : '../../../../assets/images/travel/6.jpg' }]" [hideIndicators]="true" [interval]="5000"
                        [loop]="true" [slideShow]="true">
                    </smart-carousel>
                </div>
            </div>
        </div>
    </div>
</div>