<div class="container reset-password">
    <div class="reset-password-header">
        <h2>Reset password</h2>
        <p>Enter your email to reset your password</p>
    </div>

    <div class="reset-password-body custom-row">
        <div class="card-wrapper custom-col">
            <div class="card-shadow">
                <div class="card-body">
                    <div class="form-group">
                        <label>Email</label>
                        <smart-input placeholder="Enter your email"></smart-input>
                    </div>

                    <div class="text-center">
                        <smart-button class="primary">Reset password</smart-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>