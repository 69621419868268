import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

const API_URL = 'http://localhost:8080/api/test/';

@Injectable({
    providedIn: 'root',
})
/**
 * Сервис предоставляет методы доступа к общедоступным и защищенным ресурсам.
 * Поскольку файлы cookie HttpOnly будут автоматически отправляться вместе с HTTP-запросами (через перехватчик Http),
 * поэтому мы просто используем модуль Http, не заботясь о JWT.
 */
export class UserService {
    constructor(private http: HttpClient) {}

    getPublicContent(): Observable<any> {
        return this.http.get(API_URL + 'all', { responseType: 'text' });
    }

    getUserBoard(): Observable<any> {
        return this.http.get(API_URL + 'user', { responseType: 'text' });
    }

    getModeratorBoard(): Observable<any> {
        return this.http.get(API_URL + 'mod', { responseType: 'text' });
    }

    getAdminBoard(): Observable<any> {
        return this.http.get(API_URL + 'admin', { responseType: 'text' });
    }
}