<div class="container overview-2">
    <div class="custom-card" style="margin-bottom: 1.5rem;">
        <div class="card-body">
            <div class="custom-row mb-6 card-row">
                <div class="custom-col col-entity-md-auto image-col">
                    <div class="img-as-background square rounded card-image">
                        <img src="assets/images/people/mark.jpg" alt="image">
                    </div>
                </div>

                <div class="custom-col col-entity-md">
                    <div class="d-flex justify-content-between flex-wrap mt-1">
                        <div class="d-flex align-items-center card-name">
                            <h5 style="margin-right: 0.5rem;"><a href="#"
                                    class="text-black hover-text-primary font-weight-500">John Doe</a></h5>
                            <a href="#"><i class="material-icons text-success">beenhere</i></a>
                        </div>
                        <div class="card-buttons">
                            <smart-button class="success very-small">ASK</smart-button>&nbsp;<smart-button
                                class="info very-small">HIRE</smart-button>
                        </div>
                    </div>

                    <div class="custom-row justify-content-between mt-1">
                        <div class="custom-col col-entity-lg-6 col-entity-xl-8">
                            <div class="d-flex flex-wrap meta-wrapper">
                                <a href="#"
                                    class="text-dark hover-text-primary d-inline-flex align-items-center meta-info">
                                    <i class="material-icons">email</i> j.doe@mailinator.com
                                </a>

                                <a href="#"
                                    class="text-dark hover-text-primary d-inline-flex align-items-center meta-info">
                                    <i class="material-icons">account_box</i> PR Manager
                                </a>

                                <a href="#"
                                    class="text-dark hover-text-primary d-inline-flex align-items-center meta-info">
                                    <i class="material-icons">place</i> Melbourne
                                </a>
                            </div>

                            <p>I distinguish three main text objectives could be merely to inform people. <br /> A
                                second could be persuade people.You want people to bay objective</p>
                        </div>
                        <div class="custom-col col-entity-lg-6 col-entity-xl-4 d-flex align-items-end">
                            <div class="d-flex align-items-center w-100">
                                <span class="font-weight-bold">Progress</span>

                                <div class="card-progress w-100">
                                    <smart-progress-bar [value]="74" class="w-100"></smart-progress-bar>
                                </div>
                                <span class="font-weight-bold text-dark">78%</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <hr />

            <div class="d-flex align-items-center flex-wrap cards-wrapper">
                <div class="d-flex align-items-center card-wrapper">
                    <span class="card-icon d-inline-flex align-items-center">
                        <i class="material-icons text-muted">point_of_sale</i>
                    </span>
                    <div class="d-flex flex-column">
                        <small class="font-weight-500">Earnings</small>
                        <h5 class="mb-0"><span class="font-weight-500">$</span>249,500</h5>
                    </div>
                </div>

                <div class="d-flex align-items-center card-wrapper">
                    <span class="card-icon d-inline-flex align-items-center">
                        <i class="material-icons text-muted">attach_money</i>
                    </span>
                    <div class="d-flex flex-column">
                        <small class="font-weight-500">Expenses</small>
                        <h5 class="mb-0"><span class="font-weight-500">$</span>164,700</h5>
                    </div>
                </div>

                <div class="d-flex align-items-center card-wrapper">
                    <span class="card-icon d-inline-flex align-items-center">
                        <i class="material-icons text-muted">insights</i>
                    </span>
                    <div class="d-flex flex-column">
                        <small class="font-weight-500">Net</small>
                        <h5 class="mb-0"><span class="font-weight-500">$</span>782,300</h5>
                    </div>
                </div>

                <div class="d-flex align-items-center card-wrapper">
                    <span class="card-icon d-inline-flex align-items-center">
                        <i class="material-icons text-muted">sticky_note_2</i>
                    </span>
                    <div class="d-flex flex-column">
                        <small class="font-weight-500">73 Tasks</small>
                        <a href="#" class="text-primary font-weight-500 text-hover-primary">View</a>
                    </div>
                </div>

                <div class="d-flex align-items-center card-wrapper">
                    <span class="card-icon d-inline-flex align-items-center">
                        <i class="material-icons text-muted">comment</i>
                    </span>
                    <div class="d-flex flex-column">
                        <small class="font-weight-500">648 Comments</small>
                        <a href="#" class="text-primary font-weight-500 text-hover-primary">View</a>
                    </div>
                </div>

                <div class="d-flex align-items-center card-wrapper">
                    <span class="card-icon d-inline-flex align-items-center">
                        <i class="material-icons text-muted">group</i>
                    </span>

                    <div class="members d-flex">
                        <div class="member-image-wrapper square img-as-background rounded-circle"
                            data-bs-toggle="tooltip" title="Mark Stone">
                            <img alt="Pic" src="assets/images/phonebook/andrew.png">
                        </div>
                        <div class="member-image-wrapper square img-as-background rounded-circle"
                            data-bs-toggle="tooltip" title="Charlie Stone" style="margin-left: -10px;">
                            <img alt="Pic" src="assets/images/phonebook/johnny.png">
                        </div>
                        <div class="member-image-wrapper square img-as-background rounded-circle"
                            data-bs-toggle="tooltip" title="Luca Doncic" style="margin-left: -10px;">
                            <img alt="Pic" src="assets/images/phonebook/mark.png">
                        </div>
                        <div class="member-image-wrapper square img-as-background rounded-circle"
                            data-bs-toggle="tooltip" title="Nick Mana" style="margin-left: -10px;">
                            <img alt="Pic" src="assets/images/phonebook/steven.png">
                        </div>
                        <div class="member-image-wrapper square img-as-background rounded-circle"
                            data-bs-toggle="tooltip" title="Teresa Fox" style="margin-left: -10px;">
                            <img alt="Pic" src="assets/images/phonebook/janet.png">
                        </div>
                        <div class="member-image-wrapper h-30px rounded-circle bg-secondary d-inline-flex align-items-center justify-content-center"
                            style="margin-left: -10px; z-index: 9;">
                            <small>9+</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="custom-row new-arrivals-action-row">
        <div class="custom-col col-entity-lg-8">
            <div class="custom-card h-100">
                <div class="card-header border-0">
                    <div>
                        <h4>New Arrivals</h4>
                        <small class="d-block text-muted">More than 400+ new members</small>
                    </div>
                    <div>
                        <ul class="nav nav-pills">
                            <li class="nav-item">
                                <a class="nav-link active" data-bs-toggle="tab" href="#">Month</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-bs-toggle="tab" href="#">Week</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-bs-toggle="tab" href="#">Day</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th class="p-0" style="width: 40px;"></th>
                                    <th class="p-0" style="min-width: 200px;"></th>
                                    <th class="p-0"></th>
                                    <th class="p-0" style="min-width: 125px;"></th>
                                    <th class="p-0" style="min-width: 125px;"></th>
                                    <th class="p-0" style="min-width: 175px;"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="pl-0 align-middle">
                                        <div
                                            class="table-image-wrapper square bg-light rounded d-flex align-item-center justify-content-center">
                                            <img src="assets/images/brands/circle-google.png" class="w-100" alt="">
                                        </div>
                                    </td>
                                    <td class="pl-0 align-middle">
                                        <a href="#" class="font-weight-bold hover-text-primary mb-1">Sant
                                            Outstanding</a>
                                        <div>
                                            <span class="font-weight-bold">Email:</span>&nbsp;<a
                                                class="text-muted font-weight-bold hover-text-primary"
                                                href="#">bprow@bnc.cc</a>
                                        </div>
                                    </td>
                                    <td class="text-right align-middle">
                                        <span class="font-weight-bold d-block">$2,000,000</span>
                                        <span class="text-muted font-weight-bold">Paid</span>
                                    </td>
                                    <td class="text-right align-middle">
                                        <span class="text-muted font-weight-500">ReactJs, HTML</span>
                                    </td>
                                    <td class="text-right align-middle">
                                        <span class="smart-badge smart-badge-primary">Approved</span>
                                    </td>
                                    <td class="text-right align-middle pr-0">
                                        <a href="#" class="btn btn-sm btn-outline-primary">
                                            <i class="material-icons">toggle_on</i>
                                        </a>&nbsp;<a href="#" class="btn btn-sm btn-outline-primary"
                                            style="margin: 0 0.75rem;">
                                            <i class="material-icons">edit</i>
                                        </a>&nbsp;<a href="#" class="btn btn-sm btn-outline-primary">
                                            <i class="material-icons">delete</i>
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="pl-0 align-middle">
                                        <div
                                            class="table-image-wrapper square bg-light rounded d-flex align-item-center justify-content-center">
                                            <img src="assets/images/brands/circle-jqwidgets.png" class="w-100" alt="">
                                        </div>
                                    </td>
                                    <td class="pl-0 align-middle">
                                        <a href="#" class="font-weight-bold hover-text-primary mb-1">Application
                                            Development</a>
                                        <div>
                                            <span class="font-weight-bold">Email:</span>&nbsp;<a
                                                class="text-muted font-weight-bold hover-text-primary"
                                                href="#">app@dev.com</a>
                                        </div>
                                    </td>
                                    <td class="text-right align-middle">
                                        <span class="font-weight-bold d-block">$4,600,000</span>
                                        <span class="text-muted font-weight-bold">Paid</span>
                                    </td>
                                    <td class="text-right align-middle">
                                        <span class="text-muted font-weight-500">Python, MySQL</span>
                                    </td>
                                    <td class="text-right align-middle">
                                        <span class="smart-badge smart-badge-warning">In Progress</span>
                                    </td>
                                    <td class="text-right align-middle pr-0">
                                        <a href="#" class="btn btn-sm btn-outline-primary">
                                            <i class="material-icons">toggle_on</i>
                                        </a>&nbsp;<a href="#" class="btn btn-sm btn-outline-primary"
                                            style="margin: 0 0.75rem;">
                                            <i class="material-icons">edit</i>
                                        </a>&nbsp;<a href="#" class="btn btn-sm btn-outline-primary">
                                            <i class="material-icons">delete</i>
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="pl-0 align-middle">
                                        <div
                                            class="table-image-wrapper square bg-light rounded d-flex align-item-center justify-content-center">
                                            <img src="assets/images/brands/circle-htmlelements.png" class="w-100"
                                                alt="">
                                        </div>
                                    </td>
                                    <td class="pl-0 align-middle">
                                        <a href="#" class="font-weight-bold hover-text-primary mb-1">Payrol
                                            Application</a>
                                        <div>
                                            <span class="font-weight-bold">Email:</span>&nbsp;<a
                                                class="text-muted font-weight-bold hover-text-primary"
                                                href="#">company@dev.com</a>
                                        </div>
                                    </td>
                                    <td class="text-right align-middle">
                                        <span class="font-weight-bold d-block">$560,000</span>
                                        <span class="text-muted font-weight-bold">Paid</span>
                                    </td>
                                    <td class="text-right align-middle">
                                        <span class="text-muted font-weight-500">Laravel, Metronic</span>
                                    </td>
                                    <td class="text-right align-middle">
                                        <span class="smart-badge smart-badge-success">Success</span>
                                    </td>
                                    <td class="text-right align-middle pr-0">
                                        <a href="#" class="btn btn-sm btn-outline-primary">
                                            <i class="material-icons">toggle_on</i>
                                        </a>&nbsp;<a href="#" class="btn btn-sm btn-outline-primary"
                                            style="margin: 0 0.75rem;">
                                            <i class="material-icons">edit</i>
                                        </a>&nbsp;<a href="#" class="btn btn-sm btn-outline-primary">
                                            <i class="material-icons">delete</i>
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="pl-0 align-middle">
                                        <div
                                            class="table-image-wrapper square bg-light rounded d-flex align-item-center justify-content-center">
                                            <img src="assets/images/brands/circle-google.png" class="w-100" alt="">
                                        </div>
                                    </td>
                                    <td class="pl-0 align-middle">
                                        <a href="#" class="font-weight-bold hover-text-primary mb-1">HR Management
                                            System</a>
                                        <div>
                                            <span class="font-weight-bold">Email:</span>&nbsp;<a
                                                class="text-muted font-weight-bold hover-text-primary"
                                                href="#">hr@demo.com</a>
                                        </div>
                                    </td>
                                    <td class="text-right align-middle">
                                        <span class="font-weight-bold d-block">$57,000</span>
                                        <span class="text-muted font-weight-bold">Paid</span>
                                    </td>
                                    <td class="text-right align-middle">
                                        <span class="text-muted font-weight-bold">AngularJS, C#</span>
                                    </td>
                                    <td class="text-right align-middle">
                                        <span class="smart-badge smart-badge-danger">Rejected</span>
                                    </td>
                                    <td class="text-right align-middle pr-0">
                                        <a href="#" class="btn btn-sm btn-outline-primary">
                                            <i class="material-icons">toggle_on</i>
                                        </a>&nbsp;<a href="#" class="btn btn-sm btn-outline-primary"
                                            style="margin: 0 0.75rem;">
                                            <i class="material-icons">edit</i>
                                        </a>&nbsp;<a href="#" class="btn btn-sm btn-outline-primary">
                                            <i class="material-icons">delete</i>
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="pl-0 align-middle">
                                        <div
                                            class="table-image-wrapper square bg-light rounded d-flex align-item-center justify-content-center">
                                            <img src="assets/images/brands/circle-angular.svg" class="w-100" alt="">
                                        </div>
                                    </td>
                                    <td class="pl-0 align-middle">
                                        <a href="#" class="font-weight-bold hover-text-primary mb-1">KTR Mobile
                                            Application</a>
                                        <div>
                                            <span class="font-weight-bold">Email:</span>&nbsp;<a
                                                class="text-muted font-weight-bold hover-text-primary"
                                                href="#">ktr@demo.com</a>
                                        </div>
                                    </td>
                                    <td class="text-right align-middle">
                                        <span class="font-weight-bold d-block">$45,200,000</span>
                                        <span class="text-muted font-weight-bold">Paid</span>
                                    </td>
                                    <td class="text-right align-middle">
                                        <span class="text-muted font-weight-500">ReactJS, Ruby</span>
                                    </td>
                                    <td class="text-right align-middle">
                                        <span class="smart-badge smart-badge-warning">In Progress</span>
                                    </td>
                                    <td class="text-right align-middle pr-0">
                                        <a href="#" class="btn btn-sm btn-outline-primary">
                                            <i class="material-icons">toggle_on</i>
                                        </a>&nbsp;<a href="#" class="btn btn-sm btn-outline-primary"
                                            style="margin: 0 0.75rem;">
                                            <i class="material-icons">edit</i>
                                        </a>&nbsp;<a href="#" class="btn btn-sm btn-outline-primary">
                                            <i class="material-icons">delete</i>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="custom-col col-entity-lg-4">
            <div class="custom-card h-100">
                <div class="card-header border-0">
                    <h4 class="label">Action Needed</h4>

                    <div class="settings-button" #actionButton (click)="toggleMenu(actionButton)"
                        (pointerup)="stopPropagation($event)">…</div>
                </div>
                <div class="card-body d-flex align-items-center justify-content-between flex-column">
                    <div></div>
                    <smart-circular-progress-bar class="action-progress-bar" [value]="75" show-progress-value>
                    </smart-circular-progress-bar>
                    <div>
                        <p class="text-center mt-5">Lorem ipsum, or lipsum as it is sometimes known, is dummy text used
                            in laying out print or graphic.</p>
                        <smart-button class="w-100">Generate Report</smart-button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="custom-row recent-orders-trends-row">
        <div class="custom-col col-entity-lg-6">
            <div class="custom-card h-100">
                <div class="card-header border-0">
                    <div>
                        <h4>Recent Orders</h4>
                        <small class="d-block text-muted">More than 500+ new orders</small>
                    </div>
                    <div>
                        <ul class="nav nav-pills">
                            <li class="nav-item">
                                <a class="nav-link active" data-bs-toggle="tab" href="#">Month</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-bs-toggle="tab" href="#">Week</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-bs-toggle="tab" href="#">Day</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="card-body">
                    <smart-chart #revenueChart class="overview-2-revenue-chart h-100 w-100 border-0"
                        [animation]="revenueChartData.animation" [theme]="revenueChartData.theme!"
                        [caption]="revenueChartData.caption" [description]="revenueChartData.description"
                        [showLegend]="revenueChartData.showLegend" [showBorderLine]="revenueChartData.showBorderLine"
                        [enableCrosshairs]="revenueChartData.enableCrosshairs" [padding]="revenueChartData.padding"
                        [dataSource]="revenueChartData.dataSource" [seriesGroups]="revenueChartData.seriesGroups"
                        [colorScheme]="revenueChartData.colorScheme" [xAxis]="revenueChartData.xAxis">
                    </smart-chart>
                </div>
            </div>
        </div>

        <div class="custom-col col-entity-lg-6">
            <div class="custom-card h-100">
                <div class="card-header border-0">
                    <h4 class="label">Trends</h4>

                    <div class="settings-button" #trendsButton (click)="toggleMenu(trendsButton)"
                    (pointerup)="stopPropagation($event)">…</div>
                </div>
                <div class="card-body">
                    <div class="d-flex align-items-center bg-warning-opacity-3 rounded trend-wrapper">
                        <i class="material-icons text-warning">rule</i>

                        <div class="d-flex flex-column flex-grow-1 mr-2">
                            <a href="#" class="text-black font-weight-bold hover-text-primary mb-1">Group lunch
                                celebration</a>
                            <span class="text-muted">Due in 2 Days</span>
                        </div>

                        <span class="text-warning py-1 font-weight-bold">+28%</span>
                    </div>

                    <div class="d-flex align-items-center bg-success-opacity-3 rounded trend-wrapper">
                        <i class="material-icons text-success">rule</i>

                        <div class="d-flex flex-column flex-grow-1 mr-2">
                            <a href="#" class="text-black font-weight-bold hover-text-primary mb-1">Home navigation
                                optimization</a>
                            <span class="text-muted">Due in 2 Days</span>
                        </div>

                        <span class="text-success py-1 font-weight-bold">+50%</span>
                    </div>

                    <div class="d-flex align-items-center bg-danger-opacity-3 rounded trend-wrapper">
                        <i class="material-icons text-danger">rule</i>

                        <div class="d-flex flex-column flex-grow-1 mr-2">
                            <a href="#" class="text-black font-weight-bold hover-text-primary mb-1">Rebrand strategy
                                planning</a>
                            <span class="text-muted">Due in 2 Days</span>
                        </div>

                        <span class="text-danger py-1 font-weight-bold">-27%</span>
                    </div>

                    <div class="d-flex align-items-center bg-info-opacity-3 rounded trend-wrapper">
                        <i class="material-icons text-info">rule</i>

                        <div class="d-flex flex-column flex-grow-1 mr-2">
                            <a href="#" class="text-black font-weight-bold hover-text-primary mb-1">Product goals
                                strategy meet-up</a>
                            <span class="text-muted">Due in 2 Days</span>
                        </div>

                        <span class="text-info py-1 font-weight-bold">+8%</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>