import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';

@Component({
    selector: 'app-board-moderator',
    templateUrl: './board-moderator.component.html',
    styleUrls: ['./board-moderator.component.css']
})

/**
 * Эти компоненты основаны на ролях. Но авторизация будет обработана серверной частью.
 * Нам нужно только вызвать методы UserService: getUserBoard() getModeratorBoard() getAdminBoard() Вот пример BoardAdminComponent.
 * BoardModeratorComponent и BoardUserComponent похожи.
 */
export class BoardModeratorComponent implements OnInit {
    content?: string;

    constructor(private userService: UserService) { }

    ngOnInit(): void {
        this.userService.getModeratorBoard().subscribe({
            next: data => {
                this.content = data;
            },
            error: err => {
                if (err.error) {
                    try {
                        const res = JSON.parse(err.error);
                        this.content = res.message;
                    } catch {
                        this.content = `Error with status: ${err.status} - ${err.statusText}`;
                    }
                } else {
                    this.content = `Error with status: ${err.status}`;
                }
            }
        });
    }
}