import {Component, OnInit, OnDestroy, AfterViewInit, ViewEncapsulation} from '@angular/core';
import {HouseService} from "src/app/pages/meetings/services/house.service";
import {Subscription} from "rxjs";
import {BrowserModule} from "@angular/platform-browser";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {StorageService} from "../../../services/storage.service";
import {QuestionService} from "../services/question.service";
import {UrlService} from "../services/url.service";
import { CommonModule } from '@angular/common'; // импортнули изза ошибки Can’t bind to ‘ngif’ since it isn’t a known property of ‘div’


// @ts-ignore
@Component({
    selector: 'form-create-question',
    templateUrl: 'form-create-question.component.html',
    providers: [BrowserModule, CommonModule],
    styleUrls: ['form-create-question.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class FormCreateQuestionComponent implements AfterViewInit, OnDestroy, OnInit {
    // Константа из БД таблица question_type - Мои вопросы
    typeId = 8;

    // Переменная отвечающая за подписку
    mSub: Subscription
    questionCreateForm: FormGroup

    // Переменная отвечающая за подписку
    subscription1$: Subscription;
    subscription2$: Subscription;
    subscription3$: Subscription;
    subscriptions: Subscription[] = [];

    mSubRouter: Subscription
    
    // Переменная содержит выбранное значение из выпадающего списка
    questionSolutionId: any // Критерий принятия решения

    constructor(
        public questionService: QuestionService,
        private houseService: HouseService,
        private storageService: StorageService,
        private router: Router,
        public urlService: UrlService,
        private activeRouter: ActivatedRoute, // Отображает текущий роут компонента
    ) {
        Object.assign(FormCreateQuestionComponent, {
            houseService,
            storageService,
            questionService
        });
    }
    
    /**
     * Запускается 1 на странице
     */
    ngOnInit() {
        console.log('Запустился ngOnInit()');
        // Получаем параметры из url
        this.activeRouter.params.subscribe((params: Params) => {
            this.urlService.urlParams = params
        })
        // Получаем данные через предзагрузку используем resolver questionSolutionPreload
        // questionSolutionPreload - имя ресолвера используется в компоненте app-routing.module.ts
        this.subscription1$ = this.activeRouter.data.subscribe(
            (response: any) => {
                this.questionService.questionSolution = response.questionSolutionPreload.results;
            }
        )
        // Добавляем все подписки в массив чтобы потом от них отписаться
        this.subscriptions.push(this.subscription1$);

        // Инициализируем форму и поля
        this.questionCreateForm = new FormGroup<any>({
            priority: new FormControl('', [Validators.required, Validators.min(1), Validators.max(500)]),
            questionSolution: new FormControl('', [Validators.required]),
            textMessage: new FormControl('', [Validators.required, Validators.minLength(3)]),
            textOwner: new FormControl('', [Validators.required,Validators.minLength(3)])
        })
    }

    /**
     * Создаем вопрос (Мои вопросы). Это не шаблонный вопрос. Поэтому typeId = 8
     */
    createQuestion() {
        if (this.questionCreateForm.valid) {
            console.log(this.questionCreateForm.value);
           // Отправляем параметры на back + в массив post добавили user_id
            const params = {...this.questionCreateForm.value}
            params.priority = Number(this.questionCreateForm.value.priority);
            params.user_id = this.storageService.getUser();
            params.questionSolutionId = Number(this.questionSolutionId);
            params.typeId = Number(this.typeId);
            params.meetingId = Number(this.urlService.urlParams.meetingId);
            this.mSub = this.questionService.createQuestion(params).subscribe(_ => {
                // Получили обновленные данные и записали в this.dataAdapter.dataSource
                this.questionService.getQuestionsMeeting(this.urlService.urlParams.meetingId);
            });
            // Очистка формы после submit
            this.questionCreateForm.reset();
            this.router.navigate([this.router.url]);
        }
    }

    /**
     * Очищаем подписку на стрим чтобы не было утечек по памяти
     */
    ngOnDestroy() {
        if (this.mSub) {
            this.mSub.unsubscribe();
        }
    }

    /**
     * Запускается 3 - им
     */
    init(): void {
    }

    /**
     * Св-во нужно для инициализации. Для интерфейса. Нужен для норм работы
     */
    onInit(): void {
        console.log('Запустился onInit()');
    };

    /**
     * Запускается 2 на странице после ngOnInit()
     */
    ngAfterViewInit() {

    }

    changeQuestionSolution(event: any) {
        this.questionSolutionId = event.detail.value
    }

}
