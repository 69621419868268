import {Component, OnInit, OnDestroy, AfterViewInit, ViewEncapsulation} from '@angular/core';
import {HouseService} from "src/app/pages/meetings/services/house.service";
import {Subscription} from "rxjs";
import {BrowserModule} from "@angular/platform-browser";
import {Router} from "@angular/router";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {StorageService} from "../../../services/storage.service";
import { CommonModule } from '@angular/common';

// @ts-ignore
// Добавили в providers CommonModule вместо BrowserModule чтобы убрать ошибку ngif дерективы
@Component({
    selector: 'form-create-house',
    templateUrl: 'form-create-house.component.html',
    providers: [CommonModule, BrowserModule],
    styleUrls: ['form-create-house.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class FormCreateHouseComponent implements AfterViewInit, OnDestroy, OnInit {

    // Переменная отвечающая за подписку
    mSub: Subscription
    houseCreateForm: FormGroup

    constructor(
        private houseService: HouseService,
        private storageService: StorageService,
        private router: Router
    ) {
        Object.assign(FormCreateHouseComponent, {
            houseService,
            storageService,
        });
    }

    /**
     * Запускается 1 на странице
     */
    ngOnInit() {
        console.log('Запустился ngOnInit()');
        // Инициализируем форму и поля
        this.houseCreateForm = new FormGroup<any>({
            address: new FormControl('', [Validators.required, Validators.minLength(6)]),
            comment: new FormControl('', []),
        })
    }

    /**
     * Создаем дом
     */
    createHouse() {
        if (this.houseCreateForm.valid) {
            console.log(this.houseCreateForm);
            // Отправляем параметры на back + в массив post добавили user_id
            const params = {...this.houseCreateForm.value}
            params.user_id = this.storageService.getUser();
            this.mSub = this.houseService.createHouse(params).subscribe(_ => {
                // Получили обновленные данные и записали в this.dataAdapter.dataSource
                this.houseService.getHouses();
            });
            // Очистка формы после submit
            this.houseCreateForm.reset();
            this.router.navigate(['houses/']);

        }
    }

    /**
     * Очищаем подписку на стрим чтобы не было утечек по памяти
     */
    ngOnDestroy() {
        if (this.mSub) {
            this.mSub.unsubscribe();
        }
    }

    /**
     * Запускается 3 - им
     */
    init(): void {
    }

    /**
     * Св-во нужно для инициализации. Для интерфейса. Нужен для норм работы
     */
    onInit(): void {
        console.log('Запустился onInit()');
    };

    /**
     * Запускается 2 на странице после ngOnInit()
     */
    ngAfterViewInit() {

    }


}
