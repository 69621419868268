<div class="container column-search">
    <div class="custom-row">
        <div class="column-search-header custom-col">
            <h4>Column Search</h4>
        </div>
    </div>

    <div class="custom-card">
        <div class="card-body">
            <smart-table #columnSearchTable id="column-search-table" [dataSource]="columnSearchTableSettings.dataSource"
                [paging]="columnSearchTableSettings.paging" [sortMode]="columnSearchTableSettings.sortMode"
                [tooltip]="columnSearchTableSettings.tooltip" [onInit]="columnSearchTableSettings.onInit"
                [columns]="columnSearchTableSettings.columns" [filtering]="columnSearchTableSettings.filtering"
                [filterRow]="columnSearchTableSettings.filterRow">
            </smart-table>
        </div>
    </div>
</div>