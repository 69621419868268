import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'sm-getting-started',
  templateUrl: './getting-started.component.html',
  styleUrls: ['./getting-started.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GettingStartedComponent {

  constructor() { }
  
}
