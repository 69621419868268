<div class="container chat">
    <div class="custom-row">
        <div class="custom-col col-entity-12 header-col">
            <h4>Chat Messages</h4>
        </div>
    </div>

    <div class="custom-row">
        <div class="custom-col">
            <div class="custom-card">
                <div class="card-body">
                    <div class="chat-holder">
                        <smart-list-box id="listBox" [filterable]="true" filter-input-placeholder="Search contacts..."
                            item-template="contactTemplate">
                            <smart-list-item class="online" missed="3">Eva Hayes</smart-list-item>
                            <smart-list-item class="online">Joyce Scott</smart-list-item>
                            <smart-list-item class="online" [selected]="true">Victoria Ramos</smart-list-item>
                            <smart-list-item class="offline" missed="1">Frederick Murphy</smart-list-item>
                            <smart-list-item class="offline">Zack Turner</smart-list-item>
                        </smart-list-box>
                        <div id="mainChatView">
                            <div class="chat-header">
                                <div class="avatar avatar-recipient"></div>
                                <div>
                                    <div>Victoria Ramos</div>
                                    <div><em>Typing...</em></div>
                                </div>
                                <smart-button class="primary"><span class="material-icons">phone</span>
                                </smart-button>
                                <smart-button class="success"><span class="material-icons">videocam</span>
                                </smart-button>
                                <smart-button ><span class="material-icons">settings</span></smart-button>
                            </div>
                            <div class="chat-body" id="chatBody">
                                <div class="message">
                                    <div class="avatar"></div>
                                    <div class="timestamp">1:31 pm</div>
                                    <div class="text">
                                        <div>Victoria Ramos</div>
                                        <div>Class aptent taciti 🥰</div>
                                    </div>
                                </div>
                                <div class="message">
                                    <div class="avatar"></div>
                                    <div class="timestamp">1:32 pm</div>
                                    <div class="text">
                                        <div>Victoria Ramos</div>
                                        <div>Nunc efficitur neque sit amet varius scelerisque. Phasellus in arcu felis.
                                            Etiam
                                            tellus tortor, pharetra id justo placerat, dapibus convallis quam. Sed at
                                            semper
                                            nisl. Interdum et malesuada fames ac ante ipsum primis in faucibus.
                                            Phasellus
                                            feugiat ipsum velit, ac interdum urna tempus vitae. Ut diam lectus, volutpat
                                            sed
                                            nisl ac, ullamcorper luctus tellus.
                                        </div>
                                    </div>
                                </div>
                                <div class="message me">
                                    <div class="avatar"></div>
                                    <div class="timestamp">1:34 pm</div>
                                    <div class="text">
                                        <div>John Doe</div>
                                        <div>Nulla eget tortor tempor justo egestas scelerisque scelerisque nec diam.
                                        </div>
                                    </div>
                                </div>
                                <div class="message">
                                    <div class="avatar"></div>
                                    <div class="timestamp">1:35 pm</div>
                                    <div class="text">
                                        <div>Victoria Ramos</div>
                                        <div>Phasellus in arcu felis.</div>
                                    </div>
                                </div>
                                <div class="message me">
                                    <div class="avatar"></div>
                                    <div class="timestamp">1:36 pm</div>
                                    <div class="text">
                                        <div>John Doe</div>
                                        <div>Donec purus est, commodo in molestie et, vestibulum a enim. Mauris dolor
                                            neque.
                                        </div>
                                    </div>
                                </div>
                                <div class="message">
                                    <div class="avatar"></div>
                                    <div class="timestamp">1:40 pm</div>
                                    <div class="text">
                                        <div>Victoria Ramos</div>
                                        <div>Duis molestie, sem eu bibendum sollicitudin, ex ex hendrerit purus, et
                                            ultrices
                                            nisl velit sit amet sem.
                                        </div>
                                    </div>
                                </div>
                                <div class="message me">
                                    <div class="avatar"></div>
                                    <div class="timestamp">1:41 pm</div>
                                    <div class="text">
                                        <div>John Doe</div>
                                        <div>Aenean et eleifend mi?</div>
                                    </div>
                                </div>
                                <div class="message">
                                    <div class="avatar"></div>
                                    <div class="timestamp">1:42 pm</div>
                                    <div class="text">
                                        <div>Victoria Ramos</div>
                                        <div>Тaciti 😀😀😀</div>
                                    </div>
                                </div>
                                <div class="message me">
                                    <div class="avatar"></div>
                                    <div class="timestamp">1:54 pm</div>
                                    <div class="text">
                                        <div>John Doe</div>
                                        <div>Class aptent taciti...</div>
                                    </div>
                                </div>
                                <div class="message me">
                                    <div class="avatar"></div>
                                    <div class="timestamp">2:02 pm</div>
                                    <div class="text">
                                        <div>John Doe</div>
                                        <div>Suspendisse cursus nunc ac felis rhoncus tincidunt 😇</div>
                                    </div>
                                </div>
                            </div>
                            <div id="chatFooter">
                                <smart-input placeholder="Your message here..."></smart-input>
                                <smart-button class="primary">Send</smart-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>