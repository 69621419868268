<div class="container e-commerce-dashboard">
    <div class="custom-row">
        <div class="custom-col">
            <div class="header-menu">
                <div class="welcome">Welcome back, John!</div>
                <smart-button class="today-button settings-button"><span
                        class="material-icons">calendar_today</span>Today<span
                        class="material-icons">keyboard_arrow_down</span></smart-button>
                <smart-button class="primary"><span class="material-icons">filter_alt</span></smart-button>
                <smart-button class="primary"><span class="material-icons">refresh</span></smart-button>
            </div>
        </div>
    </div>

    <div class="custom-row">
        <div class="custom-col progress-card-col">
            <div class="custom-card h-100">
                <div class="card-body">
                    <div class="custom-row margin-bottom-1-5">
                        <div class="custom-col col-full">
                            <h6 class="font-weight-bold mb-0">Income</h6>
                        </div>
                        <div class="custom-col col-full text-right">
                            <span class="smart-badge smart-badge-info">Today</span>
                        </div>
                    </div>

                    <div class="custom-row margin-bottom-1-5">
                        <div class="custom-col col-full">
                            <h5 class="mb-0">$53.305</h5>
                        </div>
                        <div class="custom-col col-full text-right">
                            <span class="">91%</span>
                        </div>
                    </div>

                    <div class="custom-row">
                        <div class="custom-col custom-col-12">
                            <smart-progress-bar [value]="91" class="info"></smart-progress-bar>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="custom-col progress-card-col">
            <div class="custom-card h-100">
                <div class="card-body">
                    <div class="custom-row margin-bottom-1-5">
                        <div class="custom-col col-full">
                            <h6 class="font-weight-bold mb-0">Orders</h6>
                        </div>
                        <div class="custom-col col-full text-right">
                            <span class="smart-badge smart-badge-warning">Annual</span>
                        </div>
                    </div>

                    <div class="custom-row margin-bottom-1-5">
                        <div class="custom-col col-full">
                            <h5 class="mb-0">9.809</h5>
                        </div>
                        <div class="custom-col col-full text-right">
                            <span class="">23%</span>
                        </div>
                    </div>

                    <div class="custom-row">
                        <div class="custom-col custom-col-12">
                            <smart-progress-bar [value]="23" class="warning"></smart-progress-bar>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="custom-col progress-card-col">
            <div class="custom-card h-100">
                <div class="card-body">
                    <div class="custom-row margin-bottom-1-5">
                        <div class="custom-col col-full">
                            <h6 class="font-weight-bold mb-0">Activity</h6>
                        </div>
                        <div class="custom-col col-full text-right">
                            <span class="smart-badge smart-badge-primary">Monthly</span>
                        </div>
                    </div>

                    <div class="custom-row margin-bottom-1-5">
                        <div class="custom-col col-full">
                            <h5 class="mb-0">17.380</h5>
                        </div>
                        <div class="custom-col col-full text-right">
                            <span class="">50%</span>
                        </div>
                    </div>

                    <div class="custom-row">
                        <div class="custom-col custom-col-12">
                            <smart-progress-bar [value]="50" class="primary"></smart-progress-bar>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="custom-col progress-card-col">
            <div class="custom-card h-100">
                <div class="card-body">
                    <div class="custom-row margin-bottom-1-5">
                        <div class="custom-col col-full">
                            <h6 class="font-weight-bold mb-0">Revenue</h6>
                        </div>
                        <div class="custom-col col-full text-right">
                            <span class="smart-badge smart-badge-success">Yearly</span>
                        </div>
                    </div>

                    <div class="custom-row margin-bottom-1-5">
                        <div class="custom-col col-full">
                            <h5 class="mb-0">$28.940</h5>
                        </div>
                        <div class="custom-col col-full text-right">
                            <span class="">41%</span>
                        </div>
                    </div>

                    <div class="custom-row">
                        <div class="custom-col custom-col-12">
                            <smart-progress-bar [value]="41" class="success"></smart-progress-bar>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="custom-row locations-revenue-orderAct">

        <div class="custom-col col-entity-lg-8">
            <div class="custom-card h-100">
                <div class="card-header">
                    <h4 class="label">Total Revenue</h4>
                    <div class="settings-button" #revenueButton (click)="toggleMenu(revenueButton)"
                        (pointerup)="stopPropagation($event)">…</div>
                </div>
                <div class="card-body revenue-chart-wrapper">
                    <smart-chart #revenueChart class="e-commerce-dashboard-revenue-chart" [animation]="'none'"
                        [theme]="theme!" [caption]="'Caption'" [description]="'Description'" [showLegend]="true"
                        [showBorderLine]="true" [padding]="revenueChartData.padding"
                        [dataSource]="revenueChartData.dataSource" [xAxis]="revenueChartData.xAxis"
                        [seriesGroups]="revenueChartData.seriesGroups" [colorScheme]="'custom'"></smart-chart>
                </div>
            </div>
        </div>

        <div class="custom-col col-entity-lg-4">
            <div class="custom-card h-100">
                <div class="card-header">
                    <h4 class="label">Order Activity</h4>
                    <div class="settings-button" #orderActivityButton (click)="toggleMenu(orderActivityButton)"
                        (pointerup)="stopPropagation($event)">…</div>
                </div>

                <div class="card-body maxh-350 p-0">
                    <div class="appointmentContainer h-100">
                        <div class="appointment">
                            <div class="label"><strong>Delivered</strong></div>
                            <p class="">30 minutes ago</p>
                        </div>
                        <div class="appointment">
                            <div class="label"><strong>In transit</strong></div>
                            <p class="">3 hours ago</p>
                        </div>
                        <div class="appointment">
                            <div class="label"><strong>Shipped</strong></div>
                            <p class="">1 day ago</p>
                        </div>
                        <div class="appointment">
                            <div class="label"><strong>Received</strong></div>
                            <p class="">2 days ago</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="custom-row realTime-sales">
        <div class="custom-col col-entity-lg-8">
            <div class="custom-card h-100">
                <div class="card-header">
                    <h4 class="label">Real-Time</h4>
                    <div class="settings-button" #bestSellingTableButton (click)="toggleMenu(bestSellingTableButton)"
                    (pointerup)="stopPropagation($event)">…</div>
                </div>
                <div class="card-body">
                    <smart-table class="e-commerce-bestSellingTable table-striped no-border" [dataSource]="bestSellingTableData.dataSource"
                        [columns]="bestSellingTableData.columns" [sortMode]="'one'"></smart-table>
                </div>
            </div>
        </div>

        <div class="custom-col col-entity-lg-4">
            <div class="custom-card h-100">
                <div class="card-header">
                    <h4 class="label">Sales Comparison</h4>
                    <div class="settings-button" #salesComparisonButton (click)="toggleMenu(salesComparisonButton)"
                    (pointerup)="stopPropagation($event)">…</div>
                </div>
                <div class="card-body">
                    <smart-chart #salesComparisonChart class="e-commerce-dashboard-sales-comparison-chart"
                        [animation]="'none'" [theme]="theme!" [caption]="'Caption'" [description]="'Description'"
                        [showLegend]="true" [showBorderLine]="true" [padding]="salesComparisonChartData.padding"
                        [dataSource]="salesComparisonChartData.dataSource" [xAxis]="salesComparisonChartData.xAxis"
                        [colorScheme]="salesComparisonChartData.colorScheme"
                        [seriesGroups]="salesComparisonChartData.seriesGroups"></smart-chart>
                </div>
            </div>
        </div>
    </div>
</div>