import {Component, OnInit, OnDestroy, ViewChild, AfterViewInit, ViewEncapsulation} from '@angular/core';
import {Subscription} from "rxjs";
import {WindowComponent, Window, WindowModule} from 'smart-webcomponents-angular/window';
import {ButtonComponent, ButtonModule} from "smart-webcomponents-angular/button";
import {BrowserModule} from "@angular/platform-browser";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {HouseService} from "../services/house.service";
import {UrlService} from "../services/url.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {QuestionService} from "../services/question.service";
import {StorageService} from "../../../services/storage.service";

// @ts-ignore
@Component({
    selector: 'list-meetings',
    templateUrl: './copy-question.component.html',
    providers: [BrowserModule, ButtonModule, WindowModule],
    styleUrls: ['./copy-question.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class CopyQuestionComponent implements AfterViewInit, OnDestroy, OnInit {

    // Переменная отвечающая за подписку
    mSub: Subscription
    copyCreateForm: FormGroup

    constructor(
        private questionService: QuestionService,
        private houseService: HouseService,
        private urlService: UrlService,
        private router: Router,
        private activeRouter: ActivatedRoute, // Отображает текущий роут компонента
        private storageService: StorageService,
    ) {
        Object.assign(CopyQuestionComponent, {
            QuestionService,
        });
    }

    @ViewChild('button', {read: ButtonComponent, static: false}) button!: ButtonComponent;
    @ViewChild('formWindow', {read: WindowComponent, static: false}) formWindow: WindowComponent;

    /**
     * Очищаем подписку на стрим чтобы не было утечек по памяти
     */
    ngOnDestroy() {
        if (this.mSub) {
            this.mSub.unsubscribe();
        }
    }

    /**
     * Запускается 3 - им
     */
    init(): void {
        // init code.
        console.log('Запустился init()');

    }

    /**
     * Св-во нужно для инициализации. Для интерфейса. Нужен для норм работы
     */
    onInit(): void {
        // init code.
        console.log('Запустился onInit()');
    };


    /**
     * Запускается 1 на странице
     */
    ngOnInit() {
        console.log('Запустился ngOnInit()');
        // Получаем параметры из url
        this.activeRouter.params.subscribe((params: Params) => {
            this.urlService.urlParams = params
        })
        // Инициализируем форму и поля
        this.copyCreateForm = new FormGroup<any>({
            meetingId: new FormControl('', [Validators.required, Validators.pattern(/[0-9]/)]),
        })
     }

    /**
     * Скопировать вопросы из существующего собрания
     */
    copyQuestionsMeeting(): void {
        const params = {...this.copyCreateForm.value}
        params.user_id = this.storageService.getUser();
        params.current_meeting = Number(this.urlService.urlParams.meetingId);
        this.mSub = this.questionService.copyQuestionsMeeting(params).subscribe(_ => {
            // Получили обновленные данные и записали в this.dataAdapter.dataSource
            // this.questionService.copyQuestionsMeeting(this.urlService.urlParams);
        });
        this.router.navigate([this.router.url]);
    }

    ngAfterViewInit(): void {
    }

}
