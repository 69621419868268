<div class="container analytics">
    <div class="custom-row">
        <div class="custom-col">
            <div class="header-menu">
                <div class="welcome">Welcome back, John!</div>
                <smart-button class="today-button settings-button" #todaysButton (click)="toggleMenu(todaysButton)"
                    (pointerup)="stopPropagation($event)"><span class="material-icons">calendar_today</span>Today<span
                        class="material-icons">keyboard_arrow_down</span></smart-button>
                <smart-button class="primary"><span class="material-icons">filter_alt</span></smart-button>
                <smart-button class="primary"><span class="material-icons">refresh</span></smart-button>
            </div>
        </div>
    </div>

    <div class="custom-row cards-row">
        <div class="custom-col">
            <div class="custom-row h-100">
                <div class="custom-col col-entity-md-6 card-col">
                    <div class="custom-card h-100">
                        <div class="card-body">
                            <div class="custom-row">
                                <div class="custom-col">
                                    <h6 class="font-weight-bold mb-0">Visitors</h6>
                                </div>
                                <div class="custom-col text-right">
                                    <span class="smart-badge smart-badge-success">Today</span>
                                </div>
                            </div>

                            <div class="custom-row">
                                <div class="custom-col">
                                    <h5 class="mb-0">5.305</h5>
                                </div>
                                <div class="custom-col text-right">
                                    <span class="">91%</span>
                                </div>
                            </div>

                            <div class="custom-row">
                                <div class="custom-col-12">
                                    <smart-progress-bar [value]="91" class="success"></smart-progress-bar>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="custom-col col-entity-md-6 card-col">
                    <div class="custom-card h-100">
                        <div class="card-body">
                            <div class="custom-row">
                                <div class="custom-col">
                                    <h6 class="font-weight-bold mb-0">Bounce</h6>
                                </div>
                                <div class="custom-col text-right">
                                    <span class="smart-badge smart-badge-warning">Annaul</span>
                                </div>
                            </div>

                            <div class="custom-row">
                                <div class="custom-col">
                                    <h5 class="mb-0">809</h5>
                                </div>
                                <div class="custom-col text-right">
                                    <span class="">23%</span>
                                </div>
                            </div>

                            <div class="custom-row">
                                <div class="custom-col-12">
                                    <smart-progress-bar [value]="23" class="warning"></smart-progress-bar>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="custom-col col-entity-md-6 card-col">
                    <div class="custom-card h-100">
                        <div class="card-body">
                            <div class="custom-row">
                                <div class="custom-col">
                                    <h6 class="font-weight-bold mb-0">Real-Time</h6>
                                </div>
                                <div class="custom-col text-right">
                                    <span class="smart-badge smart-badge-primary">Monthly</span>
                                </div>
                            </div>

                            <div class="custom-row">
                                <div class="custom-col">
                                    <h5 class="mb-0">17.380</h5>
                                </div>
                                <div class="custom-col text-right">
                                    <span class="">50%</span>
                                </div>
                            </div>

                            <div class="custom-row">
                                <div class="custom-col-12">
                                    <smart-progress-bar [value]="50" class="primary"></smart-progress-bar>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="custom-col col-entity-md-6 card-col">
                    <div class="custom-card h-100">
                        <div class="card-body">
                            <div class="custom-row">
                                <div class="custom-col">
                                    <h6 class="font-weight-bold mb-0">Activity</h6>
                                </div>
                                <div class="custom-col text-right">
                                    <span class="smart-badge smart-badge-info">Yearly</span>
                                </div>
                            </div>

                            <div class="custom-row">
                                <div class="custom-col">
                                    <h5 class="mb-0">992</h5>
                                </div>
                                <div class="custom-col text-right">
                                    <span class="">41%</span>
                                </div>
                            </div>

                            <div class="custom-row">
                                <div class="custom-col-12">
                                    <smart-progress-bar [value]="41" class="info"></smart-progress-bar>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="custom-row langs-mobileDesktop-Interests-row">
        <div class="custom-col col-entity-md-4">
            <div class="custom-card h-100">
                <div class="card-header">
                    <h4 class="label">Languages</h4>
                    <div class="settings-button" #languagesButton (click)="toggleMenu(languagesButton)"
                        (pointerup)="stopPropagation($event)">…</div>
                </div>
                <div class="card-body analytics-languages-table-wrapper">
                    <smart-table class="analytics-languages-table no-border"
                        [dataSource]="languagesTableData.dataSource" [columns]="languagesTableData.columns">
                    </smart-table>
                </div>
            </div>
        </div>

        <div class="custom-col col-entity-md-4">
            <div class="custom-card h-100">
                <div class="card-header">
                    <h4 class="label">Mobile / Desktop</h4>
                    <div class="settings-button" #mobileDesktopButton (click)="toggleMenu(mobileDesktopButton)"
                        (pointerup)="stopPropagation($event)">…</div>
                </div>
                <div class="card-body">
                    <smart-chart #devicesChart class="analytics-devicesChart w-100 h-100"
                        [animation]="deviceChartData.animation" [theme]="deviceChartData.theme!"
                        [caption]="deviceChartData.caption" [description]="deviceChartData.description"
                        [showLegend]="deviceChartData.showLegend" [showBorderLine]="deviceChartData.showBorderLine"
                        [padding]=" deviceChartData.padding" [dataSource]="deviceChartData.dataSource"
                        [xAxis]="deviceChartData.xAxis" [colorScheme]="deviceChartData.colorScheme"
                        [seriesGroups]="deviceChartData.seriesGroups">
                    </smart-chart>
                </div>
            </div>
        </div>

        <div class="custom-col col-entity-md-4">
            <div class="custom-card h-100">
                <div class="card-header">
                    <h4 class="label">Interests</h4>
                    <div class="settings-button" #interestsButton (click)="toggleMenu(interestsButton)"
                        (pointerup)="stopPropagation($event)">…</div>
                </div>
                <div class="card-body">
                    <smart-chart #interestsChart class="analytics-interestsChart w-100 h-100"
                        [animation]="interestsChartData.animation" [theme]="interestsChartData.theme!"
                        [caption]="interestsChartData.caption" [description]="interestsChartData.description"
                        [showLegend]="interestsChartData.showLegend"
                        [showBorderLine]="interestsChartData.showBorderLine" [padding]=" interestsChartData.padding"
                        [dataSource]="interestsChartData.dataSource" [xAxis]="interestsChartData.xAxis"
                        [colorScheme]="interestsChartData.colorScheme" [seriesGroups]="interestsChartData.seriesGroups">
                    </smart-chart>
                </div>
            </div>
        </div>
    </div>

    <div class="custom-row source-traffic-row">
        <div class="custom-col col-entity-md-4">
            <div class="custom-card h-100">
                <div class="card-header">
                    <h4 class="label">Source</h4>
                    <div class="settings-button" #sourceButton (click)="toggleMenu(sourceButton)"
                        (pointerup)="stopPropagation($event)">…</div>
                </div>
                <div class="card-body">
                    <smart-chart #sourceChart class="analytics-sourceChart w-100 combo-chart"
                        [animation]="sourceChartData.animation" [theme]="sourceChartData.theme!"
                        [caption]="sourceChartData.caption" [description]="sourceChartData.description"
                        [showLegend]="sourceChartData.showLegend" [showBorderLine]="sourceChartData.showBorderLine"
                        [padding]=" sourceChartData.padding" [dataSource]="sourceChartData.dataSource"
                        [colorScheme]="sourceChartData.colorScheme" [seriesGroups]="sourceChartData.seriesGroups">
                    </smart-chart>
                    <smart-table #sourceTable class="analytics-sourceTable-table no-border w-100 small-table"
                        [dataSource]="sourceTableData.dataSource" [columns]="sourceTableData.columns">
                    </smart-table>
                </div>
            </div>
        </div>

        <div class="custom-col col-entity-md-8">
            <div class="custom-card h-100">
                <div class="card-header">
                    <h4 class="label">Traffic</h4>
                    <div class="settings-button" #trafficButton (click)="toggleMenu(trafficButton)"
                        (pointerup)="stopPropagation($event)">…</div>
                </div>
                <div class="card-body traffic-table-wrapper">
                    <smart-table #trafficTable class="analytics-trafficTable-table table-striped no-border"
                        [dataSource]="trafficTableData.dataSource" [columns]="trafficTableData.columns"
                        [sortMode]="trafficTableData.sortMode">
                    </smart-table>
                </div>
            </div>
        </div>
    </div>
</div>