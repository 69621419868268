<div class="container kanban-tasks-1">
    <div class="custom-row">
        <div class="custom-col col-entity-12 header-col">
            <h4>Tasks</h4>
        </div>
    </div>

    <div class="custom-row">
        <div class="custom-col col-entity-12">
            <div class="custom-card">
                <div class="card-body">
                    <smart-kanban #kanban id="kanban-tasks-1" [collapsible]="kanbanData.collapsible"
                        [dataSource]="kanbanData.dataSource" [columns]="kanbanData.columns"
                        [userList]="kanbanData.userList" [editable]="kanbanData.editable" [users]="kanbanData.users">
                    </smart-kanban>
                </div>
            </div>
        </div>
    </div>
</div>