<div class="container dashboard2">
    <div class="dashboard2-header custom-row">
        <div class="custom-col">
            <div class="header-menu">
                <div class="welcome">Welcome back, John!</div>
                <smart-button class="today-button settings-button" #todaysButton
                    (click)="toggleMenu(todaysButton)" (pointerup)="stopPropagation($event)"><span
                        class="material-icons">calendar_today</span>Today<span
                        class="material-icons">keyboard_arrow_down</span></smart-button>
                <smart-button class="primary"><span class="material-icons">filter_alt</span></smart-button>
                <smart-button class="primary"><span class="material-icons">refresh</span></smart-button>
            </div>
        </div>
    </div>

    <div class="dashboard2-sales-dailyFeed-appoints custom-row">
        <div class="dashboard2-sales custom-col">
            <div class="custom-card">
                <div class="card-header bg-primary">
                    <h5 class="card-title text-white">Sales Stat</h5>
                    <div class="settings-button text-white" #salesButton (click)="toggleMenu(salesButton)"
                        (pointerup)="stopPropagation($event)">…</div>
                </div>

                <div class="card-body">
                    <div class="bg-primary smart-element-transparent card-chart">
                        <smart-chart #revenueChart class="dashboard2-revenue-chart" [animation]="'none'"
                            [theme]="revenueChartData.theme!" [caption]="'Caption'" [description]="'Description'"
                            [showLegend]="true" [showBorderLine]="true" [padding]="revenueChartData.padding"
                            [dataSource]="revenueChartData.dataSource" [xAxis]="revenueChartData.xAxis"
                            [seriesGroups]="revenueChartData.seriesGroups" [colorScheme]="'custom'"></smart-chart>
                    </div>

                    <div class="dashboard2-sales-body-spacer"></div>

                    <div class="dashboard2-sales-users-cards custom-row">
                        <div class="dashboard2-sales-card custom-col">
                            <div class="bg-white card-rounding">
                                <div class="bg-warning-opacity-2 text-warning">
                                    <span><i class="material-icons">insights</i></span>
                                    <a href="#" class="text-warning">Weekly Sales</a>
                                </div>
                            </div>
                        </div>

                        <div class="dashboard2-user-card custom-col">
                            <div class="bg-white card-rounding">
                                <div class="bg-info-opacity-2 text-info">
                                    <span><i class="material-icons">person_add</i></span>
                                    <a href="#" class="text-info">New Users</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="dashboard2-orders-bugs-cards custom-row">
                        <div class="dashboard2-orders-card custom-col">
                            <div class="bg-danger-opacity-2 text-danger card-rounding">
                                <span><i class="material-icons">insights</i></span>
                                <a href="#" class="text-danger">Item
                                    Orders</a>
                            </div>
                        </div>
                        <div class="dashboard2-bugs-card custom-col">
                            <div class="bg-success-opacity-2 text-success card-rounding">
                                <span><i class="material-icons">person_add</i></span>
                                <a href="#" class="text-success">Bug
                                    Reports</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="dashboard2-dailyFeed custom-col">
            <div class="custom-card h-100">
                <div class="card-header">
                    <h4 class="label">Daily feed</h4>
                    <div class="smart-badge smart-badge-primary smart-badge-pill">Today</div>
                </div>
                <div class="card-body">
                    <div class="feeds">
                        <div class="feed">
                            <div class="feed-image-wrapper">
                                <div class="img-as-background">
                                    <img src="../../../assets/images/phonebook/michael.png" />
                                </div>
                            </div>

                            <div class="feed-text-wrapper">
                                <div class=""><strong>Frederick Murphy</strong> reacted to your blog post</div>
                                <small class="text-muted">Today 8:19 PM</small>
                            </div>

                            <div class="feed-posted-ago-wrapper">
                                <small class="text-muted">7 min ago</small>
                            </div>
                        </div>

                        <div class="feed">
                            <div class="feed-image-wrapper">
                                <div class="img-as-background">
                                    <img src="../../../assets/images/phonebook/maria.png" />
                                </div>
                            </div>

                            <div class="feed-text-wrapper">
                                <div class=""><strong>Elisha Scott</strong> is now engaged</div>
                                <small class="text-muted">Today 10:45 PM</small>
                            </div>

                            <div class="feed-posted-ago-wrapper">
                                <small class="text-muted">10 hours ago</small>
                            </div>
                        </div>

                        <div class="feed">
                            <div class="feed-image-wrapper">
                                <div class="img-as-background">
                                    <img src="../../../assets/images/phonebook/michael.png" />
                                </div>
                            </div>

                            <div class="feed-text-wrapper">
                                <div class=""><strong>John Doe</strong> is now engaged</div>
                                <small class="text-muted">Today 12:03 PM</small>
                            </div>

                            <div class="feed-posted-ago-wrapper">
                                <small class="text-muted">8 hours ago</small>
                            </div>
                        </div>

                        <div class="feed">
                            <div class="feed-image-wrapper">
                                <div class="img-as-background">
                                    <img src="../../../assets/images/phonebook/monica.png" />
                                </div>
                            </div>

                            <div class="feed-text-wrapper">
                                <div class=""><strong>Monica Smith</strong> is now engaged</div>
                                <small class="text-muted">Today 09:30 PM</small>
                            </div>

                            <div class="feed-posted-ago-wrapper">
                                <small class="text-muted">5 hours ago</small>
                            </div>
                        </div>

                        <div class="feed">
                            <div class="feed-image-wrapper">
                                <div class="img-as-background">
                                    <img src="../../../assets/images/phonebook/michael.png" />
                                </div>
                            </div>

                            <div class="feed-text-wrapper">
                                <div class=""><strong>Frederick Murphy</strong> reacted to your blog post</div>
                                <small class="text-muted">Today 8:19 PM</small>
                            </div>

                            <div class="feed-posted-ago-wrapper">
                                <small class="text-muted">7 min ago</small>
                            </div>
                        </div>
                    </div>

                    <smart-button id="dashboard2-loadMore" class="primary">Load more</smart-button>
                </div>
            </div>
        </div>

        <div class="dashboard2-appoints custom-col">
            <div class="custom-card">
                <div class="card-header">
                    <h4 class="label">Appointments</h4>
                    <div class="settings-button">…</div>
                </div>
                <div class="card-body">
                    <div class="appointments">
                        <div class="appointment">
                            <div class="label"><strong>Contact Sales</strong><span>14:00</span></div>
                            <p class="">Proin sagittis nisl diam, in pretium velit congue et.</p>
                        </div>
                        <div class="appointment">
                            <div class="label"><strong>Meet with new client</strong><span>15:20</span></div>
                            <p class="">Donec sodales, tellus at facilisis commodo, lectus lectus pharetra neque, at
                                condimentum augue diam vitae massa.
                            </p>
                        </div>
                        <div class="appointment">
                            <div class="label"><strong>Dinner with manager</strong><span>19:00</span></div>
                            <p class="">Aenean facilisis mi ac vestibulum vestibulum.</p>
                        </div>
                        <div class="appointment">
                            <div class="label"><strong>Meeting with the colegues</strong><span>10:30</span></div>
                            <p class="">Aenean facilisis mi ac vestibulum vestibulum.</p>
                        </div>
                        <div class="appointment">
                            <div class="label"><strong>Lunch with husband</strong><span>12:30</span></div>
                            <p class="">Aenean facilisis mi ac vestibulum vestibulum.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="dashboard2-cards custom-row">
        <div class="card-wrapper medium-4 custom-col">
            <div class="custom-card">
                <div class="card-body">
                    <div><span class="material-icons text-primary">shopping_cart</span></div>
                    <div>
                        <h5 class="font-weight-bold">218</h5>
                        <h6 class="">Sales Today</h6>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-wrapper medium-4">
            <div class="custom-card">
                <div class="card-body">
                    <div><span class="material-icons text-warning">show_chart</span></div>
                    <div>
                        <h5 class="font-weight-bold">4.138</h5>
                        <h6 class="">Visitors Today</h6>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-wrapper medium-4">
            <div class="custom-card">
                <div class="card-body">
                    <div><span class="material-icons text-success">attach_money</span></div>
                    <div>
                        <h5 class="font-weight-bold">109.783</h5>
                        <h6 class="">Total Earnings</h6>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-wrapper medium-6">
            <div class="custom-card">
                <div class="card-body">
                    <div><span class="material-icons text-info">add_shopping_cart</span></div>
                    <div>
                        <h5 class="font-weight-bold">7</h5>
                        <h6 class="">Pending Orders</h6>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-wrapper medium-6">
            <div class="custom-card">
                <div class="card-body">
                    <div><span class="material-icons text-danger">attach_money</span></div>
                    <div>
                        <h5 class="font-weight-bold">86.002</h5>
                        <h6 class="">Total Revenue</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="dashboard2-sales-calendar-weeklySales custom-row">
        <div class="dashboard2-sales-comparison-wrapper custom-col">
            <div class="custom-card h-100">
                <div class="card-header">
                    <h4 class="label">Sales Comparison</h4>
                    <div class="settings-button" #salesComparisonButton (click)="toggleMenu(salesComparisonButton)"
                        (pointerup)="stopPropagation($event)">…
                    </div>
                </div>
                <div class="card-body">
                    <smart-chart #salesComparisonChart class="dashboard2-sales-comparison-chart" [animation]="'none'"
                        [theme]="salesComparisonChartData.theme!" [caption]="'Caption'" [description]="'Description'"
                        [showLegend]="true" [showBorderLine]="true" [padding]="salesComparisonChartData.padding"
                        [dataSource]="salesComparisonChartData.dataSource" [xAxis]="salesComparisonChartData.xAxis"
                        [colorScheme]="salesComparisonChartData.colorScheme"
                        [seriesGroups]="salesComparisonChartData.seriesGroups"></smart-chart>
                </div>
            </div>
        </div>

        <div class="dashboard2-calendar-wrapper custom-col">
            <div class="custom-card h-100">
                <div class="card-header">
                    <h4 class="label">Calendar</h4>
                    <div class="settings-button">…</div>
                </div>
                <div class="card-body p-0">
                    <smart-calendar id="calendar" class="w-100 border-0"></smart-calendar>
                </div>
            </div>
        </div>

        <div class="dashboard2-weekly-sales-wrapper custom-col">
            <div class="custom-card h-100">
                <div class="card-header">
                    <h4 class="label">Weekly sales</h4>
                    <div class="settings-button" #weeklySalesButton (click)="toggleMenu(weeklySalesButton)"
                        (pointerup)="stopPropagation($event)">…
                    </div>
                </div>
                <div class="card-body">
                    <smart-chart #weeklySalesChart class="dashboard2-weekly-sales-chart" [animation]="'none'"
                        [theme]="weeklySalesChartData.theme!" [caption]="'Caption'" [description]="'Description'"
                        [showLegend]="true" [showBorderLine]="true" [padding]="weeklySalesChartData.padding"
                        [dataSource]="weeklySalesChartData.dataSource"
                        [seriesGroups]="weeklySalesChartData.seriesGroups" [colorScheme]="'custom'">
                    </smart-chart>
                    <smart-table #weeklySalestable class="dashboard2-weekly-sales-table small-table"
                        [dataSource]="weeklySalesTableData.dataSource" [columns]="weeklySalesTableData.columns">
                    </smart-table>
                </div>
            </div>
        </div>
    </div>

    <div class="dashboard2-projects custom-row">
        <div class="dashboard2-projects-wrapper custom-col">
            <div class="custom-card">
                <div class="card-header">
                    <h4 class="label">Latest Projects</h4>
                    <div class="settings-button" #latestProjectsButton (click)="toggleMenu(latestProjectsButton)"
                        (pointerup)="stopPropagation($event)">…
                    </div>
                </div>
                <div class="card-body">
                    <smart-table #projectsTable class="dashboard2-projects-table"
                        [dataSource]="projectsTableData.dataSource" [freezeHeader]="true" [paging]="true"
                        [sortMode]="'many'" [tooltip]="true" [columns]="projectsTableData.columns"></smart-table>
                </div>
            </div>
        </div>
    </div>
</div>