<div class="container users-list">
    <div class="custom-card" style="margin-bottom: 1.5rem;">
        <div class="card-body">
            <div class="custom-row mb-6 card-row">
                <div class="custom-col col-entity-md-auto image-col">
                    <div class="img-as-background square rounded-circle card-image">
                        <img src="assets/images/people/andrew.jpg" alt="image">
                    </div>
                </div>

                <div class="custom-col col-entity-md">
                    <div class="d-flex justify-content-between flex-wrap mt-1">
                        <div class="d-flex align-items-center card-name">
                            <h5 style="margin-right: 0.5rem;"><a href="#"
                                    class="text-black hover-text-primary font-weight-500">Nick Dotson</a></h5>
                        </div>
                        <div class="my-lg-0 my-3">
                            <smart-button class="secondary very-small">Contact</smart-button>&nbsp;<smart-button
                                class="primary very-small">Hire me</smart-button>
                        </div>
                    </div>

                    <div class="custom-row justify-content-between mt-1">
                        <div class="custom-col col-entity-lg-6 col-entity-xl-8">
                            <div class="d-flex flex-wrap meta-wrapper">
                                <a href="#"
                                    class="text-dark hover-text-primary d-inline-flex align-items-center meta-info">
                                    <i class="material-icons">email</i> j.doe@mailinator.com
                                </a>

                                <a href="#"
                                    class="text-dark hover-text-primary d-inline-flex align-items-center meta-info">
                                    <i class="material-icons">account_box</i> PR Manager
                                </a>

                                <a href="#"
                                    class="text-dark hover-text-primary d-inline-flex align-items-center meta-info">
                                    <i class="material-icons">place</i> Melbourne
                                </a>
                            </div>

                            <p>I distinguish three main text objectives could be merely to inform people. <br /> A
                                second could be persuade people.You want people to bay objective</p>
                        </div>
                        <div class="custom-col col-entity-lg-6 col-entity-xl-4 d-flex align-items-end">
                            <div class="d-flex align-items-center w-100">
                                <span class="font-weight-bold">Progress</span>

                                <div class="card-progress w-100">
                                    <smart-progress-bar [value]="36" class="w-100"></smart-progress-bar>
                                </div>
                                <span class="font-weight-bold text-dark">36%</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <hr />

            <div class="d-flex align-items-center flex-wrap cards-wrapper">
                <div class="d-flex align-items-center card-wrapper">
                    <span class="card-icon d-inline-flex align-items-center">
                        <i class="material-icons text-muted">attach_money</i>
                    </span>
                    <div class="d-flex flex-column">
                        <small class="font-weight-500">Sales</small>
                        <h5 class="mb-0"><span class="font-weight-500">$</span>249,500</h5>
                    </div>
                </div>

                <div class="d-flex align-items-center card-wrapper">
                    <span class="card-icon d-inline-flex align-items-center">
                        <i class="material-icons text-muted">point_of_sale</i>
                    </span>
                    <div class="d-flex flex-column">
                        <small class="font-weight-500">Expenses</small>
                        <h5 class="mb-0"><span class="font-weight-500">$</span>164,700</h5>
                    </div>
                </div>

                <div class="d-flex align-items-center card-wrapper">
                    <span class="card-icon d-inline-flex align-items-center">
                        <i class="material-icons text-muted">insights</i>
                    </span>
                    <div class="d-flex flex-column">
                        <small class="font-weight-500">Net</small>
                        <h5 class="mb-0"><span class="font-weight-500">$</span>335,120</h5>
                    </div>
                </div>

                <div class="d-flex align-items-center card-wrapper">
                    <span class="card-icon d-inline-flex align-items-center">
                        <i class="material-icons text-muted">sticky_note_2</i>
                    </span>
                    <div class="d-flex flex-column">
                        <small class="font-weight-500">99 Projects</small>
                        <a href="#" class="text-primary font-weight-500">View</a>
                    </div>
                </div>

                <div class="d-flex align-items-center card-wrapper">
                    <span class="card-icon d-inline-flex align-items-center">
                        <i class="material-icons text-muted">comment</i>
                    </span>
                    <div class="d-flex flex-column">
                        <small class="font-weight-500">648 Comments</small>
                        <a href="#" class="text-primary font-weight-500">View</a>
                    </div>
                </div>

                <div class="d-flex align-items-center card-wrapper">
                    <span class="card-icon d-inline-flex align-items-center">
                        <i class="material-icons text-muted">group</i>
                    </span>

                    <div class="members d-flex">
                        <div class="member-image-wrapper square img-as-background rounded-circle"
                            data-bs-toggle="tooltip" title="Mark Stone">
                            <img alt="Pic" src="assets/images/phonebook/andrew.png">
                        </div>
                        <div class="member-image-wrapper square img-as-background rounded-circle"
                            data-bs-toggle="tooltip" title="Charlie Stone" style="margin-left: -10px;">
                            <img alt="Pic" src="assets/images/phonebook/johnny.png">
                        </div>
                        <div class="member-image-wrapper square img-as-background rounded-circle"
                            data-bs-toggle="tooltip" title="Luca Doncic" style="margin-left: -10px;">
                            <img alt="Pic" src="assets/images/phonebook/mark.png">
                        </div>
                        <div class="member-image-wrapper square img-as-background rounded-circle"
                            data-bs-toggle="tooltip" title="Nick Mana" style="margin-left: -10px;">
                            <img alt="Pic" src="assets/images/phonebook/steven.png">
                        </div>
                        <div class="member-image-wrapper square img-as-background rounded-circle"
                            data-bs-toggle="tooltip" title="Teresa Fox" style="margin-left: -10px;">
                            <img alt="Pic" src="assets/images/phonebook/janet.png">
                        </div>
                        <div class="member-image-wrapper h-30px rounded-circle bg-secondary d-inline-flex align-items-center justify-content-center"
                            style="margin-left: -10px; z-index: 9;">
                            <small>9+</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="custom-card" style="margin-bottom: 1.5rem;">
        <div class="card-body">
            <div class="custom-row mb-6 card-row">
                <div class="custom-col col-entity-md-auto image-col">
                    <div
                        class="d-inline-flex align-items-center justify-content-center bg-danger-opacity-3 rounded-circle card-image"
                        style="height: 125px;">
                        <h3 class="text-danger m-0">MP</h3>
                    </div>
                </div>

                <div class="custom-col col-entity-md">
                    <div class="d-flex justify-content-between flex-wrap mt-1">
                        <div class="d-flex align-items-center card-name">
                            <h5 style="margin-right: 0.5rem;"><a href="#"
                                    class="text-black hover-text-primary font-weight-500">Matt Pears</a></h5>
                        </div>
                        <div class="my-lg-0 my-3">
                            <smart-button class="secondary very-small">Contact</smart-button>&nbsp;<smart-button
                                class="primary very-small">Hire me</smart-button>
                        </div>
                    </div>

                    <div class="custom-row justify-content-between mt-1">
                        <div class="custom-col col-entity-lg-6 col-entity-xl-8">
                            <div class="d-flex flex-wrap meta-wrapper">
                                <a href="#"
                                    class="text-dark hover-text-primary d-inline-flex align-items-center meta-info">
                                    <i class="material-icons">email</i> matt@mailinator.com
                                </a>

                                <a href="#"
                                    class="text-dark hover-text-primary d-inline-flex align-items-center meta-info">
                                    <i class="material-icons">account_box</i> Designer
                                </a>

                                <a href="#"
                                    class="text-dark hover-text-primary d-inline-flex align-items-center meta-info">
                                    <i class="material-icons">place</i> Europe
                                </a>
                            </div>

                            <p>I distinguish three main text objectives could be merely to inform people. <br /> A
                                second could be persuade people.You want people to bay objective</p>
                        </div>
                        <div class="custom-col col-entity-lg-6 col-entity-xl-4 d-flex align-items-end">
                            <div class="d-flex align-items-center w-100">
                                <span class="font-weight-bold">Progress</span>

                                <div class="card-progress w-100">
                                    <smart-progress-bar [value]="55" class="error w-100"></smart-progress-bar>
                                </div>
                                <span class="font-weight-bold text-dark">55%</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <hr />

            <div class="d-flex align-items-center flex-wrap cards-wrapper">
                <div class="d-flex align-items-center card-wrapper">
                    <span class="card-icon d-inline-flex align-items-center">
                        <i class="material-icons text-muted">attach_money</i>
                    </span>
                    <div class="d-flex flex-column">
                        <small class="font-weight-500">Sales</small>
                        <h5 class="mb-0"><span class="font-weight-500">$</span>879,100</h5>
                    </div>
                </div>

                <div class="d-flex align-items-center card-wrapper">
                    <span class="card-icon d-inline-flex align-items-center">
                        <i class="material-icons text-muted">point_of_sale</i>
                    </span>
                    <div class="d-flex flex-column">
                        <small class="font-weight-500">Expenses</small>
                        <h5 class="mb-0"><span class="font-weight-500">$</span>53,100</h5>
                    </div>
                </div>

                <div class="d-flex align-items-center card-wrapper">
                    <span class="card-icon d-inline-flex align-items-center">
                        <i class="material-icons text-muted">insights</i>
                    </span>
                    <div class="d-flex flex-column">
                        <small class="font-weight-500">Net</small>
                        <h5 class="mb-0"><span class="font-weight-500">$</span>782,300</h5>
                    </div>
                </div>

                <div class="d-flex align-items-center card-wrapper">
                    <span class="card-icon d-inline-flex align-items-center">
                        <i class="material-icons text-muted">sticky_note_2</i>
                    </span>
                    <div class="d-flex flex-column">
                        <small class="font-weight-500">73 Projects</small>
                        <a href="#" class="text-primary font-weight-500">View</a>
                    </div>
                </div>

                <div class="d-flex align-items-center card-wrapper">
                    <span class="card-icon d-inline-flex align-items-center">
                        <i class="material-icons text-muted">comment</i>
                    </span>
                    <div class="d-flex flex-column">
                        <small class="font-weight-500">941 Comments</small>
                        <a href="#" class="text-primary font-weight-500">View</a>
                    </div>
                </div>

                <div class="d-flex align-items-center card-wrapper">
                    <span class="card-icon d-inline-flex align-items-center">
                        <i class="material-icons text-muted">group</i>
                    </span>

                    <div class="members d-flex">
                        <div class="member-image-wrapper square img-as-background rounded-circle"
                            data-bs-toggle="tooltip" title="Mark Stone">
                            <img alt="Pic" src="assets/images/phonebook/andrew.png">
                        </div>
                        <div class="member-image-wrapper square img-as-background rounded-circle"
                            data-bs-toggle="tooltip" title="Charlie Stone" style="margin-left: -10px;">
                            <img alt="Pic" src="assets/images/phonebook/johnny.png">
                        </div>
                        <div class="member-image-wrapper square img-as-background rounded-circle"
                            data-bs-toggle="tooltip" title="Luca Doncic" style="margin-left: -10px;">
                            <img alt="Pic" src="assets/images/phonebook/mark.png">
                        </div>
                        <div class="member-image-wrapper square img-as-background rounded-circle"
                            data-bs-toggle="tooltip" title="Nick Mana" style="margin-left: -10px;">
                            <img alt="Pic" src="assets/images/phonebook/steven.png">
                        </div>
                        <div class="member-image-wrapper square img-as-background rounded-circle"
                            data-bs-toggle="tooltip" title="Teresa Fox" style="margin-left: -10px;">
                            <img alt="Pic" src="assets/images/phonebook/janet.png">
                        </div>
                        <div class="member-image-wrapper h-30px rounded-circle bg-secondary d-inline-flex align-items-center justify-content-center"
                            style="margin-left: -10px; z-index: 9;">
                            <small>9+</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="custom-card" style="margin-bottom: 1.5rem;">
        <div class="card-body">
            <div class="custom-row mb-6 card-row">
                <div class="custom-col col-entity-md-auto image-col">
                    <div class="img-as-background square rounded-circle card-image">
                        <img src="assets/images/people/laura.jpg" alt="image">
                    </div>
                </div>

                <div class="custom-col col-entity-md">
                    <div class="d-flex justify-content-between flex-wrap mt-1">
                        <div class="d-flex align-items-center card-name">
                            <h5 style="margin-right: 0.5rem;"><a href="#"
                                    class="text-black hover-text-primary font-weight-500">Charlie Stone</a></h5>
                        </div>
                        <div class="my-lg-0 my-3">
                            <smart-button class="secondary very-small">Contact</smart-button>&nbsp;<smart-button
                                class="primary very-small">Hire me</smart-button>
                        </div>
                    </div>

                    <div class="custom-row justify-content-between mt-1">
                        <div class="custom-col col-entity-lg-6 col-entity-xl-8">
                            <div class="d-flex flex-wrap meta-wrapper">
                                <a href="#"
                                    class="text-dark hover-text-primary d-inline-flex align-items-center meta-info">
                                    <i class="material-icons">email</i> charlie@mailinator.com
                                </a>

                                <a href="#"
                                    class="text-dark hover-text-primary d-inline-flex align-items-center meta-info">
                                    <i class="material-icons">account_box</i> Web Developer
                                </a>

                                <a href="#"
                                    class="text-dark hover-text-primary d-inline-flex align-items-center meta-info">
                                    <i class="material-icons">place</i> Austria
                                </a>
                            </div>

                            <p>I distinguish three main text objectives could be merely to inform people. <br /> A
                                second could be persuade people.You want people to bay objective</p>
                        </div>
                        <div class="custom-col col-entity-lg-6 col-entity-xl-4 d-flex align-items-end">
                            <div class="d-flex align-items-center w-100">
                                <span class="font-weight-bold">Progress</span>

                                <div class="card-progress w-100">
                                    <smart-progress-bar [value]="47" class="warning w-100"></smart-progress-bar>
                                </div>
                                <span class="font-weight-bold text-dark">47%</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <hr />

            <div class="d-flex align-items-center flex-wrap cards-wrapper">
                <div class="d-flex align-items-center card-wrapper">
                    <span class="card-icon d-inline-flex align-items-center">
                        <i class="material-icons text-muted">attach_money</i>
                    </span>
                    <div class="d-flex flex-column">
                        <small class="font-weight-500">Sales</small>
                        <h5 class="mb-0"><span class="font-weight-500">$</span>49,500</h5>
                    </div>
                </div>

                <div class="d-flex align-items-center card-wrapper">
                    <span class="card-icon d-inline-flex align-items-center">
                        <i class="material-icons text-muted">point_of_sale</i>
                    </span>
                    <div class="d-flex flex-column">
                        <small class="font-weight-500">Expenses</small>
                        <h5 class="mb-0"><span class="font-weight-500">$</span>131,500</h5>
                    </div>
                </div>

                <div class="d-flex align-items-center card-wrapper">
                    <span class="card-icon d-inline-flex align-items-center">
                        <i class="material-icons text-muted">insights</i>
                    </span>
                    <div class="d-flex flex-column">
                        <small class="font-weight-500">Net</small>
                        <h5 class="mb-0"><span class="font-weight-500">$</span>382,300</h5>
                    </div>
                </div>

                <div class="d-flex align-items-center card-wrapper">
                    <span class="card-icon d-inline-flex align-items-center">
                        <i class="material-icons text-muted">sticky_note_2</i>
                    </span>
                    <div class="d-flex flex-column">
                        <small class="font-weight-500">46 Projects</small>
                        <a href="#" class="text-primary font-weight-500">View</a>
                    </div>
                </div>

                <div class="d-flex align-items-center card-wrapper">
                    <span class="card-icon d-inline-flex align-items-center">
                        <i class="material-icons text-muted">comment</i>
                    </span>
                    <div class="d-flex flex-column">
                        <small class="font-weight-500">448 Comments</small>
                        <a href="#" class="text-primary font-weight-500">View</a>
                    </div>
                </div>

                <div class="d-flex align-items-center card-wrapper">
                    <span class="card-icon d-inline-flex align-items-center">
                        <i class="material-icons text-muted">group</i>
                    </span>

                    <div class="members d-flex">
                        <div class="member-image-wrapper square img-as-background rounded-circle"
                            data-bs-toggle="tooltip" title="Mark Stone">
                            <img alt="Pic" src="assets/images/phonebook/andrew.png">
                        </div>
                        <div class="member-image-wrapper square img-as-background rounded-circle"
                            data-bs-toggle="tooltip" title="Charlie Stone" style="margin-left: -10px;">
                            <img alt="Pic" src="assets/images/phonebook/johnny.png">
                        </div>
                        <div class="member-image-wrapper square img-as-background rounded-circle"
                            data-bs-toggle="tooltip" title="Luca Doncic" style="margin-left: -10px;">
                            <img alt="Pic" src="assets/images/phonebook/mark.png">
                        </div>
                        <div class="member-image-wrapper square img-as-background rounded-circle"
                            data-bs-toggle="tooltip" title="Nick Mana" style="margin-left: -10px;">
                            <img alt="Pic" src="assets/images/phonebook/steven.png">
                        </div>
                        <div class="member-image-wrapper square img-as-background rounded-circle"
                            data-bs-toggle="tooltip" title="Teresa Fox" style="margin-left: -10px;">
                            <img alt="Pic" src="assets/images/phonebook/janet.png">
                        </div>
                        <div class="member-image-wrapper h-30px rounded-circle bg-secondary d-inline-flex align-items-center justify-content-center"
                            style="margin-left: -10px; z-index: 9;">
                            <small>9+</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="custom-card" style="margin-bottom: 1.5rem;">
        <div class="card-body">
            <div class="custom-row mb-6 card-row">
                <div class="custom-col col-entity-md-auto image-col">
                    <div
                        class="d-inline-flex align-items-center justify-content-center bg-success-opacity-3 rounded-circle card-image"
                        style="height: 125px;">
                        <h3 class="text-success m-0">SF</h3>
                    </div>
                </div>

                <div class="custom-col col-entity-md">
                    <div class="d-flex justify-content-between flex-wrap mt-1">
                        <div class="d-flex align-items-center card-name">
                            <h5 style="margin-right: 0.5rem;"><a href="#"
                                    class="text-black hover-text-primary font-weight-500">Sergei Ford</a></h5>
                        </div>
                        <div class="my-lg-0 my-3">
                            <smart-button class="secondary very-small">Contact</smart-button>&nbsp;<smart-button
                                class="primary very-small">Hire me</smart-button>
                        </div>
                    </div>

                    <div class="custom-row justify-content-between mt-1">
                        <div class="custom-col col-entity-lg-6 col-entity-xl-8">
                            <div class="d-flex flex-wrap meta-wrapper">
                                <a href="#"
                                    class="text-dark hover-text-primary d-inline-flex align-items-center meta-info">
                                    <i class="material-icons">email</i> sford@mailinator.com
                                </a>

                                <a href="#"
                                    class="text-dark hover-text-primary d-inline-flex align-items-center meta-info">
                                    <i class="material-icons">account_box</i> Angular Developer
                                </a>

                                <a href="#"
                                    class="text-dark hover-text-primary d-inline-flex align-items-center meta-info">
                                    <i class="material-icons">place</i> Canada
                                </a>
                            </div>

                            <p>I distinguish three main text objectives could be merely to inform people. <br /> A
                                second could be persuade people.You want people to bay objective</p>
                        </div>
                        <div class="custom-col col-entity-lg-6 col-entity-xl-4 d-flex align-items-end">
                            <div class="d-flex align-items-center w-100">
                                <span class="font-weight-bold">Progress</span>

                                <div class="card-progress w-100">
                                    <smart-progress-bar [value]="74" class="success w-100"></smart-progress-bar>
                                </div>
                                <span class="font-weight-bold text-dark">78%</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <hr />

            <div class="d-flex align-items-center flex-wrap cards-wrapper">
                <div class="d-flex align-items-center card-wrapper">
                    <span class="card-icon d-inline-flex align-items-center">
                        <i class="material-icons text-muted">attach_money</i>
                    </span>
                    <div class="d-flex flex-column">
                        <small class="font-weight-500">Sales</small>
                        <h5 class="mb-0"><span class="font-weight-500">$</span>138,500</h5>
                    </div>
                </div>

                <div class="d-flex align-items-center card-wrapper">
                    <span class="card-icon d-inline-flex align-items-center">
                        <i class="material-icons text-muted">point_of_sale</i>
                    </span>
                    <div class="d-flex flex-column">
                        <small class="font-weight-500">Expenses</small>
                        <h5 class="mb-0"><span class="font-weight-500">$</span>129,200</h5>
                    </div>
                </div>

                <div class="d-flex align-items-center card-wrapper">
                    <span class="card-icon d-inline-flex align-items-center">
                        <i class="material-icons text-muted">insights</i>
                    </span>
                    <div class="d-flex flex-column">
                        <small class="font-weight-500">Net</small>
                        <h5 class="mb-0"><span class="font-weight-500">$</span>565,120</h5>
                    </div>
                </div>

                <div class="d-flex align-items-center card-wrapper">
                    <span class="card-icon d-inline-flex align-items-center">
                        <i class="material-icons text-muted">sticky_note_2</i>
                    </span>
                    <div class="d-flex flex-column">
                        <small class="font-weight-500">89 Projects</small>
                        <a href="#" class="text-primary font-weight-500">View</a>
                    </div>
                </div>

                <div class="d-flex align-items-center card-wrapper">
                    <span class="card-icon d-inline-flex align-items-center">
                        <i class="material-icons text-muted">comment</i>
                    </span>
                    <div class="d-flex flex-column">
                        <small class="font-weight-500">252 Comments</small>
                        <a href="#" class="text-primary font-weight-500">View</a>
                    </div>
                </div>

                <div class="d-flex align-items-center card-wrapper">
                    <span class="card-icon d-inline-flex align-items-center">
                        <i class="material-icons text-muted">group</i>
                    </span>

                    <div class="members d-flex">
                        <div class="member-image-wrapper square img-as-background rounded-circle"
                            data-bs-toggle="tooltip" title="Mark Stone">
                            <img alt="Pic" src="assets/images/phonebook/andrew.png">
                        </div>
                        <div class="member-image-wrapper square img-as-background rounded-circle"
                            data-bs-toggle="tooltip" title="Charlie Stone" style="margin-left: -10px;">
                            <img alt="Pic" src="assets/images/phonebook/johnny.png">
                        </div>
                        <div class="member-image-wrapper square img-as-background rounded-circle"
                            data-bs-toggle="tooltip" title="Luca Doncic" style="margin-left: -10px;">
                            <img alt="Pic" src="assets/images/phonebook/mark.png">
                        </div>
                        <div class="member-image-wrapper square img-as-background rounded-circle"
                            data-bs-toggle="tooltip" title="Nick Mana" style="margin-left: -10px;">
                            <img alt="Pic" src="assets/images/phonebook/steven.png">
                        </div>
                        <div class="member-image-wrapper square img-as-background rounded-circle"
                            data-bs-toggle="tooltip" title="Teresa Fox" style="margin-left: -10px;">
                            <img alt="Pic" src="assets/images/phonebook/janet.png">
                        </div>
                        <div class="member-image-wrapper h-30px rounded-circle bg-secondary d-inline-flex align-items-center justify-content-center"
                            style="margin-left: -10px; z-index: 9;">
                            <small>9+</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="custom-card" style="margin-bottom: 1.5rem;">
        <div class="card-body">
            <div class="custom-row mb-6 card-row">
                <div class="custom-col col-entity-md-auto image-col">
                    <div class="img-as-background square rounded-circle card-image">
                        <img src="assets/images/people/maria.jpg" alt="image">
                    </div>
                </div>

                <div class="custom-col col-entity-md">
                    <div class="d-flex justify-content-between flex-wrap mt-1">
                        <div class="d-flex align-items-center card-name">
                            <h5 style="margin-right: 0.5rem;"><a href="#"
                                    class="text-black hover-text-primary font-weight-500">Lisa Muller</a></h5>
                        </div>
                        <div class="my-lg-0 my-3">
                            <smart-button class="secondary very-small">Contact</smart-button>&nbsp;<smart-button
                                class="primary very-small">Hire me</smart-button>
                        </div>
                    </div>

                    <div class="custom-row justify-content-between mt-1">
                        <div class="custom-col col-entity-lg-6 col-entity-xl-8">
                            <div class="d-flex flex-wrap meta-wrapper">
                                <a href="#"
                                    class="text-dark hover-text-primary d-inline-flex align-items-center meta-info">
                                    <i class="material-icons">email</i> lisa@mailinator.com
                                </a>

                                <a href="#"
                                    class="text-dark hover-text-primary d-inline-flex align-items-center meta-info">
                                    <i class="material-icons">account_box</i> SEO Expert
                                </a>

                                <a href="#"
                                    class="text-dark hover-text-primary d-inline-flex align-items-center meta-info">
                                    <i class="material-icons">place</i> Swiss
                                </a>
                            </div>

                            <p>I distinguish three main text objectives could be merely to inform people. <br /> A
                                second could be persuade people.You want people to bay objective</p>
                        </div>
                        <div class="custom-col col-entity-lg-6 col-entity-xl-4 d-flex align-items-end">
                            <div class="d-flex align-items-center w-100">
                                <span class="font-weight-bold">Progress</span>

                                <div class="card-progress w-100">
                                    <smart-progress-bar [value]="95" class="info w-100"></smart-progress-bar>
                                </div>
                                <span class="font-weight-bold text-dark">95%</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <hr />

            <div class="d-flex align-items-center flex-wrap cards-wrapper">
                <div class="d-flex align-items-center card-wrapper">
                    <span class="card-icon d-inline-flex align-items-center">
                        <i class="material-icons text-muted">attach_money</i>
                    </span>
                    <div class="d-flex flex-column">
                        <small class="font-weight-500">Sales</small>
                        <h5 class="mb-0"><span class="font-weight-500">$</span>678,120</h5>
                    </div>
                </div>

                <div class="d-flex align-items-center card-wrapper">
                    <span class="card-icon d-inline-flex align-items-center">
                        <i class="material-icons text-muted">point_of_sale</i>
                    </span>
                    <div class="d-flex flex-column">
                        <small class="font-weight-500">Expenses</small>
                        <h5 class="mb-0"><span class="font-weight-500">$</span>149,450</h5>
                    </div>
                </div>

                <div class="d-flex align-items-center card-wrapper">
                    <span class="card-icon d-inline-flex align-items-center">
                        <i class="material-icons text-muted">insights</i>
                    </span>
                    <div class="d-flex flex-column">
                        <small class="font-weight-500">Net</small>
                        <h5 class="mb-0"><span class="font-weight-500">$</span>552,500</h5>
                    </div>
                </div>

                <div class="d-flex align-items-center card-wrapper">
                    <span class="card-icon d-inline-flex align-items-center">
                        <i class="material-icons text-muted">sticky_note_2</i>
                    </span>
                    <div class="d-flex flex-column">
                        <small class="font-weight-500">15 Projects</small>
                        <a href="#" class="text-primary font-weight-500">View</a>
                    </div>
                </div>

                <div class="d-flex align-items-center card-wrapper">
                    <span class="card-icon d-inline-flex align-items-center">
                        <i class="material-icons text-muted">comment</i>
                    </span>
                    <div class="d-flex flex-column">
                        <small class="font-weight-500">166 Comments</small>
                        <a href="#" class="text-primary font-weight-500">View</a>
                    </div>
                </div>

                <div class="d-flex align-items-center card-wrapper">
                    <span class="card-icon d-inline-flex align-items-center">
                        <i class="material-icons text-muted">group</i>
                    </span>

                    <div class="members d-flex">
                        <div class="member-image-wrapper square img-as-background rounded-circle"
                            data-bs-toggle="tooltip" title="Mark Stone">
                            <img alt="Pic" src="assets/images/phonebook/andrew.png">
                        </div>
                        <div class="member-image-wrapper square img-as-background rounded-circle"
                            data-bs-toggle="tooltip" title="Charlie Stone" style="margin-left: -10px;">
                            <img alt="Pic" src="assets/images/phonebook/johnny.png">
                        </div>
                        <div class="member-image-wrapper square img-as-background rounded-circle"
                            data-bs-toggle="tooltip" title="Luca Doncic" style="margin-left: -10px;">
                            <img alt="Pic" src="assets/images/phonebook/mark.png">
                        </div>
                        <div class="member-image-wrapper square img-as-background rounded-circle"
                            data-bs-toggle="tooltip" title="Nick Mana" style="margin-left: -10px;">
                            <img alt="Pic" src="assets/images/phonebook/steven.png">
                        </div>
                        <div class="member-image-wrapper square img-as-background rounded-circle"
                            data-bs-toggle="tooltip" title="Teresa Fox" style="margin-left: -10px;">
                            <img alt="Pic" src="assets/images/phonebook/janet.png">
                        </div>
                        <div class="member-image-wrapper h-30px rounded-circle bg-secondary d-inline-flex align-items-center justify-content-center"
                            style="margin-left: -10px; z-index: 9;">
                            <small>9+</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>