<form [formGroup]="myQuestionCreateForm" (ngSubmit)="createQuestion()">
  <div class="container basic-inputs">
    <div class="custom-row">
      <div class="custom-col">
        <div class="custom-card">
          <div class="card-header">
            <h4 class="label">Формулировка для уведомления </h4>
          </div>
          <div class="card-body">
            <smart-input class="basic-input" [placeholder]="'Что будет в уведомлении'" formControlName="textMessage"></smart-input>
            <!--Дебажим значения в форме чтобы глянуть какие ошибки есть-->
            <!--  <pre>{{meetingCreateForm.get('address').errors | json}}</pre>-->
            <div
                    *ngIf="myQuestionCreateForm.get('textMessage').invalid && myQuestionCreateForm.get('textMessage').touched"
                    class="validation"
            >
              <small *ngIf="myQuestionCreateForm.get('textMessage').errors.required">
                Поле номер не может быть пустым
              </small>

              <small *ngIf="myQuestionCreateForm.get('textMessage').errors.minlength">
                Длинна должна быть не менее {{myQuestionCreateForm.get('textMessage').errors.minlength.requiredLength}}
                Сейчас кол-во символов {{myQuestionCreateForm.get('textMessage').errors.minlength.actualLength}}
              </small>
            </div>
          </div>
        </div>

        <div class="custom-card">
          <div class="card-header">
            <h4 class="label">Формулировка для решения собственника</h4>
          </div>
          <div class="card-body">
            <smart-text-area class="basic-input" [placeholder]="'Что будет в бюллетене'"
                             formControlName="textOwner"></smart-text-area>
          </div>
        </div>
      </div>
    </div>


    <div class="custom-row">
      <div class="custom-col">
        <div class="custom-card">
          <div class="card-header">
          </div>
          <div class="card-body">
            <smart-button type="submit" [disabled]="myQuestionCreateForm.invalid">Создать вопрос</smart-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
