<div class="container">
    <div class="dashboard-cards-wrapper custom-row">
        <div class="dashboard-card custom-col">
            <div class="custom-card bg-primary text-white text-center">
                <div class="card-body">
                    <h2>2,064</h2>
                    <h6 class="">Sessions</h6>
                </div>
            </div>
        </div>
        <div class="dashboard-card custom-col">
            <div class="custom-card bg-info text-white text-center">
                <div class="card-body">
                    <h2>1,738</h2>
                    <h6 class="">Users</h6>
                </div>
            </div>
        </div>
        <div class="dashboard-card custom-col">
            <div class="custom-card bg-success text-white text-center">
                <div class="card-body">
                    <h2>5963</h2>
                    <h6 class="">Page Views</h6>
                </div>
            </div>
        </div>
        <div class="dashboard-card custom-col">
            <div class="custom-card bg-warning text-white text-center">
                <div class="card-body">
                    <h2>10%</h2>
                    <h6 class="">Bounce Rate</h6>
                </div>
            </div>
        </div>
    </div>

    <div class="dashboard-welcomeMsgs-productCalc custom-card">
        <div class="dashboard-welcomeMsgs-productCalc-content custom-row">
            <div class="dashboard-welcomeMsgs">
                <div class="custom-card shadow-0">
                    <div class="card-header">
                        <div>
                            <h5>Welcome Steave</h5>
                            <p>you have 4 new messages</p>
                        </div>
                    </div>

                    <div class="card-body py-2">
                        <div class="feeds">
                            <div class="feed custom-row">
                                <div class="feed-image-wrapper">
                                    <div class="img-as-background">
                                        <img src="../../../assets/images/phonebook/mark.png" />
                                    </div>
                                </div>

                                <div class="feed-info">
                                    <div class=""><strong>Frederick Murphy</strong></div>
                                    <small class="text-muted mb-0 d-block">Just see the new Admin!</small>
                                    <small class="text-muted">Today 8:19 PM</small>
                                </div>
                            </div>

                            <div class="feed custom-row">
                                <div class="feed-image-wrapper">
                                    <div class="img-as-background">
                                        <img src="../../../assets/images/phonebook/maria.png" />
                                    </div>
                                </div>

                                <div class="feed-info">
                                    <div class=""><strong>Elisha Scott</strong></div>
                                    <small class="text-muted mb-0 d-block">I've sing a song! See you at</small>
                                    <small class="text-muted">Today 10:45 PM</small>
                                </div>
                            </div>

                            <div class="feed custom-row">
                                <div class="feed-image-wrapper">
                                    <div class="img-as-background">
                                        <img src="../../../assets/images/phonebook/johnny.png" />
                                    </div>
                                </div>

                                <div class="feed-info">
                                    <div class=""><strong>John Doe</strong></div>
                                    <small class="text-muted mb-0 d-block">I am a singer!</small>
                                    <small class="text-muted">8 hours ago</small>
                                </div>
                            </div>

                            <div class="feed custom-row">
                                <div class="feed-image-wrapper">
                                    <div class="img-as-background">
                                        <img src="../../../assets/images/phonebook/toni.png" />
                                    </div>
                                </div>

                                <div class="feed-info">
                                    <div class=""><strong>Sonu Nigan</strong></div>
                                    <small class="text-muted mb-0 d-block">Let's go to the coffe!</small>
                                    <small class="text-muted">12 hours ago</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="dashboard-productCalc custom-col">
                <div class="custom-card h-100 shadow-0">
                    <div class="card-header">
                        <h4 class="label">Product Calculation</h4>
                        <div class="smart-badge smart-badge-primary smart-badge-pill">Monthly</div>
                    </div>
                    <div class="card-body">
                        <smart-chart #revenueChart class="dashboard-revenue-chart" [animation]="'none'" [theme]="theme!"
                            [caption]="'Caption'" [description]="'Description'" [showLegend]="true"
                            [showBorderLine]="true" [padding]="revenueChartData.padding"
                            [dataSource]="revenueChartData.dataSource" [xAxis]="revenueChartData.xAxis"
                            [seriesGroups]="revenueChartData.seriesGroups" [colorScheme]="'custom'"></smart-chart>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="dashboard-memActivity-customerSupp custom-row">
        <div class="dashboard-memActivity custom-col">
            <div class="custom-card">
                <div class="card-header">
                    <div>
                        <h5>Members Activity</h5>
                        <h6 class="text-muted">what members preformance status</h6>
                    </div>

                    <smart-button-group class="small primary" data-source='["Today","Week","Month"]'>
                    </smart-button-group>
                </div>

                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Member</th>
                                    <th>Earnings</th>
                                    <th>Posts</th>
                                    <th>Team</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div class="member-wrapper">
                                            <div class="img-as-background">
                                                <img src="../../../assets/images/phonebook/maria.png" alt="user" />
                                            </div>
                                            <div class="member-info">
                                                <a href="#" class="d-block">Kevin Peter</a>
                                                <span class="smart-badge smart-badge-success">designer</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-center"> $325</td>
                                    <td class="text-muted text-center"> 45</td>
                                    <td>
                                        <div class="teams-wrapper">
                                            <a href="#" class="btn btn-info btn-rounded btn-sm">A</a>
                                            <a href="#" class="btn btn-danger btn-rounded btn-sm">T</a>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="member-wrapper">
                                            <div class="img-as-background">
                                                <img src="../../../assets/images/phonebook/toni.png" alt="user" />
                                            </div>
                                            <div class="member-info">
                                                <a href="#" class="d-block">John Smith</a>
                                                <span class="smart-badge smart-badge-danger">developer</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-center"> $225</td>
                                    <td class="text-muted text-center"> 35</td>
                                    <td>
                                        <div class="teams-wrapper button-group">
                                            <a href="#" class="btn btn-warning btn-rounded btn-sm">S</a>
                                            <a href="#" class="btn btn-primary btn-rounded btn-sm">V</a>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="member-wrapper">
                                            <div class="img-as-background">
                                                <img src="../../../assets/images/phonebook/johnny.png" alt="user" />
                                            </div>
                                            <div class="member-info">
                                                <a href="#" class="d-block">Harry Peter</a>
                                                <span class="smart-badge smart-badge-primary">manager</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-center"> $185</td>
                                    <td class="text-muted text-center"> 28</td>
                                    <td>
                                        <div class="teams-wrapper button-group">
                                            <a href="#" class="btn btn-success btn-rounded btn-sm">B</a>
                                            <a href="#" class="btn btn-info btn-rounded btn-sm">M</a>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="member-wrapper">
                                            <div class="img-as-background">
                                                <img src="../../../assets/images/phonebook/mark.png" alt="user" />
                                            </div>
                                            <div class="member-info">
                                                <a href="#" class="d-block">James Deo</a>
                                                <span class="smart-badge smart-badge-success">designer</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-center"> $125</td>
                                    <td class="text-muted text-center"> 25</td>
                                    <td>
                                        <div class="teams-wrapper button-group">
                                            <a href="#" class="btn btn-primary btn-rounded btn-sm">K</a>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="member-wrapper">
                                            <div class="img-as-background">
                                                <img src="../../../assets/images/phonebook/monica.png" alt="user" />
                                            </div>
                                            <div class="member-info">
                                                <a href="#" class="d-block">Willium Deo</a>
                                                <span class="smart-badge smart-badge-danger">developer</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-center"> $225</td>
                                    <td class="text-muted text-center"> 35</td>
                                    <td>
                                        <div class="teams-wrapper button-group">
                                            <a href="#" class="btn btn-danger btn-rounded btn-sm">L</a>
                                            <a href="#" class="btn btn-info btn-rounded btn-sm">J</a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="text-center">
                            <smart-button class="success small">Load more</smart-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="dashboard-customerSupp custom-col">
            <div class="custom-card">
                <div class="card-header">
                    <div>
                        <h5>Customer Support</h5>
                        <h6 class="text-muted">24 new support ticket request generate</h6>
                    </div>
                </div>

                <div class="customerSupp-comments-wrapper">
                    <!-- Comment Row -->
                    <div class="customerSupp-comment-row">
                        <div class="customerSupp-image-wrapper"><span class="img-as-background"><img
                                    src="../../../assets/images/phonebook/maria.png"></span></div>
                        <div class="customerSupp-comment-info">
                            <h6 class="font-weight-medium">James Anderson</h6>
                            <p class="customerSupp-comment-text text-muted">Lorem Ipsum is simply dummy text of the
                                printing and type setting industry. Lorem Ipsum has beenorem Ipsum is simply dummy
                                text of the printing and type setting industry.</p>
                            <div class="customerSupp-comment-status-wrapper">
                                <div class="customerSupp-comment-status">
                                    <span class="smart-badge smart-badge-info customerSupp-status-badge">Pending</span>
                                    <div>
                                        <a href="#" class="text-secondary">
                                            <span class="material-icons font-size-16px">edit</span>
                                        </a>&nbsp;<a href="#" class="text-secondary">
                                            <span class="material-icons font-size-16px">highlight_off</span>
                                        </a>&nbsp;<a href="#" class="text-danger">
                                            <span class="material-icons font-size-16px">favorite_border</span>
                                        </a>
                                    </div>
                                </div>
                                <small class="text-muted">April 14, 2016</small>
                            </div>
                        </div>
                    </div>

                    <!-- Comment Row -->
                    <div class="customerSupp-comment-row">
                        <div class="customerSupp-image-wrapper"><span class="img-as-background"><img
                                    src="../../../assets/images/phonebook/johnny.png"></span></div>
                        <div class="customerSupp-comment-info">
                            <h6 class="font-weight-medium">Michael Jorden</h6>
                            <p class="customerSupp-comment-text text-muted">Lorem Ipsum is simply dummy text of the
                                printing and type setting industry. Lorem Ipsum has beenorem Ipsum is simply dummy
                                text of the printing and type setting industry.</p>
                            <div class="customerSupp-comment-status-wrapper">
                                <div class="customerSupp-comment-status">
                                    <span
                                        class="smart-badge smart-badge-success customerSupp-status-badge">Approved</span>
                                    <div>
                                        <a href="#" class="text-secondary">
                                            <span class="material-icons font-size-16px">edit</span>
                                        </a>&nbsp;<a href="#" class="text-secondary">
                                            <span class="material-icons font-size-16px">highlight_off</span>
                                        </a>&nbsp;<a href="#" class="text-danger">
                                            <span class="material-icons font-size-16px">favorite_border</span>
                                        </a>
                                    </div>
                                </div>
                                <small class="text-muted">April 14, 2016</small>
                            </div>
                        </div>
                    </div>

                    <!-- Comment Row -->
                    <div class="customerSupp-comment-row">
                        <div class="customerSupp-image-wrapper"><span class="img-as-background"><img
                                    src="../../../assets/images/phonebook/mark.png"></span></div>
                        <div class="customerSupp-comment-info">
                            <h6 class="font-weight-medium">Johnathan Doeting</h6>
                            <p class="customerSupp-comment-text text-muted">Lorem Ipsum is simply dummy text of the
                                printing and type setting industry. Lorem Ipsum has beenorem Ipsum is simply dummy
                                text of the printing and type setting industry.</p>
                            <div class="customerSupp-comment-status-wrapper">
                                <div class="customerSupp-comment-status">
                                    <span
                                        class="smart-badge smart-badge-error customerSupp-status-badge">Rejected</span>
                                    <div>
                                        <a href="#" class="text-secondary">
                                            <span class="material-icons font-size-16px">edit</span>
                                        </a>&nbsp;<a href="#" class="text-secondary" title="Approve">
                                            <span class="material-icons font-size-16px">check</span>
                                        </a>&nbsp;<a href="#" class="text-danger">
                                            <span class="material-icons font-size-16px">favorite_border</span>
                                        </a>
                                    </div>
                                </div>
                                <small class="text-muted">April 14, 2016</small>
                            </div>
                        </div>
                    </div>

                    <!-- Comment Row -->
                    <div class="customerSupp-comment-row">
                        <div class="customerSupp-image-wrapper"><span class="img-as-background"><img
                                    src="../../../assets/images/phonebook/mark.png"></span></div>
                        <div class="customerSupp-comment-info">
                            <h6 class="font-weight-medium">James Anderson</h6>
                            <p class="customerSupp-comment-text text-muted">Lorem Ipsum is simply dummy text of the
                                printing and type setting industry. Lorem Ipsum has beenorem Ipsum is simply dummy
                                text of the printing and type setting industry.</p>
                            <div class="customerSupp-comment-status-wrapper">
                                <div class="customerSupp-comment-status">
                                    <span class="smart-badge smart-badge-info customerSupp-status-badge">Pending</span>
                                    <div>
                                        <a href="#" class="text-secondary">
                                            <span class="material-icons font-size-16px">edit</span>
                                        </a>&nbsp;<a href="#" class="text-secondary">
                                            <span class="material-icons font-size-16px">highlight_off</span>
                                        </a>&nbsp;<a href="#" class="text-danger">
                                            <span class="material-icons font-size-16px">favorite_border</span>
                                        </a>
                                    </div>
                                </div>
                                <small class="text-muted">April 14, 2016</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="dashboard-schedule-salesOvw custom-row">
        <div class="dashboard-schedule custom-col">
            <div class="custom-card h-100">
                <div class="card-header">
                    <div>
                        <h5>Today's Schedule</h5>
                        <h6 class="text-muted">Check out your daily schedule</h6>
                    </div>
                </div>

                <div class="card-body">
                    <div class="feeds">
                        <div class="feed">
                            <div class="feed-borders">
                                <div class="feed-row custom-row">
                                    <div class="feed-image-wrapper">
                                        <div class="img-as-background">
                                            <img src="../../../assets/images/phonebook/michael.png" />
                                        </div>
                                    </div>

                                    <div class="feed-info">
                                        <strong>John Doe</strong>&nbsp;<small>5 minutes ago</small>
                                        <p class="text-muted">Call today with John Doe</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="feed">
                            <div class="feed-borders">
                                <div class="feed-row custom-row">
                                    <div class="feed-image-wrapper">
                                        <div class="logo-as-background bg-danger">
                                            <span class="material-icons text-white">shopping_cart</span>
                                        </div>
                                    </div>

                                    <div class="feed-info">
                                        <strong>Shopping today</strong>&nbsp;<small>6 pm</small>
                                        <p class="text-muted">Go to buy a office equipment</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="feed">
                            <div class="feed-borders">
                                <div class="feed-row custom-row">
                                    <div class="feed-image-wrapper">
                                        <div class="img-as-background">
                                            <img src="../../../assets/images/phonebook/monica.png" />
                                        </div>
                                    </div>

                                    <div class="feed-info">
                                        <strong>Monica Belluci</strong>&nbsp;<small>7 pm</small>
                                        <p class="text-muted">Meet Monica to discuss the employment</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="feed">
                            <div class="feed-borders">
                                <div class="feed-row custom-row">
                                    <div class="feed-image-wrapper">
                                        <div class="bg-primary logo-as-background">
                                            <span class="material-icons text-white">picture_as_pdf</span>
                                        </div>
                                    </div>

                                    <div class="feed-info">
                                        <strong>Send documents to Clark</strong>
                                        <p class="text-muted">Lorem Ipsum is simply</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <smart-button id="loadMore" class="primary">Load more</smart-button>
                </div>
            </div>
        </div>

        <div class="dashboard-salesOvw custom-col">
            <div class="custom-card h-100">
                <div class="card-header">
                    <div>
                        <h5>Sales Overview</h5>
                        <h6 class="text-muted">Check the monthly sales</h6>
                    </div>

                    <smart-button-group class="small primary" data-source='["Today","Week","Month"]'>
                    </smart-button-group>
                </div>

                <div class="dashboard-salesOvw-heading">
                    <div class="dashboard-salesOvw-heading-row custom-row">
                        <div class="dashboard-salesOvw-heading-month custom-col">
                            <h4>March 2021</h4>
                            <h6 class="text-muted">Report for this month</h6>
                        </div>

                        <div class="dashboard-salesOvw-heading-sum custom-col">
                            <span class="text-primary">$3,690</span>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <smart-table #salesTable class="dashboard-sales-table" [dataSource]="salesTableData.dataSource"
                        [freezeHeader]="true" [paging]="false" [tooltip]="true" [columns]="salesTableData.columns">
                    </smart-table>
                </div>
            </div>
        </div>
    </div>

    <div class="dashboard-storageCards custom-row">
        <div class="storageCard custom-col">
            <div class="custom-card">
                <div class="card-body">
                    <div class="icon-info-wrapper">
                        <div class="dashboard-warning-icon text-warning icon-wrapper">
                            <i class="fab fa-google-drive font-size-32px"></i>
                        </div>

                        <div class="dashboard-storageCards-card-settings">
                            <a href="javascript:;" class="settings-button amz" #googleDriveButton
                                (click)="toggleMenu(googleDriveButton)"
                                (pointerup)="stopPropagation($event)"><span class="material-icons">more_horiz</span></a>
                        </div>
                    </div>

                    <div class="info-wrapper">
                        <h5>Google Drive</h5>
                        <smart-progress-bar [value]="91" class="warning dashboard-progress-bar">
                        </smart-progress-bar>

                        <div class="storage-info-text-wrapper">
                            <span>13.1Gb</span>
                            <span>15Gb</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="storageCard custom-col">
            <div class="custom-card">
                <div class="card-body">
                    <div class="icon-info-wrapper">
                        <div class="dashboard-info-icon text-info icon-wrapper">
                            <i class="fab fa-dropbox font-size-32px"></i>
                        </div>

                        <div class="dashboard-storageCards-card-settings">
                            <a href="javascript:;" class="settings-button" #dropboxButton
                                (click)="toggleMenu(dropboxButton)" (pointerup)="stopPropagation($event)"><span
                                    class="material-icons">more_horiz</span></a>
                        </div>
                    </div>

                    <div class="info-wrapper">
                        <h5>Dropbox</h5>
                        <smart-progress-bar [value]="50" class="info dashboard-progress-bar"></smart-progress-bar>

                        <div class="storage-info-text-wrapper">
                            <span>7.5Gb</span>
                            <span>15Gb</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="storageCard custom-col">
            <div class="custom-card">
                <div class="card-body">
                    <div class="icon-info-wrapper">
                        <div class="dashboard-danger-icon text-danger icon-wrapper">
                            <i class="fab fa-soundcloud font-size-32px"></i>
                        </div>

                        <div class="dashboard-storageCards-card-settings">
                            <a href="javascript:;" class="settings-button" #soundcloudButton
                                (click)="toggleMenu(soundcloudButton)" (pointerup)="stopPropagation($event)"><span
                                    class="material-icons">more_horiz</span></a>
                        </div>
                    </div>

                    <div class="info-wrapper">
                        <h5>One Drive</h5>
                        <smart-progress-bar [value]="25" class="error dashboard-progress-bar"></smart-progress-bar>

                        <div class="storage-info-text-wrapper">
                            <span>2Gb</span>
                            <span>15Gb</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="storageCard custom-col">
            <div class="custom-card">
                <div class="card-body">
                    <div class="icon-info-wrapper">
                        <div class="dashboard-primary-icon text-primary icon-wrapper">
                            <i class="fab fa-amazon font-size-32px"></i>
                        </div>

                        <div class="dashboard-storageCards-card-settings">
                            <a href="javascript:;" class="settings-button" #amazonButton
                                (click)="toggleMenu(amazonButton)" (pointerup)="stopPropagation($event)"><span
                                    class="material-icons">more_horiz</span></a>
                        </div>
                    </div>

                    <div class="info-wrapper">
                        <h5>Amazon</h5>
                        <smart-progress-bar [value]="85" class="danger dashboard-progress-bar"></smart-progress-bar>

                        <div class="storage-info-text-wrapper">
                            <span>4Gb</span>
                            <span>15Gb</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>