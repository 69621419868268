<div class="container projects-list-2">
    <div class="custom-row">
        <div class="custom-col col-entity-12 projects-list-2-header">
            <h4>Projects List 2</h4>
        </div>
    </div>

    <div class="custom-row">
        <div class="custom-col col-entity-md-10 col-entity-lg-6 item item-col">
            <div class="custom-card h-100">
                <div class="card-header border-0">
                    <div class="d-flex align-items-center">
                        <div class="img-as-background">
                            <img src="../../../../../assets/images/brands/circle-google.png" alt="image">
                        </div>

                        <div class="card-header-info">
                            <h5 class="mb-1"><a href="#" class="text-dark font-weight-bold text-decoration-none">Google
                                    - Next generation search engine</a></h5>
                            <small class="text-muted font-weight-bold">Find what you search</small>
                        </div>
                    </div>

                    <div class="settings-button" #googleButton (click)="toggleMenu(googleButton)"
                        (pointerup)="stopPropagation($event)">…</div>
                </div>

                <div class="card-body">
                    <div class="custom-row">
                        <div class="custom-col col-entity-auto projects-list-2-dates">
                            <span class="dates-progress-span">Start Date</span>
                            <small class="font-weight-bold bg-primary-opacity-2 custom-btn text-primary">14 Jan,
                                08</small>
                        </div>
                        <div class="custom-col col-entity-auto projects-list-2-dates">
                            <span class="dates-progress-span">Due Date</span>
                            <small class="font-weight-bold bg-danger-opacity-2 custom-btn text-danger">21 Nov,
                                12</small>
                        </div>

                        <div class="custom-col progress-col">
                            <span class="dates-progress-span">Progress</span>
                            <div class="d-flex align-items-center pt-1">
                                <div class="progress-wrapper">
                                    <smart-progress-bar [value]="100" class="success"></smart-progress-bar>
                                </div>
                                <span class="progress-value font-weight-bold">100%</span>
                            </div>
                        </div>
                    </div>

                    <p class="item-info">I distinguish three main text objectives.First, your objective could be merely to
                        inform people.A second be to persuade people.</p>

                    <div class="custom-row budget-expenses-members-row">
                        <div class="custom-col col-entity-auto d-flex flex-column">
                            <span class="font-weight-bold budget-expenses-span">Budget</span>
                            <span class="font-weight-bold pt-1"><span class="font-weight-bold text-dark-50">$</span>1
                                855 249,500</span>
                        </div>

                        <div class="custom-col col-entity-auto d-flex flex-column">
                            <span class="font-weight-bold budget-expenses-span">Expenses</span>
                            <span class="font-weight-bold pt-1"><span class="font-weight-bold text-dark-50">$</span>3
                                855 249,500</span>
                        </div>

                        <div class="custom-col members-col flex-column">
                            <span class="font-weight-bold d-block mb-2">Members</span>
                            <div class="members d-flex">
                                <div class="img-as-background"
                                    data-toggle="tooltip" title="Mark Stone">
                                    <img alt="Pic" src="../../../../../assets/images/phonebook/andrew.png">
                                </div>
                                <div class="img-as-background"
                                    data-toggle="tooltip" title="Charlie Stone" style="margin-left: -10px;">
                                    <img alt="Pic" src="../../../../../assets/images/phonebook/johnny.png">
                                </div>
                                <div class="img-as-background"
                                    data-toggle="tooltip" title="Luca Doncic" style="margin-left: -10px;">
                                    <img alt="Pic" src="../../../../../assets/images/phonebook/mark.png">
                                </div>
                                <div class="img-as-background"
                                    data-toggle="tooltip" title="Nick Mana" style="margin-left: -10px;">
                                    <img alt="Pic" src="../../../../../assets/images/phonebook/steven.png">
                                </div>
                                <div class="img-as-background"
                                    data-toggle="tooltip" title="Teresa Fox" style="margin-left: -10px;">
                                    <img alt="Pic" src="../../../../../assets/images/phonebook/janet.png">
                                </div>
                                <div class="members-indicator"
                                    style="margin-left: -10px; z-index: 9;">
                                    <small>99+</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="d-flex">
                        <div class="d-flex tasks-comments-wrapper">
                            <a href="#" class="text-primary task-comments small">5969 Tasks</a>
                        </div>
                        <div class="d-flex tasks-comments-wrapper">
                            <a href="#" class="text-primary task-comments small">6248 Comments</a>
                        </div>
                    </div>
                    <div class="details-wrapper">
                        <smart-button class="small-button primary">Details</smart-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="custom-col col-entity-md-10 col-entity-lg-6 item item-col">
            <div class="custom-card h-100">
                <div class="card-header border-0">
                    <div class="d-flex align-items-center">
                        <div class="img-as-background">
                            <img src="../../../../../assets/images/brands/circle-jqwidgets.png" alt="image">
                        </div>

                        <div class="card-header-info">
                            <h5 class="mb-1"><a href="https://www.jqwidgets.com/"
                                    class="text-dark font-weight-bold text-decoration-none" target="_blank">jQWidgets -
                                    Next generation UI</a></h5>
                            <small class="text-muted font-weight-bold">Better web, less time</small>
                        </div>
                    </div>
                    <div class="settings-button" #jqwidgetsButton (click)="toggleMenu(jqwidgetsButton)"
                    (pointerup)="stopPropagation($event)">…</div>
                </div>

                <div class="card-body">
                    <div class="custom-row">
                        <div class="custom-col col-entity-auto projects-list-2-dates">
                            <span class="dates-progress-span">Start Date</span>
                            <small class="font-weight-bold bg-primary-opacity-2 custom-btn text-primary">14 Jan,
                                16</small>
                        </div>
                        <div class="custom-col col-entity-auto projects-list-2-dates">
                            <span class="dates-progress-span">Due Date</span>
                            <small class="font-weight-bold bg-danger-opacity-2 custom-btn text-danger">21 Nov,
                                18</small>
                        </div>

                        <div class="custom-col progress-col">
                            <span class="dates-progress-span">Progress</span>
                            <div class="d-flex align-items-center pt-1">
                                <div class="progress-wrapper">
                                    <smart-progress-bar [value]="89" class="primary"></smart-progress-bar>
                                </div>
                                <span class="progress-value font-weight-bold">89%</span>
                            </div>
                        </div>
                    </div>

                    <p class="item-info">The leading HTML5 UI Widgets Framework. Build responsive Web Apps with Angular,
                        React, Vue, Web Components and jQuery. </p>

                    <div class="custom-row budget-expenses-members-row">
                        <div class="custom-col col-entity-auto d-flex flex-column">
                            <span class="font-weight-bold budget-expenses-span">Budget</span>
                            <span class="font-weight-bold pt-1"><span
                                    class="font-weight-bold text-dark-50">$</span>249,500</span>
                        </div>

                        <div class="custom-col col-entity-auto d-flex flex-column">
                            <span class="font-weight-bold budget-expenses-span">Expenses</span>
                            <span class="font-weight-bold pt-1"><span
                                    class="font-weight-bold text-dark-50">$</span>439,500</span>
                        </div>

                        <div class="custom-col members-col flex-column">
                            <span class="font-weight-bold d-block mb-2">Members</span>
                            <div class="members d-flex">
                                <div class="img-as-background"
                                    data-toggle="tooltip" title="Mark Stone">
                                    <img alt="Pic" src="../../../../../assets/images/phonebook/andrew.png">
                                </div>
                                <div class="img-as-background"
                                    data-toggle="tooltip" title="Charlie Stone" style="margin-left: -10px;">
                                    <img alt="Pic" src="../../../../../assets/images/phonebook/johnny.png">
                                </div>
                                <div class="img-as-background"
                                    data-toggle="tooltip" title="Luca Doncic" style="margin-left: -10px;">
                                    <img alt="Pic" src="../../../../../assets/images/phonebook/mark.png">
                                </div>
                                <div class="img-as-background"
                                    data-toggle="tooltip" title="Nick Mana" style="margin-left: -10px;">
                                    <img alt="Pic" src="../../../../../assets/images/phonebook/steven.png">
                                </div>
                                <div class="img-as-background"
                                    data-toggle="tooltip" title="Teresa Fox" style="margin-left: -10px;">
                                    <img alt="Pic" src="../../../../../assets/images/phonebook/janet.png">
                                </div>
                                <div class="members-indicator"
                                    style="margin-left: -10px; z-index: 9;">
                                    <small>5+</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="d-flex">
                        <div class="d-flex tasks-comments-wrapper">
                            <a href="#" class="text-primary task-comments small">372 Tasks</a>
                        </div>
                        <div class="d-flex tasks-comments-wrapper">
                            <a href="#" class="text-primary task-comments small">598 Comments</a>
                        </div>
                    </div>
                    <div class="details-wrapper">
                        <smart-button class="small-button primary">Details</smart-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="custom-col col-entity-md-10 col-entity-lg-6 item item-col">
            <div class="custom-card h-100">
                <div class="card-header border-0">
                    <div class="d-flex align-items-center">
                        <div class="img-as-background">
                            <img src="../../../../../assets/images/brands/circle-angular.svg" alt="image">
                        </div>

                        <div class="card-header-info">
                            <h5 class="mb-1"><a href="#" class="text-dark font-weight-bold text-decoration-none"
                                    target="_blank">Angular - Web framework</a></h5>
                            <small class="text-muted font-weight-bold">TypeScript-based open-source web application
                                framework</small>
                        </div>
                    </div>

                    <div class="settings-button" #angularButton (click)="toggleMenu(angularButton)"
                    (pointerup)="stopPropagation($event)">…</div>
                </div>

                <div class="card-body">
                    <div class="custom-row">
                        <div class="custom-col col-entity-auto projects-list-2-dates">
                            <span class="dates-progress-span">Start Date</span>
                            <small class="font-weight-bold bg-primary-opacity-2 custom-btn text-primary">14 Jan,
                                12</small>
                        </div>
                        <div class="custom-col col-entity-auto projects-list-2-dates">
                            <span class="dates-progress-span">Due Date</span>
                            <small class="font-weight-bold bg-danger-opacity-2 custom-btn text-danger">21 Nov,
                                16</small>
                        </div>

                        <div class="custom-col progress-col">
                            <span class="dates-progress-span">Progress</span>
                            <div class="d-flex align-items-center pt-1">
                                <div class="progress-wrapper">
                                    <smart-progress-bar [value]="27" class="error"></smart-progress-bar>
                                </div>
                                <span class="progress-value font-weight-bold">27%</span>
                            </div>
                        </div>
                    </div>

                    <p class="item-info">Angular is a TypeScript-based open-source web application framework led by the
                        Angular Team at Google and by a community of individuals and corporations. Angular is a complete
                        rewrite from the same team that built AngularJS</p>

                    <div class="custom-row budget-expenses-members-row">
                        <div class="custom-col col-entity-auto d-flex flex-column">
                            <span class="font-weight-bold budget-expenses-span">Budget</span>
                            <span class="font-weight-bold pt-1"><span
                                    class="font-weight-bold text-dark-50">$</span>139,500</span>
                        </div>

                        <div class="custom-col col-entity-auto d-flex flex-column">
                            <span class="font-weight-bold budget-expenses-span">Expenses</span>
                            <span class="font-weight-bold pt-1"><span
                                    class="font-weight-bold text-dark-50">$</span>939,500</span>
                        </div>

                        <div class="custom-col members-col flex-column">
                            <span class="font-weight-bold d-block mb-2">Members</span>
                            <div class="members d-flex">
                                <div class="img-as-background"
                                    data-toggle="tooltip" title="Mark Stone">
                                    <img alt="Pic" src="../../../../../assets/images/phonebook/andrew.png">
                                </div>
                                <div class="img-as-background"
                                    data-toggle="tooltip" title="Charlie Stone" style="margin-left: -10px;">
                                    <img alt="Pic" src="../../../../../assets/images/phonebook/johnny.png">
                                </div>
                                <div class="img-as-background"
                                    data-toggle="tooltip" title="Luca Doncic" style="margin-left: -10px;">
                                    <img alt="Pic" src="../../../../../assets/images/phonebook/mark.png">
                                </div>
                                <div class="img-as-background"
                                    data-toggle="tooltip" title="Nick Mana" style="margin-left: -10px;">
                                    <img alt="Pic" src="../../../../../assets/images/phonebook/steven.png">
                                </div>
                                <div class="img-as-background"
                                    data-toggle="tooltip" title="Teresa Fox" style="margin-left: -10px;">
                                    <img alt="Pic" src="../../../../../assets/images/phonebook/janet.png">
                                </div>
                                <div class="members-indicator"
                                    style="margin-left: -10px; z-index: 9;">
                                    <small>5+</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="d-flex">
                        <div class="d-flex tasks-comments-wrapper">
                            <a href="#" class="text-primary task-comments small">72 Tasks</a>
                        </div>
                        <div class="d-flex tasks-comments-wrapper">
                            <a href="#" class="text-primary task-comments small">648 Comments</a>
                        </div>
                    </div>
                    <div class="details-wrapper">
                        <smart-button class="small-button primary">Details</smart-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="custom-col col-entity-md-10 col-entity-lg-6 item item-col">
            <div class="custom-card h-100">
                <div class="card-header border-0">
                    <div class="d-flex align-items-center">
                        <div class="img-as-background">
                            <img src="../../../../../assets/images/brands/circle-htmlelements.png" alt="image">
                        </div>

                        <div class="card-header-info">
                            <h5 class="mb-1"><a href="#" class="text-dark font-weight-bold text-decoration-none"
                                    target="_blank">HTMElements - Web Components for Enterprise</a></h5>
                            <small class="text-muted font-weight-bold">Smart is a modern Vanilla JS and ES6
                                library</small>
                        </div>
                    </div>

                    <div class="settings-button" #htmlElementsButton (click)="toggleMenu(htmlElementsButton)"
                    (pointerup)="stopPropagation($event)">…</div>
                </div>

                <div class="card-body">
                    <div class="custom-row">
                        <div class="custom-col col-entity-auto projects-list-2-dates">
                            <span class="dates-progress-span">Start Date</span>
                            <small class="font-weight-bold bg-primary-opacity-2 custom-btn text-primary">13 Mar,
                                11</small>
                        </div>
                        <div class="custom-col col-entity-auto projects-list-2-dates">
                            <span class="dates-progress-span">Due Date</span>
                            <small class="font-weight-bold bg-danger-opacity-2 custom-btn text-danger">2 Jan, 21</small>
                        </div>

                        <div class="custom-col progress-col">
                            <span class="dates-progress-span">Progress</span>
                            <div class="d-flex align-items-center pt-1">
                                <div class="progress-wrapper">
                                    <smart-progress-bar [value]="99" class="warning"></smart-progress-bar>
                                </div>
                                <span class="progress-value font-weight-bold">99%</span>
                            </div>
                        </div>
                    </div>

                    <p class="item-info">Web Components for Enterprise Apps. Next-generation Vanilla JS and ES6 Front-End Web
                        UI. Material Web Components. Angular, React, Vue, Typescript.</p>

                    <div class="custom-row budget-expenses-members-row">
                        <div class="custom-col col-entity-auto d-flex flex-column">
                            <span class="font-weight-bold budget-expenses-span">Budget</span>
                            <span class="font-weight-bold pt-1"><span class="font-weight-bold text-dark-50">$</span>1
                                249,500</span>
                        </div>

                        <div class="custom-col col-entity-auto d-flex flex-column">
                            <span class="font-weight-bold budget-expenses-span">Expenses</span>
                            <span class="font-weight-bold pt-1"><span
                                    class="font-weight-bold text-dark-50">$</span>939,500</span>
                        </div>

                        <div class="custom-col members-col flex-column">
                            <span class="font-weight-bold d-block mb-2">Members</span>
                            <div class="members d-flex">
                                <div class="img-as-background"
                                    data-toggle="tooltip" title="Mark Stone">
                                    <img alt="Pic" src="../../../../../assets/images/phonebook/andrew.png">
                                </div>
                                <div class="img-as-background"
                                    data-toggle="tooltip" title="Charlie Stone" style="margin-left: -10px;">
                                    <img alt="Pic" src="../../../../../assets/images/phonebook/johnny.png">
                                </div>
                                <div class="img-as-background"
                                    data-toggle="tooltip" title="Luca Doncic" style="margin-left: -10px;">
                                    <img alt="Pic" src="../../../../../assets/images/phonebook/mark.png">
                                </div>
                                <div class="img-as-background"
                                    data-toggle="tooltip" title="Nick Mana" style="margin-left: -10px;">
                                    <img alt="Pic" src="../../../../../assets/images/phonebook/steven.png">
                                </div>
                                <div class="img-as-background"
                                    data-toggle="tooltip" title="Teresa Fox" style="margin-left: -10px;">
                                    <img alt="Pic" src="../../../../../assets/images/phonebook/janet.png">
                                </div>
                                <div class="members-indicator"
                                    style="margin-left: -10px; z-index: 9;">
                                    <small>9+</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="d-flex">
                        <div class="d-flex tasks-comments-wrapper">
                            <a href="#" class="text-primary task-comments small">1469 Tasks</a>
                        </div>
                        <div class="d-flex tasks-comments-wrapper">
                            <a href="#" class="text-primary task-comments small">2648 Comments</a>
                        </div>
                    </div>
                    <div class="details-wrapper">
                        <smart-button class="small-button primary">Details</smart-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>