import {Component, OnInit, OnDestroy, ViewChild, AfterViewInit, ViewEncapsulation, Input} from '@angular/core';
import {MeetingService} from "src/app/pages/meetings/services/meeting.service";
import {Subscription} from "rxjs";
import {BrowserModule} from "@angular/platform-browser";
import {DateTimePickerComponent} from "smart-webcomponents-angular/datetimepicker";
import {HouseService} from "../services/house.service";
import {ButtonModule} from "smart-webcomponents-angular/button";
import {WindowModule} from "smart-webcomponents-angular/window";
import {CommonModule} from "@angular/common";


// @ts-ignore
@Component({
    selector: 'current-house',
    templateUrl: 'current-house.component.html',
    providers: [BrowserModule, ButtonModule, WindowModule, CommonModule],
    styleUrls: ['current-house.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class CurrentHouseComponent implements AfterViewInit, OnDestroy, OnInit {
    @ViewChild('datetimepickerBegin', {
        read: DateTimePickerComponent,
        static: false
    }) datetimepickerBegin!: DateTimePickerComponent;
    @ViewChild('datetimepickerEnd', {
        read: DateTimePickerComponent,
        static: false,
    }) datetimepickerEnd!: DateTimePickerComponent;

    // Переменная отвечающая за подписку
    subscription1$: Subscription;
    subscription2$: Subscription;
    subscription3$: Subscription;
    subscriptions: Subscription[] = [];

    mSubRouter: Subscription

    // Переменная отвечающая за подписку
    mSub: Subscription

    isLoaded:boolean=false;

    constructor(
        public meetingService: MeetingService,
        public houseService: HouseService,
    ) {
        Object.assign(CurrentHouseComponent, {
            meetingService,
        });
    }

    /**
     * Запускается 1 на странице перед выводом визуала
     */
    ngOnInit() {
        console.log('Запустился ngOnInit()');

    }

    /**
     * Очищаем подписку на стрим чтобы не было утечек по памяти
     */
    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());

        if (this.mSubRouter) {
            this.mSubRouter.unsubscribe();
        }
    }

    /**
     * Запускается 3 - им
     */
    init(): void {
        console.log(this.isLoaded)
    }

    /**
     * Св-во нужно для инициализации. Для интерфейса. Нужен для норм работы
     */
    onInit(): void {
        console.log('Запустился onInit()');
    };

    /**
     * Запускается 2 на странице после ngOnInit().
     */
    ngAfterViewInit() {
        console.log('Loading ngAfterViewInit current-create-meeting');
    }


}
