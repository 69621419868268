<div class="container typography">
    <div class="custom-row">
        <div class="typography-header custom-col">
            <h4>Typography</h4>
        </div>
    </div>

    <div class="custom-row">
        <div class="typography-entity custom-col">
            <div class="custom-card">
                <div class="card-header">
                    <h4 class="label">Headings</h4>
                </div>
                <div class="card-body">
                    <h6>HTML headings are titles or subtitles that may be displayed on a webpage.</h6>
                    <h1>This is a heading h1</h1>
                    <p>Ultricies mi eget mauris pharetra et ultrices neque ornare. Ut etiam sit amet nisl purus in
                        mollis nunc sed.</p>
                    <h2>This is a heading h2</h2>
                    <p>Malesuada pellentesque elit eget gravida cum sociis natoque penatibus.</p>
                    <h3>This is a heading h3</h3>
                    <p>Enim praesent elementum facilisis leo vel fringilla. Dictum sit amet justo donec. Morbi enim nunc
                        faucibus a pellentesque.</p>
                    <h4>This is a heading h4</h4>
                    <p>Bibendum arcu vitae elementum curabitur vitae nunc sed velit dignissim.</p>
                    <h5>This is a heading h5</h5>
                    <p>Eget sit amet tellus cras. Sed risus ultricies tristique nulla aliquet enim tortor.</p>
                    <h6>This is a heading h6</h6>
                    <p>Odio morbi quis commodo odio aenean. Fringilla phasellus faucibus scelerisque eleifend donec
                        pretium vulputate sapien nec.</p>
                </div>
            </div>

            <div class="custom-card">
                <div class="card-header">
                    <h4 class="label">Colored text</h4>
                </div>

                <div class="card-body">
                    <p class="text-primary">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    <p class="text-secondary">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    <p class="text-success">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    <p class="text-danger">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    <p class="text-warning">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    <p class="text-info">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                </div>
            </div>
        </div>

        <div class="typography-entity custom-col">
            <div class="custom-card">
                <div class="card-header">
                    <h4 class="label">Inline text styling with tags</h4>
                </div>
                <div class="card-body">
                    <p>
                        <mark>Text highlighted with the mark tag.</mark>
                    </p>
                    <p>
                        <ins>Text underlined with the ins tag.</ins>
                    </p>
                    <p>
                        <del>Text struck through with the del tag.</del>
                    </p>
                    <p><em>Text italicized with the em tag.</em></p>
                    <p><strong>Bold text with the strong tag.</strong></p>
                </div>
            </div>

            <div class="custom-card">
                <div class="card-header">
                    <h4 class="label">Blockquote</h4>
                </div>
                <div class="card-body">
                    <h5>The blockquote tag specifies a section that is quoted from another source.</h5>
                    <blockquote>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                        incididunt ut labore et dolore magna aliqua. Leo a diam sollicitudin tempor id eu nisl.
                    </blockquote>
                </div>
            </div>

            <div class="custom-card">
                <div class="card-header">
                    <h4 class="label">Ordered list</h4>
                </div>
                <div class="card-body">
                    <ol>
                        <li>magna etiam</li>
                        <li>tempor orci</li>
                        <li>eu lobortis</li>
                        <li>labore et</li>
                        <li>dolore magna</li>
                        <li>tempor id eu</li>
                    </ol>
                </div>
            </div>

            <div class="custom-card">
                <div class="card-header">
                    <h4 class="label">Unordered list</h4>
                </div>
                <div class="card-body">
                    <ul>
                        <li>magna etiam</li>
                        <li>tempor orci</li>
                        <li>eu lobortis</li>
                        <li>labore et</li>
                        <li>dolore magna</li>
                        <li>tempor id eu</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>