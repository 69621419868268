import {Component, OnInit} from '@angular/core';
import {UserService} from '../services/user.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})

/**
 * Наш домашний компонент будет использовать UserService для получения общедоступных ресурсов из серверной части.
 */
export class HomeComponent implements OnInit {
    content?: string;

    constructor(private userService: UserService) {
    }

    ngOnInit(): void {
        this.userService.getPublicContent().subscribe({
            next: data => {
                this.content = data;
            },
            error: err => {
                if (err.error) {
                    try {
                        const res = JSON.parse(err.error);
                        this.content = res.message;
                    } catch {
                        this.content = `Error with status: ${err.status} - ${err.statusText}`;
                    }
                } else {
                    this.content = `Error with status: ${err.status}`;
                }
            }
        });
    }
}