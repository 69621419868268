<div class="container basic-inputs">
    <div class="custom-row">
        <div class="basic-inputs-header custom-col"><h4>Basic Inputs</h4></div>
    </div>

    <div class="custom-row">
        <div class="custom-col">
            <div class="custom-card">
                <div class="card-header">
                    <h4 class="label">Input</h4>
                </div>
                <div class="card-body">
                    <smart-input class="basic-input" [placeholder]="'Input'"></smart-input>
                </div>
            </div>

            <div class="custom-card">
                <div class="card-header">
                    <h4 class="label">Textarea</h4>
                </div>
                <div class="card-body">
                    <smart-text-area class="basic-input" [placeholder]="'Textarea'"></smart-text-area>
                </div>
            </div>

            <div class="custom-card">
                <div class="card-header">
                    <h4 class="label">Checkboxes</h4>
                </div>
                <div class="card-body">
                    <div class="options-container">
                        <smart-check-box>Checkbox 1</smart-check-box>
                        <smart-check-box [checked]="true">Checkbox 2</smart-check-box>
                        <smart-check-box [disabled]="true">Disabled checkbox 3</smart-check-box>
                        <smart-check-box [checked]="true" [disabled]="true">Disabled checkbox 4</smart-check-box>
                    </div>

                </div>
            </div>

            <div class="custom-card">
                <div class="card-header">
                    <h4 class="label">Radio Buttons</h4>
                </div>
                <div class="card-body">
                    <div class="options-container">
                        <smart-radio-button>Radio button 1</smart-radio-button>
                        <smart-radio-button [checked]="true">Radio button 2</smart-radio-button>
                        <smart-radio-button [disabled]="true">Disabled radio button 3</smart-radio-button>
                    </div>
                </div>
            </div>

            <div class="custom-card">
                <div class="card-header">
                    <h4 class="label">Switch Buttons</h4>
                </div>
                <div class="card-body">
                    <div class="options-container">
                        <smart-switch-button></smart-switch-button>
                        <smart-switch-button [checked]="true"></smart-switch-button>
                        <smart-switch-button [disabled]="true"></smart-switch-button>
                    </div>
                </div>
            </div>
        </div>

        <div class="custom-col">
            <div class="custom-card">
                <div class="card-header">
                    <h4 class="label">Color Input</h4>
                </div>
                <div class="card-body">
                    <smart-color-input class="basic-input" value="#85AA4C"></smart-color-input>
                </div>
            </div>

            <div class="custom-card">
                <div class="card-header">
                    <h4 class="label">Date and Time Inputs</h4>
                </div>
                <div class="card-body">
                    <smart-date-input class="basic-input"></smart-date-input>
                    <smart-time-input class="basic-input"></smart-time-input>
                </div>
            </div>

            <div class="custom-card">
                <div class="card-header">
                    <h4 class="label">Selects</h4>
                </div>
                <div class="card-body">
                    <smart-input class="basic-input" data-source='["pellentesque gravida", "neque id rutrum", "sit amet", "tristique", "cras consectetur"]' drop-down-button-position="right" value="sit amet"></smart-input>
                    <smart-list-box class="basic-input">
                        <smart-list-item>pellentesque gravida</smart-list-item>
                        <smart-list-item>neque id rutrum</smart-list-item>
                        <smart-list-item [selected]="true">sit amet</smart-list-item>
                        <smart-list-item>tristique</smart-list-item>
                        <smart-list-item>cras consectetur</smart-list-item>
                    </smart-list-box>
                </div>
            </div>

            <div class="custom-card">
                <div class="card-header">
                    <h4 class="label">Disabled</h4>
                </div>
                <div class="card-body">
                    <smart-input class="basic-input" [disabled]="true" placeholder="Disabled input"></smart-input>
                    <smart-input class="basic-input" [disabled]="true" drop-down-button-position="right" placeholder="Disabled select"></smart-input>
                    <smart-check-box class="basic-input" [disabled]="true">Disabled checkbox</smart-check-box>
                </div>
            </div>

            <div class="custom-card">
                <div class="card-header">
                    <h4 class="label">Readonly</h4>
                </div>
                <div class="card-body">
                    <smart-input class="basic-input" [readonly]="true" placeholder="Readonly input"></smart-input>
                </div>
            </div>
        </div>
    </div>
</div>