<div class="container invoice">
    <div class="custom-row">
        <div class="invoice-header custom-col">
            <h4>Invoice Preview</h4>
        </div>
    </div>

    <div class="custom-card">
        <div class="card-body">
            <div class="invoice">
                <div class="invoice-info-wrapper custom-row">
                    <div class="invoice-info custom-col">
                        <div class="invoice-info-header">
                            <h5 class="">Invoice #0000045</h5>
                            <h6>Order #56</h6>
                        </div>
                        <hr>
                        <div class="invoice-info-row custom-row">
                            <div class="custom-col">
                                <address>
                                    <strong>Billed To:</strong><br>
                                    John Smith<br>
                                    1234 Main<br>
                                    Apt. 4B<br>
                                    Springfield, ST 54321
                                </address>
                            </div>
                            <div class="custom-col">
                                <address>
                                    <strong>Shipped To:</strong><br>
                                    Jane Smith<br>
                                    1234 Main<br>
                                    Apt. 4B<br>
                                    Springfield, ST 54321
                                </address>
                            </div>
                        </div>
                        <div class="invoice-info-row custom-row">
                            <div class="custom-col">
                                <address>
                                    <strong>Payment Method:</strong><br>
                                    Visa ending **** 4242<br>
                                    jsmith@email.com
                                </address>
                            </div>
                            <div class="custom-col">
                                <address>
                                    <strong>Order Date:</strong><br>
                                    March 7, 2014<br><br>
                                </address>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="invoice-summary-wrapper custom-row">
                    <div class="custom-col">
                        <h5 class="">Order summary</h5>

                        <div class="table-responsive">
                            <smart-table class="invoice-table no-border"
                                [dataSource]="invoiceTableData.dataSource" [tooltip]="true"
                                [columns]="invoiceTableData.columns"></smart-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="printContainer">
        <smart-button class="primary">Print invoice</smart-button>
    </div>
</div>