<div class="container">
    <div class="custom-row">
        <div class="dashboard1-header-menu">
            <div class="welcome">Welcome back, John!</div>
            <smart-button #todayButton (click)="toggleMenu(todayButton)" (pointerup)="stopPropagation($event)"
                class="today-button settings-button">
                <span class="material-icons">calendar_today</span>
                Today
                <span class="material-icons">keyboard_arrow_down</span>
            </smart-button>
            <smart-button class="primary">
                <span class="material-icons">filter_alt</span>
            </smart-button>
            <smart-button class="primary">
                <span class="material-icons">refresh</span>
            </smart-button>
        </div>
    </div>

    <div class="dashboard1-cards custom-row">
        <div class="custom-col medium-4">
            <div class="custom-card h-100">
                <div class="card-body">
                    <div><span class="material-icons text-primary">shopping_cart</span></div>
                    <div>
                        <h5 class="font-weight-bold">218</h5>
                        <h6 class="">Sales Today</h6>
                    </div>
                </div>
            </div>
        </div>

        <div class="custom-col medium-4">
            <div class="custom-card h-100">
                <div class="card-body">
                    <div><span class="material-icons text-warning">show_chart</span></div>
                    <div>
                        <h5 class="font-weight-bold">4.138</h5>
                        <h6 class="">Visitors Today</h6>
                    </div>
                </div>
            </div>
        </div>

        <div class="custom-col medium-4">
            <div class="custom-card h-100">
                <div class="card-body">
                    <div><span class="material-icons text-success">attach_money</span></div>
                    <div>
                        <h5 class="font-weight-bold">109.783</h5>
                        <h6 class="">Total Earnings</h6>
                    </div>
                </div>
            </div>
        </div>

        <div class="custom-col medium-6">
            <div class="custom-card h-100">
                <div class="card-body">
                    <div><span class="material-icons text-info">add_shopping_cart</span></div>
                    <div>
                        <h5 class="font-weight-bold">7</h5>
                        <h6 class="">Pending Orders</h6>
                    </div>
                </div>
            </div>
        </div>

        <div class="custom-col medium-6">
            <div class="custom-card h-100">
                <div class="card-body">
                    <div><span class="material-icons text-danger">attach_money</span></div>
                    <div>
                        <h5 class="font-weight-bold">86.002</h5>
                        <h6 class="">Total Revenue</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="dashboard1-revenue-feed custom-row">
        <div class="dashboard1-revenue-wrapper custom-col">
            <div class="custom-card h-100">
                <div class="card-header">
                    <h4 class="label">Total Revenue</h4>
                    <div class="smart-badge smart-badge-primary smart-badge-pill">Monthly</div>
                </div>
                <div class="card-body">
                    <smart-chart #revenueChart class="dashboard1-revenue-chart" [animation]="'none'" [theme]="theme!"
                        [caption]="'Caption'" [description]="'Description'" [showLegend]="true" [showBorderLine]="true"
                        [padding]="revenueChartData.padding" [dataSource]="revenueChartData.dataSource"
                        [xAxis]="revenueChartData.xAxis" [seriesGroups]="revenueChartData.seriesGroups"
                        [colorScheme]="'custom'"></smart-chart>
                </div>
            </div>
        </div>

        <div class="dashboard1-feed-wrapper custom-col">
            <div class="custom-card h-100">
                <div class="card-header">
                    <h4 class="label">Daily feed</h4>
                    <div class="smart-badge smart-badge-primary smart-badge-pill">Today</div>
                </div>

                <div class="card-body">
                    <div class="feeds">
                        <div class="feed custom-row">
                            <div class="feed-image-wrapper">
                                <div class="img-as-background">
                                    <img src="../../../assets/images/phonebook/michael.png" />
                                </div>
                            </div>

                            <div class="feed-text-wrapper">
                                <div class=""><strong>Frederick Murphy</strong> reacted to your post</div>
                                <small class="text-muted">Today 8:19 PM</small>
                            </div>

                            <div class="feed-image-wrapper">
                                <small class="w-auto text-muted">7 min ago</small>
                            </div>
                        </div>

                        <div class="feed custom-row">
                            <div class="feed-image-wrapper">
                                <div class="img-as-background">
                                    <img src="../../../assets/images/phonebook/maria.png" />
                                </div>
                            </div>

                            <div class="feed-text-wrapper">
                                <div class=""><strong>Elisha Scott</strong> is now engaged</div>
                                <small class="text-muted">Today 10:45 PM</small>
                            </div>

                            <div class="feed-posted-ago-wrapper">
                                <small class="w-auto text-muted">10 hours ago</small>
                            </div>
                        </div>

                        <div class="feed custom-row">
                            <div class="feed-image-wrapper">
                                <div class="img-as-background">
                                    <img src="../../../assets/images/phonebook/michael.png" />
                                </div>
                            </div>

                            <div class="feed-text-wrapper">
                                <div class=""><strong>John Doe</strong> is now engaged</div>
                                <small class="text-muted">Today 12:03 PM</small>
                            </div>

                            <div class="feed-posted-ago-wrapper">
                                <small class="w-auto text-muted">8 hours ago</small>
                            </div>
                        </div>
                    </div>

                    <smart-button id="dashboard1-loadMore" class="primary">Load more</smart-button>
                </div>
            </div>
        </div>
    </div>

    <div class="dashboard1-calendar-weekly-appointments custom-row">
        <div class="dashboard1-calendar-wrapper custom-col">
            <div class="custom-card h-100">
                <div class="card-header">
                    <h4 class="label">Calendar</h4>
                    <div class="settings-button" #calendarButton (click)="toggleMenu(calendarButton)"
                        (pointerup)="stopPropagation($event)">…</div>
                </div>
                <div class="card-body">
                    <smart-calendar id="calendar"></smart-calendar>
                </div>
            </div>
        </div>

        <div class="dashboard1-weekly-sales-wrapper custom-col">
            <div class="custom-card h-100">
                <div class="card-header">
                    <h4 class="label">Weekly sales</h4>
                    <div class="settings-button" #weeklySalesButton (click)="toggleMenu(weeklySalesButton)"
                        (pointerup)="stopPropagation($event)">…
                    </div>
                </div>
                <div class="card-body">
                    <smart-chart #weeklySalesChart class="dashboard1-weekly-sales-chart" [animation]="'none'"
                        [theme]="theme!" [caption]="'Caption'" [description]="'Description'" [showLegend]="true"
                        [showBorderLine]="true" [padding]="weeklySalesChartData.padding"
                        [dataSource]="weeklySalesChartData.dataSource"
                        [seriesGroups]="weeklySalesChartData.seriesGroups" [colorScheme]="'custom'">
                    </smart-chart>
                    <smart-table #weeklySalestable class="dashboard1-weekly-sales-table"
                        [dataSource]="weeklySalesTableData.dataSource" [columns]="weeklySalesTableData.columns">
                    </smart-table>
                </div>
            </div>
        </div>

        <div class="dashboard1-appointments-wrapper custom-col">
            <div class="custom-card h-100">
                <div class="card-header">
                    <h4 class="label">Appointments</h4>
                    <div class="settings-button" #appointmentsButton (click)="toggleMenu(appointmentsButton)"
                        (pointerup)="stopPropagation($event)">
                        …</div>
                </div>
                <div class="card-body">
                    <div class="appointments">
                        <div class="appointment">
                            <div class="label"><strong>Contact Sales</strong><span>14:00</span></div>
                            <p class="">Proin sagittis nisl diam, in pretium velit congue et.</p>
                        </div>
                        <div class="appointment">
                            <div class="label"><strong>Meet with new client</strong><span>15:20</span></div>
                            <p class="">Donec sodales, tellus at facilisis commodo, lectus lectus pharetra neque, at
                                condimentum augue diam vitae massa.
                            </p>
                        </div>
                        <div class="appointment">
                            <div class="label"><strong>Dinner with manager</strong><span>19:00</span></div>
                            <p class="">Aenean facilisis mi ac vestibulum vestibulum.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="dashboard1-projects-sales custom-row">
        <div class="dashboard1-projects-wrapper custom-col">
            <div class="custom-card h-100">
                <div class="card-header">
                    <h4 class="label">Latest Projects</h4>
                    <div class="settings-button" #latestProjectsButton (click)="toggleMenu(latestProjectsButton)"
                        (pointerup)="stopPropagation($event)">…
                    </div>
                </div>
                <div class="card-body">
                    <smart-table #projectsTable class="dashboard1-projects-table"
                        [dataSource]="projectsTableData.dataSource" [freezeHeader]="true" [paging]="true"
                        [sortMode]="'many'" [tooltip]="true" [columns]="projectsTableData.columns"></smart-table>
                </div>
            </div>
        </div>

        <div class="dashboard1-sales-comparison-wrapper custom-col">
            <div class="custom-card h-100">
                <div class="card-header">
                    <h4 class="label">Sales Comparison</h4>
                    <div class="settings-button" #salesComparisonButton
                        (click)="toggleMenu(salesComparisonButton)" (pointerup)="stopPropagation($event)">…
                    </div>
                </div>
                <div class="card-body">
                    <smart-chart #salesComparisonChart class="dashboard1-sales-comparison-chart" [animation]="'none'"
                        [theme]="theme!" [caption]="'Caption'" [description]="'Description'" [showLegend]="true"
                        [showBorderLine]="true" [padding]="salesComparisonChartData.padding"
                        [dataSource]="salesComparisonChartData.dataSource" [xAxis]="salesComparisonChartData.xAxis"
                        [colorScheme]="salesComparisonChartData.colorScheme"
                        [seriesGroups]="salesComparisonChartData.seriesGroups"></smart-chart>
                </div>
            </div>
        </div>
    </div>
</div>