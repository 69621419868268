<div class="container charts">
    <div class="custom-row">
        <div class="charts-header custom-col">
            <h4>Charts</h4>
        </div>
    </div>

    <div class="custom-row">
        <div class="charts-entity-lg-6">
            <div class="custom-card">
                <div class="card-header">
                    <h4 class="label">Line Chart</h4>
                </div>
                <div class="card-body">
                    <smart-chart #lineChart [animation]="lineChartData.animation" [theme]="theme!"
                        [caption]="lineChartData.caption" [description]="'Description'"
                        [showLegend]="lineChartData.showLegend" [showBorderLine]="lineChartData.showBorderLine"
                        [padding]="lineChartData.padding" [dataSource]="lineChartData.dataSource"
                        [xAxis]="lineChartData.xAxis" [seriesGroups]="lineChartData.seriesGroups"
                        [colorScheme]="'custom'"></smart-chart>
                </div>
            </div>
        </div>

        <div class="charts-entity-lg-6">
            <div class="custom-card">
                <div class="card-header">
                    <h4 class="label">Column Chart</h4>
                </div>
                <div class="card-body">
                    <smart-chart #columnChart [animation]="columnChartData.animation" [theme]="theme!"
                        [caption]="columnChartData.caption" [description]="'Description'"
                        [showLegend]="columnChartData.showLegend" [showBorderLine]="columnChartData.showBorderLine"
                        [padding]="columnChartData.padding" [dataSource]="columnChartData.dataSource"
                        [xAxis]="columnChartData.xAxis" [seriesGroups]="columnChartData.seriesGroups"
                        [colorScheme]="'custom'"></smart-chart>
                </div>
            </div>
        </div>
    </div>

    <div class="custom-row">
        <div class="charts-entity-lg-6">
            <div class="custom-card h-100">
                <div class="card-header">
                    <h4 class="label">Donut Chart</h4>
                </div>
                <div class="card-body">
                    <smart-chart #donutChart [animation]="donutChartData.animation" [theme]="theme!"
                        [caption]="donutChartData.caption" [description]="'Description'"
                        [showLegend]="donutChartData.showLegend" [showBorderLine]="donutChartData.showBorderLine"
                        [padding]="donutChartData.padding" [dataSource]="donutChartData.dataSource"
                        [seriesGroups]="donutChartData.seriesGroups" [colorScheme]="'custom'"></smart-chart>
                </div>
            </div>
        </div>

        <div class="charts-entity-lg-6">
            <div class="custom-card h-100">
                <div class="card-header">
                    <h4 class="label">Pie Chart</h4>
                </div>
                <div class="card-body">
                    <smart-chart #pieChart [animation]="pieChartData.animation" [theme]="theme!"
                        [caption]="pieChartData.caption" [description]="'Description'"
                        [showLegend]="pieChartData.showLegend" [showBorderLine]="pieChartData.showBorderLine"
                        [padding]="pieChartData.padding" [dataSource]="pieChartData.dataSource"
                        [seriesGroups]="pieChartData.seriesGroups" [colorScheme]="'custom'"></smart-chart>
                </div>
            </div>
        </div>
    </div>

    <div class="custom-row">
        <div class="charts-entity-lg-6">
            <div class="custom-card h-100">
                <div class="card-header">
                    <h4 class="label">Spider Chart</h4>
                </div>
                <div class="card-body">
                    <smart-chart #spiderChart [animation]="spiderChartData.animation" [theme]="theme!"
                        [caption]="spiderChartData.caption" [description]="'Description'"
                        [showLegend]="spiderChartData.showLegend" [showBorderLine]="spiderChartData.showBorderLine"
                        [padding]="spiderChartData.padding" [dataSource]="spiderChartData.dataSource"
                        [xAxis]="spiderChartData.xAxis" [seriesGroups]="spiderChartData.seriesGroups"
                        [colorScheme]="'custom'"></smart-chart>
                </div>
            </div>
        </div>
        <div class="charts-entity-lg-6">
            <div class="custom-card h-100">
                <div class="card-header">
                    <h4 class="label">Polar Chart</h4>
                </div>
                <div class="card-body">
                    <smart-chart #polarChart [animation]="polarChartData.animation" [theme]="theme!"
                        [caption]="polarChartData.caption" [description]="'Description'"
                        [showLegend]="polarChartData.showLegend" [showBorderLine]="polarChartData.showBorderLine"
                        [padding]="polarChartData.padding" [dataSource]="polarChartData.dataSource"
                        [xAxis]="polarChartData.xAxis" [seriesGroups]="polarChartData.seriesGroups"
                        [colorScheme]="'custom'"></smart-chart>
                </div>
            </div>
        </div>
    </div>
</div>