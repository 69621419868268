<div class="container buttons">
    <div class="custom-row">
        <div class="buttons-header custom-col"><h4>Buttons</h4></div>
    </div>

    <div class="custom-row">
        <div class="buttons-button-type custom-col">
            <div class="custom-card">
                <div class="card-header">
                    <h4 class="label">Basic Buttons</h4>
                </div>
                <div class="card-body">
                    <div class="button-container">
                        <smart-button>Button</smart-button>
                        <smart-button class="primary">Primary</smart-button>
                        <smart-button class="secondary">Secondary</smart-button>
                    </div>
                    <div class="button-container">
                        <smart-button class="success">Success</smart-button>
                        <smart-button class="error">Error</smart-button>
                    </div>
                </div>
            </div>
        </div>

        <div class="buttons-button-type custom-col">
            <div class="custom-card">
                <div class="card-header">
                    <h4 class="label">Rounded Buttons</h4>
                </div>
                <div class="card-body">
                    <div class="button-container">
                        <smart-button class="rounded">Button</smart-button>
                        <smart-button class="rounded primary">Primary</smart-button>
                        <smart-button class="rounded secondary">Secondary</smart-button>
                    </div>
                    <div class="button-container">
                        <smart-button class="rounded success">Success</smart-button>
                        <smart-button class="rounded error">Error</smart-button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="custom-row">
        <div class="buttons-button-type custom-col">
            <div class="custom-card">
                <div class="card-header">
                    <h4 class="label">Outlined Buttons</h4>
                </div>
                <div class="card-body">
                    <div class="button-container">
                        <smart-button class="outlined">Button</smart-button>
                        <smart-button class="primary outlined">Primary</smart-button>
                        <smart-button class="secondary outlined">Secondary</smart-button>
                    </div>
                    <div class="button-container">
                        <smart-button class="success outlined">Success</smart-button>
                        <smart-button class="error outlined">Error</smart-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="buttons-button-type custom-col">
            <div class="custom-card">
                <div class="card-header">
                    <h4 class="label">Square Buttons</h4>
                </div>
                <div class="card-body">
                    <div class="button-container">
                        <smart-button class="squared">Button</smart-button>
                        <smart-button class="squared primary">Primary</smart-button>
                        <smart-button class="squared secondary">Secondary</smart-button>
                    </div>
                    <div class="button-container">
                        <smart-button class="squared success">Success</smart-button>
                        <smart-button class="squared error">Error</smart-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="custom-row">
        <div class="buttons-button-type custom-col">
            <div class="custom-card">
                <div class="card-header">
                    <h4 class="label">Icon Buttons</h4>
                </div>
                <div class="card-body">
                    <div class="button-container icon-text">
                        <smart-button class="icon"><span class="material-icons">alarm</span>Button</smart-button>
                        <smart-button class="primary icon"><span class="material-icons">explore</span>Primary</smart-button>
                        <smart-button class="secondary icon"><span class="material-icons">build_circle</span>Secondary</smart-button>
                    </div>
                    <div class="button-container icon-text">
                        <smart-button class="success icon"><span class="material-icons">eco</span>Success</smart-button>
                        <smart-button class="error icon"><span class="material-icons">highlight_off</span>Error</smart-button>
                    </div>
                    <div class="button-container">
                        <smart-button class="icon"><span class="material-icons">alarm</span></smart-button>
                        <smart-button class="primary icon"><span class="material-icons">explore</span></smart-button>
                        <smart-button class="secondary icon"><span class="material-icons">build_circle</span>
                        </smart-button>
                    </div>
                    <div class="button-container">
                        <smart-button class="success icon"><span class="material-icons">eco</span></smart-button>
                        <smart-button class="error icon"><span class="material-icons">highlight_off</span></smart-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="buttons-button-type custom-col">
            <div class="custom-card">
                <div class="card-header">
                    <h4 class="label">Social Network Buttons</h4>
                </div>
                <div class="card-body">
                    <div class="button-container icon-text">
                        <smart-button class="primary icon"><img src="https://img.icons8.com/material/24/000000/facebook-new.png"/>Facebook</smart-button>
                        <smart-button class="primary icon twitter"><img src="https://img.icons8.com/material/24/000000/twitter--v1.png"/>Twitter</smart-button>
                        <smart-button class="primary icon reddit"><img src="https://img.icons8.com/material/24/000000/reddit--v1.png"/>Reddit</smart-button>
                    </div>
                    <div class="button-container icon-text">
                        <smart-button class="primary icon youtube"><img src="https://img.icons8.com/material/24/000000/youtube--v1.png"/>YouTube</smart-button>
                        <smart-button class="primary icon instagram"><img src="https://img.icons8.com/material/24/000000/instagram-new.png"/>Instagram</smart-button>
                    </div>
                    <div class="button-container">
                        <smart-button class="primary icon"><img src="https://img.icons8.com/material/24/000000/facebook-new.png"/></smart-button>
                        <smart-button class="primary icon twitter"><img src="https://img.icons8.com/material/24/000000/twitter--v1.png"/></smart-button>
                        <smart-button class="primary icon reddit"><img src="https://img.icons8.com/material/24/000000/reddit--v1.png"/></smart-button>
                    </div>
                    <div class="button-container">
                        <smart-button class="primary icon youtube"><img src="https://img.icons8.com/material/24/000000/youtube--v1.png"/></smart-button>
                        <smart-button class="primary icon instagram"><img src="https://img.icons8.com/material/24/000000/instagram-new.png"/></smart-button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="custom-row">
        <div class="buttons-button-type custom-col">
            <div class="custom-card">
                <div class="card-header">
                    <h4 class="label">Button Sizes</h4>
                </div>
                <div class="card-body">
                    <div class="button-container">
                        <smart-button class="primary very-small">Very small</smart-button>
                        <smart-button class="primary small">Small</smart-button>
                        <smart-button class="primary large">Large</smart-button>
                    </div>
                    <div class="button-container icon-text">
                        <smart-button class="primary icon very-small"><span class="material-icons">zoom_in</span>Very small
                        </smart-button>
                        <smart-button class="primary icon small"><span class="material-icons">zoom_in</span>Small</smart-button>
                        <smart-button class="primary icon large"><span class="material-icons">zoom_in</span>Large</smart-button>
                    </div>
                </div>
            </div>
        </div>

        <div class="buttons-button-type custom-col">
            <div class="custom-card">
                <div class="card-header">
                    <h4 class="label">Flat Buttons</h4>
                </div>
                <div class="card-body">
                    <div class="button-container">
                        <smart-button class="flat">Button</smart-button>
                        <smart-button class="primary flat">Primary</smart-button>
                        <smart-button class="secondary flat">Secondary</smart-button>
                    </div>
                    <div class="button-container">
                        <smart-button class="success flat">Success</smart-button>
                        <smart-button class="error flat">Error</smart-button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="custom-row">
        <div class="buttons-button-type custom-col">
            <div class="custom-card">
                <div class="card-header">
                    <h4 class="label">Dropdown Button</h4>
                </div>
                <div class="card-body">
                    <div class="button-container">
                        <smart-drop-down-button placeholder="Dropdown Button" drop-down-append-to="body">
                            Nullam ut vulputate orci. Nunc sit amet augue libero. Nullam tincidunt vel nunc eget pulvinar.
                            Etiam a sem in ligula suscipit auctor. Aliquam placerat efficitur urna mollis tincidunt.
                            Pellentesque dignissim imperdiet lectus a facilisis. Maecenas ipsum mi, congue dapibus ante et,
                            maximus pretium justo.
                        </smart-drop-down-button>
                    </div>
                </div>
            </div>
        </div>

        <div class="buttons-button-type custom-col">
            <div class="custom-card">
                <div class="card-header">
                    <h4 class="label">Button Group</h4>
                </div>
                <div class="card-body">
                    <div class="button-container">
                        <smart-button-group data-source='["Left", "Middle", "Right"]'></smart-button-group>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>