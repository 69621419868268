<div class="container crypto-dashboard">
    <div class="custom-row">
        <div class="custom-col">
            <div class="header-menu">
                <div class="welcome">Welcome back, John!</div>
                <smart-button class="today-button settings-button" #todayButton (click)="toggleMenu(todayButton)"
                    (pointerup)="stopPropagation($event)"><span class="material-icons">calendar_today</span>Today<span
                        class="material-icons">keyboard_arrow_down</span></smart-button>
                <smart-button class="primary"><span class="material-icons">filter_alt</span></smart-button>
                <smart-button class="primary"><span class="material-icons">refresh</span></smart-button>
            </div>
        </div>
    </div>

    <div class="custom-row cards-row">
        <div class="custom-col col-entity-md-6 col-entity-xl-3">
            <div class="custom-card h-100">
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center card-heading">
                        <h5 class="font-weight-bold mb-0">BTC/USDT</h5>
                        <span class="text-danger">-0.37%</span>
                    </div>
                    <p class=" mb-0">10688.27</p>
                    <p class="mb-0">Volume: 240611229</p>
                </div>
            </div>
        </div>

        <div class="custom-col col-entity-md-6 col-entity-xl-3">
            <div class="custom-card h-100">
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center card-heading">
                        <h5 class="font-weight-bold mb-0">BCH/USDT</h5>
                        <span class="text-warning">0.4%</span>
                    </div>
                    <p class=" mb-0">227.30</p>
                    <p class="mb-0">Volume: 21193756</p>
                </div>
            </div>
        </div>

        <div class="custom-col col-entity-md-6 col-entity-xl-3">
            <div class="custom-card h-100">
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center card-heading">
                        <h5 class="font-weight-bold mb-0">LTC/BTC</h5>
                        <span class="text-success">1.18%</span>
                    </div>
                    <p class=" mb-0">0.0042867</p>
                    <p class="mb-0">Volume: 9359876</p>
                </div>
            </div>
        </div>

        <div class="custom-col col-entity-md-6 col-entity-xl-3">
            <div class="custom-card h-100">
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center card-heading">
                        <h5 class="font-weight-bold mb-0">ETH/BTC</h5>
                        <span class="text-success">0.22%</span>
                    </div>
                    <p class=" mb-0">0.033165</p>
                    <p class="mb-0">Volume: 8546909</p>
                </div>
            </div>
        </div>
    </div>

    <div class="custom-row markets-btc-chart-row">
        <div class="custom-col col-entity-lg-6">
            <div class="custom-card h-100">
                <div class="card-header">
                    <h4 class="label">Markets</h4>
                    <div class="settings-button" #marketsButton (click)="toggleMenu(marketsButton)"
                        (pointerup)="stopPropagation($event)">…</div>
                </div>
                <div class="card-body">
                    <smart-table id="currencyTable" class="crypto-table no-border table-striped maxh-350 currency-table"
                        [dataSource]="currencyTableData.dataSource" [columns]="currencyTableData.columns"
                        [sortMode]="currencyTableData.sortMode">
                    </smart-table>
                </div>
            </div>
        </div>

        <div class="custom-col col-entity-lg-6">
            <div class="custom-card h-100">
                <div class="card-header">
                    <h5 class="label">BTC/USDT</h5>
                    <div class="settings-button" #btcusdButton (click)="toggleMenu(btcusdButton)"
                        (pointerup)="stopPropagation($event)">…</div>
                </div>
                <div class="card-body">
                    <smart-chart #exchangeChart class="projects-detail-earnings-chart h-100 w-100"
                        [animation]="exchangeChartData.animation" [theme]="exchangeChartData.theme!"
                        [caption]="exchangeChartData.caption" [description]="exchangeChartData.description"
                        [showLegend]="exchangeChartData.showLegend" [showBorderLine]="exchangeChartData.showBorderLine"
                        [enableCrosshairs]="exchangeChartData.enableCrosshairs" [padding]="exchangeChartData.padding"
                        [dataSource]="exchangeChartData.dataSource" [seriesGroups]="exchangeChartData.seriesGroups"
                        [colorScheme]="exchangeChartData.colorScheme" [xAxis]="exchangeChartData.xAxis">
                    </smart-chart>
                </div>
            </div>
        </div>
    </div>

    <div class="custom-row buy-sell-orders-row">
        <div class="custom-col col-entity-lg-6">
            <div class="custom-card h-100">
                <div class="card-header">
                    <h4 class="label">Sell Orders</h4>
                    <div class="settings-button" #sellOrdersButton (click)="toggleMenu(sellOrdersButton)"
                        (pointerup)="stopPropagation($event)">…</div>
                </div>
                <div class="card-body">
                    <smart-table id="sellTable" class="crypto-table no-border table-striped sell-table"
                        [dataSource]="sellTableData.dataSource" [columns]="sellTableData.columns">
                    </smart-table>
                </div>
            </div>
        </div>

        <div class="custom-col col-entity-lg-6">
            <div class="custom-card h-100">
                <div class="card-header">
                    <h4 class="label">Buy Orders</h4>
                    <div class="settings-button" #buyOrdersButton (click)="toggleMenu(buyOrdersButton)"
                        (pointerup)="stopPropagation($event)">…</div>
                </div>
                <div class="card-body">
                    <smart-table id="buyTable" class="crypto-table no-border table-striped buy-table"
                        [dataSource]="buyTableData.dataSource" [columns]="buyTableData.columns">
                    </smart-table>
                </div>
            </div>
        </div>
    </div>
</div>