import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'sm-basic-inputs',
  templateUrl: './basic-inputs.component.html',
  styleUrls: ['./basic-inputs.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BasicInputsComponent {

  constructor() { }

}
