import {Component, ViewEncapsulation} from '@angular/core';
import { MessageService } from './services/message.service';

@Component({
  selector: 'app-messages',
  templateUrl: 'message.component.html',
  styleUrls: ['message.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class MessagesComponent {
  constructor(public messageService: MessageService) {}
}
