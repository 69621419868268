import { Component } from '@angular/core';

@Component({
  selector: 'sm-introduction',
  templateUrl: './introduction.component.html',
  styleUrls: ['./introduction.component.scss']
})
export class IntroductionComponent {

  constructor() { }

}
