import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './routing/app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { PagesModule } from './pages/pages.module';
import { SidebarService } from './services/sidebar.service';
import { SharedModule } from './shared/shared.module';

import { SmartImportsModule } from './smart-imports/smart-imports.module';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DataService } from './services/data.service';

import { TextBoxModule } from 'smart-webcomponents-angular/textbox';
import { ButtonModule } from 'smart-webcomponents-angular/button';

import { HttpClientModule } from '@angular/common/http';
import {MessageService} from "./services/message.service";
import { MessagesComponent } from './messages.component'
import {HttpErrorHandler} from "./services/http-error-handler.service";
import {WindowModule} from "smart-webcomponents-angular/window";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SignInComponent} from "./pages/pages/sign-in/sign-in.component";

// Компоненты для авторизации
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { BoardAdminComponent } from './board-admin/board-admin.component';
import { BoardModeratorComponent } from './board-moderator/board-moderator.component';
import { BoardUserComponent } from './board-user/board-user.component';
import {HttpRequestInterceptor} from "./helpers/http.interceptor";

// Модуль для загрузки файлов
import {FileUploadModule} from "smart-webcomponents-angular/fileupload";


@NgModule({
  declarations: [
    AppComponent,
    MessagesComponent,
    LoginComponent,
    RegisterComponent,
    HomeComponent,
    ProfileComponent,
    BoardAdminComponent,
    BoardModeratorComponent,
    BoardUserComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    SmartImportsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TextBoxModule,
    ButtonModule,
    WindowModule,
    ReactiveFormsModule,
    FormsModule,
    FileUploadModule
  ],
  providers: [
    {
      provide: SidebarService,
      useClass: SidebarService
    }, {
      provide: DataService,
      useClass: DataService
    },
    {
      provide: MessageService,
      useClass: MessageService
    },
    {
      provide: MessageService,
      useClass: MessageService
    },
    {
      provide: HttpErrorHandler,
      useClass: HttpErrorHandler
    },
    {
      provide: WindowModule,
      useClass: WindowModule
    },
    {
      provide: ButtonModule,
      useClass: ButtonModule
    },
    {
      provide: HttpRequestInterceptor,
      useClass: HttpRequestInterceptor
    },
  ],
  bootstrap: [AppComponent] // SignInComponent
})
export class AppModule {
}
