<div class="container dashboard3">
    <div class="custom-row cards-row">
        <div class="custom-col col-entity-md-4">
            <div class="custom-card h-100">
                <div class="card-body">
                    <div><span class="material-icons text-primary">shopping_cart</span></div>
                    <div>
                        <h5 class="font-weight-bold">744</h5>
                        <h6>Sales this Week</h6>
                    </div>
                </div>
            </div>
        </div>

        <div class="custom-col col-entity-md-4">
            <div class="custom-card h-100">
                <div class="card-body">
                    <div><span class="material-icons text-warning">show_chart</span></div>
                    <div>
                        <h5 class="font-weight-bold">52.138</h5>
                        <h6>Visitors this Week</h6>
                    </div>
                </div>
            </div>
        </div>

        <div class="custom-col col-entity-md-4">
            <div class="custom-card h-100">
                <div class="card-body">
                    <div><span class="material-icons text-success">attach_money</span></div>
                    <div>
                        <h5 class="font-weight-bold">409.783</h5>
                        <h6>Earnings this Week</h6>
                    </div>
                </div>
            </div>
        </div>

        <div class="custom-col col-entity-md-6">
            <div class="custom-card h-100">
                <div class="card-body">
                    <div><span class="material-icons text-info">add_shopping_cart</span></div>
                    <div>
                        <h5 class="font-weight-bold">153</h5>
                        <h6>Pending Orders</h6>
                    </div>
                </div>
            </div>
        </div>

        <div class="custom-col col-entity-md-6">
            <div class="custom-card h-100">
                <div class="card-body">
                    <div><span class="material-icons text-danger">attach_money</span></div>
                    <div>
                        <h5 class="font-weight-bold">8.126.002</h5>
                        <h6>Total Revenue</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="custom-row orders-trafic-row">

        <div class="custom-col col-entity-lg-8 orders-col">
            <div class="custom-card h-100">
                <div class="card-header">
                    <div>
                        <h4 class="label">Last Orders</h4>
                        <small class="d-block text-muted">Quick management of the last 10 orders</small>
                    </div>
                    <div>
                        <ul class="nav nav-pills">
                            <li class="nav-item">
                                <a class="nav-link active" data-toggle="tab" href="#">All</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#">Waiting</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#">Completed</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="card-body">
                    <div class="table-responsive" style="height: 375px;">
                        <table class="table orders-table">
                            <thead>
                                <tr>
                                    <th class="p-0 mw-200"></th>
                                    <th class="p-0"></th>
                                    <th class="p-0 mw-125"></th>
                                    <th class="p-0 mw-125"></th>
                                    <th class="p-0 mw-125"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="pl-0 align-middle">
                                        <a href="#" class="font-weight-bold hover-text-primary mb-1">Order #12312</a>
                                        <small class="d-block">
                                            <span class="font-weight-bold">Receiver:</span>&nbsp;<a
                                                class="text-muted font-weight-bold hover-text-primary" href="#">John
                                                Doe</a>
                                        </small>
                                    </td>
                                    <td class="text-right align-middle">
                                        <span class="font-weight-bold d-block">$2,320</span>
                                        <span class="text-muted font-weight-bold">Paid</span>
                                    </td>
                                    <td class="text-right align-middle">
                                        <span class="text-muted font-weight-500">PayPal</span>
                                    </td>
                                    <td class="text-right align-middle">
                                        <span class="smart-badge smart-badge-primary">Approved</span>
                                    </td>
                                    <td class="text-right align-middle pr-0">
                                        <a href="#" class="btn btn-sm btn-outline-danger">
                                            <i class="material-icons">delete</i>
                                        </a>&nbsp;<a href="#" class="btn btn-sm btn-outline-primary">
                                            <i class="material-icons">visibility</i>
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="pl-0 align-middle">
                                        <a href="#" class="font-weight-bold hover-text-primary mb-1">Order #12313</a>
                                        <small class="d-block">
                                            <span class="font-weight-bold">Receiver:</span>&nbsp;<a
                                                class="text-muted font-weight-bold hover-text-primary" href="#">Teresa
                                                Clark</a>
                                        </small>
                                    </td>
                                    <td class="text-right align-middle">
                                        <span class="font-weight-bold d-block">$1,500</span>
                                        <span class="text-warning font-weight-bold">Unpaid</span>
                                    </td>
                                    <td class="text-right align-middle">
                                        <span class="text-muted font-weight-500">Pay on Delivery</span>
                                    </td>
                                    <td class="text-right align-middle">
                                        <span class="smart-badge smart-badge-warning">Waiting</span>
                                    </td>
                                    <td class="text-right align-middle pr-0">
                                        <a href="#" class="btn btn-sm btn-outline-danger">
                                            <i class="material-icons">delete</i>
                                        </a>&nbsp;<a href="#" class="btn btn-sm btn-outline-primary">
                                            <i class="material-icons">visibility</i>
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="pl-0 align-middle">
                                        <a href="#" class="font-weight-bold hover-text-primary mb-1">Order #12314</a>
                                        <small class="d-block">
                                            <span class="font-weight-bold">Receiver:</span>&nbsp;<a
                                                class="text-muted font-weight-bold hover-text-primary" href="#">Elijah
                                                Smith</a>
                                        </small>
                                    </td>
                                    <td class="text-right align-middle">
                                        <span class="font-weight-bold d-block">$338</span>
                                        <span class="text-primary font-weight-bold">Refund</span>
                                    </td>
                                    <td class="text-right align-middle">
                                        <span class="text-muted font-weight-500">Sofort</span>
                                    </td>
                                    <td class="text-right align-middle">
                                        <span class="smart-badge smart-badge-danger">Canceled</span>
                                    </td>
                                    <td class="text-right align-middle pr-0">
                                        <a href="#" class="btn btn-sm btn-outline-danger">
                                            <i class="material-icons">delete</i>
                                        </a>&nbsp;<a href="#" class="btn btn-sm btn-outline-primary">
                                            <i class="material-icons">visibility</i>
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="pl-0 align-middle">
                                        <a href="#" class="font-weight-bold hover-text-primary mb-1">Order #12315</a>
                                        <small class="d-block">
                                            <span class="font-weight-bold">Receiver:</span>&nbsp;<a
                                                class="text-muted font-weight-bold hover-text-primary" href="#">Mark
                                                Thomas</a>
                                        </small>
                                    </td>
                                    <td class="text-right align-middle">
                                        <span class="font-weight-bold d-block">$1,125</span>
                                        <span class="text-muted font-weight-bold">Paid</span>
                                    </td>
                                    <td class="text-right align-middle">
                                        <span class="text-muted font-weight-500">Pay on Delivery</span>
                                    </td>
                                    <td class="text-right align-middle">
                                        <span class="smart-badge smart-badge-secondary">Completed</span>
                                    </td>
                                    <td class="text-right align-middle pr-0">
                                        <a href="#" class="btn btn-sm btn-outline-danger">
                                            <i class="material-icons">delete</i>
                                        </a>&nbsp;<a href="#" class="btn btn-sm btn-outline-primary">
                                            <i class="material-icons">visibility</i>
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="pl-0 align-middle">
                                        <a href="#" class="font-weight-bold hover-text-primary mb-1">Order #12312</a>
                                        <small class="d-block">
                                            <span class="font-weight-bold">Receiver:</span>&nbsp;<a
                                                class="text-muted font-weight-bold hover-text-primary" href="#">John
                                                Doe</a>
                                        </small>
                                    </td>
                                    <td class="text-right align-middle">
                                        <span class="font-weight-bold d-block">$2,320</span>
                                        <span class="text-muted font-weight-bold">Paid</span>
                                    </td>
                                    <td class="text-right align-middle">
                                        <span class="text-muted font-weight-500">PayPal</span>
                                    </td>
                                    <td class="text-right align-middle">
                                        <span class="smart-badge smart-badge-primary">Approved</span>
                                    </td>
                                    <td class="text-right align-middle pr-0">
                                        <a href="#" class="btn btn-sm btn-outline-danger">
                                            <i class="material-icons">delete</i>
                                        </a>&nbsp;<a href="#" class="btn btn-sm btn-outline-primary">
                                            <i class="material-icons">visibility</i>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>


        <div class="custom-col col-entity-lg-4 traffic-col">
            <div class="custom-card h-100">
                <div class="card-header">
                    <h4 class="label">Traffic this month</h4>
                    <div class="settings-button" #trafficButton (click)="toggleMenu(trafficButton)"
                        (pointerup)="stopPropagation($event)">…</div>
                </div>
                <div class="card-body">
                    <div id="sourceChart" class="combo-chart h-auto w-100"></div>
                    <div id="sourceTable" class="span-table no-border h-auto"></div>
                    <smart-chart #trafficChart class="dashboard3-traffic-chart"
                        [animation]="trafficDataSettings.animation" [theme]="trafficDataSettings.theme!"
                        [caption]="trafficDataSettings.caption" [description]="trafficDataSettings.description"
                        [showLegend]="trafficDataSettings.showLegend"
                        [showBorderLine]="trafficDataSettings.showBorderLine" [padding]="trafficDataSettings.padding"
                        [dataSource]="trafficDataSettings.dataSource" [seriesGroups]="trafficDataSettings.seriesGroups"
                        [colorScheme]="'custom'">
                    </smart-chart>
                    <smart-table #traffictable class="dashboard3-traffic-table span-table no-border"
                        [dataSource]="trafficTableDataSettings.dataSource" [columns]="trafficTableDataSettings.columns">
                    </smart-table>
                </div>
            </div>
        </div>
    </div>

    <div class="custom-row messages-products-row">
        <div class="custom-col col-entity-lg-8">
            <div class="custom-card h-100">
                <div class="card-header">
                    <h4 class="label">Last messages</h4>
                    <div class="settings-button" #messagesButton (click)="toggleMenu(messagesButton)"
                        (pointerup)="stopPropagation($event)">…</div>
                </div>
                <div class="card-body p-0">
                    <smart-table #messages id="hoverableTable" class="dashboard3-messages-table table-hover"
                        [dataSource]="messagesTableSettings.dataSource" [columns]="messagesTableSettings.columns"
                        [tooltip]="messagesTableSettings.tooltip">
                    </smart-table>
                </div>
            </div>
        </div>

        <div class="custom-col col-entity-lg-4">
            <div class="custom-card h-100">
                <div class="card-header">
                    <h4 class="label">Popular products</h4>
                    <div class="smart-badge smart-badge-primary smart-badge-pill">Today</div>
                </div>

                <div class="card-body py-2">
                    <div class="feeds">
                        <div class="custom-row hover-text-primary">
                            <div class="custom-col custom-col-auto">
                                <div class="img-as-background">
                                    <img src="../../../../assets/images/products/1.jpg" />
                                </div>
                            </div>

                            <div class="custom-col">
                                <div><strong>Black Luxury Lamp</strong></div>
                                <small class="text-muted">Category: Home accessories</small>
                            </div>

                            <div class="custom-col custom-col-auto">
                                <small class="w-auto text-muted">1452 visits</small>
                            </div>
                        </div>

                        <div class="custom-row hover-text-primary">
                            <div class="custom-col custom-col-auto">
                                <div class="img-as-background">
                                    <img src="../../../../assets/images/products/2.jpg" />
                                </div>
                            </div>

                            <div class="custom-col">
                                <div><strong>White chair</strong></div>
                                <small class="text-muted">Category: Furnitures</small>
                            </div>

                            <div class="custom-col custom-col-auto">
                                <small class="w-auto text-muted">1322 visits</small>
                            </div>
                        </div>

                        <div class="custom-row hover-text-primary">
                            <div class="custom-col custom-col-auto">
                                <div class="img-as-background">
                                    <img src="../../../../assets/images/products/3.jpg" />
                                </div>
                            </div>

                            <div class="custom-col">
                                <div><strong>Fresh Flower</strong></div>
                                <small class="text-muted">Category: Home coziness</small>
                            </div>

                            <div class="custom-col custom-col-auto">
                                <small class="w-auto text-muted">1113 visits</small>
                            </div>
                        </div>

                        <div class="custom-row hover-text-primary">
                            <div class="custom-col custom-col-auto">
                                <div class="img-as-background">
                                    <img src="../../../../assets/images/products/1.jpg" />
                                </div>
                            </div>

                            <div class="custom-col">
                                <div><strong>Black Luxury Lamp</strong></div>
                                <small class="text-muted">Category: Home accessories</small>
                            </div>

                            <div class="custom-col custom-col-auto">
                                <small class="w-auto text-muted">1452 visits</small>
                            </div>
                        </div>

                        <div class="custom-row hover-text-primary">
                            <div class="custom-col custom-col-auto">
                                <div class="img-as-background">
                                    <img src="../../../../assets/images/products/2.jpg" />
                                </div>
                            </div>

                            <div class="custom-col">
                                <div><strong>White chair</strong></div>
                                <small class="text-muted">Category: Furnitures</small>
                            </div>

                            <div class="custom-col custom-col-auto">
                                <small class="w-auto text-muted">1322 visits</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>