import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';

const AUTH_API = 'https://debitorka-online.ru/api/v1/auth/';

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
    providedIn: 'root',
})
/**
 * Эта служба отправляет HTTP POST-запросы на регистрацию, вход и выход на серверную часть. Он обеспечивает следующие важные функции
 */
export class AuthService {

    userId: bigint = null; // Id текущего пользователя

    constructor(private http: HttpClient) {
    }

    login(username: string, password: string): Observable<any> {
        return this.http.post(
            AUTH_API + 'signin',
            {
                username,
                password,
            },
            httpOptions
        );
    }

    register(username: string, email: string, password: string): Observable<any> {
        return this.http.post(
            AUTH_API + 'registration',
            {
                username,
                email,
                password,
            },
            httpOptions
        );
    }

    // @ts-ignore
    logout(): Observable<any> {
        console.log('signout');
       // return this.http.post(AUTH_API + 'signout', {}, httpOptions);
    }
}