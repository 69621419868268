<div class="container e-commerce-clients">
    <div class="custom-row header-row">
        <div class="custom-col custom-col-12">
            <h4>Clients</h4>
        </div>
    </div>

    <div class="custom-row content-row">
        <div class="custom-col col-entity-lg-8">
            <div class="custom-card">
                <div class="card-header">
                    <h4 class="label">Clients</h4>
                    <div class="settings-button" #bestSellingTableButton (click)="toggleMenu(bestSellingTableButton)"
                    (pointerup)="stopPropagation($event)">…</div>
                </div>
                <div class="card-body p-0">
                    <smart-table class="e-commerce-clients-table table-striped no-border"
                        [dataSource]="clientsTableData.dataSource" [columns]="clientsTableData.columns"
                        [sortMode]="clientsTableData.sortMode" [filtering]="true" [paging]="true"></smart-table>
                </div>
            </div>
        </div>

        <div class="custom-col col-entity-lg-4">
            <div class="custom-card">
                <div class="card-header">
                    <h4 class="label">Marcia Gomez</h4>
                    <div class="settings-button" #personButton (click)="toggleMenu(personButton)"
                    (pointerup)="stopPropagation($event)">…</div>
                </div>

                <div class="card-body">
                    <div class="custom-row person-info">
                        <div class="custom-col custom-col-auto">
                            <div class="img-as-background square">
                                <img src="../../../../../assets/images/phonebook/avril.png">
                            </div>
                        </div>

                        <div class="custom-col">
                            <h6 class="font-weight-bold">About client</h6>
                            <p class="">Vestibulum aliquam arcu ut nisl commodo auctor. Pellentesque at bibendum metus,
                                sit amet tempor elit.</p>
                        </div>
                    </div>

                    <div class="table-responsive">
                        <table class="table">
                            <thead style="display: none;">
                                <tr>
                                    <td style="width: 100px;"></td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tr>
                                <td class="font-weight-bold">Name</td>
                                <td class="text-right">Marcia Gomez</td>
                            </tr>
                            <tr>
                                <td class="font-weight-bold">Company</td>
                                <td class="text-right">Sandy Lake</td>
                            </tr>
                            <tr>
                                <td class="font-weight-bold">Email</td>
                                <td class="text-right">marcia.gomez@example.com</td>
                            </tr>
                            <tr>
                                <td class="font-weight-bold">Phone</td>
                                <td class="text-right">+883123123321</td>
                            </tr>
                            <tr>
                                <td class="font-weight-bold">Status</td>
                                <td class="text-right">Active</td>
                            </tr>
                        </table>
                    </div>

                    <h6 class="font-weight-bold">Activity</h6>

                    <div class="appointmentContainer h-100">
                        <div class="appointment">
                            <div class="label"><strong>Contacted manager</strong><span>14:00</span></div>
                            <p class="">Proin sagittis nisl diam, in pretium velit congue et.</p>
                        </div>
                        <div class="appointment">
                            <div class="label"><strong>Renewed contract</strong><span>yesterday</span></div>
                            <p class="">Donec sodales, tellus at facilisis commodo, lectus lectus pharetra neque, at
                                condimentum augue diam vitae massa.
                            </p>
                        </div>
                        <div class="appointment">
                            <div class="label"><strong>Requested invoice</strong><span>2d ago</span></div>
                            <p class="">Aenean facilisis mi ac vestibulum vestibulum.</p>
                        </div>
                        <div class="appointment">
                            <div class="label"><strong>Custom support payment</strong><span>1 mo ago</span></div>
                            <p class="">Praesent molestie convallis consequat.</p>
                        </div>
                        <div class="appointment">
                            <div class="label"><strong>Bought license</strong><span>3 mo ago</span></div>
                            <p class="">Aenean facilisis mi ac vestibulum vestibulum.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>