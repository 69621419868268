<div class="container forms-layouts">
    <div class="custom-row">
        <div class="forms-layouts-header custom-col"><h4>Form Layouts</h4></div>
    </div>

    <div class="custom-row">
        <div class="custom-col forms-layouts-entity-lg-6 forms-layouts-entity-with-margin">
            <div class="custom-card h-100">
                <div class="card-header">
                    <h4 class="label">Basic form</h4>
                </div>
                <div class="card-body">
                    <form id="layouts-basic-form"></form>
                </div>
            </div>
        </div>

        <div class="custom-col forms-layouts-entity-lg-6 forms-layouts-entity-with-margin">
            <div class="custom-card h-100">
                <div class="card-header">
                    <h4 class="label">Horizontal form</h4>
                </div>
                <div class="card-body">
                    <form id="layouts-horizontal-form"></form>
                </div>
            </div>
        </div>

        <div class="custom-col forms-layouts-entity-12 forms-layouts-entity-with-margin">
            <div class="custom-card h-100">
                <div class="card-header">
                    <h4 class="label">Form row</h4>
                </div>
                <div class="card-body">
                    <form id="layouts-form-row"></form>
                </div>
            </div>
        </div>

        <div class="custom-col forms-layouts-entity-12">
            <div class="custom-card h-100">
                <div class="card-header">
                    <h4 class="label">Inline form</h4>
                </div>
                <div class="card-body">
                    <form id="layouts-inline-form"></form>
                </div>
            </div>
        </div>
    </div>
</div>