import {Component, OnInit, OnDestroy, ViewChild, AfterViewInit, ViewEncapsulation} from '@angular/core';
import {Smart, DataAdapter} from 'smart-webcomponents-angular/grid';
import {Subscription} from "rxjs";
import {Meeting} from "../../../models/metting";
import {TableComponent} from "smart-webcomponents-angular/table";
import {WindowComponent, Window, WindowModule} from 'smart-webcomponents-angular/window';
import {ButtonComponent, ButtonModule} from "smart-webcomponents-angular/button";
import {BrowserModule} from "@angular/platform-browser";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {HouseService} from "../services/house.service";
import {UrlService} from "../services/url.service";
import {DatePipe} from "@angular/common";
import {QuestionService} from "../services/question.service";
import {StorageService} from "../../../services/storage.service";
import {constLocalStorage} from "../../../shared/entity.const";

// @ts-ignore
@Component({
    selector: 'my-question',
    templateUrl: './my-question.component.html',
    providers: [BrowserModule, ButtonModule, WindowModule],
    styleUrls: ['./my-question.component.scss'],
    encapsulation: ViewEncapsulation.None
})

/**
 * Компонент в конкретном собрании (вкладака Мои вопросы). Отвечает за выбор моих вопросов для собрания
 */
export class MyQuestionComponent implements AfterViewInit, OnDestroy, OnInit {

    static questionService: QuestionService;
    meetings: Meeting[];
    // Переменная отвечающая за подписку
    mSub: Subscription
    selectedItems: Array<any> = []; // Выбранные чекбоксы Id
    selectedRows: Array<any> = []; // Выбранные чекбоксы rows Id
    agreeDeleteCheckbox: boolean = false; // Флаг кнопка согласие в модальном окне
    isDisabledButtonDeleteAll: boolean = true; // Изначально отключено. Кнопка удалить все

    constructor(
        private questionService: QuestionService,
        private houseService: HouseService,
        private urlService: UrlService,
        private router: Router,
        private activeRouter: ActivatedRoute, // Отображает текущий роут компонента
        private storageService: StorageService,
    ) {
        Object.assign(MyQuestionComponent, {
            QuestionService,
        });
    }

    @ViewChild('button', {read: ButtonComponent, static: false}) button!: ButtonComponent;
    @ViewChild('modalWindow', {read: WindowComponent, static: false}) modalWindow: WindowComponent;
    @ViewChild('formWindow', {read: WindowComponent, static: false}) formWindow: WindowComponent;
    @ViewChild('table', {read: TableComponent, static: false}) tableWithButtons!: TableComponent;

    /**
     * Очищаем подписку на стрим чтобы не было утечек по памяти
     */
    ngOnDestroy() {
        if (this.mSub) {
            this.mSub.unsubscribe();
        }
    }

    onWindowReady(event: any): void {
        this.init();
    }


    onButtonClick(event: any): void {
        this.formWindow.opened ? this.formWindow.close() : this.formWindow.open();
    }

    onButtonClick2(event: any): void {
        this.formWindow.close();
    }

    /**
     * Запускается 3 - им
     */
    init(): void {
        // init code.
        console.log('Запустился init()');

    }

    /**
     * Св-во нужно для инициализации. Для интерфейса. Нужен для норм работы
     */
    onInit(): void {
        // init code.
        console.log('Запустился onInit()');
    };


    /**
     * Запускается 1 на странице
     */
    ngOnInit() {
        console.log('Запустился ngOnInit()');
        // Получаем параметры из url
        this.activeRouter.params.subscribe((params: Params) => {
            this.urlService.urlParams = params
        })

        // Получаем начальные данные по запросу
        this.getMyQuestionCurrentMeeting();
     }

    /**
     * Запускается 2 на странице после ngOnInit()
     */
    ngAfterViewInit() {
        // Заменяет иконки после прорисовки компонента на нужные
        const windowWithFeather = window as any;
        windowWithFeather.feather.replace();
        console.log('Запустился  ngAfterViewInit()');
        this.documentReady();
        window.onload = function () {
            console.log(' window.onload ');
            /*      const elems = document.body.querySelector('.ggggg1')
                  // @ts-ignore
                  elems.addEventListener('click', function(event) {
                    event.preventDefault();
                    alert('Дорогу осилит идущий');
                  })*/
        }
    }

    /**
     * Вызывается обработчик события когда кликнули по иконкам или чекбоксу
     * @param event
     */
    onCellClick(event: { detail: { dataField: string; row: { id: number; }; id: string; }; target: any; }) {
        //Клик по чекбоксу
        if (this.selectedItems === undefined || this.selectedItems.length == 0) {
            this.selectedItems.push(event.detail.row.id);
        } else {
            // Если еще нет значения в массиве то добавляем
            if (!this.selectedItems.includes(event.detail.row.id)) {
                //console.log('еще нет значения');
                this.selectedItems.push(event.detail.row.id);
            } else {
                //console.log('такое есть значения'); Удаляем из массива selectedItems
                const index = this.selectedItems.indexOf(event.detail.row.id, 0);
                //console.log('index - ' + index);
                if (index > -1) {
                    this.selectedItems.splice(index, 1);
                }
            }
        }
        //Клик по чекбоксу
        if (this.selectedRows === undefined || this.selectedRows.length == 0) {
            this.selectedRows.push(event.detail.id);
        } else {
            // Если еще нет значения в массиве то добавляем
            if (!this.selectedRows.includes(event.detail.id)) {
                //console.log('еще нет значения');
                this.selectedRows.push(event.detail.id);
            } else {
                const index = this.selectedRows.indexOf(event.detail.id, 0);
                //console.log('index - ' + index);
                if (index > -1) {
                    this.selectedRows.splice(index, 1);
                }
            }
        }
        this.storageService.saveData(constLocalStorage.SELECTED_MY_QUESTIONS_IDS + '?' + this.urlService.urlParams.meetingId, this.selectedItems);
        this.storageService.saveData(constLocalStorage.ROW_MY_QUESTIONS_IDS + '?' + this.urlService.urlParams.meetingId, this.selectedRows);
    }

    /**
     * Клик по выбрать все чекбоксы. Записываем данные в this.selectedItems
     * @param event
     */
    onChangeCheckbox(event: { detail: { dataField: string; row: { id: number; }; id: string; }; target: any; type: string }) {
        let allCheckboxSelected = document.querySelectorAll('th.selected');
        let sameCheckboxSelected = document.querySelectorAll('th.indeterminate');

        if (allCheckboxSelected.length > 0 || sameCheckboxSelected.length > 0) {
            this.isDisabledButtonDeleteAll = false;

            let checked = document.querySelectorAll('tr[aria-selected="true"]');
            checked.forEach((item, key) => {

                // @ts-ignore
                if(item.querySelector('[data-field="id"]')) {
                    let val = item.querySelector('[data-field="id"]').innerHTML;
                    // Если еще нет значения в массиве то добавляем
                    if (!this.selectedItems.includes(Number(val))) {
                        //console.log('еще нет значения');
                        this.selectedItems.push(Number(val));
                    }
                }

            })
        } else {
            // Чистим localStorage
            this.storageService.deleteFromLocalStorageByName(constLocalStorage.SELECTED_MY_QUESTIONS_IDS  + '?' + this.urlService.urlParams.meetingId)
            this.storageService.deleteFromLocalStorageByName(constLocalStorage.ROW_MY_QUESTIONS_IDS + '?' + this.urlService.urlParams.meetingId)
            this.selectedItems.length = 0 // Чтобы массив this.selectedItems был равен [];
            this.selectedRows.length = 0 // Чтобы массив this.selectedRows был равен [];
            this.selectedItems = [];
            this.selectedRows = [];
            this.isDisabledButtonDeleteAll = true;
        }
    }

    /**
     * Скопировать вопросы из стандартных моих вопросов в текущее собрание
     */
    addMyQuestionToCurrentMeeting(event: any): void {
        const params: any[any] = [];
        params.user_id = this.storageService.getUser();
        params.meeting_id = Number(this.urlService.urlParams.meetingId);
        params.selectedMyQuestionsIds = this.storageService.getSomeVariable(constLocalStorage.SELECTED_MY_QUESTIONS_IDS + '?' + this.urlService.urlParams.meetingId);
        this.mSub = this.questionService.addMyQuestionToCurrentMeeting(params).subscribe( questions => {
            // Скинули все чекбоксы
            this.tableWithButtons.clearSelection();
        });
        // Скинули в localStorage
        this.storageService.deleteFromLocalStorageByName(constLocalStorage.SELECTED_MY_QUESTIONS_IDS + '?' + this.urlService.urlParams.meetingId)
        this.router.navigate([this.router.url.replace('/my-question', '')]);
    }

    /**
     * Получить все мои вопросы
     */
    getMyQuestionCurrentMeeting(): void {
        this.mSub = this.questionService.getMyQuestionCurrentMeeting()
            .subscribe(myQuestion => {
                // Заполнили данными
                this.dataAdapter.dataSource = myQuestion.results;
            });
    }

    /**
     * Отмечаем выбранные чекбоксы сохраненные в localStorage. Подгружаем их через 2,5с после загрузки страницы
     */
    documentReady(): void {
        if (this.storageService.getSomeVariable(constLocalStorage.ROW_MY_QUESTIONS_IDS + '?' + this.urlService.urlParams.meetingId) != undefined
            && this.storageService.getSomeVariable(constLocalStorage.ROW_MY_QUESTIONS_IDS + '?' + this.urlService.urlParams.meetingId).length > 0) {
            function sleep(ms: number) {
                return new Promise(resolve => setTimeout(resolve, ms));
            }
            const delayedGreeting = async () => {
                await sleep(2500);
                this.storageService.getSomeVariable(constLocalStorage.ROW_MY_QUESTIONS_IDS + '?' + this.urlService.urlParams.meetingId).forEach((item: string, key: any) => {
                    document.querySelector('tr[row-id="' + item + '"]').firstElementChild.classList.add("selected");
                })
            }
            delayedGreeting().then(r => {});
        }
    }

    /**
     **  Провайдер данных обозначаем какие поля в нем будут
     */
    dataAdapter: DataAdapter = new Smart.DataAdapter({
        dataSource: [],
        dataFields: [
            'id: number',
            'questionDateBegin: string',
            'text_message: string',
            'text_owner: string',
        ],
    })

    dataSource = this.dataAdapter;

    /**
     * Настройки таблицы
     */
    questionsDataSettings = {
        /**
         * Св-во нужно для инициализации. Для интерфейса. Нужен для норм работы
         */
        onInit: () => {

        },

        behavior: {columnResizeMode: 'growAndShrink'},
        questionService: MyQuestionComponent.questionService,
        // Пример запроса по ajax. Проблема fetch делается несколько раз
        /*    dataSource: new window.Smart.DataAdapter({
              virtualDataSourceCache: false,
              virtualDataSource:  function (resultCallbackFunction: ResultCallbackFunction) {
                fetch('/api/v1/product/getMeetings/')
                  .then(response => response.json())
                  .then(json => {
                    const data: [] = json.results.map((result: {
                      id: number;
                      address: any;
                      meetingDate: string
                      initiator: string;
                      ownerCount: number;
                      questionCount: number;
                      meetingStatus: number;
                      paymentStatus: number;
                      comment: string;

                    }) => {
                      return {
                        id: result.id,
                        meetingDate: result.meetingDate,
                        address: result.address,
                        initiator: result.initiator,
                        ownerCount: result.ownerCount,
                        questionCount: result.questionCount,
                        meetingStatus: result.meetingStatus,
                        paymentStatus: result.paymentStatus,
                        comment: result.comment,
                      };
                    });
                    resultCallbackFunction({
                      dataSource: data
                    });
                  });
              },
              dataFields: [
                'id: number',
                'address: string',
                'meetingDate: string',
                'initiator: string',
                'ownerCount: number',
                'questionCount: number',
                'meetingStatus: number',
                'paymentStatus: number',
                'comment:       string',
                'createDate:    string',
                'updateDate: string'
              ],
            }),*/

        // Параметры таблицы
        pageIndex: 0,
        pageSize: "10",
        filtering: true,
        paging: true,
        sortMode: 'many',
        filterRow: false,
        tooltip: true,
        selection: true,
        editing: false,
        editMode: 'row',
        keyboardNavigation: true,

        // Колонки в таблице
        columns: [
            {
                label: 'id', dataField: 'id', dataType: 'number', responsivePriority: 3, width: 50,
            },
 /*           {
                label: 'Id', dataField: 'actionId', dataType: 'number', responsivePriority: 3, width: 50,
                formatFunction: function (settings: any) {
                    settings.template =
                        '<a href="javascript:;" class="nav-link rounded action-icon">' + settings.data.id + '</a>';
                }
            },*/
            {label: 'Формулировка для уведомления', dataField: 'text_message', dataType: 'string', responsivePriority: 3},
            {label: 'Формулировка для решения собственника', dataField: 'text_owner', dataType: 'string', responsivePriority: 3},
            /*       {
                       label: 'Кол-во собраний',
                       dataField: 'count_meeting',
                       allowEdit: false,
                       dataType: 'number',
                       responsivePriority: 3,
                       formatFunction: (value: any) => {
                           console.log('value.dataSource')
                     console.log(value.dataSource)
                       }
                   },*/
        /*    {label: 'Комментарий', dataField: 'comment', dataType: 'string', responsivePriority: 3},*/
            /*    {label: 'Дата создания дома', dataField: 'createDate', allowEdit: false, dataType: 'string', responsivePriority: 3},*/
            {
                label: 'Дата создания',
                dataField: 'questionDateBegin',
                allowEdit: false,
                dataType: 'string',
                responsivePriority: 3,
                width: 150,
                formatFunction: (value: any) => {
                    new DatePipe('en-US').transform(value.value, 'dd-MM-yyyy');
                    value.template = new DatePipe('en-US').transform(value.value, 'dd.MM.yyyy');
                }
            },
        ],

    }

}
