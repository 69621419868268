<div class="container validation">
    <div class="custom-row">
        <div class="validation-header custom-col">
            <h4>Form Validation</h4>
        </div>
    </div>

    <div class="custom-card">
        <div class="card-body">
            <p>Form validation using Smart.Form.</p>
            <form id="form"></form>
        </div>
    </div>
</div>