<div class="container modals">
    <div class="custom-row">
        <div class="modals-header custom-col">
            <h4>Modals</h4>
        </div>
    </div>

    <div class="custom-row">
        <div class="modals-entity custom-col">
            <div class="custom-card">
                <div class="card-header">
                    <h4 class="label">Default modal (smart-window)</h4>
                </div>
                <div class="card-body">
                    <smart-button (click)="openModal(primaryModal)" id="openPrimaryModal" class="small-button primary">
                        Open primary modal
                    </smart-button>&nbsp;<smart-button (click)="openModal(successModal)" id="openSuccessModal"
                        class="small-button success">Open success
                        modal
                    </smart-button>&nbsp;<smart-button (click)="openModal(errorModal)" id="openErrorModal"
                        class="small-button error">Open error modal
                    </smart-button>
                </div>
            </div>
        </div>

        <div class="modals-entity custom-col">
            <div class="custom-card">
                <div class="card-header">
                    <h4 class="label">Colored modal</h4>
                </div>
                <div class="card-body">
                    <smart-button (click)="openModal(primaryColoredModal)" id="openPrimaryCModal"
                        class="small-button primary">Open primary colored
                        modal</smart-button>&nbsp;<smart-button (click)="openModal(successColoredModal)"
                        id="openSuccessCModal" class="small-button success">Open
                        success colored
                        modal</smart-button>&nbsp;<smart-button (click)="openModal(errorColoredModal)"
                        id="openErrorCModal" class="small-button error">Open
                        error colored modal
                    </smart-button>
                </div>
            </div>
        </div>
    </div>

    <div class="custom-row">
        <div class="modals-entity custom-col">
            <div class="custom-card">
                <div class="card-header">
                    <h4 class="label">Modal sizes</h4>
                </div>
                <div class="card-body">
                    <smart-button (click)="openModal(smallModal)" id="openSmallModal" class="small-button primary">Open
                        small modal
                    </smart-button>&nbsp;<smart-button (click)="openModal(mediumModal)" id="openMediumModal"
                        class="small-button primary">Open medium
                        modal
                    </smart-button>&nbsp;<smart-button (click)="openModal(largeModal)" id="openLargeModal"
                        class="small-button primary">Open large modal
                    </smart-button>
                </div>
            </div>
        </div>

        <div class="modals-entity custom-col">
            <div class="custom-card">
                <div class="card-header">
                    <h4 class="label">Modal types</h4>
                </div>
                <div class="card-body">
                    <smart-button (click)="openModal(alertModal)" id="openAlertModal" class="small-button secondary">
                        Open alert modal
                    </smart-button>&nbsp;<smart-button (click)="openModal(dialogModal)" id="openDialogModal"
                        class="small-button secondary">Open dialog
                        modal
                    </smart-button>&nbsp;<smart-button id="openMultilineModal" class="small-button secondary">Open
                        multiline modal
                    </smart-button>
                </div>
            </div>
        </div>
    </div>

    <smart-window class="modals-modal" #primaryModal id="primary" label="Primary modal" [modal]="true">
        This is a modal with the primary color.
        <smart-button (click)="closeModal(primaryModal)" class="primary outlined smart-close">Close</smart-button>
    </smart-window>

    <smart-window class="modals-modal" #successModal id="success" label="Success modal" [modal]="true">
        This is a modal with the success color.
        <smart-button (click)="closeModal(successModal)" class="success outlined smart-close">Close</smart-button>
    </smart-window>

    <smart-window class="modals-modal" #errorModal id="error" label="Error modal" [modal]="true">
        This is a modal with the error color.
        <smart-button (click)="closeModal(errorModal)" class="error outlined smart-close">Close</smart-button>
    </smart-window>

    <smart-window class="modals-modal" #primaryColoredModal id="primaryc" label="Primary-colored modal" [modal]="true">
        This is a modal with the primary color.
        <smart-button (click)="closeModal(primaryColoredModal)" class="smart-close">Close</smart-button>
    </smart-window>

    <smart-window class="modals-modal" #successColoredModal id="successc" label="Success-colored modal" [modal]="true">
        This is a modal with the success color.
        <smart-button (click)="closeModal(successColoredModal)" class="smart-close">Close</smart-button>
    </smart-window>

    <smart-window class="modals-modal" #errorColoredModal id="errorc" label="Error-colored modal" [modal]="true">
        This is a modal with the error color.
        <smart-button (click)="closeModal(errorColoredModal)" class="smart-close">Close</smart-button>
    </smart-window>

    <smart-window class="modals-modal" #smallModal id="small" label="Small modal" [modal]="true">
        This is a small modal.
        <smart-button (click)="closeModal(smallModal)" class="primary smart-close">Close</smart-button>
    </smart-window>

    <smart-window class="modals-modal" #mediumModal id="medium" label="Medium modal" [modal]="true">
        This is a medium modal.
        <smart-button (click)="closeModal(mediumModal)" class="primary smart-close">Close</smart-button>
    </smart-window>

    <smart-window class="modals-modal" #largeModal id="large" label="Large modal" [modal]="true">
        This is a large modal.
        <smart-button (click)="closeModal(largeModal)" class="primary smart-close">Close</smart-button>
    </smart-window>

    <smart-alert-window #alertModal id="alert" label="Alert modal" [modal]="true">
        This is an alert modal!
    </smart-alert-window>

    <smart-dialog-window #dialogModal id="dialog" label="Dialog modal" [modal]="true">
        Proin venenatis dignissim tortor vel mattis. Mauris vel justo consectetur quam iaculis volutpat ac a libero. Ut
        in
        facilisis orci. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
        Vivamus a ex turpis. In dignissim consectetur metus, iaculis volutpat urna scelerisque id. Aliquam vel dictum
        orci,
        nec tempor metus?
    </smart-dialog-window>

    <!-- <smart-multiline-prompt-window #multilineModal id="multiline" label="Multiline modal" [modal]="true" prompt-label="Enter your address:">
    </smart-multiline-prompt-window> -->
</div>