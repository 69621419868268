import {Component, OnInit, OnDestroy, AfterViewInit, ViewEncapsulation} from '@angular/core';
import {HouseService} from "src/app/pages/meetings/services/house.service";
import {Subscription} from "rxjs";
import {BrowserModule} from "@angular/platform-browser";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {StorageService} from "../../../services/storage.service";
import {UrlService} from "../services/url.service";
import {CommonModule} from "@angular/common";


// @ts-ignore
@Component({
    selector: 'form-create-owner',
    templateUrl: 'form-create-owner.component.html',
    providers: [BrowserModule, CommonModule],
    styleUrls: ['form-create-owner.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class FormCreateOwnerComponent implements AfterViewInit, OnDestroy, OnInit {

    // Переменная отвечающая за подписку
    mSub: Subscription
    ownerCreateForm: FormGroup

    constructor(
        private houseService: HouseService,
        private storageService: StorageService,
        private urlService: UrlService,
        private router: Router,
        private activeRouter: ActivatedRoute,
    ) {
        Object.assign(FormCreateOwnerComponent, {
            houseService,
            storageService,
        });
    }

    /**
     * Запускается 1 на странице
     */
    ngOnInit() {
        // Получаем параметры из url
        this.activeRouter.params.subscribe((params: Params) => {
            this.urlService.urlParams = params
        })
        console.log('Запустился ngOnInit()');
        // Инициализируем форму и поля
        this.ownerCreateForm = new FormGroup<any>({
            name: new FormControl('', [Validators.required, Validators.minLength(6),Validators.pattern(/[А-Яа-я]{2,}\s[А-Яа-я]{2,}\s[А-Яа-я]{2,}/)]),
            phone: new FormControl('', [Validators.pattern(/[0-9]/)]),
            email: new FormControl('', [Validators.email]),
            registration_number: new FormControl('', []),
            cadastral_number: new FormControl('', []),
            registration_date: new FormControl('', []),
            room_number: new FormControl('', []),
            area: new FormControl('', [Validators.pattern(/[0-9]/)]),
            portion: new FormControl('', []),
            type_id: new FormControl('', []),
        })
    }

    /**
     * Создаем владельца
     */
    createOwner() {
        if (this.ownerCreateForm.valid) {
            console.log(this.ownerCreateForm);
            // Отправляем параметры на back + в массив post добавили user_id
            const params = {...this.ownerCreateForm.value}
            params.user_id = this.storageService.getUser();
            params.house_id = Number(this.urlService.urlParams.houseId);
            this.mSub = this.houseService.createOwner(params).subscribe(_ => {
                // Получили обновленные данные и записали в this.dataAdapter.dataSource
                this.houseService.getOwners(this.urlService.urlParams);
            });
            // Очистка формы после submit
            this.ownerCreateForm.reset();
            this.router.navigate(['houses/'+ this.urlService.urlParams.houseId]);

        }
    }

    /**
     * Очищаем подписку на стрим чтобы не было утечек по памяти
     */
    ngOnDestroy() {
        if (this.mSub) {
            this.mSub.unsubscribe();
        }
    }

    /**
     * Запускается 3 - им
     */
    init(): void {
    }

    /**
     * Св-во нужно для инициализации. Для интерфейса. Нужен для норм работы
     */
    onInit(): void {
        console.log('Запустился onInit()');
    };

    /**
     * Запускается 2 на странице после ngOnInit()
     */
    ngAfterViewInit() {

    }


}
