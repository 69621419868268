import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BasicInputSelectComponent} from "./input/select/basic-input-select.component";
import {InputModule} from "smart-webcomponents-angular/input";
import {ListBoxModule} from "smart-webcomponents-angular/listbox";
import {ReactiveFormsModule} from "@angular/forms";

@NgModule({
    declarations: [
        BasicInputSelectComponent

    ],
    imports: [
        CommonModule,
        InputModule,
        ListBoxModule,
        ReactiveFormsModule
    ],
    exports: [
        BasicInputSelectComponent
    ]
})
export class SharedModule {
}



