import {Component, OnInit} from '@angular/core';
import {StorageService} from '../services/storage.service';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.css']
})

/**
 * Этот компонент получает текущего пользователя из хранилища с помощью StorageService
 * и отображает информацию (имя пользователя, токен, адрес электронной почты, роли).
 */
export class ProfileComponent implements OnInit {
    currentUser: any;

    constructor(private storageService: StorageService) {
    }

    ngOnInit(): void {
        this.currentUser = this.storageService.getUser();
    }
}