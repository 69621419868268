<div class="table-with-buttons">
  <div class="custom-row">
    <div class="table-with-buttons-header custom-col">
      <h4>Мои собрания <i data-feather="clipboard"></i> <span></span></h4>
    </div>
  </div>
 <div class="controls-container">
    <smart-button (onClick)="addMeeting()">Создать собрание</smart-button>
     &nbsp;&nbsp;
    <smart-button #button2 (onClick)="deleteSelectedCheckBox($event)" [disabled] = this.isDisabledButtonDeleteAll >Удалить выбранные</smart-button>
  </div>
  <div class="custom-card">
    <div class="card-body">
     <smart-table #table id="table"  [dataSource]="dataSource"
                  [paging]="meetingsDataSettings.paging" [sortMode]="meetingsDataSettings.sortMode"
                  [tooltip]="meetingsDataSettings.tooltip" [onInit]="meetingsDataSettings.onInit"
                  [columns]="meetingsDataSettings.columns" [filtering]="meetingsDataSettings.filtering"
                  [filterRow]="meetingsDataSettings.filterRow" [pageIndex]="meetingsDataSettings.pageIndex" [pageSize]= "meetingsDataSettings.pageSize"
                  [selection]="meetingsDataSettings.selection" [editing]="meetingsDataSettings.editing"
                  (onCellEndEdit)="onItemAfterEdit($event)" (onCellClick)="onCellClick($event)" (onChange)="onChangeCheckbox($event)"
     ></smart-table>
    </div>
  </div>

  <smart-window #formWindow id="formWindow" [footerTemplate]="'footerTemplate'"  [opened]="false" [label]="'Внимание!'"  (onReady)="onWindowReady($event)">
    <div id="article">
      <section>
          <section>
              <h3>Уверены что хотите удалить выделенные собрания?</h3>
              <p>При нажатии на кнопку все выделенные собрания удаляться их нельзя будет вернуть </p>
          </section>
      </section>
    </div>
  </smart-window>
  <template id='footerTemplate'>
    <smart-button id="сancelButton">Нет</smart-button>
    <smart-button id="agreeHandler">Да</smart-button>
  </template>
</div>
