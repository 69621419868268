<div class="container grid-layout">
    <div class="custom-row">
        <div class="grid-layout-header custom-col"><h4>Grid Layout System</h4></div>
    </div>

    <div class="row">
        <div class="col">
            <div class="custom-card">
                <div class="card-body">
                    <p>Bootstrap provides a powerful, mobile-first 12-column Grid layout system.</p>
                    <div class="grid-cols">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="custom-card">.col-md-12</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="custom-card">.col-md-6</div>
                            </div>
                            <div class="col-md-6">
                                <div class="custom-card">.col-md-6</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="custom-card">.col-md-3</div>
                            </div>
                            <div class="col-md-3">
                                <div class="custom-card">.col-md-3</div>
                            </div>
                            <div class="col-md-3">
                                <div class="custom-card">.col-md-3</div>
                            </div>
                            <div class="col-md-3">
                                <div class="custom-card">.col-md-3</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2">
                                <div class="custom-card">.col-md-2</div>
                            </div>
                            <div class="col-md-2">
                                <div class="custom-card">.col-md-2</div>
                            </div>
                            <div class="col-md-2">
                                <div class="custom-card">.col-md-2</div>
                            </div>
                            <div class="col-md-2">
                                <div class="custom-card">.col-md-2</div>
                            </div>
                            <div class="col-md-2">
                                <div class="custom-card">.col-md-2</div>
                            </div>
                            <div class="col-md-2">
                                <div class="custom-card">.col-md-2</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-1">
                                <div class="custom-card">.col-md-1</div>
                            </div>
                            <div class="col-md-1">
                                <div class="custom-card">.col-md-1</div>
                            </div>
                            <div class="col-md-1">
                                <div class="custom-card">.col-md-1</div>
                            </div>
                            <div class="col-md-1">
                                <div class="custom-card">.col-md-1</div>
                            </div>
                            <div class="col-md-1">
                                <div class="custom-card">.col-md-1</div>
                            </div>
                            <div class="col-md-1">
                                <div class="custom-card">.col-md-1</div>
                            </div>
                            <div class="col-md-1">
                                <div class="custom-card">.col-md-1</div>
                            </div>
                            <div class="col-md-1">
                                <div class="custom-card">.col-md-1</div>
                            </div>
                            <div class="col-md-1">
                                <div class="custom-card">.col-md-1</div>
                            </div>
                            <div class="col-md-1">
                                <div class="custom-card">.col-md-1</div>
                            </div>
                            <div class="col-md-1">
                                <div class="custom-card">.col-md-1</div>
                            </div>
                            <div class="col-md-1">
                                <div class="custom-card">.col-md-1</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-8">
                                <div class="custom-card">.col-md-8</div>
                            </div>
                            <div class="col-md-4">
                                <div class="custom-card">.col-md-4</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="custom-card">.col-md-3</div>
                            </div>
                            <div class="col-md-6">
                                <div class="custom-card">.col-md-6</div>
                            </div>
                            <div class="col-md-3">
                                <div class="custom-card">.col-md-3</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>