<div class="container getting-started">
    <div class="custom-row">
        <div class="getting-started-header custom-col">
            <h4>Getting Started</h4>
        </div>
    </div>

    <div class="custom-card">
        <div class="card-body">
            <h4 class="getting-started-card-header">Installation</h4>
            <p>Smart UI for Web Components is distributed as smart-webcomponents NPM package. You can also get the
                full download from our website with all demos from the Download page.</p>
            <p>To install the npm package, enter in a terminal:</p>
            <pre><code>npm install smart-webcomponents</code></pre>
        </div>
    </div>

    <div class="custom-card">
        <div class="card-body">
            <h4 class="getting-started-card-header">Load scripts</h4>
            <p>The following code adds the custom element to the page:</p>
            <pre><code>&lt;!DOCTYPE html&gt;
&lt;html lang="en"&gt;

&lt;head&gt;
    &lt;link rel="stylesheet" href="../../source/styles/smart.default.css" type="text/css" /&gt;
    &lt;script type="text/javascript" src="../../source/smart.element.js"&gt;&lt;/script&gt;
    &lt;script type="text/javascript" src="../../source/smart.button.js"&gt;&lt;/script&gt;
    &lt;script type="text/javascript" src="../../source/smart.accordion.js"&gt;&lt;/script&gt;
&lt;/head&gt;

&lt;body&gt;
    &lt;smart-accordion&gt;
        &lt;smart-accordion-item label="Item 1" content="Content 1" expanded&gt;&lt;/smart-accordion-item&gt;
        &lt;smart-accordion-item label="Item 2" content="Content 2"&gt;&lt;/smart-accordion-item&gt;
        &lt;smart-accordion-item label="Item 3" content="Content 3"&gt;&lt;/smart-accordion-item&gt;
    &lt;/smart-accordion&gt;
&lt;/body&gt;

&lt;/html&gt;</code></pre>
        </div>
    </div>

    <div class="custom-card">
        <div class="card-body">
            <h4 class="getting-started-card-header">Browser Support</h4>
            <p>Smart is mobile-first UI Framework written in Javascript Ecmascript 6(ES6), HTML5 and CSS3. Smart
                targets only modern Web browsers and devices.</p>
            <div id="getting-started-browserIconContainer">
                <div id="getting-started-chrome" class="browser-icon mr-4"></div>&nbsp;<div id="getting-started-firefox"
                    class="browser-icon mr-4"></div>&nbsp;<div id="getting-started-safari" class="browser-icon mr-4">
                </div>&nbsp;<div id="getting-started-edge" class="browser-icon mr-4"></div>&nbsp;<div
                    id="getting-started-opera" class="browser-icon mr-4"></div>
            </div>
        </div>
    </div>
</div>