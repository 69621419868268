<div class="container tabs">
    <div class="custom-row">
        <div class="tabs-header custom-col"><h4>Tabs</h4></div>
    </div>

    <div class="custom-row">
        <div class="tabs-entity custom-col">
            <div class="custom-card h-100">
                <div class="card-header">
                    <h4 class="label">Default tabs</h4>
                </div>
                <div class="card-body">
                    <smart-tabs>
                        <smart-tab-item label="Home">
                            <h6>Default tabs</h6>
                            <p>Nulla id vestibulum tortor. Vestibulum quis ante lorem. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ac quam quis sem sodales vestibulum.</p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                        </smart-tab-item>

                        <smart-tab-item label="Profile">
                            <h6>Profile tab</h6>
                            <p>Proin venenatis dignissim tortor vel mattis.</p>
                            <p>Suspendisse pulvinar massa eu massa vestibulum imperdiet.</p>
                            <p>Aenean fringilla sodales diam eget auctor. Nullam suscipit a leo nec blandit. Aenean suscipit dolor arcu.</p>
                        </smart-tab-item>

                        <smart-tab-item label="Messages">
                            <h6>Messages tabs</h6>
                            <p>Etiam orci nunc, ornare sed rhoncus quis, facilisis venenatis neque. Vivamus in nisi elit. Nullam rhoncus nisi purus, sed egestas arcu hendrerit vel.</p>
                        </smart-tab-item>
                    </smart-tabs>
                </div>
            </div>
        </div>

        <div class="tabs-entity custom-col">
            <div class="custom-card h-100">
                <div class="card-header">
                    <h4 class="label">Colored tabs</h4>
                </div>

                <div class="card-body">
                    <smart-tabs class="colored top">
                        <smart-tab-item label="Home">
                            <h6>Colored tabs</h6>
                            <p>Nulla id vestibulum tortor. Vestibulum quis ante lorem. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ac quam quis sem sodales vestibulum.</p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                        </smart-tab-item>

                        <smart-tab-item label="Profile">
                            <h6>Profile tab</h6>
                            <p>Proin venenatis dignissim tortor vel mattis.</p>
                            <p>Suspendisse pulvinar massa eu massa vestibulum imperdiet.</p>
                            <p>Aenean fringilla sodales diam eget auctor. Nullam suscipit a leo nec blandit. Aenean suscipit dolor arcu.</p>
                        </smart-tab-item>

                        <smart-tab-item label="Messages">
                            <h6>Messages tabs</h6>
                            <p>Etiam orci nunc, ornare sed rhoncus quis, facilisis venenatis neque. Vivamus in nisi elit. Nullam rhoncus nisi purus, sed egestas arcu hendrerit vel.</p>
                        </smart-tab-item>
                    </smart-tabs>
                </div>
            </div>
        </div>
    </div>

    <div class="custom-row">
        <div class="tabs-entity custom-col">
            <div class="custom-card h-100">
                <div class="card-header">
                    <h4 class="label">Icon tabs</h4>
                </div>

                <div class="card-body">
                    <smart-tabs>
                        <smart-tab-item label="homeTemplate">
                            <h6>Icon tabs</h6>
                            <p>Nulla id vestibulum tortor. Vestibulum quis ante lorem. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ac quam quis sem sodales vestibulum.</p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                        </smart-tab-item>

                        <smart-tab-item label="profileTemplate">
                            <h6>Profile tab</h6>
                            <p>Proin venenatis dignissim tortor vel mattis.</p>
                            <p>Suspendisse pulvinar massa eu massa vestibulum imperdiet.</p>
                            <p>Aenean fringilla sodales diam eget auctor. Nullam suscipit a leo nec blandit. Aenean suscipit dolor arcu.</p>
                        </smart-tab-item>

                        <smart-tab-item label="messagesTemplate">
                            <h6>Messages tabs</h6>
                            <p>Etiam orci nunc, ornare sed rhoncus quis, facilisis venenatis neque. Vivamus in nisi elit. Nullam rhoncus nisi purus, sed egestas arcu hendrerit vel.</p>
                        </smart-tab-item>
                    </smart-tabs>
                </div>
            </div>
        </div>

        <div class="tabs-entity custom-col">
            <div class="custom-card h-100">
                <div class="card-header">
                    <h4 class="label">Colored icon tabs</h4>
                </div>

                <div class="card-body">
                    <smart-tabs class="colored top success">
                        <smart-tab-item label="homeTemplate">
                            <h6>Colored icon tabs</h6>
                            <p>Nulla id vestibulum tortor. Vestibulum quis ante lorem. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ac quam quis sem sodales vestibulum.</p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                        </smart-tab-item>

                        <smart-tab-item label="profileTemplate">
                            <h6>Profile tab</h6>
                            <p>Proin venenatis dignissim tortor vel mattis.</p>
                            <p>Suspendisse pulvinar massa eu massa vestibulum imperdiet.</p>
                            <p>Aenean fringilla sodales diam eget auctor. Nullam suscipit a leo nec blandit. Aenean suscipit dolor arcu.</p>
                        </smart-tab-item>

                        <smart-tab-item label="messagesTemplate">
                            <h6>Messages tabs</h6>
                            <p>Etiam orci nunc, ornare sed rhoncus quis, facilisis venenatis neque. Vivamus in nisi elit. Nullam rhoncus nisi purus, sed egestas arcu hendrerit vel.</p>
                        </smart-tab-item>
                    </smart-tabs>
                </div>
            </div>
        </div>
    </div>

    <div class="custom-row">
        <div class="tabs-entity custom-col">
            <div class="custom-card h-100">
                <div class="card-header">
                    <h4 class="label">Vertical icon tabs</h4>
                </div>
                <div class="card-body">
                    <smart-tabs tab-position="left">
                        <smart-tab-item label="homeTemplate">
                            <h6>Vertical icon tabs</h6>
                            <p>Nulla id vestibulum tortor. Vestibulum quis ante lorem. Lorem ipsum dolor sit amet,
                                consectetur adipiscing elit. Phasellus ac quam quis sem sodales vestibulum.</p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                        </smart-tab-item>
                        <smart-tab-item label="profileTemplate">
                            <h6>Profile tab</h6>
                            <p>Proin venenatis dignissim tortor vel mattis.</p>
                            <p>Suspendisse pulvinar massa eu massa vestibulum imperdiet.</p>
                            <p>Aenean fringilla sodales diam eget auctor. Nullam suscipit a leo nec blandit. Aenean suscipit
                                dolor arcu.</p>
                        </smart-tab-item>
                        <smart-tab-item label="messagesTemplate">
                            <h6>Messages tabs</h6>
                            <p>Etiam orci nunc, ornare sed rhoncus quis, facilisis venenatis neque. Vivamus in nisi elit.
                                Nullam rhoncus nisi purus, sed egestas arcu hendrerit vel.</p>
                        </smart-tab-item>
                    </smart-tabs>
                </div>
            </div>
        </div>

        <div class="tabs-entity custom-col">
            <div class="custom-card h-100">
                <div class="card-header">
                    <h4 class="label">Colored vertical icon tabs</h4>
                </div>
                <div class="card-body">
                    <smart-tabs class="colored left error" tab-position="left">
                        <smart-tab-item label="homeTemplate">
                            <h6>Colored vertical icon tabs</h6>
                            <p>Nulla id vestibulum tortor. Vestibulum quis ante lorem. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ac quam quis sem sodales vestibulum.</p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                        </smart-tab-item>
                        <smart-tab-item label="profileTemplate">
                            <h6>Profile tab</h6>
                            <p>Proin venenatis dignissim tortor vel mattis.</p>
                            <p>Suspendisse pulvinar massa eu massa vestibulum imperdiet.</p>
                            <p>Aenean fringilla sodales diam eget auctor. Nullam suscipit a leo nec blandit. Aenean suscipit dolor arcu.</p>
                        </smart-tab-item>
                        <smart-tab-item label="messagesTemplate">
                            <h6>Messages tabs</h6>
                            <p>Etiam orci nunc, ornare sed rhoncus quis, facilisis venenatis neque. Vivamus in nisi elit. Nullam rhoncus nisi purus, sed egestas arcu hendrerit vel.</p>
                        </smart-tab-item>
                    </smart-tabs>
                </div>
            </div>
        </div>
    </div>
</div>

<template id="homeTemplate"><span class="material-icons">home</span></template>
<template id="profileTemplate"><span class="material-icons">settings</span></template>
<template id="messagesTemplate"><span class="material-icons">message</span></template>