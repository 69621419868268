import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'any' //  Этот уровень позволяет создавать отдельный экземпляр зависимости
})

export class SharedService {

    currentUrlApi = '/api/v1/'

    constructor(
        private http: HttpClient

    ) {

    }

    getSelectList(urlForGetData:string): Observable<any> {
        const url = `${this.currentUrlApi + urlForGetData}`;
        return this.http.post(url, {})

    }

}
