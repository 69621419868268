<div *ngIf="!isCurrentMeeting"
     class="table-with-buttons">
    <div class="custom-row">
        <div class="table-with-buttons-header custom-col">
            <h4>Дом <i data-feather="clipboard"></i> <span></span></h4>
        </div>
    </div>
    <div class="controls-container">
        <div class="custom-row">
            <div class="col-md-4">
                <div class="col-6">
                    <label class="btn btn-default p-0">
                        <input type="file" (change)="selectFile($event)" />
                    </label>
                </div>
                <br>
                <div class="col-6">
                    <button class="btn btn-success btn-sm" [disabled]="!selectedFiles" (click)="upload($event)">
                        Загрузить собственников (excel)
                    </button>
                </div>

                <div *ngIf="currentFile" class="progress my-3">
                    <div
                            class="progress-bar progress-bar-info progress-bar-striped"
                            role="progressbar"
                            attr.aria-valuenow="{{ progress }}"
                            aria-valuemin="0"
                            aria-valuemax="100"
                            [ngStyle]="{ width: progress + '%' }"
                    >
                        {{ progress }}%
                    </div>
                </div>

                <div *ngIf="message" class="alert alert-secondary" role="alert">{{ message }}</div>
            </div>
            <div class="col-md-4">
                <smart-button #button1 (onClick)="deleteAllOwners($event)" [disabled]=this.isDisabledButtonAgreeAllOwners>Удалить всех собственников</smart-button>
            </div>
            <div class="col-md-4">
            </div>
        </div>
    </div>
</div>

<div class="table-with-buttons">
    <div class="custom-row">
        <div class="table-with-buttons-header custom-col">
            <h4>Участники голосования <i data-feather="clipboard"></i> <span></span></h4>
        </div>
    </div>
<!--    <div class="controls-container">
        <smart-button (onClick)="addOwners($event)">Создать владельца</smart-button>
        &nbsp;&nbsp;
        <smart-button #button2 (onClick)="deleteSelectedCheckBox($event)" [disabled] = this.isDisabledButtonDeleteAll >Удалить выбранные</smart-button>

    </div>-->
    <div class="custom-card">
        <div class="card-body">
            <smart-table #table id="table"  [dataSource]="dataSource"
                         [paging]="ownersDataSettings.paging" [sortMode]="ownersDataSettings.sortMode"
                         [tooltip]="ownersDataSettings.tooltip" [onInit]="ownersDataSettings.onInit"
                         [columns]="ownersDataSettings.columns" [filtering]="ownersDataSettings.filtering"
                         [filterRow]="ownersDataSettings.filterRow" [pageIndex]="ownersDataSettings.pageIndex" [pageSize]= "ownersDataSettings.pageSize"
                         [selection]="ownersDataSettings.selection" [editing]="ownersDataSettings.editing"
                         (onCellEndEdit)="onItemAfterEdit($event)" (onCellClick)="onCellClick($event)" (onChange)="onChangeCheckbox($event)"
            ></smart-table>
        </div>
    </div>

    <smart-window #formWindow id="formWindow" [footerTemplate]="'footerTemplate'"  [opened]="false" [label]="'Внимание!'"  (onReady)="onWindowReady($event)">
        <div id="article">
            <section>
                <section>
                    <h3>Уверены что хотите удалить выделенных владельцев?</h3>
                    <p>При нажатии на кнопку все выделенные владельцы удаляться их нельзя будет вернуть </p>
                </section>
            </section>
        </div>
    </smart-window>
    <template id='footerTemplate'>
        <smart-button id="сancelButton">Нет</smart-button>
        <smart-button id="agreeHandler">Да</smart-button>
    </template>

    <smart-window #formWindowDeleteAll id="formWindowDeleteAll" [footerTemplate]="'footerTemplateformWindowDeleteAll'"  [opened]="false" [label]="'Внимание!'"  (onReady)="onWindowReady($event)">
        <div>
            <section>
                <section>
                    <h3>Уверены что хотите удалить выделенных владельцев?</h3>
                    <p>При нажатии на кнопку все выделенные владельцы удаляться их нельзя будет вернуть </p>
                </section>
            </section>
        </div>
    </smart-window>
    <template id='footerTemplateformWindowDeleteAll'>
        <smart-button id="сancelButtonformWindowDeleteAll">Нет</smart-button>
        <smart-button id="agreeHandlerformWindowDeleteAll">Да</smart-button>
    </template>
</div>


