<div class="subheader" [class.subheader-sidebar-not-expanded]="!isSidebarExpanded">
    <div class="subheader-breadcrumbs-wrapper">
        <h6 class="" id="breadcrumb-title">Dashboard</h6>
        <ul class="breadcrumb" id="breadcrumb-list">
            <li class="breadcrumb-item">
                <a class="text-muted">Dashboard</a>
            </li>
        </ul>
    </div>

    <div class="subheader-dropdowns-wrapper">
        <div class="dropdown" data-bs-toggle="tooltip" data-bs-placement="top" title="Quick actions"
            data-bs-custom-class="sub-header-custom-tooltip">
            <button (click)="toggleMenu($event, 'memberMenu')" id="memberButton" type="button"
                class="dropdown-button memberMenuButton" aria-haspopup="true" aria-expanded="false">
                <i class="material-icons">article</i> Добавить памятку/заметку
            </button>
            <div class="dropdown-menu memberMenu" aria-labelledby="dropdownMenuButton"
                (click)="toggleMenu($event, 'memberMenu')">
                <a class="dropdown-item" href="#">Action</a>
                <a class="dropdown-item" href="#">Another action</a>
                <a class="dropdown-item" href="#">Something else here</a>
            </div>
        </div>
        <div class="dropdown" data-bs-toggle="tooltip" data-bs-placement="top" title="Quick actions"
            data-bs-custom-class="sub-header-custom-tooltip">
            <button (click)="toggleMenu($event, 'reportMenu')" id="reportButton" type="button"
                class="dropdown-button reportMenuButton" aria-haspopup="true" aria-expanded="false">
                <i class="material-icons">article</i> Посмотреть информацию
            </button>
            <div class="dropdown-menu reportMenu" aria-labelledby="dropdownMenuButton"
                (click)="toggleMenu($event, 'reportMenu')">
                <a class="dropdown-item" href="#">Action</a>
                <a class="dropdown-item" href="#">Another action</a>
                <a class="dropdown-item" href="#">Something else here</a>
            </div>
        </div>
    </div>
</div>